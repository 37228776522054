import {
  Component,
  OnDestroy
} from '@angular/core';

@Component({
  //moduleId: module.id,
    selector: 'accessible-wrapper',
    templateUrl: 'accessible-wrapper.html',
    styleUrls: ['accessible-wrapper.scss']
})
export class AccessibleWrapper implements OnDestroy {
  //#region Constructor

  constructor() {
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnDestroy() {
  }

  //#endregion
}
