import {Injectable} from '@angular/core';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {Fields} from '@app/shared/models/fields';
import {ReportParams} from '@app/shared/models/i-analytics-page';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {PageOrientation} from '@app/shared/services/pdf/page-orientation';
import {PDF} from '@app/shared/services/pdf/pdf';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  // chart types
  private readonly DOUGHNUT = 'doughnut';
  private readonly LINE = 'line';

  // variables
  public mapData : Map<string, ChartData> = new Map();

  constructor() {
    //TODO: get later from api all current data and make better mapping
  }

  private getLabels( key: string, ids: string[] ): string[] {
    if( this.mapData.has(key) ) {
      let labels = this.mapData.get(key).getLabels(ids, key == Fields.LOCATION_COUNTRY);

      if( key == Fields.NUMBER_PLATE_TYPE ) {
        for( let i = 0; i < labels.length; i++ ) {
          const idx = labels[i].indexOf(' : ');
          if( idx != -1 ) {
            labels[i] = labels[i].substring(0, idx);
          }
        }
      }
      return labels;
    }
    return ids;
  }

  private getKeyLabelMap( key: string ) {
    if( key == Fields.LOCATION_COUNTRY ) {
      return 'Location';
    } else
    if( key == Fields.BRAND_NO ) {
      return 'Brands';
    } else
    if( key == Fields.DATASOURCE ) {
      return 'Data source';
    } else
    if( key == Fields.NUMBER_PLATE_TYPE ) {
      return 'Number plate';
    } else
    if( key == Fields.SEARCH_TYPE_ID ) {
      return 'Number type';
    } else
    if( key == Fields.VEHICLE_TYPES ) {
      return 'Vehicle type';
    } else
    if( key == Fields.GENART_NO ) {
      return 'Generic article';
    }
    return key;
  }

  private isBarChart( params: ReportParams ) {
     return params.chartType && params.chartType.toLowerCase().endsWith('bar');
  }

  /**
   *
   * @param params
   * @param canvasImage
   * @param currentChartData
   * @param chartParams
   * @param page
   * @param infoText
   *
   * returns the pageNoCount
   */
  public generatePDF(params: ReportParams, canvasImage: string,
                        currentChartData: ReportResponse,
                        chartParams: ChartRequest,
                        page: AnalyticsPage,
                        infoText?: string,
                        preRenderForPageNos?: boolean ): number {

    const isNumberPlate = page && page.filter && page.filter.showNumberPlateType;

    let pageCounts = 0;
    if( !preRenderForPageNos ) {
      pageCounts = this.generatePDF(params, canvasImage, currentChartData, chartParams, page, infoText, true);
    }

    // decide page orientation

    //console.log( this.mapData );
    // console.log( params );

    let orientation: PageOrientation = PageOrientation.LANDSCAPE;

    // if is more than 10 visible set page portrait
    if ( params.chartType.startsWith('horiz') && params.countItems > 10 ) {
      orientation = PageOrientation.PORTRAIT;
    }

    const doc = new PDF().init(orientation, params.chartLabel, params.infoText || infoText);
    doc.pageCounts = pageCounts;

    // add filter settings
    doc.addLabelAndText('Date range:', currentChartData.dateRange);
    const needLabels = [
      '' + Fields.DATASOURCE,
      '' + Fields.BRAND_NO,
      '' + Fields.SEARCH_TYPE_ID,
      '' + Fields.VEHICLE_TYPES,
      '' + Fields.LOCATION_COUNTRY,
      '' + Fields.GENART_NO ];

    for (let key of chartParams.params.keys()) {

      if (key === Fields.ARTICLE_NUMBER) {
        // 'brand_article_number' example 33 / NUMBER - in article details
        continue;
      }

      const keys: string[] = chartParams.params.get(key);

      if (!keys) {
        continue;
      }

      let values: string[] = keys;
      if (needLabels.indexOf(key) != -1) {

        if (isNumberPlate && key == Fields.SEARCH_TYPE_ID) {
          key = Fields.NUMBER_PLATE_TYPE;
        }

        const labels = this.getLabels(key, keys);

        //if (labels != keys && this.fitsToPage(labels, orientation)) {
        if (labels && labels.length) {
          values = labels;
        }
      }

      if (values) {
        doc.checkAddPage();
        let label = this.getKeyLabelMap(key);
        doc.addLabelAndTextMultiline(label, values);
      }
    }

    // calculate chart image height
    let height = this.getChartHeight( params, doc.maxY, orientation, params.countItems );

    // calculate chart image width
    let width = this.getChartWidth(params, orientation, height, doc.maxX, params.chartHeight, params.chartWidth );

    // if the width does not fit page recalc height by ratio
    if( width > doc.maxX - 5 ) {
      width = doc.maxX - 5;
      height = this.getChartHeightByRatio( params, orientation, width, doc.maxX, params.chartHeight, params.chartWidth );
    }

    let posX = params.chartType == this.DOUGHNUT ? Math.round((280 - width) / 2) : 20;
    doc.addImageCenteredHorizontal(canvasImage, width, height);

    // footer
    doc.addFooter();

    if( !preRenderForPageNos ) {
      doc.save( this.makeFileName(params) );
    }

    return doc.pageNo;
  }

  private getChartWidth( params: ReportParams, orientation: PageOrientation, height, maxX, origChartHeight, origChartWidt ) {
    var ratio = ( origChartWidt / origChartHeight );
    return height * ratio;
  }

  private getChartHeightByRatio( params: ReportParams, orientation: PageOrientation, width, maxX, origChartHeight, origChartWidt ) {
    var ratio = ( origChartWidt / origChartHeight );
    return width / ratio; // / 1.2;
  }

  private getChartHeight( params: ReportParams, maxY, orientation: PageOrientation, count ) {

    if( params.chartType == 'bar' ) {
      return 150;
    } else
    if( this.isBarChart(params) ) {
      let factor = 6;
      /*if( orientation == PageOrientation.PORTRAIT ) {
        factor = 4;
      }*/
      // 10mm per bar + for padding top and bottom legend
      return ( params.countItems * factor ) + 50;
    }
    if( params.chartType == this.LINE ) {
      return Math.min( 120, maxY - 50 );
    }
    return Math.min( 160, maxY - 50 );
  }

  private makeFileName( params: ReportParams ): string {
    let fileName = params.facetField;
    const idx = fileName.indexOf(':');
    if( idx != -1 ) {
      fileName = fileName.substring(0, idx);
    }
    return fileName + ".pdf"
  }

}
