<div class="container-fluid">
  <div class="row">
    <div class="col pl-0 pr-0" id="main-container">
      <wallpaper [wallpapers]="_wallpapers"></wallpaper>
      <main role="main" (scroll)="onScroll($event)">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 py-2">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
<portal-footer></portal-footer>
