<analytics-filter #filter></analytics-filter>

<div class="container-fluid vehicles-container">
  <div class="row make-tabs-row" [hidden]="items.length == 0">
    <div class="make-tabs-wrapper">
      <p-tabMenu #tab [model]="items" [activeItem]="selectedItem" styleClass ="ta-tabmenu-container"
                 (click)="activateMenu(tab)">
      </p-tabMenu>
    </div>
  </div>
  <div class="row row-grid">
    <div class="col-12" [hidden]="selectedItem != itemVehicles">
        <chart [tourSupported]="selectedItem == itemVehicles" #vehiclesChart [field]="fieldVehicles" [headLabel]="chartLabel" [selectable]="true" [showSearch]=true [searchTooltip]="vehicleSearchTooltip"
        [infoText]="'This graph shows the most popular vehicles for your brands (including number plate and VIN search)'"></chart>
    </div>
    <div [hidden]="!hasBrandlessFacet || selectedItem != itemVehicles" class="col-12">
        <chart [tourSupported]="false" #vehiclesBrandlessChart [field]="fieldVehiclesBradLess" [headLabel]="chartLabelMarket" [selectable]="true"
        [infoText]="'This graph shows the most popular vehicles for all brands (including number plate and VIN search)'"></chart>
    </div>


    <div class="col-12" [hidden]="selectedItem != itemMakes">
        <chart [tourSupported]="selectedItem == itemMakes" #vehicleMakesChart [field]="fieldVehicleMakes" [headLabel]="chartLabel"
        [infoText]="'This graph shows the most popular vehicle makes for your brands (including number plate and VIN search)'"></chart>
    </div>
    <div [hidden]="!hasBrandlessFacet || selectedItem != itemMakes" class="col-12">
        <chart [tourSupported]="false" #vehicleMakesBrandlessChart [field]="fieldVehicleMakesBrandLess" [headLabel]="chartLabelMarket"
        [infoText]="'This graph shows the most popular vehicle makes for all brands (including number plate and VIN search)'"></chart>
    </div>

    <div class="col-12" [hidden]="selectedItem != itemModels">
          <chart [tourSupported]="selectedItem == itemModels" #vehicleModelsChart [field]="fieldVehicleModels" [headLabel]="chartLabel"
          [infoText]="'This graph shows the most popular vehicle models for your brands (including number plate and VIN search)'"></chart>
    </div>
    <div [hidden]="!hasBrandlessFacet || selectedItem != itemModels" class="col-12">
        <chart [tourSupported]="false" #vehicleModelsBrandlessChart [field]="fieldVehicleModelsBradLess" [headLabel]="chartLabelMarket"
        [infoText]="'This graph shows the most popular vehicle models for all brands (including number plate and VIN search)'"></chart>
    </div>
  </div>
</div>
