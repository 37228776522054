import { Component } from '@angular/core';

import { SessionManagerService } from '../../../shared';

@Component({
  //moduleId: module.id,
    selector: 'page-logout',
    template: `<div></div>`,
})
export class LogoutPage {
  constructor(
    private _sessionManager: SessionManagerService) {
    this._sessionManager.logout();
  }
}