import {
  EventEmitter,
  Injectable
} from '@angular/core';

import {
  RESTfulService,
  AuthService
} from '../../core';

import {SideMenuItem, ApiService} from '../../shared';

import {Observable, BehaviorSubject} from 'rxjs';
import {Features} from '@app/shared/models/features';
import {UserRoleChecker} from '@app/shared/models/user-role-checker';
import {MenuInterface} from '@app/admin-shared/services/menu-interface';
import {DatasourceFeaturesMap} from '@app/shared/models/datasource-features-map';
import {DatasourceSettings} from '@app/shared/models/datasource-settings';

/**
 * @file menu.service.ts
 * @module app.admin
 * @summary Menu service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2016
 */
@Injectable()
export class MenuService extends RESTfulService implements MenuInterface {
  //#region Fields

  private _menu = new BehaviorSubject(null);
  private _menuObs = this._menu.asObservable();

  private menuMap: Map<string, SideMenuItem>;
  private menu: SideMenuItem[];

  private _features: Features[];
  private _selectedDatasourceIds: string[];

  //#endregion

  //#region Constructor

  constructor(private _auth: AuthService, private _api: ApiService) {
    super();
    _auth.loggedIn.subscribe(this.onLoggedIn);
    _auth.loggedOut.subscribe(this.onLoggedOut);
    _api.menuInterface = this;
  }

  private _props: any;

  private log( msg, obj? ) {
    //console.log( 'MenuService: ' + msg, obj );
  }

  private loadProperties() {
    this._api.getProps().subscribe((props) => {
      this._props = props;
      this.setMenuVisibility();

      this._propertyLoadedEmitter.emit(this._props);
    });
  }


  public async fetchPropertiesAsync() {
    if (!this._props) {
      this._props = await this._api.getProps().toPromise();
    }

    if (!this.menu) {
      this.buildMenu("analytics");
    }
  }

  public getFeaturesForDatasource(dsId: string, cartEnabled: boolean): DatasourceSettings {

    const features = DatasourceFeaturesMap.getFeaturesForDatasourcePage(dsId);

    const dss = new DatasourceSettings([]);

    if (features && features.length) {

      if (this._props && this.menu) {
        for (const m of this.menu) {
          // filter for datasource
          const feature = Features.getById(m.id);

          if (!feature || ( feature && features.indexOf(feature) == -1 ) ) {
            continue;
          }
          feature.name = m.text;

          let hidden = false;
          if (m.id === Features.ADMIN.id) {
            hidden = !this._api.isAdmin();
          } else if (m.id == Features.VEHICLE_SEARCHES.id) {
            hidden = !(this._api.isInternalUser() || this._props.features.indexOf(m.id) !== -1);
          } else if (m.id === Features.HOME.id) {
            hidden = false;
          } else {
            hidden = this._props.features.indexOf(m.id) === -1 || ( m.id === Features.SALES.id && !cartEnabled );
          }

          if (!hidden && feature && feature.datasourceDependend) {
            dss.features.push(feature);
          }
        }

      }
    }



    return dss;
  }

  private setMenuVisibility(fromFilter = false): DatasourceSettings {
    this.log('setMenuVisibility fromFilter:', fromFilter);
    this._selectedDatasourceIds = this._api.getSelectedDatasourceId();

    this.log('dsIds: ', this._selectedDatasourceIds);

    if (this._selectedDatasourceIds) {
      this._features = DatasourceFeaturesMap.getFeatures(this._selectedDatasourceIds);
    }

    this.log('features: ', this._features);

    if (this._features) {
      return this.setMenuVisibilityByAvailabeFeatures(this._features, fromFilter);
    }
    return null;
  }

  public datasourceSelected(fromFilter: boolean): DatasourceSettings {
    return this.setMenuVisibility(fromFilter);
  }

  private async allSelectedDatasourcesHasCart(): Promise<boolean> {
    const dsIds = this._api.getSelectedDatasourceId();
    let dss = await this._api.getUserDataSources(null).toPromise();

    for (const ds of dss) {
      if (dsIds.indexOf(ds.id) != -1 && !ds.cartEnabled) {
        return false;
      }
    }

    return true;
  }


  private setMenuVisibilityByAvailabeFeatures(features: Features[] = null, fromFilter = false): DatasourceSettings {

    const dss = new DatasourceSettings([], []);

    if (this._props && this.menu) {
      for (const m of this.menu) {
        // filter for datasource
        const feature = Features.getById(m.id);
        if (feature && feature.datasourceDependend && !features) {
          m.hidden = true;
          continue;
        }

        if (features && feature && features.indexOf(feature) == -1 &&
          feature.datasourceDependend) {
          m.hidden = true;
          continue;
        }

        if (m.id === Features.ADMIN.id) {
          m.hidden = !this._api.isAdmin();
        } else if (m.id == Features.VEHICLE_SEARCHES.id) {
          m.hidden = !(this._api.isInternalUser() || this._props.features.indexOf(m.id) !== -1);
        } else if (m.id === Features.HOME.id) {
          m.hidden = false;
        } else {
          m.hidden = this._props.features.indexOf(m.id) === -1;

          if( !m.hidden && m.id === Features.SALES.id) {

            this.allSelectedDatasourcesHasCart().then(result => {
              m.hidden = !result;
            })

            // m.hidden = !this.allSelectedDatasourcesHasCart();
          }
        }

        if (!m.hidden && feature && feature.datasourceDependend) {
          dss.features.push(feature);
        }

        if (!m.hidden) {
          for (const mi of m.nodes) {
            dss.allVisiblePaths.push(mi.link);
          }
        }
      }
    }

    this._api.datasourceSettings = dss;

    return dss;
  }

  private hideMenuEntries() {

  }

  //#endregion

  //#region Methods

  /**
   * Returns menu item list.
   *
   * @returns Observable<SideMenuItem[]>
   */
  getMainMenu(site): Observable<SideMenuItem[]> {
    this._menu.next(this.buildMenu(site));

    if (this._auth.isLogged) {
      if (!this._props) {
        this.loadProperties();
      }
    } else {
      this.hideMenuEntries();
      this._props = null;
    }

    return this._menuObs;
  }


  //#endregion

  //#region Events Handlers

  private onLoggedIn = () => {
    this._menu.next(this.buildMenu('analytics'));

  };

  private onLoggedOut = () => {
    this._menu.next(this.buildMenu('analytics'));
  };

  //#endregion

  //#region Helpers

  private buildMenu(site): SideMenuItem[] {
    this.menu = [
      <SideMenuItem>{
        id: Features.HOME.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Data source(s)',
        hidden: true,
        link: '/analytics',
        nodes: []
      },
      <SideMenuItem>{
        id: Features.MARKET_OVERVIEW.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Market overview',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-overview-competition',
            icon: '',
            text: 'Competition',
            link: '/analytics/market-overview-competition'
          },
          {
            id: 'menu-overview-trends',
            icon: '',
            text: 'Trends',
            link: '/analytics/market-overview-trends'
          }
        ]
      },
      <SideMenuItem>{
        id: Features.ARTICLE_VISIBILITY.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Article visibility',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-article-visibility-brands',
            icon: '',
            text: 'Brands',
            link: '/analytics/article-visibility-brands'
          },
          {
            id: 'menu-article-visibility-generic-articles',
            icon: '',
            text: 'Generic articles',
            link: '/analytics/article-visibility-genarts'
          },
          {
            id: 'menu-article-visibility-article-numbers',
            icon: '',
            text: 'Article numbers',
            link: '/analytics/article-visibility-article-numbers'
          },
          /*{
            id: 'menu-selected-oe-numbers',
            icon: '',
            text: 'OE numbers',
            link: '/analytics/article-views-oe-numbers'
          },
          {
            id: 'menu-selected-vehicles',
            icon: '',
            text: 'Vehicles',
            link: '/analytics/article-views-vehicles',
          },*/
          /*{
            id: 'menu-selected-countries',
            icon: '',
            text: 'Countries',
            link: '/analytics/article-visibility-countries',
          }*/
        ]
      },
      <SideMenuItem>{
        id: Features.MOST_SELECTED_ARTICLES.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Article views',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-selected-brands',
            icon: '',
            text: 'Brands',
            link: '/analytics/article-views-brands'
          },
          {
            id: 'menu-selected-generic-articles',
            icon: '',
            text: 'Generic articles',
            link: '/analytics/article-views-genarts'
          },
          {
            id: 'menu-selected-article-numbers',
            icon: '',
            text: 'Article numbers',
            link: '/analytics/article-views-article-numbers'
          },
          {
            id: 'menu-selected-oe-numbers',
            icon: '',
            text: 'OE numbers',
            link: '/analytics/article-views-oe-numbers'
          },
          {
            id: 'menu-selected-vehicles',
            icon: '',
            text: 'Vehicles',
            link: '/analytics/article-views-vehicles',
          },
          {
            id: 'menu-selected-countries',
            icon: '',
            text: 'Countries',
            link: '/analytics/article-views-countries',
          }
        ]
      },
      <SideMenuItem>{
        id: Features.ORDER_TRENDS.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Order Trends',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-generic-article-order-trends',
            icon: '',
            text: 'Generic articles',
            link: '/analytics/generic-article-order-trends'
          },
          {
            id: 'menu-country-order-trends',
            icon: '',
            text: 'Countries',
            link: '/analytics/country-order-trends'
          }
        ]
      },
      <SideMenuItem>{
        id: Features.MOST_SEARCHED_NUMBERS.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Most searched',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-searched-numbers',
            icon: '',
            text: 'Numbers',
            link: '/analytics/most-searched-numbers',
          },
          {
            id: 'menu-searched-vehicles',
            icon: '',
            text: 'Vehicles',
            link: '/analytics/most-searched-vehicles',
          },
          {
            id: 'menu-searched-countries',
            icon: '',
            text: 'Countries',
            link: '/analytics/most-searched-countries',
          },
          {
            id: 'menu-searched-oe-number',
            icon: '',
            text: 'OE Numbers',
            link: '/analytics/most-searched-oe-numbers',
          }
        ]
      },
      <SideMenuItem>{
        id: Features.SALES.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Sales',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-selected-brands',
            icon: '',
            text: 'Brands',
            link: '/analytics/sales-brands'
          },
          {
            id: 'menu-selected-generic-articles',
            icon: '',
            text: 'Generic articles',
            link: '/analytics/sales-genarts'
          },
          {
            id: 'menu-selected-article-numbers',
            icon: '',
            text: 'Article numbers',
            link: '/analytics/sales-article-numbers'
          },
          {
            id: 'menu-selected-countries',
            icon: '',
            text: 'Countries',
            link: '/analytics/sales-countries'
          },
        ]
      },
      <SideMenuItem>{
        id: Features.VEHICLE_SEARCHES.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Vehicle searches',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-searched-vin-numbers',
            icon: '',
            text: 'Vin numbers',
            link: '/analytics/most-searched-vin-numbers',
          },
          {
            id: 'menu-searched-number-plates',
            icon: '',
            text: 'Number plates',
            link: '/analytics/most-searched-number-plates',
          }
        ]
      },
      <SideMenuItem>{
        id: Features.FULL_EXPORT.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Full export',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-full-export-list',
            icon: '',
            text: 'Listing',
            link: '/analytics/full-export'
          }]
      },
      <SideMenuItem>{
        id: Features.RMI_DATA.id,
        icon: 'fa-fw fa-info-circle',
        text: 'RMI Data',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'menu-rmi-overview',
            icon: '',
            text: 'Modules overview',
            link: '/analytics/rmi-overview'
          }]
      },
      <SideMenuItem>{
        id: Features.ADMIN.id,
        icon: 'fa-fw fa-info-circle',
        text: 'Admin',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'user-list',
            icon: '',
            text: 'Users',
            link: '/manage/users'
          },
          {
            id: 'menu-brand-filters',
            icon: '',
            text: 'Brand filters',
            link: '/manage/brand-filters'
          },
          {
            id: 'menu-solr-core-timelines',
            icon: '',
            text: 'Datasources timeline',
            link: '/analytics/monitoring/solr'
          }
        ]
      },
      <SideMenuItem>{
        id: Features.BASIC_USER_ACTIVITY.id,
        icon: 'fa-fw fa-info-circle',
        text: 'User activity',
        hidden: true,
        link: '',
        nodes: <SideMenuItem[]>[
          {
            id: 'user-stats',
            icon: '',
            text: 'Traffic',
            link: '/analytics/user-stats'
          },
          {
            id: 'menu-searched-visitors',
            icon: '',
            text: 'Visitors',
            link: '/analytics/user-stats-premium',
          }
        ]
      }];

    this.menuMap = new Map();

    this.menu.forEach(item => {
      item.state = 'collapsed';
      this.menuMap.set(item.id, item);
    });

    this.setMenuVisibility();

    return this.menu;
  }

  private _propertyLoadedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public propertyLoadedEmitter(): EventEmitter<any> {
    return this._propertyLoadedEmitter;
  }

  initialized(): Boolean {
    return !!(this.menu && this._props);
  }

}
