import { Component, OnInit} from '@angular/core';
import { ApiService } from '@app/shared';
import {ManagerPage, MenuService} from '@app/admin-shared';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FullExportListingResponse } from '@app/shared/models/full-export-listing-response';
import { FullExportItem } from '@app/shared/models/full-export-item';
import { DownloadInfo } from '@app/shared/models/download-info';
import { FullExportFormatInfo } from '@app/shared/models/full-export-format-info';
import { saveAs } from "file-saver";
import {HttpEvent, HttpEventType} from '@angular/common/http';
import { DownloadStatus } from "@app/shared/models/download-status";
import {DownloadHandler} from '@app/shared/models/download-handler';
import {Features} from '@app/shared/models/features';
import {Router} from '@angular/router';

@Component({
  selector: 'full-export-list',
  templateUrl: './full-export-list.component.html',
  styleUrls: ['./full-export-list.component.scss']
})
export class FullExportListComponent extends ManagerPage<any> implements OnInit {

  private months = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
  };

  public years: Array<number>;

  public listings: FullExportItem[];
  public downloads: DownloadInfo[] = [];
  public formatInfo: FullExportFormatInfo[];

  public refresh(): void {

  }

  pageName: string = 'FullExport Listing';
  public hasChanges(): boolean {
    return false;
  }

  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected router: Router
               ) {
      super(appInsights, confirm, translate, navigation, apiService, router);
  }

  ngOnInit() {
    this.initDownloadHandlers();
    this.apiService.getFullExportListing( 'full-export' ).subscribe(
      (data: FullExportListingResponse) => {

        this.downloads = data.downloads;
        this.listings = data.listing;

        const formatInfo = [];

        if( data.listing ) {
          const yearsSet: Set<number> = new Set();
          const formatIds: Set<string> = new Set();
          for( const itm of data.listing ) {
            yearsSet.add(itm.year);
            formatIds.add(itm.viewId);
          }
          if( data.formatInfo ) {
            for( const v of data.formatInfo ) {
              if( formatIds.has(v.id) ) {
                formatInfo.push(v);
              }
            }
            formatInfo.sort((a, b) => a.id.localeCompare(b.id));
          }
          let yearsArray = Array.from(yearsSet);
          yearsArray.sort((a, b) => b - a);
          this.years = yearsArray;
        }
        this.formatInfo = formatInfo;

        this.addDownloadHandlers();
      }
    );
  }

  public getMonth( month ) {
    return this.months[month];
  }

  public getDownloadLinks( year, month ): FullExportItem[] {
    let r = [];
    for( const mi of this.listings ) {
      if( mi.year == year && mi.month == month ) {
        r.push(mi);
        if (!this.downloadHandlers.has(mi.eTag)) {
          this.downloadHandlers.set(mi.eTag, new DownloadHandler(true));
        }
      }
    }
    r.sort((a,b) => a.viewId.localeCompare(b.viewId));
    return r;
  }

  public getDownloadLink(dl: DownloadInfo) {
    return this.apiService.apiUrl('/download/byId/' + dl.id);
  }

  public getLink(mi: FullExportItem) {
    return this.apiService.apiUrl('/rawexport/compose/' + mi.eTag);
  }

  public getLink12Month() {
    return this.apiService.apiUrl('/rawexport/compose/12month');
  }

  DownloadStatusValues = DownloadStatus;
  public downloadHandlers: Map<string, DownloadHandler>;
  public readonly DOWNLOAD_12_MONTH_ID = '12months';

  private addDownloadHandlers() {
    if(!this.downloads){
      return;
    }
    for (let download of this.downloads) {
      this.downloadHandlers.set(download.id, new DownloadHandler(true));
    }
  }

  private initDownloadHandlers() {
    this.downloadHandlers = new Map<string, DownloadHandler>();
    this.downloadHandlers.set(this.DOWNLOAD_12_MONTH_ID, new DownloadHandler(true));
  }

  public downloadExportedFile(link: string, statusId: string) {
    let downloadHandler = this.downloadHandlers.get(statusId);
    this.apiService.download(link, downloadHandler);
  }

  protected isAuthorizationRequiredPage(): boolean {
    return true;
  }

  protected getFeatureOnMenu() {
    return Features.FULL_EXPORT;
  }

}

