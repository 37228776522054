<div class="timeselect">
    <div class="timeselect-input-container" [hidden]="calendarView">
        <p-dropdown #timespanSelect [options]="options" [(ngModel)]="selectedTimespan" optionLabel="name"
                styleClass="timespan-selector"></p-dropdown>
    </div>
    <div class="timeselect-input-container" [hidden]="!calendarView">
        <p-calendar #calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"
                        [showOnFocus]="true"
                            inputStyleClass="w-100" styleClass="w-100" [numberOfMonths]="2"
                              [maxDate]="maxDate" dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="timeselect-change-container">
        <p-toggleButton tooltipPosition="top"  [pTooltip]="calendarView?'Switch to simple selection':'Switch to detail selection'" [onIcon]="'fa ta-icon-calendar'" [offIcon]="'fa ta-icon-calendar'"
        [onLabel]="''" [offLabel]="''" [(ngModel)]="calendarView"></p-toggleButton>
    </div>
</div>
