
<div class="d-flex flex-column ds">
  <div class="header">
    <!-- span class="fa ta-icon-master-data float-left icon" aria-hidden="true"></span -->
    <span class="float-left label">Custom selection</span>
  </div>
  <div class="p-2 body">

    <div class="trees" *ngFor="let gr of datasourceTree">
      <p-tree [value]="gr.children" selectionMode="checkbox" [(selection)]="selectedDatasources"
            [style]="{ width: '100%' }" class="tree" (onNodeSelect)="onNodeSelect(gr.children)"></p-tree>
    </div>

  </div>
  <div class="footer">
      <button class="btn-start float-right" (click)="onSelected()" [disabled]="!selectedDatasources || !selectedDatasources.length">Select</button>
  </div>
</div>
