<analytics-filter #filter [showGenericArticleFilter]=false [showBrandFilter]=false [showCountryFilter]=false [showDatasourceFilter]=false></analytics-filter>

<div class="row">
    <ng-template ngFor let-coreTimeLines [ngForOf]="allCoreTimeLines">
        <div class="col-sm-12">
        <div class="row">
            <div class="col-12">
                <p-chart
                    type="line" title="From"
                    [data]="coreTimeLines"
                    [responsive]="true" [options]="chartOptions"
                    [height]="300">
                </p-chart>
            </div>
        </div>
        </div>
    </ng-template>
</div>
