import { Injectable } from '@angular/core';

import {
    CanActivateChild, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { OAuth2Service, AuthService } from '../../core';

@Injectable()
export class LoginGuard implements CanActivateChild {

    constructor(
        private _auth: AuthService,
        private _router: Router) {
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this._auth.isLogged) {
            return true;
        }

        this._router.navigate(['/portal/apps']);

        return false;
    }
}