import {Component, EventEmitter, Output} from '@angular/core';
import {ChartActor} from '@app/shared/components/chart-actor';
import {Settings} from '@app/shared/models/settings/settings';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {FacetItem} from '@app/shared/models/facet-item';
import {ChartRequest} from '@app/shared/models/chart-request';

@Component({
  selector: 'weekday-filter',
  templateUrl: './weekday-filter.component.html',
  styleUrls: ['./weekday-filter.component.scss']
})
export class WeekdayFilterComponent extends ChartActor implements Settings {
  key_s: string = 'weekdays';
  _selectedWeekdays: string[];
  _lastSelectedWeekdays: string[];
  readonly _all: string[] = ["-1", "2", "3", "4", "5", "6", "7", "1"];
  @Output() public onWeekdaysChange = new EventEmitter();

  protected getSettings(): Settings {
    const weekdaysFilterSettingsImpl = new WeekdaysFilterSettingsImpl();
    this.copySettings(weekdaysFilterSettingsImpl, this);
    return weekdaysFilterSettingsImpl;
  }

  ngOnInit() {
    this._selectedWeekdays = ["2","3","4","5","6"];
    this.updateChartRequest();
    this.saveSettings();
  }

  protected setData(d: ChartData, changed: boolean) {
  }

  protected settingsLoaded(o: Settings): void {
  }

  isSelectionChanged(): boolean {
    if (typeof this._lastSelectedWeekdays === 'undefined') {
      return typeof this._selectedWeekdays !== 'undefined';
    }
    if (typeof this._selectedWeekdays === 'undefined') {
      return typeof this._lastSelectedWeekdays !== 'undefined';
    }
    const mergeSelection = [...this._selectedWeekdays, ...this._lastSelectedWeekdays];
    for (let i = 0; i < mergeSelection.length; i++) {
      const val = mergeSelection[i];
      if (this._selectedWeekdays.includes(val) != this._lastSelectedWeekdays.includes(val)) {
        return true;
      }
    }
    return false;
  }

  protected standardizeSelection($status){
    //Check
    if ($status.checked) {
      if (this._selectedWeekdays.includes("-1") || this._selectedWeekdays.length === 7) {
        this._selectedWeekdays = this._all;
      }
      return;
    }
    //Uncheck
    if (this._selectedWeekdays.includes("-1")) {
      let indexOfAll = this._selectedWeekdays.indexOf("-1");
      this._selectedWeekdays.splice(indexOfAll, 1);
    } else if (this._selectedWeekdays.length === 7) {
      this._selectedWeekdays = [];
    }
  }

  changeSelectedWeekdays($status) {
    this.standardizeSelection($status);
    this.updateChartRequest();
    this.saveSettings();
    this.onWeekdaysChange.emit();
  }

  private updateChartRequest() {
    const chartRequest: ChartRequest = this.page.getChartParams();
    chartRequest.weekdays = [];
    for (const day of this._selectedWeekdays) {
      chartRequest.weekdays.push(day);
    }
  }
}

export class WeekdaysFilterSettingsImpl implements Settings {
  key_s: string = '';
}
