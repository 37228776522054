import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// angular 4
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//import { QRCodeModule } from 'angular2-qrcode';

import {CheckboxModule} from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';

import { CaptchaModule } from 'primeng/captcha';

//Growl is deprecated since v6, use Toast instead.
// import { GrowlModule } from 'primeng/growl';


import { TooltipExtendedModule } from '../primeng/components/tooltip/tooltip';
import { SharedModule } from '../shared/shared.module';

import { AccessibleRoutingModule } from './accessible.routing.module';

import { AccessibleShell } from './shells/accessible-shell/accessible-shell';

import { LoginGuard } from './guards/login-guard';

import {
  AccessibleWrapper,
  LoginPage,
  LoginFormComponent,
  ForgotPasswordPage,
  ForgotPasswordFormComponent
} from '.';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AccessibleRoutingModule,
    CheckboxModule,
    CaptchaModule,
    TooltipExtendedModule,
    SharedModule,
    TooltipModule
  ],
  declarations: [
    AccessibleShell,
    AccessibleWrapper,

    LoginPage,
    LoginFormComponent,    
    ForgotPasswordPage,
    ForgotPasswordFormComponent
  ],
  providers: [LoginGuard]
})
export class AccessibleModule {
}
