import { FoundByChartParams } from "./found-by-chart-params";
import { ReportResponse } from "./analytics/report-response";
import { Fields } from "./fields";
import { ApiService } from "../services/api/api.service";
import { PageRequest } from "./page-request";
import { FacetItem } from "./facet-item";

export class FoundBy {

    private static readonly FOUND_BY_ARTICLE_SEARCH = 'found_by_article_search';


    isBrandLess = false;

    chartsComponentParams: FoundByChartParams[];

    private report: ReportResponse;
    private charts: FoundByChartParams[] = [];

    constructor( private type: string ) {
        this.chartsComponentParams = this.getParams( type );
    }

    private findFirstBrandLess(): number {
        for( let i = 0; i < this.chartsComponentParams.length; i++ ) {
            if( this.chartsComponentParams[i].field.indexOf(Fields.BRANDLESS) != -1 ) {
                return i;
            }
        }
        return -1;
    }

    init(charts: FoundByChartParams[], data: ReportResponse, fieldName?: string) {
        this.isBrandLess = fieldName && fieldName.indexOf(Fields.BRANDLESS) != -1;
        this.report = data;
        this.charts = charts;

        this.initMultiNumberMatches(data);
        this.addFirstField();
    }

    public getParams(type: string): FoundByChartParams[] {

        if (type == 'a') {
            return [
                {
                    label: 'Found by ...',
                    field: 'found_by_article'
                },
                {
                    labelTemplate: 'Found by article number type',
                    label: 'Found by article number type',
                    field: FoundBy.FOUND_BY_ARTICLE_SEARCH
                },
                {
                    label: 'Found by vehicle selection',
                    field: 'found_by_vehicle'
                },
                {
                    label: 'Found by number plate',
                    field: 'found_by_number_plate'
                },
                {
                    label: 'Found by ...',
                    field: 'found_by_article' + Fields.BRANDLESS
                },
                {
                    labelTemplate: 'Found by article number type',
                    label: 'Found by article number type',
                    field: FoundBy.FOUND_BY_ARTICLE_SEARCH + Fields.BRANDLESS
                },
                {
                    label: 'Found by vehicle selection',
                    field: 'found_by_vehicle' + Fields.BRANDLESS
                },
                {
                    label: 'Found by number plate',
                    field: 'found_by_number_plate' + Fields.BRANDLESS
                }
            ];
        } else
            if (type == 'v') {
                return [
                    {
                        label: 'Found by ...',
                        field: 'found_by_vehicle'
                    },
                    {
                        label: 'Found by number plate',
                        field: 'found_by_number_plate'
                    },
                    {
                        label: 'Found by ...',
                        field: 'found_by_vehicle' + Fields.BRANDLESS
                    },
                    {
                        label: 'Found by number plate',
                        field: 'found_by_number_plate' + Fields.BRANDLESS
                    }
                ];
            }
    }

    public initMultiNumberMatches(data: ReportResponse) {

        let multiNumberMatches = null;
        const foundBy = this.isBrandLess ? Fields.FOUND_BY_BRANDLESS : Fields.FOUND_BY;
        const field = FoundBy.FOUND_BY_ARTICLE_SEARCH + (this.isBrandLess ? Fields.BRANDLESS : '');

        if (data.facets.has(foundBy)) {
            for (const f of data.facets.get(foundBy).originalData) {
                if (f.id == 'multi_number_match' || f.id == 'mm') {
                    multiNumberMatches = ' with ' + f.count + ' multi match(es)';
                    break;
                }
            }

            if (multiNumberMatches) {
                for (const ct of this.chartsComponentParams) {
                    if (ct.field == field) {
                        ct.label = ct.labelTemplate;
                        ct.info = ct.labelTemplate + multiNumberMatches;
                        break;
                    }
                }
            }
        }
    }

    addFirstField() {
        this.addField(this.chartsComponentParams[this.isBrandLess ? this.findFirstBrandLess() : 0].field);
    }

    addField(field: string): boolean {
        for (const ct of this.chartsComponentParams) {
            if (ct.field == field) {
                this.charts.push(ct);
                return true;
            }
        }
        return false;
    }


    removeAfter(field: string) {
        let after = false;
        for (const ct of this.charts) {
            if (ct.field == field) {
                after = true;
            } else
                if (after && this.report.facets.has(ct.field)) {
                    this.report.facets.get(ct.field).selected = null;
                }
        }
        for (const ct of this.charts) {
            if (ct.field == field) {
                this.charts = this.charts.slice(0, this.charts.indexOf(ct) + 1);
                return;
            }
        }
    }

    toChildrenKey(item) {
        return 'found_by_' + item.id + (this.isBrandLess ? Fields.BRANDLESS : '');
    }

    itemSelected(item: FacetItem, fieldName?: string): FoundByChartParams[] {
        const keyChildren = this.toChildrenKey(item);
        this.removeAfter(fieldName);

        this.report.facets.get(fieldName).selected = item;
        this.addField(keyChildren);

        return this.charts;
    }
}
