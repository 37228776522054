<div class="row">
  <div class="col-lg-6 col-md-12 py-4">
    <div class="card">
      <div class="card-header">
        <span>General Informations</span>
      </div>
      <div class="card-body">
        <div class="row" [hidden]="!isVehicleName">
            <div class="col-sm-8">
                <div class="row">
                    <div class="col-sm-5">
                      Vehicle Description:
                    </div>
                    <div class="col-sm-7">
                      {{pageRequest.vehicleId}}
                    </div>
                  </div>
            </div>
        </div>
        <div class="row" [hidden]="isVehicleName">
          <div class="col-sm-8">
              <div class="row">
                  <div class="col-sm-5">
                    Make:
                  </div>
                  <div class="col-sm-7">
                    {{vehicle.make}}
                  </div>
                </div>
            <div class="row">
              <div class="col-sm-5">
                Model:
              </div>
              <div class="col-sm-7">
                {{vehicle.defaultModelName}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Type:
              </div>
              <div class="col-sm-7">
                {{vehicle.defaultTypeName}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Motorcodes:
              </div>
              <div class="col-sm-7">
                {{vehicle.MCode}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-3 col-md-12 col-sm-12 py-4 chart" *ngFor="let chart of charts">
    <chart [field]="chart.field"
           [headLabel]="chart.label"
           [chartType]="'doughnut'"
           [selectable]=true
           [pageInstance]="this"
           [diagrammFixedLabel]="' '">
    </chart>
  </div>
</div>
