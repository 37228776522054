<div [hidden]="isEditing" class="container-fluid rendered-quill-content ql-editor">
  <div [innerHTML]="releaseNotes.content | safeHTML" (dblclick)="onTextDblClick()">
  </div>
</div>

<!--p-dialog #editDialog [(visible)]="isEditing" [closable]="true"
          (onShow)="onEditorShow()" [minWidth]="920" [minHeight]="520" [resizable]="false" -->
  <div [hidden]="!isEditing" class="container-fluid">
    <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
    <div class="col">
      <button pButton type="button" icon="fa fa-times" class="ui-button-secondary" (click)="isEditing = false" pTooltip="Close editor / Cancel"></button>
      <button pButton type="button" label="Save" class="ml-2" (click)="saveContent( true )"></button>
      <button pButton type="button" label="Save and close" class="ml-2" (click)="saveContent( false )"></button>
      <button pButton type="button" icon="fa fa-undo" class="ui-button-secondary ml-2" (click)="undo()" pTooltip="Undo"></button>
      <button pButton type="button" icon="fa fa-repeat" class="ui-button-secondary ml-2" (click)="redo()" pTooltip="Redo"></button>
      <button pButton type="button" icon="fa fa-refresh" class="ui-button-secondary ml-2" (click)="loadReleaseNotes()" pTooltip="Reload from Server"></button>
      <p-toggleButton [(ngModel)]="showAllButtons" class="ml-2" onLabel="" offLabel="" onIcon="fa fa-th" offIcon="fa fa-th-large" pTooltip="Toggle button bar"></p-toggleButton>

      <div class="row pull-right mr-1">
        Version/Build: <input pInputText type="text" [(ngModel)]="releaseNotes.versionInfo.version" pTooltip="user will see bubble on footer version if you change this" class="ml-2 mr-2" />
        /<p-spinner [(ngModel)]="releaseNotes.versionInfo.build" class="ml-2" pTooltip="user will see bubble on footer version if you change this"></p-spinner>
      </div>
    </div>
    <div class="col py-2">
      <p-editor [hidden]="showAllButtons"
                #editorComponentMin [(ngModel)]="editContent"
                (keyup)="onEditorKeyUp($event)"
                [style]="{'height':'70vh', 'font-size': '16px'}"
                [modules]="editor_modules_min">
        <p-header>
        </p-header>
      </p-editor>
      <p-editor [hidden]="!showAllButtons"
                #editorComponentAll [(ngModel)]="editContent2"
                (keyup)="onEditorKeyUp($event)"
                [style]="{'height':'70vh', 'font-size': '16px'}"
                [modules]="editor_modules_all">
        <p-header>
        </p-header>
      </p-editor>
    </div>
  </div>
<!-- /p-dialog -->
