import { OnInit, OnDestroy, ViewChild, AfterViewInit, Directive } from '@angular/core';

import { ConfirmationService, MenuItem } from 'primeng/api';

import { TrackerService, NavigationService } from '../../core';
import { AdminWrapper } from '../shells/admin-wrapper/admin-wrapper';

import {ApiService, PageComponent} from '../../shared';
import { TranslateService } from '@ngx-translate/core';
import { PAGE } from '@routes/index';
import {Router} from '@angular/router';

export interface IMasterManager {
  refresh(): void;
}

export interface IDetailManager {
  isDirty(): boolean;
  save(): void;
  delete(): void;
  reset(): void;
}

/**
 * Base entity manager component.
 */
@Directive()
export abstract class ManagerPage<TRowData> extends PageComponent
  implements AfterViewInit, OnInit, OnDestroy {
  //#region Fields

  protected _rowData: TRowData;
  private _breadcrumb: MenuItem[];

  //#endregion

  //#region Queries

  @ViewChild(AdminWrapper) protected _wrapper: AdminWrapper;

  public get showDetail(): boolean {
    return this._wrapper && this._wrapper.showDetail;
  }

  //#endregion

  //#region Constructor

  constructor(
    appInsights: TrackerService,
    confirmation: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected router: Router
  ) {
    super(appInsights, confirmation, apiService, router);
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    if (this._wrapper) {
      this._wrapper.showDetail = false;
    }
  }

  //#endregion

  //#region Abstract Methods

  /**
   * Refresh data.
   */
  public abstract refresh(): void;

  //#endregion

  //#region Protected Methods

  protected openDetail(data: TRowData): void {
    this._rowData = data;
    this._wrapper.showDetail = data !== null && typeof data !== 'undefined';
  }

  protected closeDetail() {
    this._rowData = null;
    this._wrapper.showDetail = false;
  }

  protected showLoading() {
    this._wrapper.loading = true;
  }

  protected hideLoading() {
    this._wrapper.loading = false;
  }

  protected showDetailLoading() {
    this._wrapper.loadingDetail = true;
  }

  protected hideDetailLoading() {
    this._wrapper.loadingDetail = false;
  }

  /**
     * Gets the navigation menu items.
     *
     * @type {MenuItem[]}
     * @memberof ManagerPage
     * @public
     */
    get breadcrumb(): MenuItem[] {
      return this._breadcrumb;
    }

    /**
     * Sest the navigation menu items.
     *
     * @type {MenuItem[]}
     * @memberof ManagerPage
     * @public
     */
    set breadcrumb(value: MenuItem[]) {
      this._breadcrumb = value;
    }

  /**
   * Creates the breadcrumb.
   */
  protected createBreadcrumb(): void {
    const breadcrumb = [
      <MenuItem>{
        label: this.translate.instant('HOME_PAGE.NAME'),
        url: '/home',
        command: (event: any) => {
          if (event && event.originalEvent) {
            event.originalEvent.preventDefault();
            event.originalEvent.stopPropagation();
          }

          this.navigation.go(PAGE.home);
        }
      },
    ];

    this.navigation.history.forEach((item, index) => {
      breadcrumb.push(<MenuItem>{
        label: item.label,
        url: this.navigation.getHistoryUrl(item.page.id),
        command: (event: any) => {
          if (event && event.originalEvent) {
            event.originalEvent.preventDefault();
            event.originalEvent.stopPropagation();
          }
          this.navigation.rollbackAt(index);
        }
      });
    });

    this.breadcrumb = breadcrumb;
  }

  //#endregion
}
