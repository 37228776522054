export enum InfoTexts {
  VEHICLE_INFO = 'This graph shows the most popular vehicles for your brands (including number plate and VIN search)',
  MOST_SEARCHED_VEHICLES = 'This graph shows the most searched / selected vehicles',
  ARTICLE_VISIBILITY_BRANDS = 'This graph shows the most viewed brands in the search results page',
  ARTICLE_VISIBILITY_GENARTS = 'This graph shows the most viewed generic articles in the search results page',
  ARTICLE_VISIBILITY_ARTNR = 'This graph shows the most viewed article numbers in the search results page',
  BRANDS_INFO = 'This graph shows how often users have selected articles from your brands',
  GENART_INFO = 'This graph shows the distribution of generic articles when users have selected articles from your brands',
  ARTICLE_NUMBER_INFO = 'This graph shows how often users have selected specific article numbers from your brands',
  ARTICLE_SEARCH_INFO = 'This graph shows most searched numbers',
  COUNTRIES_SEARCH_INFO = 'This graph shows most searched countries',
  OE_MOST_SEARCH_INFO = 'This graph shows most searched OE numbers',
  VIN_SEARCH_INFO = 'This graph shows most searched vin numbers',
  NUMBER_PLATE_SEARCH_INFO = 'This graph shows most searched number plates',
  OE_NUMBER_INFO = 'This graph shows the most selected articles from your brands by OE number. The OE number is matched either by any number search or direct OE number search',
  USER_STATS_INFO = 'This graphs shows the basic user activity. Note that some user activity might be invisible due to adblockers!',
  NO_DATA_AVAILABLE_FOR_PAGE = ' - NO DATA AVAILABLE FOR THIS PAGE AND SELECTED DATASOURCE - ',
  MOST_SEARCHED_COUNTRIES = 'This graph shows in which countries the users were selecting articles from your brands',
  RMI_OVERVIEW = 'This graph shows you how often which RMI Module is called',
  SOLR_CORES_TIME_LINE = 'This graphs shows timeline per datasource',
  MOST_SEARCHED_OE_NUMBERS = 'This graph shows the most popular OE Numbers'
}
