import {Component, ElementRef, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {OverlayComponent} from '@app/shared/components/multi-select/overlay-component';
import {TreeNode, TreeModule, Tree} from 'primeng';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {UtilsService} from '@app/core';
import {FacetItem} from '@app/shared/models/facet-item';

@Component({
  selector: 'datasource-selector',
  templateUrl: './datasource-selector.component.html',
  styleUrls: ['./datasource-selector.component.scss']
})
export class DatasourceSelectorComponent implements OverlayComponent, OnInit {

  private selectedIds_ss: string[];
  private facetIdMap = {};
  private treeIdMap = {};

  setData(d: ChartData, changed: boolean) {
    this.datasourceTree = UtilsService.createDatasourceTreeByGroups(d.originalData);

    this.facetIdMap = {};
    for(const fa of d.originalData) {
      this.facetIdMap[fa.id] = fa;
    }

    this.treeIdMap = {};
    for( const tng of this.datasourceTree ) {
      for( const tn of tng.children ) {
        this.treeIdMap[tn.data] = tn;
        if (tn.children) {
          for (const tnc of tn.children) {
            this.treeIdMap[tnc.data] = tnc;
          }
        }
      }
    }

    if( this.selectedIds_ss && this.selectedIds_ss.length ) {
      this.setSelectedIds(this.selectedIds_ss);
    }
  }

  constructor() { }

  @ViewChild('rootElement', {static: true}) rootElement: ElementRef<HTMLDivElement>;
  private eventEmitterSelection: EventEmitter<FacetItem[]> = new EventEmitter<FacetItem[]>();

  public datasourceTree: TreeNode[];

  private _selectedDatasources: TreeNode[] = [];

  private getSelectedIds() {
    const r = [];
    for( const tn of this.selectedDatasources ) {
      r.push( this.facetIdMap[tn.data] );
    }
    return r;
  }

  public set selectedDatasources ( v : TreeNode[] ) {
    this._selectedDatasources = v;
  }

  public get selectedDatasources (): TreeNode[] {
    return this._selectedDatasources;
  }


  ngOnInit() {
  }

  getRootElement(): ElementRef<HTMLDivElement> {
    return this.rootElement;
  }

  onNodeSelect(event: any, children: TreeNode[], select: boolean) {
    const evt = event.originalEvent;
    const x = evt.layerX;

    const node = event.node;
    const id = node.data;


    if( node.children && node.children.length && x < 60 && select) {
      this._selectedDatasources = [node];
    }

    window.setTimeout(() => {
      const newSelected = [];
      for(const tn of this._selectedDatasources) {
        if( children.indexOf(tn) != -1 || children.indexOf(tn.parent) != -1 ) {
          newSelected.push( tn );
        }
      }
      this._selectedDatasources = newSelected;

      this.eventEmitterSelection.emit(this.getSelectedIds());

    }, 100);
  }

  getSelectionEventEmmiter(): EventEmitter<FacetItem[]> {
    return this.eventEmitterSelection;
  }

  setSelectedIds(selectedIds_ss: string[]): void {
    if( selectedIds_ss ) {

      this.selectedIds_ss = selectedIds_ss;

      const newSelected = [];
      for (const id of selectedIds_ss) {
        if (this.treeIdMap[id]) {
          newSelected.push(this.treeIdMap[id]);
        }
      }
      this._selectedDatasources = newSelected;
    }
  }
}
