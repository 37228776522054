import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    // HttpClient works with inmutable requests.

    /* TODO:
     *
    if (!this._auth) {
      switch (this._config.authType) {
        case 'jwt':
          this._auth = this._injector.get(JWTAuthService);
          break;

        case 'oauth2':
        default:
          this._auth = this._injector.get(OAuth2Service);
          break;
      }
    }

    if (this._auth.token) {
      switch (this._auth.type) {
        case 'jwt':
        case 'oauth2':
          const authReq = req.clone({ headers: req.headers.set('Authorization', ['Bearer', this._auth.token].join(' ')) });

          return concat<HttpEvent<any>>(
              this.tryRefreshToken(req),
              next.handle(authReq)
          );

        default:
          throw Error('Authentication method unknown');
      }
    }
    */
    return next.handle(req);
  }
}
