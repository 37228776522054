<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
  <div class="col-12">
    <chart #genartChart [field]="fieldGenartNo" [headLabel]="genartChartHeadLabel"
           [showTimeLineButton]=true [showTopDropdown]=false
           [showAggregationTimeSpan]=true
           [timeLineDatasetLimit]="5"
           [stackedBar]=true
           yAxesTitle="Article Views"
           [infoText]="'This graph shows the distribution of generic articles when users have selected articles from your brands'"></chart>
  </div>
</div>

<div class="row row-grid">
  <div class="col-12">
    <chart [tourSupported]="false" [chartType]="'bar'" #countriesChart [field]="fieldCountries" [headLabel]="countriesChartHeadLabel"
           [showTopDropdown]=false
           [showSearch]="false" [showTimeLineButton]=true
           [stackedBar]=true
           [showAggregationTimeSpan]=true
           [timeLineDatasetLimit]="5"
           yAxesTitle="Article Views"
           [infoText]="'This graph shows in which countries the users were selecting articles from your brands'"></chart>
  </div>
</div>
