import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputMaskModule } from 'primeng/inputmask';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ColorPickerModule } from 'primeng/colorpicker';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';

import { TooltipExtendedModule } from '../primeng/components/tooltip/tooltip';
import { SharedModule } from '../shared/shared.module';

import { AdminShell } from './shells/admin-shell/admin-shell';

import { AuthGuard } from './guards/auth-guard';
import { ConfirmDeactivate } from './guards/confirm-deactivate';

import {
  // Shells
  AdminWrapper,
  AdminMainTitle,
  AdminMainActions,
  AdminMain,
  AdminDetailTitle,
  AdminDetailActions,
  AdminDetail,

  // Services
  MenuService,
} from '.';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ConfirmDialogModule,
        ColorPickerModule,
        TabViewModule,
        AccordionModule,
        CheckboxModule,
        InputSwitchModule,
        InputMaskModule,
        RadioButtonModule,
        CalendarModule,
        SharedModule.forRoot(),
        TooltipExtendedModule
    ],
    declarations: [
        // Shells
        AdminShell,
        AdminWrapper,
        AdminMainTitle,
        AdminMainActions,
        AdminMain,
        AdminDetailTitle,
        AdminDetailActions,
        AdminDetail
    ],
    providers: [],
    exports: [
        // Modules --------------
        // Angular modules
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // Primeng modules
        ConfirmDialogModule,
        ColorPickerModule,
        TabViewModule,
        AccordionModule,
        CheckboxModule,
        InputSwitchModule,
        InputMaskModule,
        RadioButtonModule,
        CalendarModule,
        SharedModule,
        // App modules
        TooltipExtendedModule,
        // Components -----------
        // Shells
        AdminShell,
        AdminWrapper,
        AdminMainTitle,
        AdminMainActions,
        AdminMain,
        AdminDetailTitle,
        AdminDetailActions,
        AdminDetail
    ]
})
export class AdminSharedModule {
  static forRoot(): ModuleWithProviders<AdminSharedModule> {
    return {
      ngModule: AdminSharedModule,
      providers: [
        AuthGuard,
        ConfirmDeactivate,
        ConfirmationService,
        MenuService
      ]
    };
  }
}
