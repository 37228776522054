import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safeHTML'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(value: string) {
    if (!value) {
        return '';
    }

    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
