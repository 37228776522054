import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { BootstrapModule } from './app/bootstrap/bootstrap.module';

import { registerLocales } from './app/register-locales';

if (environment.production) {
  enableProdMode();
}

if (environment.production) {
  enableProdMode();
}

registerLocales();

// deactivate logging for production - write to browser console console.log = log because it is backup
if (environment.production) {
    if (window) {
        window['log'] = console.log;
        window['error'] = console.error;
        window['debug'] = console.debug;
    }
    console.log = console.debug = console.error = () => { };
}


platformBrowserDynamic().bootstrapModule(BootstrapModule)
  .catch(err => console.log(err));
