/**
 * @file Directive: TaskReporter
 * @author David López Rguez
 */
declare var $: any;

import {
    Directive,
    Input,
    DoCheck,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef,
    Injector
} from '@angular/core';

import { UtilsService, TaskTrackerService } from '../../../core';
import { TaskReporterOptions, ITaskReporterOptions } from './TaskReporterOptions';
import { LoadingComponent } from '../loading/loading';
import { BackdropComponent } from '../backdrop/backdrop';


@Directive({
    selector: '[task-reporter]'
})
export class TaskReporterDirective implements DoCheck {
    @Input('options')
    options: ITaskReporterOptions;

    defaults: TaskReporterOptions;
    settings: ITaskReporterOptions;
    backdrop: boolean;

    private _loadingRef: ComponentRef<LoadingComponent>;
    private _backdropRef: ComponentRef<BackdropComponent>;

    constructor(
        private _utils: UtilsService,
        private _tracker: TaskTrackerService,
        private _cfResolver: ComponentFactoryResolver,
        private _vcRef: ViewContainerRef,
        private _injector: Injector
    ) {
        this.defaults = new TaskReporterOptions();
    }

    // As ngOnChanges does not work on Object detection, ngDoCheck is using
    ngDoCheck() {
        this.settings = Object.assign({}, this.defaults, this.options || {});

        if (!this._loadingRef
            || this.backdrop !== this.settings.backdrop
        ) {
            this.destroyComponents();

            this.backdrop = this.settings.backdrop;

            if (this.settings.backdrop) {
                this.createBackdrop();
            }

            this.createLoading();
        }
    }

    ngOnDestroy() {
        this.destroyComponents();
    }

    private destroyComponents() {
        if (this._loadingRef) {
            this._loadingRef.destroy();
            this._loadingRef = null;
        }

        if (this._backdropRef) {
            this._backdropRef.destroy();
            this._backdropRef = null;
        }
    }

    private createBackdrop() {
        this._backdropRef = this._vcRef.createComponent(BackdropComponent);

        let instance = this._backdropRef.instance;
        instance.useTaskTracker = true;
        instance.options = this.settings;
    }

    private createLoading() {
        this._loadingRef = this._vcRef.createComponent(LoadingComponent);

        let instance = this._loadingRef.instance;
        instance.useTaskTracker = true;
        instance.options = this.settings;
    }
}