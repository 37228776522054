<p-overlayPanel #infoTextOverlay [appendTo]="'body'" [styleClass]="'infoTextOverlay'">
  {{infoText}}
</p-overlayPanel>

<p-contextMenu #exportTypesMenu [model]="exportTypes" (onShow)="onExportMenuShow($event)"
               (onHide)="onExportMenuHide($event)" appendTo="body"></p-contextMenu>
<div [ngClass]="tourSupported?'card tour-supported':'card'">
  <div class="card-header">
    <!-- info icon -->
    <span *ngIf="infoText" class="fa ta-icon-info-circled float-left" aria-hidden="true"
          (mouseover)="infoTextOverlay.show($event)" (mouseout)="infoTextOverlay.hide()"></span>

    <!-- header label -->
    <span class="float-left label">{{headLabel}}</span>

    <span class="center label">{{dateRangeLabel}}</span>

    <!-- search field -->
    <input #search type="text" pInputText (keyup)="onSpecialSearch($event)"
           [hidden]="!showSearch"
           [pTooltip]="searchTooltip" [escape]="false" placeholder="Search" class="form-control search-field float-left"
           maxlength="100" size="5"/>

    <!-- switch to doughnut chart -->
    <button [pTooltip]="chartType.toLowerCase().endsWith('bar')?'Switch to donut chart':'Switch to bar chart'"
            class="btn fa float-right borderless-icon-button" [ngClass]="{'ta-icon-chart-bar' : chartType == 'doughnut',
                'ta-icon-chart-pie': chartType.toLowerCase().endsWith('bar')}" [hidden]="!enableToogle || showAsTimeLine"
            (click)="toggleChartType()"></button>

    <!-- switch to line chart -->
    <button [pTooltip]="showAsTimeLine?'Switch to bar chart':'Switch to line chart'"
            class="btn fa float-right borderless-icon-button"
            [hidden]="!showTimeLineButton"
            [ngClass]="{'ta-icon-chart-line' : !showAsTimeLine, 'ta-icon-chart-bar': showAsTimeLine}"
            (click)="showTimeLine($event)"></button>

    <!-- save button -->
    <button pTooltip="Save" #exportButton class="btn fa ta-icon-save float-right borderless-icon-button"
            (click)="doExport($event, exportTypesMenu)"
            (keyup.enter)="doExport($event, exportTypesMenu)"
            [hidden]="!enableExport" *ngIf="downloadHandler.downloadStatus===DownloadStatusValues.NONE"></button>

    <!-- download animation -->
    <div class="float-right download" *ngIf="downloadHandler.downloadStatus!==DownloadStatusValues.NONE&&enableExport">
      <div *ngIf="downloadHandler.downloadStatus===DownloadStatusValues.STARTED"
           class="spinner-border text-primary"
           role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <circle-progress
        *ngIf="downloadHandler.downloadStatus===DownloadStatusValues.IN_PROGRESS"
        [percent]="downloadHandler.contentLengthAvailable?downloadHandler.downloadProgress:100"
        [radius]="14"
        [outerStrokeWidth]="3"
        [outerStrokeColor]="'#ffffff'"
        [showSubtitle]="false"
        [showInnerStroke]="false"
        [showTitle]="!downloadHandler.contentLengthAvailable"
        [titleFontSize]="9"
        [titleColor]="'#ffffff'"
        [title]="downloadHandler.downloadProgress"
        [showUnits]="false"
        [showImage]="false"
        [imageHeight]="30"
        [imageWidth]="30"
        [backgroundPadding]="0">
      </circle-progress>
      <button class="btn fa fa-close" (click)="downloadHandler.cancel()"></button>
    </div>

    <!-- timelines selector -->
    <p-multiSelect *ngIf="showAsTimeLine||stackedBar" #selectInput [options]="timeLineAllDatasets"
                   [selectionLimit]="timeLineDatasetLimit"
                   [(ngModel)]="timeLineSelected"
                   class="float-right"
                   optionLabel="label" styleClass="datasets" [maxSelectedLabels]=1 rows
                   [tooltip]="getTimeLineSelectedTooltip()" [tooltipPosition]="'left'">
      <ng-template let-dataset let-i="index" pTemplate="item">
              <span [pTooltip]="dataset.label" [ngClass]="{'font-italic': (dataset.value.id.startsWith('bots/')) }">
                  <div style="width: 10px; height: 20px; float: left; margin-right: 5px;"
                       [ngStyle]="{ 'background-color': dataset.value.color }">
                  </div>
                {{dataset.label}}
              </span>
      </ng-template>
    </p-multiSelect>

    <!-- top 10,20,30 options -->
    <p-dropdown [options]="topOptions" [(ngModel)]="selectedTopOptions"
                (onChange)="changeTop($event)"
                autoWidth="false"
                [hidden]="!showTopDropdown || showAsTimeLine" styleClass="top-selector"
                class="float-right"></p-dropdown>

    <!-- OE Coverage -->
    <p-toggleButton [(ngModel)]="showCoverage" [pTooltip]="getOECoverageTooltip()"
                    tooltipPosition="top"
                    styleClass="btn-coverage"
                    [onLabel]="getOECoverageText()"
                    [offLabel]="getOECoverageText()"
                    class="float-right"
                    [hidden]="!showCoverageButton"></p-toggleButton>
  </div>
  <div class="card-body div-chart">
    <div class="row">
      <div class="tooltip-container text-nowrap">
        <div class="chartjs-tooltip"></div>
      </div>
      <div class="pb-0 pl-0" #htmlDivElementChart [ngClass]="getChartCssClass()">
        <img #chartImage [hidden]="!showPrint"/>
        <p-chart #chartComponent type="{{chartTypeGet}}"
                 [data]="timeLineData || othersChartData || chartData"
                 [options]="chartOptions"
                 (onDataSelect)="onSelectBar($event)" [responsive]="true"
                 [plugins]="chartLegendHeightAdjustmentPlugin"></p-chart>
      </div>
      <div class="col-2">
        <div class="row">
          <div class="aggregation-timespan"
               [hidden]="!showAggregationTimeSpan
            || (isEmptyTimeLineData()&&!hasIncorrectAggregationConfig())">
            <p-dropdown [options]="aggregationTimeOptions" [(ngModel)]="aggregationTime"
                        (ngModelChange)="changeAggregationTimeSpan()"
                        styleClass="aggregation-selector"
                        class="float-right"></p-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-1">&nbsp;</div>
          <div class="col-11" [hidden]="!showWeekDaySelection()">
            <p-button [pTooltip]="showAsSmoothTimeLine?'Show daily values':'Show smoothed line chart'"
                      (click)="toggleSmoothTimeLine($event)"
                      icon="pi pi-chart-line"></p-button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 py-4 p-0" [hidden]="!showWeekDaySelection()">
            <weekday-filter #weekdayActor (onWeekdaysChange)="applyTimeLineConfig()"></weekday-filter>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
