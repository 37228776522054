import { CisResponse } from "@app/core";

/**
 * Cis error message object, providing multiple error details.
 * @typedef {object} CisError - Error message object.
 * @property {string} msgKey - The error text key.
 * @property {number} status - HTTP status code.
 * @property {number} status2 - Additional status code.
 * @property {string} statusText - The error message text.
 * @implements {CisResponse}
 * @extends Error
 * @summary An error message object generated by an error resolver that provides further cause information.
 * @class
 * @public
 */
export class CisError extends Error implements CisResponse {
  /**
   * Error text key.
   * @type {(string)} msgKey - Stores the text key of the error message.
   * @fieldOf CisError
   * @public
   */
  readonly msgKey: string;

  /**
   * HTTP status code.
   * @type {number} status - Stores solely HTTP status codes.
   * @fieldOf CisError
   * @public
   */
  readonly status: number;

  /**
   * Error status code.
   * @type {number} status2 - Stores numeric status codes.
   * @fieldOf CisError
   * @public
   */
  readonly status2: number;

  /**
   * Error message text.
   * @type {string} statusText - Error message as a string.
   * @fieldOf CisError
   * @public
   */
  readonly statusText: string;

  /**
   * Creates a new instance of CisError using passed arguments.
   * @constructor
   * @param {CisResponse} cisResponse - The Cis response object.
   * @param {string} [message] - Additional message. (optional)
   * @public
   */
  constructor(cisResponse: CisResponse, message?: string) {
    super(message || cisResponse.statusText);
    this.name = 'CisError';
    /**
     * @see {
     *  @link https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
     *  |Support for new.target
     * }
     */
    Object.setPrototypeOf(this, new.target.prototype);

    this.msgKey = cisResponse.msgKey;
    this.status = cisResponse.status;
    this.status2 = cisResponse.status2;
    this.statusText = cisResponse.statusText;
  }
}
