import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Version} from '@app/shared/models/version';
import {ReleaseNotes} from '@app/shared/models/release-notes';
import {catchError, map} from 'rxjs/operators';
import {environment} from '@env/environment';
import {AuthService, TaskTrackerService} from '@app/core';
import {ApiService} from '@app/shared';
import {BasicResponse} from '@app/shared/models/basic-response';
import {AuthBaseService} from '@app/core/services/security/auth-base';

@Injectable()
export class ReleaseNotesService {

  public apiUrl( path: string ): string {
    let basePath = environment.api.url + path;
    return basePath + '?' + (environment.api.fixedParams || '');
  }

  getVersion(): Observable<Version> {
    return this.http.get<Version>(this.apiUrl('/info/version' ) + '&_=' + new Date().getTime());
  }

  getReleaseNotes(): Observable<ReleaseNotes> {
    return this.http.get(this.apiUrl( '/info/release/notes' )  + '&_=' + new Date().getTime(), this.api.getOptions())
      .pipe(
         map((data: ReleaseNotes) => {
           return data;
         }),
         catchError(error => this.handleError(error, <ReleaseNotes>{}))
      );
  }

  public handleError<T>(error: HttpErrorResponse, fallbackValue: T): Observable<T> {
    //TODO: Error Handling oder Message ausgabe
    return of(fallbackValue);
  }

  constructor(protected http: HttpClient, public api: ApiService) {
    
  }

  saveReleaseNotes(releaseNotes: ReleaseNotes): Observable<BasicResponse> {
    const r: Observable<BasicResponse> =  this.http.post(this.apiUrl( '/info/release/notes' )  + '&_=' + new Date().getTime(),
      releaseNotes,
      this.api.getOptions())
    .pipe(
         map((data: ReleaseNotes) => {
           return data;
         }),
         catchError(error => this.handleError(error, <ReleaseNotes>{}))
      );

    return r;
  }
}




