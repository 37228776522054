import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit
} from '@angular/core';

import { TrackerService, NavigationService } from '../../../core';
import { ManagerPage } from '../../../admin-shared';

import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {Router} from '@angular/router';

@Component({
  //moduleId: module.id,
  selector: 'page-imprint',
  templateUrl: 'imprint.html',
  styleUrls: ['imprint.scss']
})
export class ImprintPage extends ManagerPage<any> implements OnInit, OnDestroy, AfterViewInit {
  //#region Properties

  get pageName(): string {
    return 'Imprint';
  }

  //#endregion

  //#region Fields
  //#endregion

  //#region Queries (Grid & Views)
  //#endregion

  //#region Constructor

  constructor(appInsights: TrackerService,
              confirmation: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected router: Router) {
    super(appInsights, confirmation, translate, navigation, apiService, router);
  }

  //#endregion

  //#region Lifecyle Hooks

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  //#endregion

  //#region Page Methods

  public hasChanges(): boolean {
    return false;
  }

  //#endregion

  //#region ManagerPage Methods

  public refresh() {

  }

  //#endregion

  //#region Master Actions
  //#endregion

  //#region Master Events Hanlder
  //#endregion

  //#region Details Actions
  //#endregion

  //#region Detail Events Hanlder
  //#endregion

  //#region Helpers
  //#endregion
}
