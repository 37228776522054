import { ChangeDetectorRef, Component, SimpleChanges, ViewChild } from '@angular/core';

import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { ActivatedRoute, Params, Router } from '@angular/router';

import { Message } from 'primeng/api';

import { AuthService, CisResponse, ContextService, InitSession2Request, RequestCacheService, StorageService, TaskTrackerService } from '@app/core';

import { GrowlService, ReactiveFormComponent, ValidationError } from '../../../../shared';


import { EMPTY, Observable, of } from 'rxjs';
import { WebShopContext } from '@app/shared/models/web-shop-context';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { ForgotPasswordData } from '@app/accessible/models/ForgotPasswordData';
import { ForgotPasswordRequest } from '@app/core/services/security/cis/forgot-password-request';
import { Location } from '@angular/common';
import { CustomValidators } from '@app/shared/components/CustomValidators';
import {MAX_3_TIMES_PER_DAY_FORGOT_PASSWORD} from '@app/shared/consts/messages';

@Component({
  selector: 'forgot-password-form',
  templateUrl: 'forgot-password-form.html',
  styleUrls: ['forgot-password-form.scss']
})
export class ForgotPasswordFormComponent extends ReactiveFormComponent<
ForgotPasswordData,
ForgotPasswordData
> {
  //#region Fields

  context: WebShopContext;

  formTitle = 'Forgot password';
  buttonText = 'Send mail';

  loginError = false;
  errorMessage = '';
  email_uuid = '';

  signature = null;

  //#endregion

  msgs: Message[] = [];

  //#region Consctructor

  constructor(
    ref: ChangeDetectorRef,
    growl: GrowlService,
    fb: FormBuilder,
    private _context: ContextService,
    private _auth: AuthService,
    private _tracker: TaskTrackerService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _cache: RequestCacheService,
    private _http: HttpClient,
    private _storage: StorageService,
    private _location: Location
  ) {
    super(ref, growl, fb);
    this.context = _context.getInstance<WebShopContext>();
    this.languages = [{ val: 'en', desc: 'English' }];

    this.readSignature();
  }

  //#endregion

  //#region Lifecycle Hooks

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    super.ngOnInit();

    this.readSignature();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  tempCodeSended = false
  isPasswordChange = false;

  userNameOrEmail = '';
  saveUserNameOrEmail(event) {
    this.userNameOrEmail = event.target.value;
  }

  //#endregion

  //#region ReactiveFormComponent Methods

  public refreshI18nFormControls(): void { }

  isInputValueTheEditModel(): boolean {
    return true;
  }

  buildForm(): void {
    this._form = this._fb.group({
      userNameOrEmail: new FormControl('', CustomValidators.userNameOrEmail().validators),
      tempCode: new FormControl('', [
        Validators.required
      ]),
      newPassword: new FormControl( '',CustomValidators.password().validators),
      newPasswordRepeat: new FormControl('', CustomValidators.passwordRepeat().validators),
    },
    {
      validator: CustomValidators.passwordMatch('newPassword', 'newPasswordRepeat')
    });
  }

  buildValidationMessages(): void {
    this._validationMessages = {
      userNameOrEmail: CustomValidators.userNameOrEmail().messages,
      tempCode: {
        required: this.VALIDATION_REQUIRED
      },
      newPassword: CustomValidators.password().messages,
      newPasswordRepeat: CustomValidators.passwordRepeat().messages
    };
  }

  loadModel(params: ForgotPasswordData): Observable<ForgotPasswordData> {
    throw new Error('Function not implemented');
  }

  saveModel(): Observable<ForgotPasswordData> {
    // prepare model
    const formModel = this._form.value;

    let tempCode = null;
    if( this.tempCodeSended && this.signature ) {
      if(this.isPasswordChange) {
        tempCode = ("" + this.signature).trim();
      } else
      if( this.signature.indexOf('.') != -1 ) {
        tempCode = ("" + this.signature).trim() + ("" + formModel.tempCode).trim();
      } else {
        tempCode = ("" + this.signature).trim() + "." + ("" + formModel.tempCode).trim();
      }
    }

    const request: ForgotPasswordRequest = <ForgotPasswordRequest>{
      userNameOrEmail: formModel.userNameOrEmail,
      tempCode: tempCode,
      newPassword: this.tempCodeSended ? ("" + formModel.newPassword).trim() : null,
      endPointExtension: 'AfterLogin'
    };

    this.resetPasswordRequest(request);

    return EMPTY;
  }

  storeSignatureAndUser(signatureAndTime, userNameOrEmail) {
    const p = signatureAndTime.split(':');
    this.email_uuid = p[0];
    this.signature = p[1];
    const life = Math.round( parseInt( p[2] ) * 0.9 ); // 10% less time than server to have time to reset

    this._storage.storePasswordChangeCookies(userNameOrEmail,
          this.signature,
          this.email_uuid,
          life);
  }

  countdownWaitNextRequest;
  countdownWaitTimerHandle;
  startCountDownWaitInErrorMessage( waitSeconds ) {
    if( this.countdownWaitTimerHandle != null ) {
      return;
    }
    this.countdownWaitNextRequest = waitSeconds;
    this.countdownWaitTimerHandle = window.setInterval(
      () => {

        if( this.countdownWaitNextRequest <= 0 ) {
          window.clearInterval(this.countdownWaitTimerHandle);
          this.countdownWaitTimerHandle = null;
          this.errorMessage = '';
        } else {
          this.errorMessage = "Wait " + this.countdownWaitNextRequest + " seconds, before next try!";
        }

        this.countdownWaitNextRequest--;

      }, 1000);
  }

  resetPasswordRequest(request: ForgotPasswordRequest) {
    (this._tracker.add(this.isPasswordChange ? this._auth.changePassword(request)
                                        : this._auth.forgotPassword(request)) as Observable<
      CisResponse
    >).subscribe(
      (response: CisResponse) => {

        console.log(response);

        this.loginError = response.status != 200;
        this.errorMessage = '';

        if (!this.loginError) {
          if( !this.tempCodeSended ) {
            this.storeSignatureAndUser( response.statusText, request.userNameOrEmail );
            this.tempCodeSended = true;
            this._growl.infoMessage(this.formTitle, 'Please check your mails for a token.', 5000);
            this.emptyHiddenFields();
            this.updateTexts();
          } else {
            this._growl.infoMessage(this.formTitle, 'Successfully changed password.', 5000);

            this._router.navigate([ this.isPasswordChange ? '/analytics' : '/portal/login'] );
            this.resetState();
          }
        } else {
          let msg = response.statusText;
          if (msg == 'message_ws_wrong_login') {
            msg = 'Wrong user name or email address.';
          }

          if( response.status === 402 && response.statusText && response.statusText.startsWith( 'wait:' ) ) {
            this.startCountDownWaitInErrorMessage( Number.parseInt( response.statusText.substring('wait:'.length) ) );
          } else if( response.status === 402 && response.statusText === 'limit' ) {
            this.errorMessage = MAX_3_TIMES_PER_DAY_FORGOT_PASSWORD;
          } else {
            this.errorMessage = msg;
          }
        }
      },
      (err: any) => {
        this.loginError = true;
        if (err && err.message && err.message.indexOf('unknown url') != -1
          || err.message.indexOf('Timeout has occurred') != -1) {
          this._growl.showError(this.formTitle, 'Server function error.');
        }
        console.log(err);
      },
      () => { }
    );
  }

  deleteModel(): Observable<any> {
    throw new Error('Function not implemented');
  }

  modelReady(): void {
    if (!this._form) {
      return;
    }

    this._form.reset();

    if (!this.model) {
      return;
    }

    // input dot to avoid invalid hidden fields
    this.resetHiddenFields();
  }

  resetHiddenFields() {
    const hv = this.tempCodeSended ? this.isPasswordChange ? '.' : '' : '.';
    const pw = this.tempCodeSended ? '' : 'A!b1c2Gjs.77hsZ';

    this._form.setValue({
      userNameOrEmail: this.model.userNameOrEmail || this.userNameOrEmail,
      tempCode: hv,
      newPassword: pw,
      newPasswordRepeat: pw
    });
  }

  emptyHiddenFields() {
    this._form.setValue({
      userNameOrEmail: this._form.value.userNameOrEmail,
      tempCode: '',
      newPassword: '',
      newPasswordRepeat: ''
    });
  }

  //#endregion

  //#region Events Handlers

  onShowResponse(event: any) {
    // call to a backend to verify against recaptcha with key
  }

  onResetPassword() {
    this._form.get('userNameOrEmail').markAsDirty();

    if (this.tempCodeSended) {
      this._form.get('tempCode').markAsDirty();
      this._form.get('newPassword').markAsDirty();
      this._form.get('newPasswordRepeat').markAsDirty();
    }

    this.onValueChanged();

    this.save();
  }

  updateTexts() {
    this.buttonText = this.tempCodeSended ? 'Update password' : 'Send mail';
    this.formTitle =  this.isPasswordChange ? 'Change password' : 'Forgot password';
  }

  readSignature() {

    this.isPasswordChange = this._router.url.indexOf('/changePassword') != -1;


    this.signature = this._storage.getPasswordChangeSignature();


    if( this.signature ) {
      this.tempCodeSended = true;
      this.userNameOrEmail = this._storage.getPasswordChangeUser();
      this.email_uuid = this._storage.getPasswordChangeEmailUUID();
    } else {
      this.tempCodeSended = false;
    }
    this.updateTexts();
  }

  resetState() {
    if( !this.tempCodeSended ) {
      this.emptyHiddenFields();
      this._location.back();
    } else {
      this.userNameOrEmail = '';
      this.email_uuid = '';
      this.tempCodeSended = false;
      this.signature = null;
      this._storage.removePassworChangeCookies();
      this.updateTexts();
      this.resetHiddenFields();
    }
  }
}
