<analytics-filter #filter [showGenericArticleFilter]=false [showBrandFilter]=false [showSearchTypeId]=true></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #numbersChart [field]="fieldArticleNumber" [headLabel]="articleNumberChartHeadLabel"
        [showTimeLineButton]=true
        [showCoverageButton]="true"
        [selectable]="false" [showSearch]=true></chart>
    </div>
</div>
