export enum Fields {
  BRANDLESS = ':bl:',
  TIME_LINE = ':tl:',
  DEMAND_TIME_LINE = ':dtl:',
  FILTER = ':filter',
  BRAND_NO = 'brand_id',
  COMPETITORS = 'competitors',
  BRAND_NO_FILTER = 'brand_id:filter',
  LOCATION_COUNTRY = 'location_country',
  LOCATION_COUNTRY_BRANDLESS = 'location_country:bl:',
  LOCATION_COUNTRY_FILTER = 'location_country:filter',
  DATASOURCE = 'datasource',
  GENART_NO = 'genart_id',
  GENART_NO_FAVS = 'genart_id:favs',
  GENART_NO_FILTER = 'genart_id:filter',
  GENART_NO_BRANDLESS = 'genart_id:bl:',
  OE_NUMBER = 'oe_number',
  OE_NUMBER_BRANDLESS = 'oe_number:bl:',
  ARTICLE_NUMBER = 'brand_article_number',
  RAW_ARTICLE_NUMBER = 'article_number',
  SEARCH_STRING = 'search_string:bl:',
  SEARCH_TYPE_ID_FILTER = 'search_type_id:filter',
  SEARCH_TYPE_ID_OE_FILTER = 'search_type_id_oe:filter',
  SEARCH_TYPE_ID_OE = 'search_type_id_oe',
  SEARCH_TYPE_ID = 'search_type_id',
  NUMBER_PLATE_TYPE_FILTER = 'number_plate_type:filter',
  NUMBER_PLATE_TYPE = 'number_plate_type',
  VEHICLES = 'linking_target_id',
  VEHICLE_MAKES = 'make_id',
  VEHICLE_MODELS = 'model_id',
  VEHICLES_BRANDLESS = 'linking_target_id:bl:',
  VEHICLE_MAKES_BRANDLESS = 'make_id:bl:',
  VEHICLE_MODELS_BRANDLESS = 'model_id:bl:',
  VEHICLE_TYPES_FILTER = 'linking_target_type:filter',
  VEHICLE_TYPES = 'linking_target_type',
  FOUND_BY = 'found_by',
  FOUND_BY_BRANDLESS = 'found_by:bl:',
  BRAND_ASSOCIATIONS = 'brand_associations',
  GENART_ASSOCIATIONS = 'genart_associations',
  OPERATION_NAME_BRANDLESS = 'operation_name_s:bl:',
  EVENT_TYPE_BRANDLESS = 'event_type:bl:',
  EVENT_TYPE = 'event_type',
  DATASOURCE_ID_BRANDLESS = 'datasource_id:bl:',
  TIMELINE_DATASETS = '_tl_datasets',
  TIMELINE_SELECTEDIDS = '_tl_selectedIds',
  SERVER_TIME = 'server_time'
  //BRAND_GENART_ASSOCIATIONS = "brand_genart_associations"
}
