import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * AssembliesPage params:
   * - `mandator`
   * - `targetType`
   *
   * AssembliesPage query params:
   * - `targetId` (not required if targetType is `universal`)
   * - `typeNumber` (not required if targetType is `universal`)
   *
   * We can arrive from:
   * - Vehicle selection pages
   * - Universal | Engine | Axle pages
   */
  // tslint:disable-next-line:max-line-length
  assemblies: { id: 'assgroup', name: 'AssembliesPage', route: '/:mandator/parts/:targetType/assemblies', query: ['targetId', 'typeNumber', 'searchType', 'searchValueType', 'searchValue'] },

  /**
   * AssembliesTreePage params:
   * - `mandator`
   * - `targetType`
   * - `assemblyGroupId`
   *
   * AssembliesTreePage query params:
   * - `targetId` (not required if targetType is `universal`)
   * - `typeNumber` (not required if targetType is `universal`)
   */
  // tslint:disable-next-line:max-line-length
  assembliesTree: { id: 'asstree', name: 'AssembliesTreePage', route: '/:mandator/parts/:targetType/assemblies/:assemblyGroupId/tree', query: ['targetId', 'typeNumber'] },
};
