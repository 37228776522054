import {
    Component,
    Input,
    SimpleChanges
} from '@angular/core';

@Component({
    //moduleId: module.id,
    selector: 'wallpaper',
    template: `
        <div class="wallpaper-section-cover-wrapper">
            <div class="wallpaper-section-cover" [ngStyle]="{'background': selected}"></div>
        </div>
    `,
    styleUrls: ['wallpaper.scss']
})
export class WallpaperComponent {
    @Input()
    wallpapers: Array<string>;

    selected: string;

    constructor() {
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['wallpapers'] && this.wallpapers && this.wallpapers.length) {
            let item = this.wallpapers[Math.floor(Math.random() * this.wallpapers.length)];
            this.selected = 'url(' + item + ') no-repeat center center fixed';
        }
    }
}
