import { Injectable} from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ContextService } from '@app/core';

@Injectable()
export class HeadersInterceptor implements HttpInterceptor {
  constructor(
    private _context: ContextService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    // HttpClient works with inmutable requests.

    if (!/^http[s]?:\/\//.test( req.url ) && !(req.url.startsWith('/') || req.url.startsWith('./') || req.url.startsWith('../'))) {
      const headers = req.headers.set('Accept-Language', this._context.instance.language);
      // headers = headers.set('Content-Type', 'application/json');

      const newReq = req.clone({ headers: headers });

      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }
}
