import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoTexts } from '@app/shared/models/info-texts';
import {PageRequest} from '@app/shared/models/page-request';
import {Features} from '@app/shared/models/features';
import { ChartComponent } from '@app/shared/components/chart/chart.component';
import {ChartRequest} from '@app/shared/models/chart-request';

@Component({
  selector: 'sales-countries',
  templateUrl: './sales-countries.component.html',
  styleUrls: ['./sales-countries.component.scss']
})
export class SalesCountriesComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.MOST_SEARCHED_COUNTRIES;
  }

  getPageStoreKey(): string {
     return "SalesCountriesComponent";
  }

  getChartRequest(): ChartRequest {
    const r = new ChartRequest( EventTypes.TECDOC_3_REPORT );
    r.facetCount = -1;
    return r;
  }

  get pageName(): string {
    return 'Sales countries';
  }

  public readonly fieldCountries = Fields.LOCATION_COUNTRY;
  public readonly fieldCountriesBrandless = Fields.LOCATION_COUNTRY_BRANDLESS;

  public worldMapChartData: any;

  public yourBrandCountriesChartHeadLabel = 'Your Brand(s)';
  @ViewChild('yourBrandCountriesChart', {static: true}) yourBrandCountriesChart: ChartActor;

  public marketViewCountriesChartHeadLabel = 'Market View';
  @ViewChild('marketViewCountriesChart', {static: true}) marketViewCountriesChart: ChartComponent;

  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router ) {
      super( appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [Fields.LOCATION_COUNTRY, Fields.LOCATION_COUNTRY_BRANDLESS];
  }

  getChartActors(): ChartActor[] {
    return [ this.yourBrandCountriesChart, this.marketViewCountriesChart ];
  }

  getReportPath(): string {
    return '/report/order/sales';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  dateRangeLabel: string;
  reportLoaded(data: ReportResponse): void {
    if(data.responseData && data.responseData.facets && data.responseData.facets.location_country) {
      this.worldMapChartData = data.responseData.facets.location_country.data;
    }
    this.dateRangeLabel = data.dateRange;
  }

  itemSelected(item: FacetItem, fieldName?:string): void {
  }

  protected getFeatureOnMenu(): Features {
    return Features.SALES;
  }

}
