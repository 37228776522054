import {
    Component,
    ViewEncapsulation,
    OnDestroy
} from '@angular/core';

@Component({
    //moduleId: module.id,
    selector: 'admin-wrapper',
    templateUrl: 'admin-wrapper.html',
    styleUrls: ['admin-wrapper.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AdminWrapper implements OnDestroy {
    //#region Fields

    _showDetail: boolean = false;
    _loading: boolean = false;
    _loadingDetail: boolean = false;

    //#endregion

    //#region Properties

    public set showDetail(value: boolean) {
        this._showDetail = value;
    }

    public get showDetail(): boolean {
        return this._showDetail;
    }

    public set loading(value: boolean) {
        this._loading = value;
    }

    public get loading(): boolean {
        return this._loading;
    }

    public set loadingDetail(value: boolean) {
        this._loadingDetail = value;
    }

    public get loadingDetail(): boolean {
        return this._loadingDetail;
    }

    //#endregion

    //#region Constructor

    constructor() {
    }

    //#endregion

    //#region Lifecycle Hooks

    ngOnDestroy() {
    }

    //#endregion
}

@Component({
    selector: 'admin-main-title',
    template: '<ng-content></ng-content>'
})
export class AdminMainTitle { }

@Component({
    selector: 'admin-main-actions',
    template: '<ng-content></ng-content>'
})
export class AdminMainActions { }

@Component({
    selector: 'admin-main',
    template: '<ng-content></ng-content>'
})
export class AdminMain { }

@Component({
    selector: 'admin-detail-title',
    template: '<ng-content></ng-content>'
})
export class AdminDetailTitle { }

@Component({
    selector: 'admin-detail-actions',
    template: '<ng-content></ng-content>'
})
export class AdminDetailActions { }

@Component({
    selector: 'admin-detail',
    template: '<ng-content></ng-content>'
})
export class AdminDetail { }