import { Injectable } from '@angular/core';

import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { ConfirmationService } from 'primeng/api';

import {
  DISCART_CHANGE_TITLE,
  DISCART_CHABGE_MESSAGE,
  LOGOUT_CONFIRM_TITLE,
  LOGOUT_CONFIRM_MESSAGE
} from '../../shared/consts/messages';
import { AuthService } from '../../core';
import { PageComponent } from '../../shared';

@Injectable()
export class ConfirmDeactivate implements CanDeactivate<PageComponent> {
  constructor(
    private auth: AuthService,
    private confirmation: ConfirmationService) {
  }

  canDeactivate(
    target: PageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.auth.isLogged || (!target.hasChanges() && nextState.url.indexOf('logout') === -1)) {
        resolve(true);
        return;
      }

      this.confirmation.confirm({
        key: 'discart-changes',
        header: nextState.url.indexOf('logout') === -1
          ? DISCART_CHANGE_TITLE
          : LOGOUT_CONFIRM_TITLE,
        message: target.hasChanges()
          ? DISCART_CHABGE_MESSAGE
          : LOGOUT_CONFIRM_MESSAGE,
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      });
    });
  }
}
