import {Component, ViewChild} from '@angular/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {ChartActor} from '@app/shared/components/chart-actor';
import {NavigationService, TrackerService, UtilsService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {FacetItem} from '@app/shared/models/facet-item';
import {ActivatedRoute, Router} from '@angular/router';
import {Collections} from '@app/shared/models/collections';
import {Fields} from '@app/shared/models/fields';
import {ReportParams} from '@app/shared/models/i-analytics-page';
import {TimeLineRows} from '@app/shared/models/analytics/time-line-rows';
import "chartjs-plugin-colorschemes";
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'order-trends',
  templateUrl: './order-trends.component.html',
  styleUrls: ['./order-trends.component.scss']
})
export class OrderTrendsComponent extends AnalyticsPage {
  public readonly fieldGenartNoBrandLess = Fields.GENART_NO;
  public readonly fieldCountryBrandLess = Fields.LOCATION_COUNTRY + Fields.BRANDLESS;
  public readonly fieldCountry = Fields.LOCATION_COUNTRY;

  public readonly countryChartHeadLabelBrandLess = "Countries ( market view )";
  public readonly countryChartHeadLabel = "Countries ( your brands )";

  @ViewChild('genartBrandLessChart', {static: true}) genartBrandLessChart: ChartActor;

  @ViewChild('countryChartBrandLess', {static: true}) countryChartBrandLess: ChartActor;
  @ViewChild('countryChart', {static: true}) countryChart: ChartActor;

  public worldMapChartMarketData: any;
  public worldMapChartBrandData: any;

  visibleData = [];

  whatIsVisible: string[] = [];

  get countriesHidden(): boolean {
    return this.chartParams.facets.indexOf(Fields.LOCATION_COUNTRY) == -1;
  }

  applySelection(event) {

    const toLoad = [];
    for( const f of this.whatIsVisible ) {
      if( this.chartParams.facets.indexOf(f) == -1 ) {
        toLoad.push( f );
      }
    }

    console.log('toLoad: ', toLoad);

    const toRemove = [];
    for( const f of this.chartParams.facets ) {
      if( this.whatIsVisible.indexOf(f) == -1 ) {
        toRemove.push( f );
      }
    }

    console.log('toRemove:', toRemove);

    if( toLoad.length ) {
      this.chartParams.facets = toLoad;

      const rp = <ReportParams>{
        skipCache: true,
        notNeedFilterData: true,
      };
      this.loadReport(rp);
    }

    if( toRemove.length && this.lastData ) {
      for( const key of this.genartKeys) {
        if( toRemove.indexOf(key) != -1 ) {
          this.reportLoaded(this.lastData, false);
        }
      }
    }
    this.chartParams.facets = this.whatIsVisible;
  }

  public get infoText(): string {
    return '';
  }

  getPageStoreKey(): string {
    return 'OrderTrendsComponent';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(Collections.ARTICLE_SELECTION);
  }

  get pageName(): string {
    return 'Order Trends';
  }

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected route: ActivatedRoute,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);

  }

  /*Ferodo: [402] Brake Pad Set, disk brake // [82] Brake Disc
Monroe: [854] Shock Absorber
Moog / Monroe: [914] Tie Rod End // [273] Track Control Arm // [2462] Ball Joint*/

  getPageFacets(): string[] {
    return this.whatIsVisible;
  }

  getChartActors(): ChartActor[] {
    return [ this.countryChartBrandLess, this.countryChart ];
  }

  getReportPath(): string {
    return '/report/order/trendline';
  }

  getExportPath(): string {
    return '/report/order/trendline';
  }


  public gaOrders: GaOrderTimeLineRows[] = [];
  public chartOptions = {
    tooltips: {
      enabled: true,
      yAlign: 'nearest', // nearest, default avarage
      mode: 'label',
      callbacks: {
        title: function(tooltipItems, data) {
          return tooltipItems[0].xLabel;
        },
        label: function(tooltipItems, data) {
          var idx = tooltipItems.index;

          var dataset = data.datasets[tooltipItems.datasetIndex];
          var currentValue = dataset.data[idx];
          let titleDisplay = [];

          titleDisplay.push( UtilsService.simpleNumberFormat(currentValue) );

          return titleDisplay;
        }
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          display: true,
          callback: function (value){
            return UtilsService.shortenInteger(value);
          }
        },
        gridLines: {color: 'rgba(0, 0, 0, 0)'}
      }],
      yAxes: [{
        ticks: {
          display: true,
          beginAtZero: true,
          callback: function(value) {
            return UtilsService.shortenInteger(value);
          }
        },
        gridLines: {color: 'rgba(0, 0, 0, 0.1)', borderDash: [2, 4]}
      }]
    }
  };

  public loading = {};

  private lastData: ReportResponse;

  private genartKeys: string[] = [Fields.GENART_NO, Fields.GENART_NO_BRANDLESS];
  showVisibleDataOptions: boolean = true;

  doesFacetsChange(data: ReportResponse) {
    const r = [];
    // check if any of keys are new or changed
    for( const key of this.genartKeys) {
      if( data.facets.has(key)
          &&
          ( !this.lastData.facets.has(key) ||
            this.lastData.facets.get(key) != data.facets.get(key) ) ) {
        r.push(key);
      }
    }
    return r;
  }

  reportLoaded(data: ReportResponse, checkChanges=true): void {

    if(!this.lastData) {
    if(data.responseData && data.responseData.facets && data.responseData.facets.location_country && data.responseData.facets["location_country:bl:"] ) {
      this.worldMapChartMarketData = data.responseData.facets["location_country:bl:"].data;
      this.worldMapChartBrandData = data.responseData.facets.location_country.data;
      }
      // console.log('#1 -----------');
      if(data.facets.has(Fields.GENART_NO) || data.facets.has(Fields.GENART_NO_BRANDLESS)) {
        // console.log('#1.1 -----------');
        this.lastData = data;
      } else {
        return;
      }
    } else
    if( checkChanges ) {
      // console.log('#2 -----------');
      const changes = this.doesFacetsChange(data);
      if( !changes.length ) {
        // console.log('#2.2 -----------');
        return;
      }
      for( const key of changes ) {
        this.lastData.facets.set(key, data.facets.get(key));
      }
    }

    // console.log('#3 -----------');

    this.gaOrders = [];
    let map = {};
    let timeLineRows = {};

    for (let [facetKey, facetValue] of this.lastData.facets) {

      if ( this.genartKeys.indexOf(facetKey) == -1
            || this.whatIsVisible.indexOf(facetKey) == -1 ) {
        continue;
      }

      for (let dataset of facetValue.timeLine.datasets) {
        if( !map[ dataset.id ] ) {
          map[ dataset.id ] = [];
        }
        map[ dataset.id ].push(dataset);

        if( !timeLineRows[ dataset.id ] ) {
          timeLineRows[ dataset.id ] = facetValue.timeLine.originalData;
        }
      }
    }

    for( let gaId in map ) {
      let gaOrder = new GaOrderTimeLineRows(timeLineRows[gaId]);
      gaOrder.datasets = map[gaId];
      gaOrder.genartId = gaId;
      this.addOrUpdateGAOrder(gaOrder);
    }



  }

  private addOrUpdateGAOrder(gaOrder: GaOrderTimeLineRows) {
    for (let i = 0; i < this.gaOrders.length; i++) {
      if (gaOrder.genartId === this.gaOrders[i].genartId) {//Update existed
        this.gaOrders[i] = gaOrder;
        this.loading[gaOrder.genartId] = false;
        return;
      }
    }
    //Add
    this.gaOrders.push(gaOrder);

    this.loading[gaOrder.genartId] = false;
  }

  itemSelected(item: FacetItem): void {

  }

  ngOnInit() {
    this.gaOrders = [];
    this.loading = {};

    this.chartParams.facets = this.getPageFacets();

    super.ngOnInit();
  }

  public loadGaOrders(genartId: string, timeSpan: object) {
    this.showLoadingAnimation = false;
    this.loading[genartId] = true;
    const r = this.chartParams;
    r.setIds(Fields.GENART_NO, [genartId]);
    //r.timespan = timeSpan['value'];
    const rp = <ReportParams>{
      skipCache: true,
      notNeedFilterData: true,
    };
    this.loadReport(rp);
  }

  public isLoading(genartId: string) {
    if (!this.loading[genartId]) {
      return false;
    }
    return this.loading[genartId];
  }

  protected getFeatureOnMenu() {
    return Features.ORDER_TRENDS;
  }
}

export class GaOrderTimeLineRows extends TimeLineRows {
  public genartId: string;

  constructor(public originalData: TimeLineRows) {
    super(originalData);
  }
}

