<div class="p-3">
  <div class="welcome mb-3">
    {{ getTimeOfDay() + _auth.userDetail?.login }}
  </div>
  <div style="width: 100%;">
    <div class="row" [hidden]="!showDSLinks">
      <div class="fa fa-bars float-right" aria-hidden="true" (click)="showLinks = !showLinks"></div>
    </div>
    <div class="row ds_links" [hidden]="!showLinks">
        <a *ngFor="let ds of datasourcesInfo" class="ds_link"
           (click)="scroll($event, 'datasource_tile_' + ds.id)">
          {{ds.name}}
        </a>
    </div>
    <div>
      <div *ngIf="!datasourcesLoaded" class="loading-data-sources">
        <loading [busy]="!datasourcesLoaded" [showCloseButton]="false"></loading>
      </div>
      
      <div
        cdkDropListGroup
      >
      <div class="row datasource-list"
      *ngFor="let row of dataRows"
      [cdkDropListData]="row"
      cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
          <div *ngFor="let ds of row; let i = index;" class="col">
            <ng-template [ngIf]="ds != 'fill' && ds.id == 'all'">
              <multiple-datasource
                cdkDrag
                [cdkDragData]="i"
                [datasource]="datasourcesInfo"
                class="col datasource-item flex-fill"
                [page]="this"
                id="datasource_tile_all">
              </multiple-datasource>
            </ng-template>
            <ng-template [ngIf]="ds != 'fill' && ds.id != 'all'">
              <datasource
                cdkDrag
                [cdkDragData]="i"
                [datasource]="ds"
                class="col datasource-item"
                [page]="this"
                [id]="'datasource_tile_'+ds.id">
              </datasource>
            </ng-template>
            <ng-template [ngIf]="ds == 'fill'">
              <div class="col datasource-item"></div>
            </ng-template>
          </div>
      </div>

      </div>

    </div>

  </div>
</div>
