import { ChartComponent } from '@app/shared/components/chart/chart.component';
import { UIChart } from 'primeng/chart';
import {DomInfo} from '@app/shared/models/dom-info';
import { UtilsService } from '@app/core';

export class CustomTooltip {

  static vehicleTooltip(chartComponent: ChartComponent) {
    return tooltipModel => {
      let chart = chartComponent.chartComponent;
      const tooltipEl = this.getTooltipElement(chart);

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      let dataPoint = chart.data.originalData[tooltipModel.dataPoints[0].index];
      let tooltipContent = '';

      let manufacturerName = dataPoint.details['manufacturer_name'];
      let modelNames = dataPoint.details['model_name'];
      let typeNames = dataPoint.details['types_name'];
      let motorCodes = dataPoint.details['motor_codes'];
      let tecdocNum = dataPoint.id;

      if (manufacturerName) {
        tooltipContent += '<div class="font-weight-bold">' + manufacturerName;
        if (modelNames) {
          tooltipContent += ', ' + modelNames;
        }
        tooltipContent += '</div>';
      }
      if (typeNames) {
        tooltipContent += '<div><i class="fa ta-icon-car-front"></i>' + typeNames + '</div>';
      }
      if (motorCodes) {
        tooltipContent += '<div><i class="fa ta-icon-engine"></i>' + motorCodes + '</div>';
      }
      if (tecdocNum) {
        tooltipContent += '<div><i class="fa ta-icon-tecdoc-type-number"></i>' + tecdocNum + '</div>';
      }

      if (tooltipModel.body) {
        let value = tooltipModel.body[0].lines[0];
        value = UtilsService.simpleNumberFormat(value);
        let bgColor = tooltipModel.labelColors[0].backgroundColor;
        tooltipContent += '<div class="d-flex flex-row"><div class="d-inline-block tooltip-body-value" style="background-color:' + bgColor + '"></div><div>' + value + '</div></div>';
      }
      tooltipEl.innerHTML = tooltipContent;

      this.setCommonStyle(tooltipModel, tooltipEl);
      this.setPosition(tooltipEl, tooltipModel, (3 / 4), chart);
      //tooltipEl.style.left = window.pageXOffset + (3 / 4) * tooltipModel.caretX + 'px';
    };
  }

  private static setPosition( tooltipEl, tooltipModel, coefficient, chart ) {

    // console.log( tooltipModel );

    const rightPadding = 50;
    const bottomPadding = 50;
    const topPadding = 0;

    let posX = window.pageXOffset + coefficient * tooltipModel.caretX;
    if( window.pageXOffset + DomInfo.mouseX + tooltipModel.width + rightPadding > window.innerWidth ) {
      posX = posX - tooltipModel.width;
    }
    tooltipEl.style.left = posX + 'px';

    let posY = window.pageYOffset + tooltipModel.caretY;

    /* console.log( 'posY: ' + posY + ', DomInfo.scrollTop: ' + DomInfo.scrollTop +
                 ', DomInfo.mouseY: ' + DomInfo.mouseY +
                 ', tooltipModel.caretY: ' + tooltipModel.caretY ); */

    if( window.pageYOffset + DomInfo.mouseY + tooltipModel.height + bottomPadding > window.innerHeight ) {
      posY = posY - tooltipModel.height;
    } else if( chart.type != 'bar' && posY < window.pageYOffset + topPadding ) {
      posY = window.pageYOffset + topPadding;
    }

    tooltipEl.style.top = posY + 'px';
  }

  private static getTooltipElement(chart: UIChart) {
    let row = chart.el.nativeElement.parentElement.parentElement;
    let tooltipContainer;
    let tooltipEl;
    for (let i = 0; i < row.childNodes.length; i++) {
      if (row.childNodes[i].className.indexOf('tooltip-container') != -1) {
        tooltipContainer = row.childNodes[i];
        tooltipEl = tooltipContainer.childNodes[0];
        break;
      }
    }
    return tooltipEl;
  }

  private static setCommonStyle(tooltipModel, tooltipEl) {
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }
    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'relative';

    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }

  static oeCoverageTooltip(chartComponent: ChartComponent) {
    return tooltipModel => {
      let chart = chartComponent.chartComponent;
      const tooltipEl = this.getTooltipElement(chart);

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      let dataPoint = chart.data.originalData[tooltipModel.dataPoints[0].index];
      let tooltipContent = '';

      function getBody(bodyItem) {
          return bodyItem.lines;
      }
      if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        let bodyLines = tooltipModel.body.map(getBody);

        titleLines.forEach(function (title) {
          const articleCount = !isNaN (dataPoint.articleCount) ? ' (<span class="font-weight-bold">'+dataPoint.articleCount+'</span> matching articles)' : '';
          tooltipContent += '<div><span class="font-weight-bold">' + title +'</span>'+ articleCount+ '</div>';
        });

        bodyLines.forEach(function (body, i) {
          let bodyValue = body.length == 0 ? 0 : body;
          bodyValue = UtilsService.simpleNumberFormat(bodyValue);
          let colors = tooltipModel.labelColors[i];
          let bgColor = colors.backgroundColor;
          const rgbaPattern = /rgba\(\d{1,3}, \d{1,3}, \d{1,3},/;
          if (rgbaPattern.test(bgColor)) {
            bgColor = bgColor.replace(/[^,]+(?=\))/, '1');
          }
          tooltipContent += ('<div class="d-flex flex-row"><div class="d-inline-block tooltip-body-value" style="background-color:' + bgColor + '"></div><div>' + bodyValue + '</div></div>');
        });
      }

      if(dataPoint && dataPoint.details && dataPoint.details['covarts']) {

        let covarts = dataPoint.details['covarts'];

        tooltipContent += '<table class="oe-cov-articles">';
        for(const a of covarts) {
          tooltipContent += '<tr>'
                            + '<td class="cov-art-num">' + a.articleNo + '</td>'
                            + '<td class="cov-brand-name">(' +  a.brandName + ')</td>'
                        + '</tr>';
        }
        tooltipContent += '</table>';
      }

      /*if (tooltipModel.body) {
        let value = tooltipModel.body[0].lines[0];
        let bgColor = tooltipModel.labelColors[0].backgroundColor;
        tooltipContent += '<div class="d-flex flex-row"><div class="d-inline-block tooltip-body-value" style="background-color:' + bgColor + '"></div><div>' + value + '</div></div>';
      }*/
      tooltipEl.innerHTML = tooltipContent;

      this.setCommonStyle(tooltipModel, tooltipEl);
      this.setPosition(tooltipEl, tooltipModel, (3 / 4), chart);
      //tooltipEl.style.left = window.pageXOffset + (3 / 4) * tooltipModel.caretX + 'px';
    };
  }

  static standardTooltip(chartComponent: ChartComponent) {
    return tooltipModel => {
      let chart = chartComponent.chartComponent;
      const tooltipEl = this.getTooltipElement(chart);

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      let tooltipContent = '';
      function getBody(bodyItem) {
        return bodyItem.lines;
      }
      if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        let bodyLines = tooltipModel.body.map(getBody);

        titleLines.forEach(function (title) {
          tooltipContent += '<div class="font-weight-bold">' + title + '</div>';
        });

        bodyLines.forEach(function (body, i) {
          let bodyValue = body.length == 0 ? 0 : body;
          bodyValue = UtilsService.simpleNumberFormat(bodyValue);
          let colors = tooltipModel.labelColors[i];
          let bgColor = colors.backgroundColor;
          const rgbaPattern = /rgba\(\d{1,3}, \d{1,3}, \d{1,3},/;
          if (rgbaPattern.test(bgColor)) {
            bgColor = bgColor.replace(/[^,]+(?=\))/, '1');
          }
          tooltipContent += ('<div class="d-flex flex-row"><div class="d-inline-block tooltip-body-value" style="background-color:' + bgColor + '"></div><div>' + bodyValue + '</div></div>');
        });

      }
      tooltipEl.innerHTML = tooltipContent;

      this.setCommonStyle(tooltipModel, tooltipEl);
      let coefficient = 'horizontalBar' == chart.type ? (3 / 4) : 1;
      this.setPosition(tooltipEl, tooltipModel, coefficient, chart);
      //tooltipEl.style.left = window.pageXOffset + coefficient * tooltipModel.caretX + 'px';
    }
  }

  static doughnutTooltip(chartComponent: ChartComponent) {
    return tooltipModel => {
      let chart = chartComponent.chartComponent;
      const tooltipEl = this.getTooltipElement(chart);

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      let dataPoint = chart.data.originalData[tooltipModel.dataPoints[0].index];
      let tooltipContent = '';

      let title = dataPoint.name + ' (' + UtilsService.simpleNumberFormat(dataPoint.percent) + '%)';
      tooltipContent += '<div class="font-weight-bold">' + title + '</div>';
      let value = dataPoint.count;
      value = UtilsService.simpleNumberFormat(value);;
      let bgColor = tooltipModel.labelColors[0].backgroundColor;
      tooltipContent += '<div class="d-flex flex-row"><div class="d-inline-block tooltip-body-value" style="background-color:' + bgColor + '"></div><div>' + value + '</div></div>';

      tooltipEl.innerHTML = tooltipContent;

      this.setCommonStyle(tooltipModel, tooltipEl);
      let coefficient = 'horizontalBar' == chart.type ? (3 / 4) : 1;
      this.setPosition(tooltipEl, tooltipModel, coefficient, chart);
      //tooltipEl.style.left = window.pageXOffset + coefficient * tooltipModel.caretX + 'px';
    }
  }


  static titleStandard = function (tooltipItems, data) {

    //console.log('titleStandard');

    var idx = tooltipItems[0].index;

    var labelPrefix = '';
    if (data.labelPrefix) {
      labelPrefix = data.labelPrefix[idx] + ' ';
    }

    var labelSuffix = '';
    if (data.labelSuffix) {
      labelSuffix = ' ' + data.labelSuffix[idx];
    }

    let titleDisplay = [];
    let firstRowTooltip = labelPrefix + data.labels[idx] + labelSuffix;
    titleDisplay.push(firstRowTooltip);

    return titleDisplay;
  };

  static titlePercent = function (tooltipItems, data) {

    //console.log('titlePercent');

    var idx = tooltipItems[0].index;

    var labelPrefix = '';
    if (data.labelPrefix) {
      labelPrefix = data.labelPrefix[idx] + ' ';
    }

    var labelSuffix = '';
    if (data.labelSuffix) {
      labelSuffix = ' ' + data.labelSuffix[idx];
    }

    let titleDisplay = [];
    let firstRowTooltip = labelPrefix + data.labels[idx] + labelSuffix;
    titleDisplay.push(firstRowTooltip);

    /*var dataset = data.datasets[tooltipItems[0].datasetIndex];
    var currentValue = dataset.data[idx];
    titleDisplay.push(currentValue + '%');*/

    return titleDisplay;
  };

  static titleDoughnutStandard = function (tooltipItem, data) {

    var idx = tooltipItem[0].index;

    //get the concerned dataset
    var dataset = data.datasets[tooltipItem[0].datasetIndex];
    var currentValue = dataset.data[idx];

    let percentage = '';

    var total = dataset.data.reduce((previousValue, currentValue) => {
      return parseInt(previousValue, 10) + parseInt(currentValue, 10);
    });
    percentage = (currentValue * 100 / total).toFixed(2) + '%';

    let titleDisplay = [];

    let firstRowTooltip = data.labels[idx];

    var idx1 = firstRowTooltip.lastIndexOf('(');
    var idx2 = firstRowTooltip.lastIndexOf('%');
    if (firstRowTooltip && idx1 != -1 && idx2 > idx1) {
      firstRowTooltip = firstRowTooltip.substring(0, idx1);
    }

    titleDisplay.push(firstRowTooltip);
    titleDisplay.push(percentage);

    return titleDisplay;
  };

  static titleDoughnutPercent = function (tooltipItem, data) {

    // console.log('titleDoughnutPercent');

    var idx = tooltipItem[0].index;

    //get the concerned dataset
    var dataset = data.datasets[tooltipItem[0].datasetIndex];
    var currentValue = dataset.data[idx];

    let percentage = currentValue + '%';

    let titleDisplay = [];

    let firstRowTooltip = data.labels[idx];

    var idx1 = firstRowTooltip.lastIndexOf('(');
    var idx2 = firstRowTooltip.lastIndexOf('%');
    if (firstRowTooltip && idx1 != -1 && idx2 > idx1) {
      firstRowTooltip = firstRowTooltip.substring(0, idx1);
    }

    titleDisplay.push(firstRowTooltip);
    titleDisplay.push(percentage);

    return titleDisplay;
  };

  public static initPositioners() {
    (() => {
      // @ts-ignore

      Chart
        .Tooltip
        .positioners
        .custom = function (elements, position) {//position is the pointer's position
          // @ts-ignore
          const averagePos = Chart.Tooltip.positioners.average(elements);
          // Happens when nothing is found
          if (averagePos === false || !elements.length) {
            return false;
          }

          const yObject = elements.map(el => el._view.y);
          let minY = 100000;
          for (const key in yObject) {
            if (key == 'length') {
              continue;
            }
            if (yObject[key] < minY) {
              minY = yObject[key];
            }
          }

          return {
            x: averagePos.x,
            y: minY,
          };
        };
    }
    )();
  }

}

