<analytics-filter #filter [showBrandFilter]="false" [showCountryFilter]="false" [showDatasourceFilter]="true"
                  [showGenericArticleFilter]="false"></analytics-filter>

<p-tabView #tabView class="my-5" [hidden]="!siteTraffics.length">
  <p-tabPanel class="col-lg-12" *ngFor="let siteTraffic of siteTraffics" [header]="siteTraffic.label">
    <div class="row">
      <div class="col-sm-12">
        <h6>Overview</h6>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let key of ['Sessions', 'Bounce rate', 'Page views']" class="card site-traffic m-2">
        <div class="card-body text-center">
          <h5 class="card-title">{{key}}</h5>
          <p class="card-text">{{formatFieldValue(siteTraffic.visitorOverview.get(key), key)}}</p>
        </div>
      </div>
    </div>

    <div class="row channel">
      <div class="col-7">
        <h6>From what channels people are visiting my website?</h6>
        <p-chart
          [options]="chartOptions"
          type="line" title="From"
          [data]="siteTraffic.visitorsByRefererType"
          [responsive]="true">
        </p-chart>
      </div>
      <div class="col-4" style="padding-top: 53px; padding-bottom: 70px;">
        <div class="card site-traffic" style="height: 100%;">
          <div class="card-body text-center">
            <h5 class="card-title">Visitors</h5>
            <p class="card-text">{{formatFieldValue(siteTraffic.visitorOverview.get('Visitors'), 'Visitors')}}</p>
            <p-chart
              type="pie"
              [data]="siteTraffic.newVsReturningVisitor"
              [responsive]="true"
              [options]="pieOptions">
            </p-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="row source">
      <div class="col-10">
        <div class="sources-box-header">
          <div class="d-inline-block bar-label">
            <h6>What sources are driving the outcomes?</h6>
          </div>
          <div class="d-inline-block float-right user-box-header-toolbar">
            <p-dropdown [options]="topOptions" [(ngModel)]="displayCount" styleClass="top-selector"
                        (onChange)="changeTop($event)"></p-dropdown>
            <button pTooltip="Save" class="btn fa ta-icon-save float-right"
                    (click)="exportData(siteTraffic)"></button>
          </div>
        </div>
        <p-table [value]="getFilteredData( siteTraffic.visitorsBySourceMediums )" [customSort]="false" class="trafficTable">
          <ng-template pTemplate="header">
            <tr>
              <th [pSortableColumn]="col" *ngFor="let col of visitorsBySourceMediumCols">
                {{col}}
                <p-sortIcon [field]="col"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" ngFor let-visit [ngForOf]="siteTraffic.visitorsBySourceMediums">
            <tr>
              <td *ngFor="let col of visitorsBySourceMediumCols">
                <span *ngIf="col==='Visitors'; then thenBlock else elseBlock"></span>
                <ng-template #thenBlock>
                  {{formatFieldValue(visit.get(col), col) + ' (' + visit.get(VISITORS_SOURCE_MEDIUM_PERCENTAGE) + '%)'}}
                </ng-template>
                <ng-template #elseBlock>
                  {{formatFieldValue(visit.get(col), col)}}
                </ng-template>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </p-tabPanel>
</p-tabView>
