<analytics-filter #filter [showBrandFilter]="false" [showCountryFilter]="false" [showDatasourceFilter]="false" [showGenericArticleFilter]="false"></analytics-filter>

<div class="row row-grid">
    <div class="col-xl-6 col-lg-12 px-0">
        <div class="col-xl-12">
            <chart #chartRmiOverview [field]="fieldOperationName"
                [headLabel]="chartOperationNamesLabel" [selectable]=true
                [showTimeLineButton]=true></chart>
        </div>
        <div class="col-xl-12">
            <chart #chartRmiEvents [field]="fieldEventsName"
            [headLabel]="chartFunctionNamesLabel" [selectable]=true
            [showTimeLineButton]=true></chart>
        </div>
    </div>
    <div class="col-xl-6 col-lg-12 px-0">
        <chart #chartCompanys [field]="fieldCompanysName" [headLabel]="chartCompanysLabel"
        [selectable]=true [showTimeLineButton]=true></chart>
    </div>
</div>
