import { Component, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Browser, Control, DomUtil, geoJSON, latLng, Layer, Map, tileLayer } from 'leaflet';
import { CountryUtil } from '@app/shared/components/ta-lealef-map/contries';
import { UtilsService } from '@app/core';

@Component({
  selector: 'ta-lealef-map',
  templateUrl: './ta-lealef-map.component.html',
  styleUrls: ['./ta-lealef-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TaLealefMapComponent implements OnChanges {

  @Input() panelTitle;
  @Input() chartData;
  @Input() public infoText: string;
  @Input() dateRangeLabel: string;

  lealefLayers: any;


  onMapReady = (map: Map) => {

    let legend = new Control({
      position: 'bottomright'
    });
    legend.onAdd = () => {
      let div = DomUtil.create('div', 'info legend');
      let grades = [0, 10, 100, 1000, 10000, 100000, 1000000, 10000000];
      let labels = [];

      const noData = '<i style="background:' + UtilsService.getCountryColorForMap(0) + '"></i> No data';
      labels.push(noData);
      for (var i = 0; i < grades.length; i++) {
        let lowValueLabel = getShortForm(grades[i] + 1);
        lowValueLabel = lowValueLabel.indexOf('+') != -1 ? '(' + lowValueLabel + ')' : lowValueLabel;
        let highValueLabel = grades[i + 1] ? getShortForm(grades[i + 1]) : null;
        let legendLabel = lowValueLabel + (highValueLabel ? '&ndash;' + highValueLabel : '')
        const legendItem = '<i style="background:' + UtilsService.getCountryColorForMap(grades[i] + 1) + '"></i> ' + legendLabel;
        labels.push(legendItem);
      }

      div.innerHTML = labels.join('<br>');
      return div;
    };
    legend.addTo(map);
  }

  convertToGeoJSON = (selectedCountries: any, worldCountries: GeoJSON.FeatureCollection<any>) => {
    const result: GeoJSON.FeatureCollection<any> = {
      features: [],
      type: "FeatureCollection"
    };
    if (selectedCountries && selectedCountries.length) {
      selectedCountries.forEach(country => {
        for (let i = 0; i < worldCountries.features.length; i++) {
          if (worldCountries.features[i].id === country.id.toUpperCase()) {
            worldCountries.features[i].properties.density = country.count;
            result.features.push(worldCountries.features[i]);
            break;
          }
        }
      });
    }
    return result;


  }

  style = feature => {
    return {
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7,
      fillColor: UtilsService.getCountryColorForMap(feature.properties.density)
    };
  }

  highlightFeature = e => {
    let layer = e.target;
    layer.setStyle({
      fillOpacity: 0.5
    });

    if (!Browser.ie && !Browser.opera && !Browser.edge) {
      layer.bringToFront();
    }
  }

  resetHighlight = e => {
    let layer = e.target;
    layer.setStyle({
      fillOpacity: 0.7
    });
  }

  onEachFeature = (feature: any, layer: Layer) => {
    layer.on({
      mouseover: this.highlightFeature,
      mouseout: this.resetHighlight
    });
  }

  lealefOptions: any = {
    layers: [
      tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw',
        {
          maxZoom: 18,
          attribution: 'TecAlliance',
          id: 'mapbox/light-v9',
          tileSize: 512,
          zoomOffset: -1,
          noWrap: true,
          bounds: [
            [-90, -180],
            [90, 180]
          ],
          minZoom: 1
        })
    ],
    zoom: 2,
    center: latLng(14.5994, 28.6731),
    scrollWheelZoom: false,
    maxBounds: [
      [-90.0, -180.0],
      [180.0, 180.0]
    ]
  };

  ngOnChanges(changes: any): void {
    const geoJSONData: GeoJSON.FeatureCollection<any> = this.convertToGeoJSON(this.chartData, CountryUtil.getCountries());
    this.lealefLayers = [
      geoJSON(geoJSONData, {
        style: this.style,
        onEachFeature: this.onEachFeature
      }).bindTooltip(layer => {
        // @ts-ignore
        return `<div><b>${layer.feature.properties.name}</b></div><div>${UtilsService.simpleNumberFormat(layer.feature.properties.density)}</div>`;
      })
    ];
  }

}

function getShortForm(num: number): string {
  if (num > 10000000) {
    return '>10M';
  } else if (num == 10000000) {
    return '10M';
  } else if (num > 1000000) {
    return '>1M';
  } else if (num == 1000000) {
    return '1M';
  } else if (num > 100000) {
    return '>100K';
  } else if (num == 100000) {
    return '100K';
  } else if (num > 10000) {
    return '>10K';
  } else if (num == 10000) {
    return '10K';
  } else if (num > 1000) {
    return '>1K';
  } else if (num == 1000) {
    return '1K';
  } else return num + '';
}
