<div class="container-fluid">
  <div class="row">
    <div class="col-sm-2">
      <p-autoComplete #countriesAutocomplete styleClass="w-100" [suggestions]="countries.filtered"
        [multiple]="false" field="label" [(ngModel)]="countries.selected" (completeMethod)="countries.search($event)"
        [placeholder]="'country'" [forceSelection]=true [minLength]="0"></p-autoComplete>
    </div>
    <div class="col-sm-3">
      <p-autoComplete #regionsAutocomplete styleClass="w-100" [suggestions]="regions.filtered"
        [multiple]="false" field="label" [(ngModel)]="regions.selected" (completeMethod)="regions.search($event)"
        [disabled]="!countries.isSelected" [placeholder]="'region'" [minLength]="0"
        (onSelect)="resetPostalCode()"></p-autoComplete>
    </div>
    <div class="col-sm-4">
      <p-autoComplete #postalCodesAutocomplete class="p-autocomplete"
                      styleClass="w-100" [inputStyle]="{ 'width': '100%' }" [suggestions]="postalCodes.filtered"
                [placeholder]="'postal code / place name'" [multiple]="false" field="label" [(ngModel)]="postalCodes.selected"
                (completeMethod)="postalCodes.search($event)" [disabled]="!countries.isSelected" [minLength]="0"
                (onSelect)="resetRegionOnly()">
      </p-autoComplete>
    </div>
    <div class="col-sm-1">
      <input pInputText type="text" class="w-100" [disabled]="!postalCodes.isSelected" [(ngModel)]="distance"
        [title]="'distance in km'" />
    </div>
    <div class="col-sm-2">
      <button class="btn btn-primary pull-right" [disabled]="!(regions.isSelected || postalCodes.isSelected)"
        (click)="addFavorite()">Add to favorites</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">

      <!-- Favorites Table: begin  -->

      <p-dataView #dv [value]="locationList" [paginator]="true" paginatorPosition="top" [rows]="12">
        <p-header>
          <div class="ui-g">
            <div class="ui-g-12 ui-md-1">
              <i [className]="globalSelected ? 'fa fa-2 fa-check-square-o': 'fa fa-2 fa-square-o'" (click)="changeSelectionAll()"
                    aria-hidden="true" title="select / deselect all">&nbsp;</i>
              &nbsp;&nbsp;<i class="fa fa-2 fa-trash-o" (click)="changeRemoveAll()" aria-hidden="true" title="mark all to remove">&nbsp;</i>
              &nbsp;&nbsp;<i [className]="deletedVisible ? 'fa fa-2 fa-recycle' : 'fa fa-2 fa-recycle red-text'" aria-hidden="true" (click)="deletedVisible = !deletedVisible"
                    [hidden]="!showRecycle" title="show / hide all remove marked">&nbsp;</i>
            </div>
          </div>
        </p-header>
        <ng-template let-location pTemplate="listItem">
          <div class="ui-g" (mouseup)="selectLocation($event, location)" [hidden]="location.remove && !deletedVisible" [className]="location.remove ? 'marked-to-remove' : ''">
            <div class="ui-g-12 ui-md-1">
              <i [className]="location.selected ? 'fa fa-2 fa-check-square-o': 'fa fa-2 fa-square-o'" (click)="location.selected = !location.selected" aria-hidden="true">&nbsp;</i>
              &nbsp;&nbsp;<i class="fa fa-2 fa-trash-o" (click)="setRemoved(location)" aria-hidden="true">&nbsp;</i>
            </div>
            <div class="ui-g-12 ui-md-2">{{location.details.country_name}}</div>
            <div class="ui-g-12 ui-md-3">{{location.details.region_name}}</div>
            <div class="ui-g-12 ui-md-2">{{location.details.postal_code}}</div>
            <div class="ui-g-10 ui-md-3">{{location.details.place_name}}</div>
            <div class="ui-g-10 ui-md-1" [innerText]="getDistance(location)"></div>
          </div>
        </ng-template>
      </p-dataView>

      <!-- Favorites Table: end -->

    </div>
  </div>
</div>
