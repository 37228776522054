
export class PageRequest {

    public fromDate = '';
    public toDate = '';
    public brandNo = 0;
    public genartNo = '';
    public displayName = '';
    public timespan = '';
    public articleNumber = '';
    public params_map: any = '';

    public vehicleId: any = '';
    public countryId: any = '';
    public countryName: any = '';
    public fieldName = '';
    public fieldValue = '';

    public make = '';
    public model = '';
    public type = '';

    public _decode( url: string, paramsSplit: string = ';', valueSplit = '=' ): PageRequest {

        let idx = -1;
        if( ( idx = url.indexOf( ';' ) ) != -1 ) {
            let params = url.substring(idx + 1);
            this._decodeType(this, params, paramsSplit, valueSplit);
        }
        return this;
    }

    public _decodeType( inst: any, params: string, paramsSplit: string = ';', valueSplit = '=' ): void {
        let idx = -1;
        const ps = params.split(paramsSplit);
        for( const p of ps ) {
            if( ( idx = p.indexOf(valueSplit) ) != -1 ) {
                const key = p.substring(0, idx);
                let val: any = p.substring(idx + 1);

                if( key.endsWith("_map") ) {
                    inst[key] = this.decodeMap(val);
                } else
                if( Array.isArray( inst[key] ) ) {
                    inst[key] = val.split(',');
                } else
                if( !isNaN( inst[key] ) && !isNaN(val) ) {
                    inst[key] = parseInt(val);
                } else {
                    inst[key] = decodeURI( val );
                }
            }
        }
    }

    private decodeMap( params: string, paramsSplit: string = '::', valueSplit = ':' ): Map<string, string[]> {
        const r: Map<string, string[]> = new Map();

        let idx = -1;
        const ps = params.split(paramsSplit);
        for( const p of ps ) {
            if( ( idx = p.indexOf(valueSplit) ) != -1 ) {
                const key = p.substring(0, idx);
                let val: any = p.substring(idx + 1);

                r.set(key, val.split(','));
            }
        }

        return r;
    }

    public setParams( params: Map<string, string[]>, paramsSplit: string = '::', valueSplit: string = ':' ): void {
        let r = '';
        let c = '';
        if( params ) {
            params.forEach((values, field) => {
                if( values && values.length ) {
                    r += c + field + valueSplit + values.join(',');
                    c = paramsSplit;
                }
            });
        }
        this.params_map = r;
    }
}
