
<p-dialog class="popup-get-full-version" #dialog [header]="'Get access to the full version'"
          [(visible)]="showDialog" [style]="popUpStyle"  [modal]="true">

  <loading [busy]="isSending"></loading>

  <div class="container-fluid px-4" [hidden]="!messageSended">
    <div class="row confirm-content ">
      Thank you for requesting the Demand Dashboard upgrade. We will be reaching out to you soon.
    </div>
    <div class="row pull-right py-4">
      <p-button class="btn-submit" label="Close" (onClick)="showDialog = false"></p-button>
    </div>
  </div>

  <form class="px-4" [hidden]="messageSended">
      Demand dashboard brings up-to date insights from the automotive aftermarket demand.<br><br>

      Full version contains below functionalities:

      <ul class="feature-list">
        <li>Demand trends for generic articles</li>
        <li>Article search & view insights for your own catalogue</li>
        <li>Article search & view insights for TecDoc 3.0 catalogue
          <ul>
            <li>Aggregated view of all TecAlliance & white-label catalogues</li>
          </ul>
        </li>
        <li>Ability to filter all results by time & location</li>
        <li>Export results as PDF or CSV</li>
      </ul>

      <div class="form-group">
        <input type="text" name="name" [(ngModel)]="user.name" maxlength="25" class="form-control required"
               placeholder="Name" [ngClass]="{ 'error-field': error.name }" />
      </div>
      <div class="form-group">
        <input type="text" name="surname" required="required" [(ngModel)]="user.surname" maxlength="25"
               class="form-control required" placeholder="Surname" [ngClass]="{ 'error-field': error.surname }" />
      </div>
      <div class="form-group">
        <input type="text" name="email" required="required" [(ngModel)]="user.email" maxlength="50"
               class="form-control required" placeholder="E-mail" [ngClass]="{ 'error-field': error.email }" />
      </div>
      <div class="form-group error-div">
        <div class="alert-danger" *ngIf="errorMsg">
          {{errorMsg}}
        </div>
      </div>
      <div class="form-group pull-right">
        <p-button class="btn-submit" label="Request a demo!" (onClick)="sendForm($event)"></p-button>
      </div>
  </form>
</p-dialog>
