import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IAnalyticsPage, ReportParams} from '@app/shared/models/i-analytics-page';
import {Fields} from '@app/shared/models/fields';
import {ChartActor} from '@app/shared/components/chart-actor';
import {MultiSelectComponent} from '../multi-select/multi-select.component';
import {DataSourcePages} from '@app/shared/models/datasource-pages';
import {InfoTexts} from '@app/shared/models/info-texts';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {FilterDialogComponent} from '../filter-dialog/filter-dialog.component';
import {SelectionComponent} from '@app/shared/models/selection-component';
import {BrandSelectComponent} from '@app/shared/components/multi-select/brand-select.component';
import {ConfigMode} from '@app/shared/models/config-mode';
import {GenericArticlesFilterComponent} from '@app/shared/components/generic-articles-filter/generic-articles-filter.component';
import {FilterConfigEvent} from '@app/shared/models/filter-config-event';


@Component({
  selector: 'analytics-filter',
  templateUrl: './analytics-filter.component.html',
  styleUrls: ['./analytics-filter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalyticsFilterComponent implements OnInit {

  private _selectedDataSource: string[] = [];
  public set selectedDataSource( v: string[] ) {
    this._selectedDataSource = v;
    this.updateInfoText();
  }

  private page: IAnalyticsPage;

  @Input()
  public showBrandFilter: boolean = true;

  @Input()
  public showGenericArticleFilter: boolean = true;

  @Input()
  public showCountryFilter: boolean = true;

  @Input()
  public showDatasourceFilter: boolean = true;

  @Input()
  public showSearchTypeId: boolean = false;

  @Input()
  public showSearchTypeOEId: boolean = false;

  @Input()
  public genartFieldTooltip: string;

  @Input()
  public showNumberPlateType: boolean = false;

  @Input()
  public showVehicleTypes: boolean = false;

  @Input()
  public locationDisabled: boolean = false;

  //public infoText: string = null;
  public infoText2: string = null;

  public readonly fieldLocation = Fields.LOCATION_COUNTRY_FILTER;
  public readonly fieldDataSource = Fields.DATASOURCE;
  public readonly fieldGenArt = Fields.GENART_NO_FILTER;

  public readonly fieldBrandFilter: string = Fields.BRAND_NO_FILTER;
  public readonly fieldSearchTypeId: string = Fields.SEARCH_TYPE_ID_FILTER;
  public readonly fieldSearchTypeOEId: string = Fields.SEARCH_TYPE_ID_OE_FILTER;
  public readonly fieldNumberPlateType: string = Fields.NUMBER_PLATE_TYPE_FILTER;
  public readonly fieldVehicleTypes: string = Fields.VEHICLE_TYPES_FILTER;

  @ViewChild('brandActor', {static: true}) brandActor: BrandSelectComponent;
  @ViewChild('locationActor', {static: true}) locationActor: MultiSelectComponent;
  @ViewChild('datasourceActor', {static: true}) datasourceActor: MultiSelectComponent;
  @ViewChild('timespanActor', {static: true}) timespanActor: ChartActor;
  @ViewChild('gaFilter', {static: true}) gaFilter: GenericArticlesFilterComponent;
  @ViewChild('searchTypeIdActor', {static: true}) searchTypeIdActor: MultiSelectComponent;
  @ViewChild('searchTypeIdOEActor', {static: true}) searchTypeIdOEActor: MultiSelectComponent;
  @ViewChild('numberPlateTypeActor', {static: true}) numberPlateTypeActor: MultiSelectComponent;
  @ViewChild('vehicleTypesActor', {static: true}) vehicleTypesActor: MultiSelectComponent;
  @ViewChild('filterDialog', {static: true}) filterDialog: FilterDialogComponent;

  @ViewChild('htmlDivElementDatasources', {static: true}) htmlDivElementDatasources: ElementRef<HTMLDivElement>;
  @ViewChild('htmlDivElementCountries', {static: true}) htmlDivElementCountries: ElementRef<HTMLDivElement>;

  private report: ReportResponse;

  public setPage( v: IAnalyticsPage ) {
    this.page = v;

    this.page.getDataSubscriber().asObservable().subscribe((data: ReportResponse) => {
      this.report = data;
      this.updateInfoText();
    });
  }

  constructor() {}

  lastFilterFieldUsed;

  // --- Interface methods begin
  ngOnInit() {

    if( this.showBrandFilter ) {
      this.brandActor.setPage( this.page );
    }
    this.locationActor.setPage( this.page );

    this.datasourceActor.setPage( this.page );
    this.timespanActor.setPage( this.page );

    if( this.showVehicleTypes ) {
      this.vehicleTypesActor.setPage( this.page );
    }

    if( this.showGenericArticleFilter ) {
      this.gaFilter.setPage( this.page );
    }

    if( this.showSearchTypeId ) {
      this.searchTypeIdActor.setPage( this.page );
    }

    if( this.showSearchTypeOEId ) {
      this.searchTypeIdOEActor.setPage( this.page );
    }


    if( this.showNumberPlateType ) {
      this.numberPlateTypeActor.setPage( this.page );
    }

    if(this.page) {
      //this.infoText = this.page.infoText;
      this.updateInfoText();
    }

    this.datasourceActor.changeListener.subscribe((ids: string[]) => {
       const enabled = true; // ids.indexOf('3') == -1;
       this.locationActor.setEnabled( enabled );
       this.locationDisabled = !enabled;
    });

    let changeFilterCallback = (event: FilterConfigEvent) => {
      if (event.mode == ConfigMode.DISPLAY_CONFIRMATION) {
        this.isConfiguringFilter = true;
        this.page.getChartParams().markFilterFieldUserChanged( event.field );
      } else {
        this.saveFiltersAndLoadReport();
      }
    };

    this.timespanActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.brandActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.locationActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.datasourceActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.searchTypeIdActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.searchTypeIdOEActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.numberPlateTypeActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.vehicleTypesActor.onConfiguringFilter.subscribe(changeFilterCallback);
    this.gaFilter.onConfiguringFilter.subscribe(changeFilterCallback);

    window.addEventListener('resize', () => {
      this.onResize();
    });

    this.onResize();
  }

  onAdvancedFilterClicked( fieldName: string, target: SelectionComponent ) {
    if( this.report.facets.has(fieldName) ) {
      this.filterDialog.setData(this.report.facets.get(fieldName).originalData, target);
      this.filterDialog.display = true;
    }
  }

  private updateInfoText(): void {
    this.infoText2 = this.getNotAvailableText();
  }

  isConfiguringFilter: boolean = false;
  saveFiltersAndLoadReport(){
    this.isConfiguringFilter = false;
    this.timespanActor.saveSettings();
    this.brandActor.saveSettings();
    this.locationActor.saveSettings();
    this.datasourceActor.saveSettings();
    this.searchTypeIdActor.saveSettings();
    this.numberPlateTypeActor.saveSettings();
    this.vehicleTypesActor.saveSettings();
    this.gaFilter.saveSettings();
    if ('TAAnalyticsDashboardPage' == this.page.getPageStoreKey()) {
      this.page.getChartParams().resetRequestParams();
    }
    this.page.loadReport(<ReportParams>{ pageLoad: true });
  }

  private getNotAvailableText(): string {
    if( this._selectedDataSource.indexOf('3') != -1 ) {
      for( const path of DataSourcePages.disabledMenuItemsWs ) {
        if( document.location.href.indexOf( 'analytics/' + path ) != -1 ) {
          return InfoTexts.NO_DATA_AVAILABLE_FOR_PAGE;
        }
      }
    }
    return '';
  }

  revertFilters(){
    this.timespanActor.loadSettings();
    this.brandActor.revertSelection();
    this.locationActor.revertSelection();
    this.datasourceActor.revertSelection();
    this.searchTypeIdActor.revertSelection();
    this.numberPlateTypeActor.revertSelection();
    this.vehicleTypesActor.revertSelection();
    this.gaFilter.revertSelection();
    this.isConfiguringFilter = false;
  }

  /**
   * loads the user brands
   */

  countryFilterWidth: number;
  dataSourceWidth: number;

  onResize() {
    this.dataSourceWidth = this.htmlDivElementDatasources.nativeElement.offsetWidth;
    this.countryFilterWidth = this.htmlDivElementCountries.nativeElement.offsetWidth;
  }
}
