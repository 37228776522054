// Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//GrowlModule deprecated, use Toast instead
import {TooltipModule} from 'primeng/tooltip';



import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AccessibleModule } from '../accessible/accessible.module';
import { AdminModule } from '../admin/admin.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BootstrapRoutingModule } from './bootstrap.routing.module';

import { TecPortalComponent } from './components/tec-portal/tec-portal';
import { AuthTypes } from '@app/core/services/security';
import { WebShopContext } from '@app/shared/models/web-shop-context';
import { WebShopUser } from '@app/shared/models/web-shop-user';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AppContext } from '@app/core';
import {ToastModule} from 'primeng/toast';

@NgModule({
    bootstrap: [TecPortalComponent],
    declarations: [
        TecPortalComponent
    ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // GrowlModule,
    CoreModule.forRoot({
      authType: AuthTypes.Cis,
      contextFactory: WebShopContext.factory,
      userFactory: WebShopUser.factory
    }),
    SharedModule.forRoot(),
    AccessibleModule,
    AdminModule,
    BootstrapRoutingModule,
    TooltipModule,
    ToastModule
  ],
    providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl }
    ]
})
export class BootstrapModule {
}

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

export function ContextLoader(): Promise<AppContext> {
    return new Promise<AppContext>((resolve, reject) => {
        let element: HTMLElement = document.getElementById('app-context');
        if (element) {
            let context = JSON.parse(element.innerText) as AppContext;
            resolve( context );
        } else {
            reject();
        }
    });
}

/**
 * Creates a TranslateHttpLoader.
 *
 * @export
 * @param {HttpClient} http The HTTP client.
 * @returns A TranslateHttpLoader instance.
 */
export function TranslateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.translate.baseUrl + '/', '.json?ver=' + new Date().getTime());
  }
