import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';

import { AuthService, NavigationService } from '../../../core';

import { UIActionsService } from '../../../shared';
import { environment } from '@env/environment';
import { Features } from '@app/shared/models/features';
import { GuidedTourService, Orientation } from 'ngx-guided-tour';
import { ViewChild } from '@angular/core';
import { DashboardTour } from './dashboard-tour';

@Component({
  //moduleId: module.id,
  selector: 'tec-portal',
  templateUrl: 'tec-portal.html',
  styleUrls: ['tec-portal.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TecPortalComponent implements OnInit, OnDestroy {
  //#region Constructor

  constructor(
    private _router: Router,
    public _auth: AuthService,
    private _guidedTourService: GuidedTourService,
    private _productTour: DashboardTour,
    private _uiActions: UIActionsService,
    protected navigation: NavigationService
  ) {

  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
    //TODO should handle tour initializing in dashboard-tour but if using this method, have to use embedded html of steps???
    this._productTour.tour = {
      tourId: 'experiment-tour',
      useOrb: false,
      preventBackdropFromAdvancing: true,
      steps: [
        {
          content: this.step0.nativeElement.innerHTML,
          orientation: Orientation.Center
        },
        {
          selector: '#nav-menu-container',
          content: this.step1.nativeElement.innerHTML,
          orientation: Orientation.Right
        },
        {
          selector: '#divAnalyticFilter',
          content: this.step2.nativeElement.innerHTML,
          orientation: Orientation.BottomLeft,
          useHighlightPadding: true,
          highlightPadding: 3,
          action: DashboardTour.scrollToTopFunction
        },
        {
          selector: '#divGAFilter',
          content: this.step3.nativeElement.innerHTML,
          orientation: Orientation.BottomLeft,
          useHighlightPadding: true,
          highlightPadding: 3
        },
        {
          selector: '#divFavorite',
          content: this.step4.nativeElement.innerHTML,
          orientation: Orientation.BottomRight,
          useHighlightPadding: true,
          highlightPadding: 3
        },
        {
          selector: 'div.card.tour-supported > div.card-header > span.ta-icon-info-circled',
          content: this.step5.nativeElement.innerHTML,
          orientation: Orientation.BottomLeft,
          useHighlightPadding: true,
          highlightPadding: 3
        },
        {
          selector: 'div.card.tour-supported > div.card-header > button.btn.fa.ta-icon-save',
          content: this.step6.nativeElement.innerHTML,
          orientation: Orientation.BottomRight,
          useHighlightPadding: true,
          highlightPadding: 3,
          closeAction: DashboardTour.removeDrillDownClassFunction
        },
        {
          selector: 'div.card.tour-supported > div.card-body.div-chart',
          content: this.step7.nativeElement.innerHTML,
          orientation: Orientation.TopRight,
          useHighlightPadding: true,
          highlightPadding: 3,
          action: DashboardTour.addDrillDownClassFunction
        },
        {
          content: this.step8.nativeElement.innerHTML,
          orientation: Orientation.Center,
          closeAction: DashboardTour.removeDrillDownClassFunction
        }
      ]
    };
  }

  ngOnDestroy() {
  }

  get header_title() {
    return environment.header_title || 'Demand Dashboard';
  }

  //#endregion

  //#region Methods

  isAdminOpen(): boolean {
    return this._router.isActive('/admin', false);
  }

  get isFreeFeaturePage(): boolean {
    return this._router.isActive(Features.BASIC_USER_ACTIVITY.defaultPath, true)
      || this._router.isActive(Features.PREMIUM_USER_ACTIVITY.defaultPath, true);
  }


  onSwitchMenu() {
    this._uiActions.switchMenu.emit();
  }


  //#endregion
  goToHome() {
    if (this._auth.isLogged) {
      this._router.navigate(['/analytics']);
    }
  }

  //#endregion
  @ViewChild('step0', { static: true }) step0;
  @ViewChild('step1', { static: true }) step1;
  @ViewChild('step2', { static: true }) step2;
  @ViewChild('step3', { static: true }) step3;
  @ViewChild('step4', { static: true }) step4;
  @ViewChild('step5', { static: true }) step5;
  @ViewChild('step6', { static: true }) step6;
  @ViewChild('step7', { static: true }) step7;
  @ViewChild('step8', { static: true }) step8;
  tourStepWidth: number = 500;

  public onTourStart(): void {
    this._productTour.setupTour(this._router.url);
    this._guidedTourService.startTour(this._productTour.tour);
  }

  isTourSupported() {
    return DashboardTour.isTourSupported(this._router.url);
  }

}



