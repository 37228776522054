import { ReplaySubject, Observable } from 'rxjs';

export class CacheSubject<T> {
  private _key: string;
  private _loaded = false;
  private _loading = false;
  private _subject: ReplaySubject<T>;
  private _obs: Observable<T>;
  private _value: T;

  get obs(): Observable<T> {
    return this._obs;
  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._loading = false;
    this._loaded = true;
    this._value = value;
    this._subject.next(value);
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get loaded(): boolean {
    return this._loaded;
  }

  set loaded(value: boolean) {
    this._loaded = value;
  }

  get key(): string {
    return this._key;
  }

  set key(value: string) {
    this._key = value;
  }

  constructor() {
    this._subject = new ReplaySubject<T>(1);
    this._obs = this._subject.asObservable();
  }

  unsubscribe() {
    this._subject.unsubscribe();
  }
}
