import * as moment from 'moment/moment';
import { FacetItem } from '@app/shared/models/facet-item';
import { EventEmitter } from '@angular/core';
import { FilterChangeEvent } from './filter-change-event';
import { Fields } from './fields';

const DATE_FORMAT = 'YYYY-MM-DD';

export class ChartRequest {
  setFacetTimeLined(fieldName: string, active: boolean) {
    const nfc = [];
    let res = null;
    for( let fc of this.facets ) {
        if(fc == fieldName)  {
            if(active) {
                res = fc = fieldName + Fields.DEMAND_TIME_LINE;
            } else {
                res = fc = fieldName.replace(Fields.DEMAND_TIME_LINE, "");
            }
        }
        nfc.push(fc);
    }
    this.facets = nfc;
    return res;
  }
    weekdays: string[] = [];
    fromDate: Date;
    toDate: Date;
    timespan: string;
    facets: string[] = [];
    facetCount: number;

    params: Map<string, string[]> = new Map();
    private timelineParams: Map<string, string[]> = new Map();

    userChangedFilters: Set<string> = new Set<string>();

    public paramsChangeListener: EventEmitter<FilterChangeEvent> = new EventEmitter();

    public constructor(public eventType: string) {
    }

    public getFilterFields(): string[] {
      return Array.from( this.params.keys() );
    }

    public setParams(field: string, v: FacetItem[]): string[] {

        const origIds: string[] = v.map(v => v.id);
        // const ids: string[] = origIds.filter((s, i, a) => a.indexOf(s) == i);
        const ids: string[] = origIds.length ? Array.from(new Set<string>(origIds.toString().split(','))) : [];

        this.setIds( field, ids );

        return origIds;
    }

    private emitChange(field: string, ids: string[]) {
        this.paramsChangeListener.emit(new FilterChangeEvent(field, ids));
    }

    public static extractField(field: string): string {
        const cb = [':'];
        for( const c of cb ) {
            if (field.indexOf(c) != -1) {
                field = field.substring(0, field.indexOf(c));
            }
        }
        return field;
    }

    public clone(): ChartRequest {
        const r: ChartRequest = new ChartRequest(this.eventType);
        r.params = new Map();

        this.params.forEach((value, key) => {
            r.params.set(key, value);
        });
        r.facets = [];
        for (const f of this.facets) {
            r.facets.push(f);
        }
        r.timespan = this.timespan;
        if (this.toDate) {
            r.toDate = new Date(this.toDate.getTime());
        }
        if (this.fromDate) {
            r.fromDate = new Date(this.fromDate.getTime());
        }
        r.facetCount = this.facetCount;
        r.weekdays = [];
        for (const d of this.weekdays) {
          r.weekdays.push(d);
        }

        r.timelineParams = new Map();
        this.timelineParams.forEach((value, key) => {
          r.timelineParams.set(key, value);
        });

        /*r.backupParams = new Map();
        this.backupParams.forEach((value, key) => {
          r.backupParams.set(key, value);
        });*/
        return r;
    }

    public setIds(field: string, ids: string[]) {
        field = ChartRequest.extractField(field);

        if( field == 'number_plate_type' ) {
            field = 'search_type_id';
        }

        if (ids && ids.length) {
            this.params.set(field, ids);
        } else {
            this.params.delete(field);
        }

        this.emitChange(field, ids);
    }

    removeParam(field: string): any {
        field = ChartRequest.extractField(field);

        this.params.delete(field);
    }

    public toggleParam(field: string, v: FacetItem): string[] {
        const id = v.id;
        if (!this.params.get(field)) {
            this.params.set(field, []);
        }
        const index = this.params.get(field).indexOf(id);
        if (index != -1) {
            this.params.get(field).splice(index, 1);
            console.log(this.params);

        } else {
            this.params.get(field).push(id);
        }
        return this.params.get(field);
    }

    public static dateAsString(date: Date) {
        return moment(date).format(DATE_FORMAT);
    }

    private getDateRange(fromDate: Date, toDate: Date): string {
        return '&fromDate=' + ChartRequest.dateAsString(fromDate)
            + '&toDate=' + ChartRequest.dateAsString(toDate);
    }

    public get chartLabel(): string {
        return moment(this.fromDate).format(DATE_FORMAT) + ' ― ' + moment(this.toDate).format(DATE_FORMAT);
    }

    private toParamList(name: string, values: any[]): string {
        if (values && values.length) {
            return '&' + name + '=' + values.join(',');
        }
        return '';
    }

    private fromParamsMap( params, filterFields ): string {
        let r = '';
        let fields = '';
        let coma = '';
        params.forEach((values, field) => {
            if (values && values.length) {
                r += this.toParamList(field, values);
                fields += coma + field;
                coma = ',';
            }
        });
        if( filterFields ) {
            return r + '&filterFields=' + fields;
        }
        return r;
    }

    private getTimespan(): string {
        return this.timespan != null ? '&timespan=' + this.timespan
            : this.getDateRange(this.fromDate, this.toDate);
    }

    private getFacets(): string {
        return '&facets=' + this.facets.join(',');
    }

    private getFacetCount(): string {
        return this.facetCount ? '&facetCount=' + this.facetCount : '';
    }

    private getEventType(): string {
        return 'eventType=' + this.eventType;
    }

    public setTimeLineIds( field, ids: string[] ) {
      console.log('F22 setSpecialParams: ' + field + " = " + ids );
      this.timelineParams.set( field + Fields.TIMELINE_DATASETS, ids );
    }

    public getDatasource(): string {
      return this.params.get('datasource').join(',');
    }

    public resetRequestParams() {
      this.timelineParams.clear();
    }

    public toRequestParams(): string {
      return this.getEventType() +
        this.getTimespan() +
        this.fromParamsMap(this.params, true) +
        this.fromParamsMap(this.timelineParams, false) +
        this.getFacets() +
        this.getFacetCount()
    }

  markFilterFieldUserChanged(field: string) {
    this.userChangedFilters.add(ChartRequest.extractField(field));
  }

  getTimeSpanForTracking() {
      return this.timespan || ChartRequest.dateAsString(this.fromDate) + '—' + ChartRequest.dateAsString(this.toDate);
  }
}
