import { Injectable } from '@angular/core';

import {
    CanActivate,
    CanActivateChild,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

import { OAuth2Service, CisAuthService, AuthService } from '../../core';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private _auth: AuthService,
        private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this._auth.isLogged) {
            return true;
        }

        this._router.navigate(['/portal/login']);

        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }
}