<p-overlayPanel #passwordPanel [dismissable]="true">
  <form [formGroup]="_form">
    <fieldset>
      <div class="container-fluid change-password">
        <p class="user-email">
          <i class="fa fa-envelope"></i>
          {{ getUserEmail() }}
        </p>
        
        <div class="title">
          Change password
          <i [ngClass]="'fa '+(showPassword?'fa-eye-slash':'fa-eye')"
             [pTooltip]="showPassword?'Hide password':'Show password'"
             tooltipPosition="top" (click)="showPassword = !showPassword"></i>
          <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
        </div>
        <div>
          <input [type]="showPassword?'text':'password'" id="oldPassword" maxlength="25" class="required form-control"
                 placeholder="Enter old password" formControlName="oldPassword"
                 [pTooltip]="_formErrors.oldPassword" tooltipPosition="top" tooltipToValidateInput="true">
        </div>
        <div>
          <input [type]="showPassword?'text':'password'" id="newPassword" maxlength="25" class="required form-control"
                 placeholder="Enter new password" formControlName="newPassword"
                 [pTooltip]="_formErrors.newPassword" tooltipPosition="top" tooltipToValidateInput="true"
                 [escape]="false">
        </div>
        <div>
          <input [type]="showPassword?'text':'password'" id="newPasswordAgain" maxlength="25"
                 class="required form-control"
                 placeholder="Enter new password again" formControlName="newPasswordAgain"
                 [pTooltip]="_formErrors.newPasswordAgain" tooltipPosition="top" tooltipToValidateInput="true">
        </div>
        <div class="row alert-danger" *ngIf="errorMessage">
          {{errorMessage}}
        </div>
        <div class="row alert-info" *ngIf="successMessage">
          {{successMessage}}
        </div>
        <div class="row py-2 pull-right">
          <button type="button" pButton label="Close" (click)="passwordPanel.hide()"
                  class="pull-right ui-button-secondary"></button>
          <button type="button" pButton label="Update" (click)="changePassword()"
                  class="ml-2 pull-right default-button" [disabled]="isRequesting"></button>
        </div>
      </div>
    </fieldset>
  </form>
</p-overlayPanel>
