import {Component, OnInit, ViewChild} from '@angular/core';
import {InputText} from 'primeng/inputtext';
import {InputTextarea} from 'primeng/inputtextarea';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Rating} from 'primeng/rating';

import {Feedback} from '@app/shared/models/feedback';
import {ApiService} from '@app/shared';
import {BasicResponse} from '@app/shared/models/basic-response';

@Component({
  selector: 'feedback-tool',
  templateUrl: './feedback-tool.component.html',
  styleUrls: ['./feedback-tool.component.scss']
})
export class FeedbackToolComponent implements OnInit {

  constructor( private api: ApiService ) { }

  maxRatting = 5;
  subjectMaxLen = 50;
  messageMaxLen = 500;

  feedback: Feedback = <Feedback>{
    maxRatting: this.maxRatting
  };
  errorMessage;
  successMessage;
  isRequesting:boolean = false;
  feedbackSended: boolean = false;

  @ViewChild('feedbackPanel', {static: true}) feedbackPanel: OverlayPanel;
  @ViewChild('subjectInput', {static: true}) subjectInput: InputText;
  @ViewChild('messageInput', {static: true}) messageInput: InputTextarea;
  @ViewChild('ratting', {static: true}) ratting: Rating;


  ngOnInit() {
  }



  public show(event) {
    this.feedbackPanel.show(event);
    if( !this.isRequesting ) {
      this.resetVars();
    }
  }

  public toggle(event) {
    if (!this.feedbackPanel.overlayVisible) {
      this.feedbackPanel.show(event);
      if( !this.isRequesting ) {
        this.resetVars();
      }
    } else {
      this.feedbackPanel.hide();
    }
  }

  private resetFeedback() {
    this.feedback = <Feedback>{
      maxRatting: this.maxRatting
    };
  }

  private resetVars() {
    this.successMessage = null;
    this.errorMessage = null;
    this.isRequesting = false;
    this.feedbackSended = false;
  }

  validateSubjectInput() {
    if (!this.feedback.subject || !this.feedback.subject.trim().length) {
      this.errorMessage = 'Missing subject';
      return false;
    } else {
      this.errorMessage = null;
      return true;
    }
  }

  validateMessageInput() {
      if( !this.feedback.message || !this.feedback.message.trim().length ) {
        this.errorMessage = 'Missing comment';
        return false;
      } else {
        this.errorMessage = null;
        return true;
      }
  }

  validateAllInput() {
    if( !this.validateSubjectInput() ) {
      return false;
    }
    if( !this.validateMessageInput() ) {
      return false;
    }
    return true;
  }

  sendFeedback() {

    if( !this.validateAllInput() ) {
      return;
    }

    this.isRequesting = true;
    this.api.sendFeedback( this.feedback )
      .subscribe(( response: BasicResponse ) => {
        this.isRequesting = false;
        if( response.statusCode == 200 ) {
          this.successMessage = 'Your feedback is sent successfully!';
          this.feedbackSended = true;
          this.resetFeedback();
        } else {
          this.errorMessage = response.message;
        }
      }
      );
  }
}
