import {Component, ViewChild} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import {NavigationService, TrackerService} from '@app/core';
import {ApiService} from '@app/shared';
import {TranslateService} from '@ngx-translate/core';
import {ChartRequest} from '@app/shared/models/chart-request';
import {Fields} from '@app/shared/models/fields';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {EventTypes} from '@app/shared/models/event-types';
import {FacetItem} from '@app/shared/models/facet-item';
import {InfoTexts} from '@app/shared/models/info-texts';
import {ActivatedRoute, Router} from '@angular/router';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'market-overview-trends',
  templateUrl: './market-overview-trends.component.html',
  styleUrls: ['./market-overview-trends.component.scss']
})
export class MarketOverviewTrendsComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.GENART_INFO;
  }

  getPageStoreKey(): string {
    return 'MarketOverviewTrendsComponent';
  }

  getChartRequest(): ChartRequest {
    let chartRequest = new ChartRequest(EventTypes.ARTICLE_SELECTION);
    chartRequest.facetCount = 30;
    return chartRequest;
  }

  public readonly fieldGenartNo = Fields.GENART_NO;
  public readonly fieldCountries = Fields.LOCATION_COUNTRY

  get pageName(): string {
    return 'Market overview trends component';
  }

  public genartChartHeadLabel = 'Generic articles';
  public countriesChartHeadLabel = 'Countries';
  @ViewChild('genartChart', {static: true}) genartChart: ChartActor;
  @ViewChild('countriesChart', {static: true}) countriesChart: ChartActor;

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected route: ActivatedRoute,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [Fields.GENART_NO + Fields.DEMAND_TIME_LINE, Fields.LOCATION_COUNTRY + Fields.DEMAND_TIME_LINE];
  }

  getChartActors(): ChartActor[] {
    return [this.genartChart, this.countriesChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem, fieldName?: string): void {

  }

  protected getFeatureOnMenu() {
    return Features.MARKET_OVERVIEW;
  }

}
