import {Component} from '@angular/core';
import {AnalyticsPage} from '../analytics-page';
import {NavigationService, TrackerService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {ActivatedRoute, Router} from '@angular/router';
import {Fields} from '@app/shared/models/fields';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ChartRequest} from '@app/shared/models/chart-request';
import {EventTypes} from '@app/shared/models/event-types';
import {FoundByChartParams} from '@app/shared/models/found-by-chart-params';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {FoundBy} from '@app/shared/models/found-by';
import {FacetItem} from '@app/shared/models/facet-item';
import * as moment from 'moment/moment';
import {ReportParams} from '@app/shared/models/i-analytics-page';
import {Recommender} from '@app/shared/models/recommender';
import {RecommendationPage} from '@app/shared/models/recommendionsPage';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'generic-article-details',
  templateUrl: './generic-article-details.component.html',
  styleUrls: ['./generic-article-details.component.scss']
})
export class GenericArticleDetailsComponent extends AnalyticsPage
  implements RecommendationPage {
  public readonly BRAND_ASSOC = Fields.BRAND_ASSOCIATIONS;
  public readonly GENART_ASSOC = Fields.GENART_ASSOCIATIONS;

  public charts: FoundByChartParams[] = [];
  public report: ReportResponse;
  public foundBy: FoundBy;

  public recommender: Recommender;

  hasBrandsRecommends = false;
  hasGenartRecommends = false;

  recommendsOthers: Map<String, FacetItem[]> = new Map();
  recommendsOriginal: Map<String, FacetItem[]> = new Map();
  article: any;

  isBrandLess = false;

  public get infoText(): string {
    return '[' + this.pageRequest.genartNo + '] ' + this.pageRequest.displayName;
  }

  getPageFacets(): string[] {
    return [Fields.FOUND_BY];
  }

  getChartActors(): ChartActor[] {
    return [];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(EventTypes.ARTICLE_SELECTION);
  }

  reportLoaded(data: ReportResponse): void {
    if (this.report) {
      this.report.mergeFacets(data);
    } else {
      this.report = data;
    }
    this.charts = [];

    console.log('reportLoaded', data);

    this.foundBy.init(this.charts, data, this.pageRequest.fieldName);

    window.setTimeout(() => {
      this.dataSubscriber.emit(data);
    }, 300);

    this.loadRecomendations();
  }

  itemSelected(item: FacetItem, fieldName?: string): void {
    this.charts = this.foundBy.itemSelected(item, fieldName);

    setTimeout(() => {
      this.dataSubscriber.emit(this.report);
    }, 500);
  }

  getPageStoreKey(): string {
    return 'GenericArticleDetailsComponent';
  }

  get pageName(): string {
    return 'Generic article details';
  }

  constructor(
    appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    public apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
    super(appInsights, confirm, translate, navigation, apiService, router);
    this.foundBy = new FoundBy('a');
    this.recommender = new Recommender();
    this.needFilterData = false;
  }

  ngOnInit() {

    const isBrandLess = this.pageRequest.fieldName.indexOf(Fields.BRANDLESS) != -1;

    //console.log('isBrandLess: ' + isBrandLess + ", fieldName: " + this.pageRequest.fieldName);

    const r = this.getChartParams();

    r.facets = [Fields.FOUND_BY];
    if (isBrandLess) {
      r.facets = [Fields.FOUND_BY_BRANDLESS];
    }
    r.facetCount = -1;

    r.params = this.pageRequest.params_map;
    r.setIds(Fields.GENART_NO, [this.pageRequest.genartNo]);

    if (this.pageRequest.timespan) {
      r.timespan = this.pageRequest.timespan;
    } else {
      if (this.pageRequest.fromDate) {
        r.fromDate = moment(this.pageRequest.fromDate).toDate();
      }
      if (this.pageRequest.toDate) {
        r.toDate = moment(this.pageRequest.toDate).toDate();
      }
    }

    console.log('article-details chart request:', r);

    const rp = <ReportParams>{
      skipCache: true,
      notNeedFilterData: true
    };

    this.loadReport(rp);
  }

  loadRecomendations() {
    if (!this.report) {
      return;
    }
    //this.recommender.loadRecomendations(this);
  }

  protected loadReportCustomized(params?: ReportParams): boolean {
    if (
      params &&
      (params.facetField == this.BRAND_ASSOC ||
        params.facetField == this.GENART_ASSOC)
    ) {
      if (params.export) {
        ChartData.exportAsCsv(
          this.recommendsOriginal.get(params.facetField),
          params.facetField +
          '_' +
          this.pageRequest.brandNo +
          '_' +
          this.pageRequest.articleNumber +
          '.csv',
          ['id', 'name', 'count']
        );
      }
      return true;
    }
    return false;
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }

  createInfoText(detailInfo: string) {
    if (!detailInfo) {
      return this.infoText;
    }
    return this.infoText + ' - ' + detailInfo;
  }
}
