import { Injectable } from "@angular/core";
import { LanguagesRequest } from "@app/shared/models/common/languages/languages-request";
import { LanguagesResponse } from "@app/shared/models/common/languages/languages-response";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ContextService, CisResponse } from "@app/core";
import { tap } from "rxjs/operators";
import { CisResponseStatusCodes } from "@app/shared/enums/response-status-codes.enum";
import { CisError } from "@app/shared/models/common/cis-error";
import { environment } from "@env/environment";

@Injectable()
export class TecdocCommonService {

    private readonly defaultHeaders: HttpHeaders;

    constructor( private http: HttpClient, private context: ContextService ) {
        this.defaultHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    }

    private post<T extends CisResponse>( body: any ): Observable<T>  {        
        return this.http.post<T>(environment.cis.services.baseUrl, 
                    { headers: this.defaultHeaders,
                        params: body }).pipe(tap(response => {
                            TecdocCommonService.checkResponseForError(<CisResponse>response)}));
    }

    public getLanguages( languagesRequest: LanguagesRequest ): Observable<LanguagesResponse> {
        return this.post<LanguagesResponse>({ getLanguages: { arg0: languagesRequest } });
    }

     /**
   * Checks status of given response.
   * @param {(T extends CisResponse)} response - Object of an CisResponse extending type.
   * @template T
   * @summary Checks given response for error and resolves it when occurs. Returns {undefined} when there're no issues.
   * @memberOf TecdocHttpClientService
   * @static
   */
  private static checkResponseForError<T extends CisResponse>(response: T): void {
    const errorOccurred = TecdocCommonService.resolveResponseStatus(response);
    if (errorOccurred !== undefined) {
      throw errorOccurred;
    }
  }

    /**
     * Resolves a Cis response by it's status.
     * @param {CisResponse} response - Cis full (outer) response object.
     * @returns {(CisError|undefined)} - Cis error report object.
     * @summary Resolves a Cis response by analysing the statuses. Returns an error message object when an error occurs.
     * @memberOf TecdocHttpClientService
     * @static
     */
    private static resolveResponseStatus(response: CisResponse): CisError | undefined {
        switch (response.status) {
          case CisResponseStatusCodes.OK:
          case CisResponseStatusCodes.ORDER_OK_SAVE_BASKET_ERROR:
          case CisResponseStatusCodes.ORDER_OK_WAWI_ERROR:
            return undefined;
        }
        if (!!response.statusText && response.statusText.length > 0) {
          return new CisError(response);
        } else {
          return new CisError(response, 'TecdocHttpClientService');
        }
      }
    
}