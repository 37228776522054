import {
  Component, ElementRef,
  HostListener,
  OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  //moduleId: module.id,
  selector: 'accessible-shell',
  templateUrl: 'accessible-shell.html',
  styleUrls: ['accessible-shell.scss']
})
export class AccessibleShell implements OnInit {
  //#region Fields

  _wallpapers: Array<string> = [
    'assets/images/wallpapers/ddb_login.png',
    // 'assets/images/wallpapers/2.jpg',
    // 'assets/images/wallpapers/3.jpg'
  ];

  //#endregion

  //#region Constructor

  constructor() {

  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {

  }

  //#endregion

  //#region Events Handlers
  //#endregion
  onScroll( event: Event) {

  }
}
