import {Component, ViewChild} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService, TrackerService} from '@app/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ApiService} from '@app/shared';
import {Fields} from '@app/shared/models/fields';
import {ChartRequest} from '@app/shared/models/chart-request';
import {EventTypes} from '@app/shared/models/event-types';
import {FacetItem} from '@app/shared/models/facet-item';
import {InfoTexts} from '@app/shared/models/info-texts';
import {PageRequest} from '@app/shared/models/page-request';
import {ActivatedRoute, Router} from '@angular/router';
import {Features} from '@app/shared/models/features';
import {MenuItem} from 'primeng/api';
import {ReportParams} from '@app/shared/models/i-analytics-page';

@Component({
  selector: 'ta-analytics-most-searched-vehicles',
  templateUrl: 'ta-analytics-most-searched-vehicles.component.html',
  styleUrls: ['ta-analytics-most-searched-vehicles.component.scss']
})
export class TaAnalyticsMostSearchedVehiclesComponent extends AnalyticsPage {

  public itemVehicles: MenuItem = { label: 'Vehicles' };
  public itemMakes: MenuItem = { label: 'Manufacturers' };
  public itemModels: MenuItem = { label: 'Models' };

  public items: MenuItem[] = [];

  public selectedItem: MenuItem = this.itemVehicles;

  public activateMenu( tab ) {
     this.selectedItem = tab.activeItem;
  }

  public get infoText(): string {
    return InfoTexts.VEHICLE_INFO;
  }

  public vehicleSearchTooltip = '<div><b>E.g.</b> "golf" --> Exact match</div>'
  + '<div>golf* --> Starts with golf</div>'
  + '<div>*golf --> Ends with golf</div>'
  + '<div>*golf* --> Contains golf</div>';

  getPageStoreKey(): string {
    return "TaAnalyticsMostSearchedVehiclesComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(EventTypes.VEHICLE_SELECTION);
  }

  reportLoaded(data: ReportResponse): void {
      const menuItems = [];

      if( data.hasFacets( Fields.VEHICLE_MAKES ) ) {
        console.log( data.facets.get(Fields.VEHICLE_MAKES) );
        menuItems.push(this.itemMakes);
      }
      if( data.hasFacets( Fields.VEHICLE_MODELS ) ) {
        menuItems.push(this.itemModels);
      }
      if( menuItems.length ) {
        menuItems.push(this.itemVehicles);
      } else {
        this.selectedItem = this.itemVehicles;
      }

      this.items = menuItems;
  }

  @ViewChild('vehiclesChart', {static: true}) vehiclesChart: ChartActor;
  @ViewChild('vehiclesBrandlessChart', {static: true}) vehiclesBrandlessChart: ChartActor;

  @ViewChild('vehicleMakesChart', {static: true}) vehicleMakesChart: ChartActor;
  @ViewChild('vehicleMakesBrandlessChart', {static: true}) vehicleMakesBrandlessChart: ChartActor;

  @ViewChild('vehicleModelsChart', {static: true}) vehicleModelsChart: ChartActor;
  @ViewChild('vehicleModelsBrandlessChart', {static: true}) vehicleModelsBrandlessChart: ChartActor;

  constructor(appInsights: TrackerService,
    confirm: ConfirmationService,
    public translate: TranslateService,
    public navigation: NavigationService,
    public apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router ) {
      super(appInsights, confirm, translate, navigation, apiService, router);
  }

  public fieldVehicles = Fields.VEHICLES;
  public fieldVehiclesBradLess = Fields.VEHICLES_BRANDLESS;

  public fieldVehicleMakes = Fields.VEHICLE_MAKES;
  public fieldVehicleMakesBrandLess = Fields.VEHICLE_MAKES_BRANDLESS;

  public fieldVehicleModels = Fields.VEHICLE_MODELS;
  public fieldVehicleModelsBradLess = Fields.VEHICLE_MODELS_BRANDLESS;

  public chartLabel = 'Your Brand(s)';
  public chartLabelMarket = 'Market View';

  get pageName(): string {
    return 'Most popular vehicles';
  }

  getPageFacets(): string[] {
    return  [ Fields.VEHICLES, Fields.VEHICLES_BRANDLESS,
      Fields.VEHICLE_MAKES, Fields.VEHICLE_MAKES_BRANDLESS,
      Fields.VEHICLE_MODELS, Fields.VEHICLE_MODELS_BRANDLESS ];
  }

  getChartActors(): ChartActor[] {
    return [ this.vehiclesChart, this.vehiclesBrandlessChart,
            this.vehicleMakesChart, this.vehicleMakesBrandlessChart,
             this.vehicleModelsChart, this.vehicleModelsBrandlessChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  itemSelected(item: FacetItem, fieldName?:string): void {

    const p = this.chartParams.clone().params;
    p.set(Fields.DATASOURCE, this.mergeDataSourceIds( item.datasource ) );

    const r = new PageRequest();
    r.vehicleId = item.id;
    r.fieldName = fieldName;
    r.timespan = this.chartParams.timespan;
    r.setParams( p );

    if( r.timespan ) {
      delete r.fromDate;
      delete r.toDate;
    } else {
      r.fromDate = ChartRequest.dateAsString( this.chartParams.fromDate );
      r.toDate = ChartRequest.dateAsString( this.chartParams.toDate );
      delete r.timespan;
    }

    this.router.navigate( [ 'details/level1', r ], { relativeTo: this.route } );
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }



}
