<h1 class="display-4 hidden-xs-down">
    Data Protection Declaration </h1>
<p>
    <b>of TecAlliance GmbH</b>
</p>
<hr>
<div class="row">
    <div class="col-md-12">
        <div class="text-justify">
            <h2> General information </h2>
            <p> We, TecAlliance GmbH, based in Ismaning, take protecting your private data very seriously and would like for
                you to feel comfortable visiting our website.Protecting your privacy when handling personal data is an important
                concern for us, which we take into account in our business processes.We handle data that is collected when
                visiting our website in accordance with the data protection regulations of the Federal Republic of Germany.
                <br>
            </p>

            <h2>Collection and processing of personal data</h2>
            <p>Personal data is only handled within the scope of the applicable statutory regulations. This means that TecAlliance
                GmbH (and the agency involved, INCREON) may collect, process and use your personal data(e.g.name, address,
                telephone number or e-mail address) insofar as it is required for the establishment, content-related arrangement
                and amendment of the contractual relationship with regard to the use of the website and the offered services
                and offerings.</p>
            <p>
                Every access to our website is recorded for a limited period in log files in line with the law. This concerns your IP address,
                the website from which you are visiting us, our websites that you are visiting, as well as the date and the
                duration of the visit.
                <br>This data is exclusively used for internal purposes, i.e. for statistical purposes as well as to ensure the
                stability and operational security of the system.
            </p>
            <p>TecAlliance GmbH does not collect any personal data via these websites insofar as you have not provided it to
                us voluntarily and submitted your declaration of consent in the corresponding place in the menu.
                <br>This personal data is only used for the purpose stated in the statement of consent and is deleted immediately
                after performing the respective service.The collected data is not disseminated to third parties (subject
                to a court order as part of a criminal investigation).</p>
            <p>Naturally, you can withdraw your consent at any time in whole or in part with effect for the future with regard
                to TecAlliance GmbH by simply sending an e-mail to&nbsp;
                <a href="mailto:dataprivacy@tecalliance.net" title="Opens window for sending email">dataprivacy@tecalliance.net </a>. We will then delete the affected data where not otherwise agreed with you.
            </p>

            <h2> Use of Cookies</h2>
            <p>Cookies are files that the web server leaves behind on the computers of users for the purpose of tracking. We
                use cookies to facilitate repeated access and the use of our website for users.They do not cause any damage
                to computer systems and files.It is possible in most browsers to block the saving of all cookies, remove
                existing cookies and warn the user before a cookie is saved.
                <br>
            </p>

            <h2> Data Security</h2>
            <p>Your data is hosted with a provider in accordance with Section 11 of the Federal Data Protection Act (BDSG, Bundesdatenschutzgesetz).
                Both TecAlliance GmbH and the provider employ technical and organisational security measures to protect your
                collected data from accidental or deliberate manipulation, loss, destruction, and against access by unauthorised
                persons.Our security measures are constantly being improved in line with technological advancements.
                <br>
            </p>

            <h2> Right to information</h2>
            <p>
                On request, we will be happy to inform you, in line with applicable law, whether and which personal data is saved with us
                about you.Our contact details:
                <br>
                <a href="mailto:dataprivacy@tecalliance.net" title="Opens window for sending email">dataprivacy@tecalliance.net </a>
                <br>TecAlliance GmbH
                <br>Steinheilstr. 10
                <br>85737 Ismaning
                <br>
            </p>

            <h2>Changes to this data protection statement</h2>
            <p>
                Please note that this data protection statement can be amended if new legal regulations make this necessary.
                <br>Ismaning, 1st May 2015
            </p>
        </div>
    </div>
</div>
