export enum EventTypes {
  ARTICLE_SEARCH = 'article_search',
  ARTICLE_SELECTION = 'article_selection',
  ARTICLE_VISIBILITY = 'article_visibility',
  VEHICLE_SEARCH = 'vehicle_search',
  VISITOR_SEARCH = 'visitor_search',
  TECDOC_3_REPORT = 'tecdoc_3_report',
  VEHICLE_SELECTION = 'vehicle_selection',
  VIN_SEARCH = 'vin_search',
  NUMBERPLATE_SEARCH = 'numberplate_search'
}
