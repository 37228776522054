import {ChangeDetectorRef, Component, SimpleChanges, ViewChild} from '@angular/core';
import {GrowlService, ReactiveFormComponent} from '@app/shared';
import {ForgotPasswordData} from '@app/accessible/models/ForgotPasswordData';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {AuthService, CisResponse} from '@app/core';
import {EMPTY, Observable} from 'rxjs';
import {CustomValidators} from '@app/shared/components/CustomValidators';
import {OverlayPanel} from 'primeng/overlaypanel';
import {ChangePasswordRequest} from '@app/core/services/security/cis/change-password-request';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends ReactiveFormComponent<ForgotPasswordData,
  ForgotPasswordData> {

  errorMessage: string;
  successMessage: string;
  isRequesting: boolean = false;
  showPassword: boolean = false;

  @ViewChild('passwordPanel', {static: true}) passwordPanel: OverlayPanel;

  constructor(
    ref: ChangeDetectorRef,
    growl: GrowlService,
    fb: FormBuilder,
    private _auth: AuthService
  ) {
    super(ref, growl, fb);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  //#region ReactiveFormComponent Methods
  public refreshI18nFormControls(): void {
  }

  isInputValueTheEditModel(): boolean {
    return true;
  }

  buildForm(): void {
    this._form = this._fb.group({
        oldPassword: new FormControl('', [Validators.required]),
        newPassword: new FormControl('', CustomValidators.password().validators),
        newPasswordAgain: new FormControl('', CustomValidators.passwordRepeat().validators),
      },
      {
        validator: CustomValidators.passwordMatch('newPassword', 'newPasswordAgain')
      });
  }

  buildValidationMessages(): void {
    this._validationMessages = {
      oldPassword: {
        required: this.VALIDATION_REQUIRED,
      },
      newPassword: CustomValidators.password().messages,
      newPasswordAgain: CustomValidators.passwordRepeat().messages
    };
  }

  loadModel(params: ForgotPasswordData): Observable<ForgotPasswordData> {
    throw new Error('Function not implemented');
  }

  saveModel(): Observable<ForgotPasswordData> {
    this.isRequesting = true;

    const request: ChangePasswordRequest = <ChangePasswordRequest>{
      oldPassword: this._form.get('oldPassword').value,
      newPassword: this._form.get('newPassword').value
    };

    this._auth.changePassword(request).subscribe((response: CisResponse) => {
        this.isRequesting = false;
        if (response.status == 200) {
          this.passwordPanel.hide();
          this._growl.infoMessage('Password is changed successfully.', '', 5000);
        } else {
          this.errorMessage = response.statusText;
        }
      }
    );

    return EMPTY;
  }

  deleteModel(): Observable<any> {
    throw new Error('Function not implemented');
  }

  modelReady(): void {
    if (!this._form) {
      return;
    }
    this._form.reset();
    if (!this.model) {
      return;
    }
  }

  public toggle(event) {
    if (!this.passwordPanel.overlayVisible) {
      this.passwordPanel.show(event);
      if (!this.isRequesting) {
        this.resetVars();
      }
    } else {
      this.passwordPanel.hide();
    }
  }

  private resetVars() {
    this.successMessage = null;
    this.errorMessage = null;
    this.isRequesting = false;
    this._form.reset();
  }

  changePassword() {
    this.onValueChanged();

    this.save();
  }

  getUserEmail(): string {
    return this._auth.userDetail && this._auth.userDetail.email ?  this._auth.userDetail.email : "";
  }

}
