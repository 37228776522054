import {Component, ViewChild} from '@angular/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {ChartActor} from '@app/shared/components/chart-actor';
import {NavigationService, TrackerService, UtilsService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {FacetItem} from '@app/shared/models/facet-item';
import {ActivatedRoute, Router} from '@angular/router';
import {Collections} from '@app/shared/models/collections';
import {formatNumber} from '@angular/common';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {TimeLineRows} from '@app/shared/models/analytics/time-line-rows';
import "chartjs-plugin-colorschemes";
import {Features} from '@app/shared/models/features';
import {DownloadHandler} from '@app/shared/models/download-handler';
import {DownloadStatus} from '@app/shared/models/download-status';
import {TabView} from 'primeng/tabview';

@Component({
  selector: 'most-searched-visitors',
  templateUrl: './most-searched-visitors.component.html',
  styleUrls: ['./most-searched-visitors.component.scss']
})
export class MostSearchedVisitorsComponent extends AnalyticsPage {

   topOptions: any;
   displayCount = 10;

  public get infoText(): string {
    return '';
  }

  getPageStoreKey(): string {
    return 'MostSearchedVisitorsComponent';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(Collections.ARTICLE_SELECTION);
  }

  get pageName(): string {
    return 'Most searched visitors';
  }

  @ViewChild('tabView', {static: true}) tabView: TabView;

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected route: ActivatedRoute,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);

    this.topOptions = [
      {label: 'Top 10', value: 10},
      {label: 'Top 20', value: 20},
      {label: 'Top 30', value: 30}
    ];

    this.displayCount = 10;
  }

  public changeTop($event: any) {
    this.displayCount = $event.value;
  }


  getPageFacets(): string[] {
    return [];
  }

  getChartActors(): ChartActor[] {
    return [];
  }

  getReportPath(): string {
    return '/piwikApi/visitors';
  }

  getExportPath(): string {
    return '/piwikApi/export';
  }

  public siteTraffics: SiteTraffic[] = [];
  public visitorsBySourceMediumCols: string[] = null;
  private readonly VISIT_OVERVIEW_FACET_PREFIX = 'siteVisitOverview_';
  private readonly VISIT_BY_REFERER_TYPE_FACET_PREFIX = 'siteVisitByRefererType_';
  private readonly VISIT_BY_SOURCE_MEDIUM_FACET_PREFIX = 'siteVisitBySourceMedium_';
  public readonly VISITORS_SOURCE_MEDIUM_PERCENTAGE = 'visitors_source_medium_percentage';
  public readonly SOURCE_MEDIUM_LABEL = 'Source / medium';
  public readonly pieOptions = {
    legend: {
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let itemIndex = tooltipItem.index;
          let dataset = data.datasets[tooltipItem.datasetIndex];

          let fieldLabel = data.labels[itemIndex];
          let fieldValue = dataset.data[itemIndex];

          let percentage = fieldLabel + ': ' + UtilsService.simpleNumberFormat(fieldValue) + '%';
          return percentage;
        }
      }
    }
  };

  readonly chartOptions = {
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let itemIndex = tooltipItem.index;
          let dataset = data.datasets[tooltipItem.datasetIndex];
          return UtilsService.simpleNumberFormat(dataset.data[itemIndex]);
          
        }
      }
    },

    scales: {
      xAxes: [{
        ticks: {
          callback: function (value) {
            value = UtilsService.shortenInteger(value);
            if (value && value.length > 20) {
              return value.substr(0, 20) + ' ...';//truncate
            }
            return value;
          },
          beginAtZero: true,
          display: true
        }
      }],
      yAxes: [{
        ticks: {
          callback: function (value) {
            value = UtilsService.shortenInteger(value);
            if (value && value.length > 20) {
              return value.substr(0, 20) + ' ...';//truncate
            }
            return value + '    ';
          },
          beginAtZero: true,
          display: true
        }
      }
      ]
    }
  };

  reportLoaded(data: ReportResponse): void {
    this.siteTraffics = [];
    for (let [facetKey, facetValue] of data.facets) {
      let siteId = this.extractSiteId(facetKey);
      if (siteId !== null) {
        let siteTraffic = this.calculateSiteTraffic(siteId, facetKey, facetValue);
        siteTraffic.label = facetValue.label;
        this.addSiteTraffic(siteTraffic);
      }
    }
    for (let siteTraffic of this.siteTraffics) {
      this.calculateVisitorBySourceMediumPercentage(siteTraffic);
    }

  }

  private calculateVisitorBySourceMediumPercentage(siteTraffic: SiteTraffic) {
    let totalUniqueVisitors = siteTraffic.visitorOverview.get('Visitors');
    for (let visitorsBySourceMedium of siteTraffic.visitorsBySourceMediums) {
      let visitors = visitorsBySourceMedium.get('Visitors');
      let visitorsPercentage = UtilsService.simpleNumberFormat(Number((visitors / totalUniqueVisitors * 100).toFixed(2)));
      visitorsBySourceMedium.set(this.VISITORS_SOURCE_MEDIUM_PERCENTAGE, visitorsPercentage);
    }
  }

  private calculateSiteTraffic(siteId: string, facetKey: string, facetValue: ChartData): SiteTraffic {
    let siteTraffic = this.getSiteTraffic(siteId);
    if (facetKey.startsWith(this.VISIT_OVERVIEW_FACET_PREFIX)) {
      this.calculateTrafficOverview(siteTraffic, facetValue);
    } else if (facetKey.startsWith(this.VISIT_BY_REFERER_TYPE_FACET_PREFIX)) {
      this.calculateVisitorsByRefererType(siteTraffic, facetValue);
    } else if (facetKey.startsWith(this.VISIT_BY_SOURCE_MEDIUM_FACET_PREFIX)) {
      this.calculateVisitorsBySourceMedium(siteTraffic, facetValue);
      if (siteTraffic.visitorsBySourceMediums.length !== 0) {
        this.initVisitsBySourceMediumColumns(siteTraffic.visitorsBySourceMediums[0]);
      }
    }

    console.log('site traffic, ', siteTraffic);
    return siteTraffic;
  }

  private addSiteTraffic(siteTraffic: SiteTraffic) {
    if (this.findSiteTraffic(siteTraffic.siteId) == null) {
      this.siteTraffics.push(siteTraffic);
    }
  }

  private extractSiteId(facetKey: string): string {
    if (facetKey.startsWith(this.VISIT_OVERVIEW_FACET_PREFIX)) {
      return facetKey.replace(this.VISIT_OVERVIEW_FACET_PREFIX, '');
    }
    if (facetKey.startsWith(this.VISIT_BY_REFERER_TYPE_FACET_PREFIX)) {
      return facetKey.replace(this.VISIT_BY_REFERER_TYPE_FACET_PREFIX, '');
    }
    if (facetKey.startsWith(this.VISIT_BY_SOURCE_MEDIUM_FACET_PREFIX)) {
      return facetKey.replace(this.VISIT_BY_SOURCE_MEDIUM_FACET_PREFIX, '');
    }
    return null;
  }

  private findSiteTraffic(siteId: string): SiteTraffic {
    return this.siteTraffics.find(st => st.siteId === siteId);
    /*if (!siteTraffics || siteTraffics.length === 0) {
      return null;
    }
    return siteTraffics[0];*/
  }

  private getSiteTraffic(siteId: string): SiteTraffic {
    let siteTraffic = this.findSiteTraffic(siteId);
    return siteTraffic == null ? new SiteTraffic(siteId) : siteTraffic;
  }

  private calculateVisitorsByRefererType(siteTraffic: SiteTraffic, facetValue: ChartData) {
    siteTraffic.visitorsByRefererType = facetValue.timeLine;
  }

  private calculateTrafficOverview(siteTraffic: SiteTraffic, facetValue: ChartData) {
    let visitOverview = facetValue.originalData[0];

    for (let fieldLabel in visitOverview.details) {
      if (!visitOverview.details.hasOwnProperty(fieldLabel)) {
        continue;
      }
      let fieldValue = visitOverview.details[fieldLabel];
      if (fieldLabel === 'Returning visitors rate') {
        siteTraffic.newVsReturningVisitor = this.createNewVsReturningPieModel(fieldValue);
      } else {
        fieldValue = this.roundNumberValue(fieldLabel, fieldValue);

        siteTraffic.visitorOverview.set(fieldLabel, fieldValue);
      }
    }
  }

  private createNewVsReturningPieModel(returning: number): any {
    let returningPercent = Number(Number(returning * 100).toFixed(2));
    return {
      labels: ['New', 'Returning'],
      datasets: [
        {
          data: [100 - returningPercent, returningPercent],
          backgroundColor: [
            '#FFC107',
            '#03A9F4'
          ],
          hoverBackgroundColor: [
            '#FFE082',
            '#81D4FA'
          ]
        }]
    };
  }

  private roundNumberValue(fieldLabel: string, fieldValue: number): any {
    if (fieldLabel.endsWith('rate')) {
      fieldValue = fieldValue * 100;
    }
    return fieldValue % 1 === 0 ? fieldValue : UtilsService.simpleNumberFormat(Number(fieldValue.toFixed(2)));
  }

  public formatFieldValue(fieldValue: any, fieldLabel: string = ''): string {

    if (typeof fieldValue === 'undefined') {
      return '';
    }

    if (isNaN(fieldValue) && isNaN(fieldValue.replaceAll(',', ''))) { //accommodate for number that have , as decimal separation
      return fieldValue;
    }
    let suffix = fieldLabel.endsWith('rate') ? '%' : '';//doesn't work on firefox
    // let suffix = fieldLabel.indexOf('rate') === fieldLabel.length - 4 ? '%' : '';
    return UtilsService.simpleNumberFormat(fieldValue) + suffix;
  }

  itemSelected(item: FacetItem): void {

  }

  private calculateVisitorsBySourceMedium(siteTraffic: SiteTraffic, facetValue: ChartData) {
    for (let facetItem of facetValue.originalData) {
      let visitsBySourceMedium = new Map<string, any>();
      visitsBySourceMedium.set(this.SOURCE_MEDIUM_LABEL, facetItem.id);
      for (let fieldLabel in facetItem.details) {
        if (!facetItem.details.hasOwnProperty(fieldLabel)) {
          continue;
        }
        visitsBySourceMedium.set(fieldLabel, this.roundNumberValue(fieldLabel, facetItem.details[fieldLabel]));
      }
      siteTraffic.visitorsBySourceMediums.push(visitsBySourceMedium);
    }
  }

  private initVisitsBySourceMediumColumns(visitsBySourceMedium: Map<string, any>) {
    if (this.visitorsBySourceMediumCols !== null) {
      return;
    }
    this.visitorsBySourceMediumCols = [];
    for (let col of visitsBySourceMedium.keys()) {
      if(col == 'Goal conversion rate') {
        continue; // should not be displayed - see https://tecalliance.visualstudio.com/Demand%20Dashboard/_workitems/edit/330189
                  // may be later for catalogs which have shopping cart
      }

      this.visitorsBySourceMediumCols.push(col);
    }
  }

  public customSort($event: any) {
    $event.data.sort((data1: Map<string, any>, data2: Map<string, any>) => {
      let value1 = data1.get($event.field);
      let value2 = data2.get($event.field);
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return ($event.order * result);
    });
  }

  protected getFeatureOnMenu() {
    return Features.BASIC_USER_ACTIVITY;
  }

  exportData( siteTraffic: SiteTraffic ) {

    if( siteTraffic.visitorsBySourceMediums.length ) {

      const fileName = siteTraffic.label + '.csv';

      const rows = [['sep=;']];
      const header = ['Source / medium', 'Visitors', 'Visitors percentage', 'Average page views in session'];
      rows.push(header);

      for(let i = 0; i < siteTraffic.visitorsBySourceMediums.length; i++) {
        const row = siteTraffic.visitorsBySourceMediums[i];
        const rowValues = [];
        for( let field of header ) {

          if( field == header[2]) {
            field = this.VISITORS_SOURCE_MEDIUM_PERCENTAGE;
          }

          let val = row.get(field) || '';
          if( field == this.VISITORS_SOURCE_MEDIUM_PERCENTAGE) {
            if(!val) {
              val = '0,00';
            }
            val = ('' + val).replace('.', ',') + '%';
          }
          if( field === header[3] ) {
            val = ('' + val).replace('.', ',');
          }
          rowValues.push( val );
        }
        rows.push(rowValues);
      }

      UtilsService.exportToCsv(fileName, rows);
    }
  }

  getFilteredData(visitorsBySourceMediums: Map<string, any>[]) {
    let r: Map<string, any>[] = [];

    const sorted: Map<string, any>[] = visitorsBySourceMediums.sort((a, b) => b.get('Visitors') - a.get('Visitors'));

    for( let i = 0; i < sorted.length && i < this.displayCount; i++ ) {
      r.push(sorted[i]);
    }
    return r;
  }
}

export class SiteTraffic {
  public siteId: string;
  public label: string;
  public visitorOverview: Map<string, number>;
  public visitorsByRefererType: TimeLineRows;
  public visitorsBySourceMediums: Map<string, any>[];

  public newVsReturningVisitor: any;

  constructor(siteId: string) {
    this.siteId = siteId;
    this.visitorOverview = new Map<string, number>();
    this.visitorsBySourceMediums = [];
  }
}

