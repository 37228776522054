import { Component, OnInit, ViewChild } from '@angular/core';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { InfoTexts } from '@app/shared/models/info-texts';
import { ActivatedRoute, Router } from '@angular/router';
import { PageRequest } from '@app/shared/models/page-request';
import {Features} from '@app/shared/models/features';
import { ChartComponent } from '@app/shared/components/chart/chart.component';

@Component({
  selector: 'most-popular-genarts',
  templateUrl: './most-popular-genarts.component.html',
  styleUrls: ['./most-popular-genarts.component.scss']
})
export class MostPopularGenartsComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.GENART_INFO;
  }

  getPageStoreKey(): string {
    return "MostPopularGenartsComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.ARTICLE_SELECTION );
  }

  public readonly fieldGenartNo = Fields.GENART_NO;
  public readonly fieldGenartNoBrandLess = Fields.GENART_NO_BRANDLESS;

  get pageName(): string {
    return 'Most popular generic articles';
  }

  public genartChartHeadLabel = 'Your Brand(s)';
  public genartChartHeadLabelMarket = 'Market View';
  @ViewChild('genartChart', {static: true}) genartChart: ChartActor;
  @ViewChild('genartBrandLessChart', {static: true}) genartBrandLessChart: ChartComponent;


  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected route: ActivatedRoute,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.GENART_NO, Fields.GENART_NO_BRANDLESS ];
  }

  getChartActors(): ChartActor[] {
    return [ this.genartChart, this.genartBrandLessChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem, fieldName?: string): void {
    const p = this.chartParams.clone().params;
    p.set(Fields.DATASOURCE, this.mergeDataSourceIds( item.datasource ) );

    const r = new PageRequest();
    r.genartNo = item.id;
    r.displayName = item.rawName;
    r.fieldName = fieldName;
    r.timespan = this.chartParams.timespan;
    r.setParams( p );

    if( r.timespan ) {
      delete r.fromDate;
      delete r.toDate;
    } else {
      r.fromDate = ChartRequest.dateAsString( this.chartParams.fromDate );
      r.toDate = ChartRequest.dateAsString( this.chartParams.toDate );
      delete r.timespan;
    }

    this.router.navigate( [ 'details/level1', r ], { relativeTo: this.route } );
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }

}
