<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #artNrChart [field]="fieldArtNo" [headLabel]="artNrChartHeadLabel"
               [showTimeLineButton]=false
               [enableExport]=false
               [showPercentage]=false
               [infoText]="infoText"></chart>
    </div>
</div>

