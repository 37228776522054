export const Languages = [
  {
    code: 'bg',
    name: 'Bulgarian',
    nativeName: 'български'
  },
  {
    code: 'cs',
    name: 'Czech',
    nativeName: 'Čeština'
  },
  {
    code: 'da',
    name: 'Danish',
    nativeName: 'Danske'
  },
  {
    code: 'de',
    name: 'German',
    nativeName: 'Deutsch'
  },
  {
    code: 'el',
    name: 'Greek',
    nativeName: 'Ελληνική'
  },
  {
    code: 'en',
    name: 'English',
    nativeName: 'English'
  },
  {
    code: 'es',
    name: 'Spanish',
    nativeName: 'Español'
  },
  {
    code: 'et',
    name: 'Estonian',
    nativeName: 'Eesti'
  },
  {
    code: 'fi',
    name: 'Finnish',
    nativeName: 'Suomi'
  },
  {
    code: 'fr',
    name: 'French',
    nativeName: 'Français'
  },
  {
    code: 'he',
    name: 'Hebrew',
    nativeName: 'עברית'
  },
  {
    code: 'hr',
    name: 'Croatian',
    nativeName: 'Hrvatski'
  },
  {
    code: 'hu',
    name: 'Hungarian',
    nativeName: 'Magyar'
  },
  {
    code: 'it',
    name: 'Italian',
    nativeName: 'Italiano'
  },
  {
    code: 'lt',
    name: 'Lithuanian',
    nativeName: 'Lietuviškai'
  },
  {
    code: 'lv',
    name: 'Latvian',
    nativeName: 'Latviešu'
  },
  {
    code: 'nl',
    name: 'Dutch',
    nativeName: 'Nederlandse'
  },
  {
    code: 'no',
    name: 'Norwegian',
    nativeName: 'Norsk'
  },
  {
    code: 'pl',
    name: 'Polish',
    nativeName: 'Polski'
  },
  {
    code: 'pt',
    name: 'Portuguese',
    nativeName: 'Português'
  },
  {
    code: 'ro',
    name: 'Romanian',
    nativeName: 'Română'
  },
  {
    code: 'ru',
    name: 'Russian',
    nativeName: 'Pусский'
  },
  {
    code: 'sk',
    name: 'Slovak',
    nativeName: 'Slovenský'
  },
  {
    code: 'sl',
    name: 'Slovenian',
    nativeName: 'Slovenščina'
  },
  {
    code: 'sr',
    name: 'Serbian',
    nativeName: 'Српски'
  },
  {
    code: 'sv',
    name: 'Swedish',
    nativeName: 'Svenska'
  },
  {
    code: 'tr',
    name: 'Turkish',
    nativeName: 'Türkçe'
  },
  {
    code: 'zh',
    name: 'Chinese',
    nativeName: '中文'
  }
];
