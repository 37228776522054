import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FacetItem } from '@app/shared/models/facet-item';
import { SelectionComponent } from '@app/shared/models/selection-component';
import { GeoLocationUserFavoritesComponent } from '../geo-location-user-favorites/geo-location-user-favorites.component';
import { ChartData } from '@app/shared/models/analytics/chart-data';
import { merge } from 'rxjs';

@Component({
  selector: 'filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {

  @Input()
  public display: boolean = false;

  @ViewChild('geoLocationFavorites', {static: true}) geoLocationFavorites: GeoLocationUserFavoritesComponent;

  private allItems: FacetItem[];
  public groupItems: FacetItem[];
  public items: FacetItem[];
  public itemsMap: Map<string, FacetItem>;
  public selectedItems: FacetItem[];

  public target: SelectionComponent;

  constructor() { }

  ngOnInit() {
  }

  private initGeoLocationFavorites(items: FacetItem[], target: SelectionComponent) {
    try {
      this.geoLocationFavorites.initComponent(items, target);
    } catch( e ) {
      console.log(e);
    }
  }

  setData(items: FacetItem[], target: SelectionComponent) {

    this.allItems = items;

    this.initGeoLocationFavorites(items, target);

    this.target = target;
    const groupItems: FacetItem[] = [];
    const itms: FacetItem[] = [];
    this.itemsMap = new Map();

    for (const fi of items) {
      fi.selected = false;
      if (fi.id && fi.id.indexOf(',') != -1) {
        groupItems.push(fi);
      } else
      if(!fi.id || ( fi.id && !fi.id.startsWith("@") ) ) {
        itms.push(fi);
        this.itemsMap.set(fi.id.trim(), fi);
      }
    }

    const selected: FacetItem[] = target.getSelected();
    for (const s of selected) {
      s.selected = true;
    }

    for (const g of groupItems) {
      if (g.selected) {
        this.selectAllGroupItems(g);
      }
    }

    itms.sort((a, b) => a.name.localeCompare(b.name));
    groupItems.sort((a, b) => a.name.localeCompare(b.name));

    this.items = itms;
    this.groupItems = groupItems;
  }

  onGroupItemClicked(v: FacetItem) {
    v.selected = !v.selected;
    this.selectAllGroupItems(v);
  }

  selectAllGroupItems(v: FacetItem) {
    const ids = v.id.split(',');
    for (const id of ids) {
      const tId = id.trim();
      if (this.itemsMap.has(tId)) {
        this.itemsMap.get(tId).selected = v.selected;
      }
    }
  }

  onGroupMouseOver(v: FacetItem, over: boolean) {
    v.preSelected = over;
    const ids = v.id.split(',');
    for (const id of ids) {
      const tId = id.trim();
      if (this.itemsMap.has(tId)) {
        this.itemsMap.get(tId).preSelected = over;
      }
    }
  }

  checkAllOfGroupSelected(group: FacetItem): boolean {
    const ids = group.id.split(',');
    let foundOne = false;
    for (const id of ids) {
      const tId = id.trim();
      if (this.itemsMap.has(tId) ) {
        foundOne = true;
      }

      if (this.itemsMap.has(tId) &&
        !this.itemsMap.get(tId).selected) {
        return false;
      }
    }
    if( !foundOne ) {
      return false;
    }
    return true;
  }

  getAllSelectedOfGroup(group: FacetItem): FacetItem[] {
    const r: FacetItem[] = [];

    const ids = group.id.split(',');
    for (const id of ids) {
      const tId = id.trim();
      if (this.itemsMap.has(tId)
        && this.itemsMap.get(tId).selected) {
        r.push(this.itemsMap.get(tId));
      }
    }

    return r;
  }

  removeAllOfGroup(group: FacetItem) {
    const ids = group.id.split(',');
    for (const id of ids) {
      const tId = id.trim();
      if (this.itemsMap.has(tId)) {
        this.itemsMap.delete(tId);
      }
    }
  }

  applySelected() {
    if( this.geoLocationFavorites.changed
        || this.geoLocationFavorites.hasItemsToRemove() ) {
          this.geoLocationFavorites.saveFavorites(() => {
            this.continueApply();
          });
    } else {
      this.continueApply();
    }
  }

  continueApply() {

    const r: Set<FacetItem> = new Set();

    const groupItems: FacetItem[] = [].concat(this.groupItems);

    groupItems.sort((a, b) => b.id.length - a.id.length);

    // adding all Groups and remove group depended
    for (const group of groupItems) {
      group.selected = this.checkAllOfGroupSelected(group);

      if (group.selected) {
        r.add(group);
        this.removeAllOfGroup(group);
      }
    }

    this.itemsMap.forEach((v, id) => {
      if(v && v.selected) {
        r.add(v);
      }
    });

    // get favorites selection
    this.geoLocationFavorites.getSelected().forEach((v, id) => {
      if( v && v.selected ) {
          r.add(v);
      }
    });
    this.target.setSelected(Array.from(r));

    // sync favorites
    let mergedList = ChartData.addNew(this.allItems, this.geoLocationFavorites.locationList);
    mergedList = ChartData.removeMarked(mergedList, this.geoLocationFavorites.locationList);
    this.target.setItems( mergedList );

    this.display = false;

    this.geoLocationFavorites.resetAll();
  }

  clearSelections() {
    for( const itm of this.allItems ) {
      itm.selected = false;
    }
    this.geoLocationFavorites.clearSelection();
  }

}
