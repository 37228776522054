import { async } from '@angular/core/testing';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { formatNumber } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from '@app/shared';
import { ChartData } from '@app/shared/models/analytics/chart-data';
import { DatasourceInfo } from '@app/shared/models/datasource-info';
import { UIChart } from 'primeng';
import { HomeComponent } from '../home/home.component';
import "chartjs-plugin-colorschemes";
import {DatasourceFeaturesMap} from '@app/shared/models/datasource-features-map';
import {Features} from '@app/shared/models/features';


@Component({
  selector: 'datasource',
  templateUrl: './datasource.component.html',
  styleUrls: ['./datasource.component.scss']
})
export class DatasourceComponent implements OnInit {
  public static readonly SELECTED_DATASOURCE = "selectedDatasource";
  private readonly MAX_LEGEND_LENGTH = 11;

  private _datasource: DatasourceInfo;

  @Input()
  set datasource( v : DatasourceInfo ) {
    this._datasource = v;
    if( v ) {
      this.userDescription = v.description;
    }
  }

  @Output()
  featureSelected: EventEmitter<DatasourceInfo>

  get datasource(): DatasourceInfo {
    return this._datasource;
  }

  showContent: boolean = true;
  chartData: ChartData;
  @Input() page: HomeComponent;

  @ViewChild('chartComponent', { static: true }) chartComponent: UIChart;

  @ViewChild('descriptionEditor', { static: true }) descriptionEditor: ElementRef<HTMLTextAreaElement>;

  public chartOptions = {
    responsive: true,
    events: ['mousemove', 'mouseout', 'wheel'],
    tooltips: {
      callbacks: {
        label: ( tooltipItem, data ) => {
          const itemData = data.originalData[tooltipItem.index];
          if (itemData) {
            return itemData.label + ": " +  formatNumber(itemData.count, 'de-DE', '1.0-2') + " ("+itemData.percentFormat.replace(".", ",")+")";
          }
          return '';

        }
      },

    },
    legend: {
      position: 'bottom',
      align: "start",
      display: false,
    },
    plugins: {
      colorschemes: {
        custom: null, // function(schemeColors) { return schemeColors; } //,
        scheme: [ '#3D94C2',
                  '#004DBF',
                  '#DE6726',
                  '#B82192',
                  '#911A74',
                  '#3CCC7D',
                  '#268D6C',
                  '#6F38B1',
                  '#D2D90D',
                  '#2780EB',
                  '#EC5454',
                  '#37C1C1',
                  '#5D7EFF',
                  '#01BA49',
                  '#B5A100',
                  '#BC9CFF',
                  '#B42700',
                  '#01A3A4',
                  '#C4004A',
                  '#82D89A',
                  '#FF5784',
                  '#185E3B',
                  '#6D5E00',
                  '#E7C17B',
                  '#F2B2E6',
                  '#FF97AC',
                  '#DD252F',
                  '#00A1D5',
                  '#FABC32',
                  '#FF8800' ]
      }
    }


  }
  constructor(
    private _apiService: ApiService,
    private _router: Router
  ) {

  }

  ngOnInit(): void {
    let tempData = new ChartData('country', this.datasource.topCountries || [], this.datasource.numFound || 0, "label", "count");

    tempData.setLabels( this.prepareLabels(tempData) );

    this.chartData = tempData;
    this.setColorOther();
    this.setChartOptions();
    this.setLabelsAndColors();

    this._apiService._auth.loggedOut.subscribe(() => {
      this.isAdminInitialized = false;
    });
  }

  private getAdminEmptyDescription() {
    if( !this.isAdminInitialized && this._apiService.isPropsLoaded() ) {
      this.isAdminInitialized = true;

      if( this._apiService.isAdmin() ) {
        this.adminEmptyDescriptionInfo = 'Doubleclick to edit ...';
      }
    }
    return this.adminEmptyDescriptionInfo;
  }

  setColorOther(){
    var originalData =  this.chartData.originalData;

    var label = originalData[originalData.length-1] ? originalData[originalData.length-1].label : 0;
    if(label == 'others'){
      this.chartOptions.plugins.colorschemes.scheme[originalData.length-1] = 'gray';
    }
  }

  private setChartOptions() {
    if (this.chartComponent) {
      this.chartComponent.options = this.chartOptions;
    }
  }

  private prepareLabels( chartData: ChartData ) {
    let labels =  chartData.originalData.map(d => {
      return d.label && d.percent?  d.label + " ("+d.percent+"%)" : '';
    });

    labels = labels.map(l => {
      return l.padEnd(25, ' ');
    });

    return labels;
  }

  ngOnDestroy() {

  }

  onSelected() {
    const ds: string[] = [this.datasource.id];

    const settings = this._apiService.setSelectedDatasourceId( ds );
    this.page.goToFirstAuthorizedItem( settings.features );
  }

  goToFeature( feature: Features ) {

    console.log( feature );

    const ds: string[] = [this.datasource.id];
    const settings = this._apiService.setSelectedDatasourceId( ds );
    this.page.goToFirstAuthorizedItem( [ feature ] );
  }

  labels : string [] = []
  percent : string [] = []
  colors : string [] = []
  editMode = false;
  isAdminInitialized = false;
  adminEmptyDescriptionInfo: string = '';

  setLabelsAndColors() {
    for(let i =0 ;i < 6 && i < this.chartData.originalData.length; i ++){
      this.labels.push(this.chartData.originalData[i].label);
      this.percent.push('('+ (this.chartData.originalData[i].percent + "").replace(".", ",")+'%)');
      this.colors.push(this.chartOptions.plugins.colorschemes.scheme[i]);
    }
  }


  truncateLabel(label: string) {
    console.log('lable is', label, window.innerWidth);
    return label.length < this.MAX_LEGEND_LENGTH ? label : label.substr(0, this.MAX_LEGEND_LENGTH - 3) + "...";
  }

  legendTooltip(label: string, percentage: string) {
    if (this.truncateLabel(label) === label) {
      return '';
    }
    return label + ' '+ percentage;
  }


  toggleDsInfo($event) {

    const target = $event.target  || event.srcElement || event.currentTarget ;
    if (this.showContent) {//lock the parent height to disable unnecessary scrolling
      const closestParent = target.closest(".ds");
      if (closestParent) {
        closestParent.style.height = closestParent.offsetHeight + "px";
      }
    }

    this.showContent = !this.showContent;
  }

  onDescriptionClick($event: MouseEvent) {
    if( !this._apiService.isAdmin() ) {
      return;
    }
    this.editMode = true;

    window.setTimeout(() => {
      this.descriptionEditor.nativeElement.focus();
    }, 100);
  }

  public userDescription: string = '';

  isSubCatalog() {
    return this.datasource && this.datasource.id.indexOf(':') != -1;
  }

  getRootDatasource() {
    if( this.isSubCatalog() ) {
      return this.datasource.id.substring(0, this.datasource.id.indexOf(':'));
    }
    return this.datasource.id;
  }

  saveDescription( all ) {
    if( !this._apiService.isAdmin() ) {
      this.hideDescriptionEditor();
      return;
    }
    this.datasource.description = this.userDescription;
    this.hideDescriptionEditor();

    let id = this.datasource.id;
    if( all && this.isSubCatalog() ) {
      id = this.getRootDatasource() + ':all';
    }
    this._apiService.saveDatasourceDescription( id, this.datasource.description );
  }

  cancelDescription() {
    this.hideDescriptionEditor();
  }

  hideDescriptionEditor() {
    window.setTimeout(() => {
      this.editMode = false;
    }, 100);
  }

  get description(): string {
    if( this.datasource
                && this.datasource.description
                      && this.datasource.description.trim().length > 0 ) {
      var re = new RegExp("\n", 'g');
      return this.datasource.description.replace(re, "<br>");
    }
    return this.getAdminEmptyDescription();
  }


}
