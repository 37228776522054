import {UtilsService} from '@app/core';

export class CustomAxes {
  static yAxisValueHandler = function (value) {
    value = UtilsService.shortenInteger(value);
    if (value && value.length > 20) {
      return value.substr(0, 20) + ' ...';//truncate
    }
    return value + '    ';
  };

  static xAxisValueHandler = function (value) {
    value = UtilsService.shortenInteger(value);
    if (value && value.length > 20) {
      return value.substr(0, 20) + ' ...';//truncate
    }
    return value;
  };

  static vehicleYAxisValueHandler = function (value) {
    const maxLength = 50;
    if (value && value.length > maxLength) {
      return value.substr(0, maxLength) + ' ...';//truncate
    }
    return value;
  };
}



