import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} =  {
  /**
   * VehicleBrandsPage params:
   * - `mandator`
   * - `targetType`
   *
   * VehicleBrandsPage query params:
   * - `advanced`
   * - `searchType`
   * - `searchValueType`
   * - `searchValue`
   */
  // tslint:disable-next-line:max-line-length
  vehicleBrands: { id: 'brands', name: 'VehicleBrandsPage', route: '/:mandator/parts/:targetType/brands',  historyOptions: { resetHistory: true }, query: ['advanced', 'searchType', 'searchValueType', 'searchValue'] },

  /**
   * VehicleModelsPage params:
   * - `mandator`
   * - `targetType`
   * - `manufacturerId`
   *
   * @param {NavParmas} from
   * @param {NavParams} params
   */
  vehicleModels: { id: 'models', name: 'VehicleModelsPage', route: '/:mandator/parts/:targetType/:manufacturerId/models' },

  /**
   * VehiclesPage params:
   * - `mandator`
   * - `targetType`
   * - `manufacturerId`
   * - `modelId`
   *
   * @param {NavParmas} from
   * @param {NavParams} params
   */
  vehicles: { id: 'cars', name: 'VehiclesPage', route: '/:mandator/parts/:targetType/:manufacturerId/models/:modelId/vehicles' },
};
