import { Injectable } from '@angular/core';

import { Message } from 'primeng/api';

import { MessageService } from 'primeng/api';

@Injectable()
export class GrowlService {
    constructor(private _messageService: MessageService) {

    }

    public push(msg: Message): void {
        this._messageService.add(msg);
    }

    /**
     * Pushs a session expired message.
     */
    public sessionExpired( params?: any ) {
        this.push(<Message>{
            severity: 'error',
            summary: 'Session expired',
            detail: ( params ? params.msg : null ) || 'Your session expired due to 30 minutes inactivity. Please log in again.',
            life: 5000
        });
    }

     /**
     * Pushs a session expired message.
     */
    public showError( title: string, message: string ) {
        this.push(<Message>{
            severity: 'error',
            summary: title,
            detail: message,
            life: 3000
        });
    }

    /**
     * Pushs a session expired message.
     */
    public showErrorLong( title: string, message: string ) {
        this.push(<Message>{
            severity: 'error',
            summary: title,
            detail: message,
            life: 30000
        });
    }

    public infoMessage( title, message, life=3000) {
        this.push(<Message>{
            severity: 'success',
            summary: title,
            detail: message,
            life: life
        });
    }

    /**
     * Pushs a successful message when n model was saved.
     *
     * @param title
     * @param message
     */
    public modelSaved(title?: string, message?: string) {
        this.push(<Message>{
            severity: 'success',
            summary: title || 'Save',
            detail: message || 'Save OK',
            life: 3000
        });
    }

    /**
     * Pushs a successful message when n model was saved.
     *
     * @param title
     * @param message
     */
    public modelDeleted(title?: string, message?: string) {
        this.push(<Message>{
            severity: 'success',
            summary: title || 'Delete',
            detail: message || 'Delete OK',
            life: 3000
        });
    }

    /**
     * Pushs a unsuccessful message when a model couldn't be saved.
     *
     * @param title
     * @param message
     */
    public modelError(title?: string, message?: string) {
        this.push(<Message>{
            severity: 'error',
            summary: title || 'Error',
            detail: message || 'An error occurred when trying to save the entry.',
            life: 3000
        });
    }
}
