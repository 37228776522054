import {Injectable} from '@angular/core';
import * as moment from 'moment/moment';
import {Moment} from 'moment';
import {DatasourceInfo} from '@app/shared/models/datasource-info';
import {SelectItem, Tree, TreeNode} from 'primeng';
import {DatePipe, formatNumber} from '@angular/common';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {FacetItem} from '@app/shared/models/facet-item';
/**
 * Utilities services.
 */
@Injectable()
export class UtilsService {
  //#region Fields

  public readonly ITALY_UTF_OFFSET: number = -120;
  private php_js: any;

  //#endregion

  //#region Constructor

  constructor() {
  }

  //#endregion

  //#region Miscellanea
  static getCountryColorForMap(d) {
    return d > 10000000 ? '#084081' :
      d > 1000000 ? '#0868ac' :
        d > 100000 ? '#2b8cbe' :
          d > 10000 ? '#4eb3d3' :
            d > 1000 ? '#7bccc4' :
              d > 100 ? '#a8ddb5' :
                d > 10 ? '#ccebc5' :
                  d > 0 ? '#e0f3db' : '#f6f6f4';
  }

  stripScripts(str: string): string {
    if (!str) {
      return '';
    }

    let result = str.replace(/<[^>]*script[^>]*>.*?<\/[^>]*script[^>]*>/gmi, '');

    /*
    let div = document.createElement('div');
    div.innerHTML = str;
    let scripts = div.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
      scripts[i].parentNode.removeChild(scripts[i]);
    }
    let result = div.innerHTML;
    */

    return result.replace(/<[^>]+\s+on[\w]+=[^>]+\/?>/gmi, '');
  }

  /**
   * Given a date calcuates the age.
   *
   * @param birthday
   */
  calculateAge(birthday: Date): number {
    let ageDifMs = Date.now() - birthday.getTime();
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  /**
   * Returns a random number between min (inclusive) and max (exclusive)
   */
  getRandom(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive)
   * Using Math.round() will give you a non-uniform distribution!
   */
  getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * Toggle flag state.
   *
   * @param {Object} root Root object.
   * @param {String} flag Flag name to set value (boolean). Property of root or child of root.
   * @param {Boolean} value  Value to set flag. Can be undefined.
   */
  toggleFlag(root: Object, flag: string, value?: boolean): void {
    if (!flag) {
      return;
    }

    let path = [flag];
    if (flag.indexOf('.') > -1) {
      path = flag.split('.');
    }

    let object: any = null;
    if (path.length > 1) {
      for (let i = 0; i < path.length; i++) {
        if (i === path.length - 1) {
          break;
        }

        if (!object) {
          if (!root.hasOwnProperty(path[i])) {
            return;
          }

          object = root[path[i]];
          continue;
        }

        if (!object.hasOwnProperty(path[i])) {
          return;
        }

        object = object[path[i]];
      }
    } else if (root.hasOwnProperty(path[0])) {
      object = root;
    } else {
      return;
    }

    object[path[path.length - 1]] = typeof value !== 'undefined'
      ? value
      : !object[path[path.length - 1]];
  }

  /**
   * Clear all the elements of an array/object without changing the reference, this must be used in objects binded
   * instead of:
   *      obj = {}
   * This assignments change the reference of the object (pointer) because they create a new object, with this method this can be avoided.
   */
  clearObject(obj: Object): void {
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      delete obj[keys[i]];
    }
  }

  /**
   * Return a uniq id.
   * https://github.com/kvz/phpjs/blob/master/functions/misc/uniqid.js
   *
   * example 1: uniqid();
   * returns 1: 'a30285b160c14'
   * example 2: uniqid('foo');
   * returns 2: 'fooa30285b1cd361'
   * example 3: uniqid('bar', true);
   * returns 3: 'bara20285b23dfd1.31879087'
   *
   * @param {string} prefix
   * @param {boolean} more_entropy
   * @returns {string}
   */
  uniqid(prefix: string, more_entropy: boolean): string {
    if (typeof prefix === 'undefined') {
      prefix = '';
    }

    let retId: string;
    let formatSeed = function (seed: string, reqWidth: number): string {
      seed = parseInt(seed, 10).toString(16); // to hex str
      if (reqWidth < seed.length) { // so long we split
        return seed.slice(seed.length - reqWidth);
      }
      if (reqWidth > seed.length) { // so short we pad
        return Array(1 + (reqWidth - seed.length)).join('0') + seed;
      }
      return seed;
    };

    // BEGIN REDUNDANT
    if (!this.php_js) {
      this.php_js = {};
    }
    // END REDUNDANT
    if (!this.php_js.uniqidSeed) { // init seed with big random int
      this.php_js.uniqidSeed = Math.floor(Math.random() * 0x75bcd15);
    }

    this.php_js.uniqidSeed++;

    retId = prefix; // start with prefix, add current milliseconds hex string
    retId += formatSeed((new Date().getTime() / 1000).toString(), 8);
    retId += formatSeed(this.php_js.uniqidSeed, 5); // add seed hex string

    if (more_entropy) {
      // for more entropy we add a float lower to 10
      retId += (Math.random() * 10).toFixed(8).toString();
    }

    return retId;
  }

  /**
   * Return a hash key for a string. This function takes a string as input.
   * It processes the string four bytes at a time, and interprets each of the
   * four-byte chunks as a single long integer value
   *
   * @param {String} string
   * @param {Number} m. Module.
   * @returns {Number}
   */
  hashkey(string: string, m: number) {
    if (this.isUndefined(m)) {
      m = 100000;
    }

    let fold = 4;
    let intLength = Math.floor(string.length / fold);
    let sum = 0;
    for (let i = 0; i < intLength; i++) {
      let c = string.substring(i * fold, (i * fold) + fold);
      let mult = 1;

      for (let j = 0; j < c.length; j++) {
        sum += c.charCodeAt(j) * mult;
        mult *= 256;
      }
    }

    let c = string.substring(intLength * 4);
    let mult = 1;

    for (let j = 0; j < c.length; j++) {
      sum += c.charCodeAt(j) * mult;
      mult *= 256;
    }

    return (Math.abs(sum) % m);
  }

  //#endregion

  //#region Conversion Methods

  /**
   * Given a array, the method take the array values and builds
   * an object whose keys will be the arrays values.
   *
   * @param array
   * @param value
   */
  array2Object(array: Array<any>, value: any = ''): any {
    if (!array || !array.length) {
      return {};
    }

    let object = {};
    array.forEach((item: any) => {
      object[item.toString()] = value;
    });
    return object;
  }

  //#endregion

  //#region Date Methods

  /**
   * The locales and options arguments are not supported in all browsers yet. To check whether an implementation supports them already,
   * you can use the requirement that illegal language tags are rejected with a RangeError exception
   */
  toLocaleDateStrSupportsLocales(): boolean {
    try {
      new Date().toLocaleDateString('i');
    } catch (e) {
      if (this.isUndefined(e)) {
        return false;
      }

      return e.name === 'RangeError';
    }

    return false;
  }

  /**
   * Conver server date to local date.
   *
   * @param {Date} date
   * @param {number} offset UTC offset
   * @returns {Date}
   */
  date2Local(date: Date, offset: number): Date {
    if (!offset) {
      offset = this.ITALY_UTF_OFFSET;
    }

    let localDate = new Date();
    let localOffset = -localDate.getTimezoneOffset(); // Difference between UTC and Local date
    let utc = date.getTime() + (offset * 60000);      // obtain UTC time in msec for the given date

    // convert msec value to date for the local date.
    localDate = new Date(utc + (60000 * localOffset));

    return localDate;
  }

  /**
   * Return the time stamp string.
   *
   * @returns {String}
   */
  getTmsTag(): string {
    let current = new Date();
    // Used, for example, for files named to upload.
    // No use charactes as ':'
    return (current.getFullYear()
      + '-' + (current.getMonth() + 1)
      + '-' + current.getDate()
      + '.' + current.getHours()
      + '.' + current.getMinutes()
      + '.' + current.getSeconds());
  }

  /**
   * Return Date object from array values.
   *
   * @param {Array} values
   * @returns {Date}
   */
  getDateFromArray(values: Array<number>) {
    return (new Date(
      values[0],     // year
      values[1] - 1, // month
      values[2],     // date
      values.length >= 4 ? values[3] : 0, // hour
      values.length >= 5 ? values[4] : 0, // minute
      values.length >= 6 ? values[5] : 0  // seconds
    ));
  }

  /**
   * Return Date object. if days|hours|min parameter is specified, the function adds the days to the date.
   *
   * @param (Number) days
   * @returns (Date)
   */
  getDate(days: number, hours: number, min: number): Date {
    let date = new Date();
    if (days || hours || min) {
      let minutes = 0;
      if (days) {
        minutes += (days * 24 * 60);
      }
      if (hours) {
        minutes += (hours * 60);
      }
      if (min) {
        minutes += (min);
      }

      date.setTime(date.getTime() + (minutes * 60 * 1000));
    }

    return date;
  }

  /**
   * Return Array from date in string format.
   *
   * @param {String} date
   * @param {String} time
   * @returns {Array}
   */
  getArrayFromDateStr(date: string, time: string): Array<number> {
    let d = new Date(date + (this.isUndefined(time) ? '' : ' ' + time));
    if (isNaN(d.getTime())) {
      return new Array();
    }

    let result = [d.getFullYear(), d.getMonth() + 1, d.getDate(), d.getHours(), d.getMinutes()];
    return result;
  }

  validateDate(year: number, month: number, day: number): boolean {
    if (day < 1 || day > 31) {
      return false;
    }

    if (month < 1 || month > 12) {
      return false;
    }

    if ((month === 4 || month === 6 || month === 9 || month === 11) && day === 31) {
      return false;
    }

    if (month === 2) { // bisiesto
      let bisiesto = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
      if (day > 29 || (day === 29 && !bisiesto)) {
        return false;
      }
    }

    return true;
  }

  //#endregion

  //#region Check Methods

  /**
   * Checks if value is a Date.
   *
   * @param value
   */
  isDate(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object Date]';
  }

  /**
   * Checks if value is a RegExp.
   *
   * @param value
   */
  isRegExp(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object RegExp]';
  }

  /**
   * Checks if value is a Function.
   *
   * @param value
   */
  isFunction(value: any): boolean {
    return (typeof value === 'function');
  }

  /**
   * Checks if value is defined.
   *
   * @param value
   */
  isDefined(value: any): boolean {
    return (typeof value !== 'undefined');
  }

  /**
   * Return true if param is 'null' or empty or undefined. Otherwise it returns false
   *
   * @param value
   * @returns {Boolean}
   */
  isUndefined(value: any): boolean {
    if ((value === null) || (value === '') || (typeof value === 'undefined')) {
      return true;
    }

    return false;
  }

  /**
   * Return true if all array|object items are 'null' or empty or undefined. Otherwise it returns false
   *
   * @param items
   * @returns {Boolean}
   */
  isUndefinedItems(items: Array<any>): boolean {
    for (let i in items) {
      if (!this.isUndefined(items[i])) {
        return false;
      }
    }

    return true;
  }

  /**
   * Return true if param is a object. Otherwise it returns false
   *
   * @param {type} object
   * @returns {Boolean}
   */
  isObject(object: Object): boolean {
    if (this.isUndefined(object)) {
      return false;
    }

    return (typeof object === 'object');
  }

  //#endregion

  //#region Validation Methods

  /**
   * Checks if text contains CJK characters (Chinese, Japanese, and Korean languages).
   *
   * @param text
   * @param all
   */
  containsCJKChar(text: string, all: boolean): boolean {
    if (!all) {
      let CJK_Unified_Ideographs_Extension_A = /[\u3400-\u4DBF]/;
      let CJK_Unified_Ideographs = /[\u4E00-\u9FFF]/;

      return CJK_Unified_Ideographs_Extension_A.test(text) || CJK_Unified_Ideographs.test(text);
    }

    return /^[\/\\\(\)\{\}\?¿!¡_\-\.\*+·\$€\s,;:\u3400-\u9FFF]*$/gi.test(text);
  }

  /**
   * Checks if text contains latin characters.
   *
   * @param text
   */
  containsLatinChar(text: string): boolean {
    return /[\wñ]/.test(text);
  }

  /**
   * Checks if text begins with latin characters.
   *
   * @param text
   */
  beginsWithLatinChar(text: string): boolean {
    return /^[\b\w]/.test(text);
  }

  /**
   * Checks if text contains special characters like
   * / \ ( ) { } ¿ ? ! ¡ _ - . , : ; * + $ €
   *
   * @param text
   */
  containsSpecialChar(text: string): boolean {
    return /[\/\\\(\)\{\}\?¿!¡_\-\.\*+·\$€,;:]/.test(text);
  }

  /**
   * Checks if text contains special characters and digits.
   *
   * @param text
   */
  containsSpecialCharOrDigits(text: string): boolean {
    return /[\/\\\(\)\{\}\?¿!¡_\-\.\*+·\$€,;:0-9]/.test(text);
  }

  /**
   * Checks if text begins with special characters or digits.
   *
   * @param text
   */
  beginsWithSpecialCharOrDigits(text: string): boolean {
    return /^[\b\/\\\(\)\{\}\?¿!¡_\-\.\*+·\$€,;:0-9]/.test(text);
  }

  /**
   * Checks if value is a valid email
   *
   * @param mail
   */
  isEmail(value: string): boolean {
    //let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return filter.test(value);
  }

  strToIntArray(v: string[]): number[] {
    return v.map(v => parseInt(v));
  }

  static exportToCsv(filename, rows) {
    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        ;
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|;|\r|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ';';
        finalVal += result;
      }
      return finalVal + '\r\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
    var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
  }

  //#endregion
  static shortenInteger(value) {
    if (!Number.isInteger(value)) {
      return value;
    }
    if (value >= 1000000000) {
      return value / 1000000000 + 'B';
    }
    if (value >= 1000000) {
      return value / 1000000 + 'M';
    }
    if (value >= 1000) {
      return value / 1000 + 'K';
    }
    return value;
  }

  static getQuarterLastMonth(quarter: number): number {
    if (quarter == 1) {
      return 3;
    } else if (quarter == 2) {
      return 6;
    } else if (quarter == 3) {
      return 9;
    } else {
      return 12;
    }
  }

  static getQuarterLength(date: Moment): number {
    const quarter = date.quarter();
    if (quarter == 1) {
      let daysInFeb = moment(date.year() + '-02').daysInMonth();
      return daysInFeb + 62;
      return 89;
    } else if (quarter == 2) {
      return 91;
    } else {
      return 92;
    }
  }

  static getYearLength(date: Moment): number {
    let daysInFeb = moment(date.year() + '-02').daysInMonth();
    return daysInFeb == 28 ? 365 : 366;
  }

  static getQuarter(month: number) {
    return (month - 1) / 3 + 1;
  }

  static readonly dateFormat = 'MMM dd, yyyy';
  static readonly datePipe = new DatePipe('en-US');

  /**
   * format input date to MMM dd, yyyy. Input date should be in yyyy-MM-dd format
   * @param date
   */
  static formatDate(date: string) {
    if (!date) {
      return '';
    }
    if (date && date.indexOf(':') !== -1 && (date.length == 5 || date.length == 8)) {
      return date;
    }
    const dateObj = new Date(date);
    return UtilsService.datePipe.transform(dateObj, UtilsService.dateFormat);
  }

  /**
   * format input dates to MMM dd, yyyy. Input dates should be in yyyy-MM-dd format
   * @param dates
   */
  static formatDates(dates: string[]) {
    return dates.map(date => UtilsService.formatDate(date));
  }

  static convertSecondToHourAndMinute(seconds: number): string {
    let hour = Math.floor(seconds / 3600);
    let remainingSeconds = seconds - hour * 3600;
    let minute = Math.round(remainingSeconds / 60);
    if (minute == 60) {
      hour = hour + 1;
      return hour + 'h';
    }
    if (minute == 0) {
      if (hour == 0) {
        return remainingSeconds + 's';
      } else {
        let secondStr = remainingSeconds == 0 ? '' : ' ' + remainingSeconds + 's';
        return hour + 'h' + secondStr;
      }
    }
    let hourStr = hour == 0 ? '' : hour + 'h ';
    return hourStr + minute + 'm';
  }

  static sortArray(arr, sortField, desc?, ignoreCase?) {
    const m = desc ? -1 : 1;

    arr.sort((a, b) => {
      let av = a[sortField];
      let bv = b[sortField];

      if (ignoreCase) {
        av = av.toLowerCase();
        bv = bv.toLowerCase();
      }

      if (av < bv) //sort string ascending
        return -1 * m;
      if (av > bv)
        return 1 * m;
      return 0;
    });
  }

  static sortArrayIgnoreCase(arr, sortField, desc?) {
    UtilsService.sortArray(arr, sortField, desc, true);
  }

  static shadeColor(color, percent) {

    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt('' + (R * (100 + percent) / 100));
    G = parseInt('' + (G * (100 + percent) / 100));
    B = parseInt('' + (B * (100 + percent) / 100));

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
  }

static simpleNumberFormat(inputNumber: any) {

    if (Array.isArray(inputNumber)) {
        if (inputNumber.length === 0) {
            return 0;
        }

        inputNumber = inputNumber[0];
    }

    if (typeof(inputNumber) === "string") {
        try {
            //if the input number has multiple ., that means it has . has thousands separator
            if (inputNumber.length - inputNumber.replace(/\./g, "").length > 1 ) {
                inputNumber = inputNumber.replace(/\./g, "");
            }

            inputNumber = parseFloat(inputNumber.replaceAll(',','.'))
        } catch(e) {
            return 0;
        }
    }

    return formatNumber(inputNumber, 'de-DE', '1.0-2');
  }  

  static isEllipsisActive(e) {
    var c = e.cloneNode(true);
    c.style.display = 'inline';
    c.style.width = 'auto';
    c.style.visibility = 'hidden';
    document.body.appendChild(c);
    const truncated = c.offsetWidth >= e.clientWidth;
    c.remove();
    return truncated;
  }

  static createDatasourceTree(v: DatasourceInfo[] | FacetItem[]): TreeNode[] {
    const r: TreeNode[] = [];
    let rg: TreeNode[] = [];
    const rgMap = {};

    // add all main
    for (const t of v) {
      if (t.id.indexOf(':') == -1) {

        const group = t.group || 'unknown';
        if (!rgMap[group]) {
          rgMap[group] = <TreeNode>{
            label: group,
            data: group,
            children: []
          };
          rg.push(rgMap[group]);
        }
        const tn = <TreeNode>{
          label: t.name,
          data: t.id
        };
        r.push(tn);

        rgMap[group].children.push(tn);
      }
    }

    // add found children - if there is no parent, it will be added after this
    const notAdded: DatasourceInfo[] = [];
    for (const t of v) {
      if (t.id.indexOf(':') != -1) {
        const parentId = t.id.substring(0, t.id.indexOf(':'));
        let found = false;
        for (const tp of r) {
          if (tp.data == parentId) {

            const tn = <TreeNode>{
              label: t.name,
              data: t.id
            };

            if (!tp.children) {
              tp.children = [];
            }

            tp.children.push(tn);
            found = true;
            break;
          }
        }
        if (!found) {
          notAdded.push(t);
        }
      }
    }

    for (const t of notAdded) {

      const group = t.group || 'unknown';

      if (!rgMap[group]) {
        rgMap[group] = <TreeNode>{
          label: group,
          data: group,
          children: []
        };
        rg.push(rgMap[group]);
      }
      const tn = <TreeNode>{
        label: t.name,
        data: t.id
      };

      rgMap[group].children.push(tn);
    }

    rg = UtilsService.sortAndLabelDatasources(rg);

    return rg;
  }

  static groups = {
      cat: {
        name: 'Catalog groups',
        sort: 1,
        ids: ['2','1','5','5fafa'],
        sort_children: (a, b) => {
              var id1 = a.data || a.id;
              var id2 = b.data || b.id;

              //var ids = ['2','1','5','5fafa'];
              var idx1 = UtilsService.groups.cat.ids.indexOf( id1 );
              var idx2 = UtilsService.groups.cat.ids.indexOf( id2 );

              if(idx1 == -1) {
                idx1 = 1000;
              }
              if(idx2 == -1) {
                idx2 = 1000;
              }
              return idx1 - idx2;
        }
      },
      ws: {
        name: 'Catalog Webservice',
        sort: 2,
      },
      catindv: {
        name: 'Individual Catalogs',
        sort: 3
      },
      rmi: {
        name: 'RMI ( Repair Manuals )',
        sort: 4
      }
    };

  static sortDatasourcesDefault( ds: DatasourceInfo[] ): DatasourceInfo[] {
    for( const d of ds ) {
      d.sort = UtilsService.groups[d.group] ? UtilsService.groups[d.group].sort : 1000;
    }


    return ds.sort( (a, b) => {
      var d = a.sort - b.sort;
      if( d == 0 && this.groups[a.group] && this.groups[a.group].sort_children ) {
        //console.log('sort children');
        return this.groups[a.group].sort_children(a, b);
      }
      return d;
    } );
  }

  static sortAndLabelDatasources( tns: TreeNode[] ) {

    for( const tn of tns ) {
      const key = tn.data;
      tn.data = {
        id: key,
        sort: 1000 // sort down unknown category
      }

      if( UtilsService.groups[ key ] ) {
        tn.label = UtilsService.groups[ key ].name;
        tn.data['sort'] = UtilsService.groups[ key ].sort;

        if( UtilsService.groups[ key ].sort_children && tn.children && tn.children.length ) {
          tn.children = tn.children.sort(UtilsService.groups[key].sort_children);
        }
      }
    }

    tns = tns.sort((a, b) => a.data.sort - b.data.sort);

    return tns;
  }

  static createDatasourceTreeByGroups(v: DatasourceInfo[] | FacetItem[]): TreeNode[] {
    const r: TreeNode[] = [];
    let rg: TreeNode[] = [];
    const rgMap = {};

    // add all main
    for (const t of v) {

      const group = t.group || 'unknown';
      if (!rgMap[group]) {
        rgMap[group] = <TreeNode>{
          label: group,
          data: group,
          children: []
        };
        rg.push(rgMap[group]);
      }
      const tn = <TreeNode>{
        label: t.name,
        data: t.id
      };
      r.push(tn);

      rgMap[group].children.push(tn);
    }

    rg = UtilsService.sortAndLabelDatasources(rg);

    return rg;
  }

  static numAndLabel( num, label ) {
     return Math.round( num ) + ' ' + label + ( num > 1 ? 's' : '' );
  }

  static makePositive( num ) {
    if( num < 0 ) {
      return num * -1;
    }
    return num;
  }

  static calcDaysOrYearsAgo( date: string, format='YYYY-MM-DDTHH:mm:ssZ' ) {
      var dateObj = moment(date, format);

      var dt = moment();
      var diff = dt.diff(dateObj);

      var duration = moment.duration(diff);

      var days = duration.asDays();

      if( days < 1 ) {
        const hours = this.makePositive( duration.asHours() );
        if( hours < 1 ) {
          let minutes = this.makePositive( duration.asMinutes() );
          if( minutes < 1 ) {
            return this.numAndLabel( this.makePositive(  duration.asSeconds() ), 'second' );
          }
          return this.numAndLabel( minutes, 'minute' );
        }
        return this.numAndLabel( hours, 'hour' );
      } else
      if ( days >= 7 && days < 31 ) {
        return this.numAndLabel( duration.asWeeks(), 'week' );
      } else
      if ( days >= 31 && days < 365 ) {
        return this.numAndLabel( duration.asMonths(), 'month' );
      } else
      if(days >= 365) {
        return this.numAndLabel( duration.asYears(), 'year' );
      }

      return this.numAndLabel( Math.round( days ), 'day' );
  }

}
