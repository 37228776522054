import { NgModule } from '@angular/core';

import { AdminSharedModule } from '../admin-shared/admin-shared.module';
import { TecAllianceAnalyticsRoutingModule } from './tecalliance-analytics.routing.module';

import { AmChartsModule } from '@amcharts/amcharts3-angular';

import {
  TAAnalyticsDashboardPage
} from '.';
import { MostPopularBrandsComponent } from './pages/most-popular-brands/most-popular-brands.component';
import { TaAnalyticsMostSearchedOeNumbersComponent } from './pages/ta-analytics-most-searched-oe-numbers/ta-analytics-most-searched-oe-numbers.component';
import { TaAnalyticsMostSearchedVehiclesComponent } from './pages/ta-analytics-most-searched-vehicles/ta-analytics-most-searched-vehicles.component';
import { MostPopularGenartsComponent } from "./pages/most-popular-genarts/most-popular-genarts.component";
import { MostPopularArticlenumbersComponent } from './pages/most-popular-articlenumbers/most-popular-articlenumbers.component';
import { ArticlenumbersDetailsComponent } from './pages/articlenumbers-details/articlenumbers-details.component';
import { VehicleDetailsComponent } from './pages/vehicle-details/vehicle-details.component';
import { MostSearchedNumbersComponent } from './pages/most-searched-numbers/most-searched-numbers.component';
import { MostSearchedVehiclesComponent } from './pages/most-searched-vehicles/most-searched-vehicles.component';
import { FullExportListComponent } from './pages/full-export-list/full-export-list.component';
import { GenericArticleDetailsComponent } from './pages/generic-article-details/generic-article-details.component';
import { MostSelectedCountriesComponent } from './pages/most-selected-countries/most-selected-countries.component';
import { RmiOverviewComponent } from './pages/rmi-overview/rmi-overview.component';
import { RmiOverviewDetailsComponent } from './pages/rmi-overview-details/rmi-overview-details.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {CountryDetailsComponent} from '@app/tecalliance-analytics/pages/country-details/country-details.component';
import {DropdownModule} from 'primeng/dropdown';
import {MostSearchedVisitorsComponent} from '@app/tecalliance-analytics/pages/most-searched-visitors/most-searched-visitors.component';
import {TableModule} from 'primeng/table';
import { MostSearchedVinNumbersComponent } from './pages/most-searched-vin-numbers/most-searched-vin-numbers.component';
import { MostSearchedNumberPlatesComponent } from './pages/most-searched-number-plates/most-searched-number-plates.component';
import {OrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends.component';
import { SolrMonitoringComponent } from './pages/solr-monitoring/solr-monitoring.component';
import {GenericArticleOrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends-generic-article.component';
import {CountryOrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends-country.component';
import {MarketOverviewCompetitionComponent} from '@app/tecalliance-analytics/pages/market-overview-competition/market-overview-competition.component';
import {MarketOverviewTrendsComponent} from '@app/tecalliance-analytics/pages/market-overview-trends/market-overview-trends.component';
import {HomeComponent} from '@app/tecalliance-analytics/pages/home/home.component';
import { SalesBrandsComponent } from './pages/sales-brands/sales-brands.component';
import { SalesCountriesComponent } from './pages/sales-countries/sales-countries.component';
import { SalesGenartsComponent } from './pages/sales-genarts/sales-genarts.component';
import { SalesArticleNumbersComponent } from './pages/sales-article-numbers/sales-article-numbers.component';
import { SalesCountryDetailsComponent } from './pages/sales-country-details/sales-country-details.component';
import { SalesArticleNumbersDetailsComponent } from './pages/sales-article-numbers-details/sales-article-numbers-details.component';
import {TabMenuModule} from 'primeng/tabmenu';
import { DatasourceComponent } from './pages/datasource/datasource.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MostSearchedCountriesComponent } from './pages/most-searched-countries/most-searched-countries.component';
import { ArticleVisibilityBrandsComponent } from './pages/article-visibility-brands/article-visibility-brands.component';
import { ArticleVisibilityGenartsComponent } from './pages/article-visibility-genarts/article-visibility-genarts.component';
import { ArticleVisibilityArticleNumberComponent } from './pages/article-visibility-article-number/article-visibility-article-number.component';
import { MostSearchedOeNumbersComponent } from './pages/most-searched-oe-numbers/most-searched-oe-numbers.component';

@NgModule({
    imports: [
        TecAllianceAnalyticsRoutingModule,
        AdminSharedModule.forRoot(),
        AmChartsModule,
        LeafletModule,
        DropdownModule,
        TableModule,
        TabMenuModule,
        DragDropModule
    ],
  declarations: [
    TAAnalyticsDashboardPage,
    MostPopularBrandsComponent,
    TaAnalyticsMostSearchedOeNumbersComponent,
    TaAnalyticsMostSearchedVehiclesComponent,
    MostPopularGenartsComponent,
    MostPopularArticlenumbersComponent,
    ArticlenumbersDetailsComponent,
    VehicleDetailsComponent,
    MostSearchedNumbersComponent,
    MostSearchedCountriesComponent,
    MostSearchedVehiclesComponent,
    MostSearchedVisitorsComponent,
    OrderTrendsComponent,
    GenericArticleOrderTrendsComponent,
    CountryOrderTrendsComponent,
    FullExportListComponent,
    GenericArticleDetailsComponent,
    MostSelectedCountriesComponent,
    CountryDetailsComponent,
    RmiOverviewComponent,
    RmiOverviewDetailsComponent,
    MostSearchedVinNumbersComponent,
    MostSearchedNumberPlatesComponent,
    SolrMonitoringComponent,
    MarketOverviewCompetitionComponent,
    MarketOverviewTrendsComponent,
    HomeComponent,
    DatasourceComponent,
    SalesBrandsComponent,
    SalesCountriesComponent,
    SalesGenartsComponent,
    SalesArticleNumbersComponent,
    SalesCountryDetailsComponent,
    SalesArticleNumbersDetailsComponent,
    ArticleVisibilityBrandsComponent,
    ArticleVisibilityGenartsComponent,
    ArticleVisibilityArticleNumberComponent,
    MostSearchedOeNumbersComponent
  ],
  exports: [
    DragDropModule
  	],
    providers: []
})
export class TecAllianceAnalyticsModule { }
