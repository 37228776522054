import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {ChartRequest} from '@app/shared/models/chart-request';
import * as moment from 'moment/moment';
import {FacetItem} from '@app/shared/models/facet-item';
import {Calendar} from 'primeng/calendar';
import {Dropdown} from 'primeng/dropdown';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {Settings} from '@app/shared/models/settings/settings';
import {ConfigMode} from '@app/shared/models/config-mode';

@Component({
  selector: 'timespan-select',
  templateUrl: './timespan-select.component.html',
  styleUrls: ['./timespan-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimespanSelectComponent extends ChartActor implements TimespanSettings {

  calendarView_b: boolean = false;

  timespan_s: string = null;
  timespanObject: FacetItem;

  key_s: string = 'timespan';
  dateRange_dts: Date[];

  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('timespanSelect') timespanSelect: Dropdown;

  public options: FacetItem[];
  public maxDate = moment().add('days', -1).toDate();

  public set calendarView( v: boolean ) {
    this.calendarView_b = v;
    if( v ) {
      //TODO: open calendar ?
      window.setTimeout(() => {
        this.calendar.inputfieldViewChild.nativeElement.focus()
      }, 100);
    } else {
      window.setTimeout(() => {
        this.timespanSelect.containerViewChild.nativeElement.click();
      }, 100);
    }
  }

  public set selectedTimespan( v: FacetItem ) {
    this.timespanObject = v;

    this.page.getChartParams().timespan = v.id;

    console.log("tms.loadReport #1");
    this.onConfiguringFilter.emit( { mode: ConfigMode.DISPLAY_CONFIRMATION, field: this.fieldName } );

    this.timespan_s = v.id;
  }

  public get selectedTimespan(): FacetItem {
    return this.timespanObject;
  }

  public get calendarView(): boolean {
    return this.calendarView_b;
  }

  public reportLoaded( report: ReportResponse ) {
    this.dateRange_dts = [
      moment(report.fromDate).toDate(),
      moment(report.toDate).toDate()
    ];

    this.page.getChartParams().fromDate = this.dateRange_dts[0];
    this.page.getChartParams().toDate = this.dateRange_dts[1];
    //console.log(this.dateRange_dts);
  }

  public setData(d: ChartData, changed: boolean) {

  }

  constructor() {
    super();

    this.options = [
      { id: "ytd", name: "This year / YtD" },
      { id: "lastYear", name: "Last year" },
      { id: "lastQuarter", name: "Last quarter" },
      { id: "lastMonth", name: 'Last month' },
      { id: "lastWeek", name: 'Last week' },
      { id: "yesterday", name: 'Yesterday' },
      { id: "last12months", name: "Last 12 full months" },
      { id: "last24months", name: "Last 24 full months" },
    ];

    this.timespan_s = this.options[0].id;
    this.dateRange_dts = [
      moment().add('days', -30).toDate(),
      moment().add('days', -1).toDate() ];
   }

   private findTimeSpan( id: string ): FacetItem {
     if( id == null ) {
       return this.options[0];
     }

     for( const obj of this.options ) {
        if( obj.id == id ) {
          return obj;
        }
     }
     return this.options[0];
   }


  public set rangeDates( v: Date[] ) {
    if( this.page && v.length > 1 && v[0] && v[1] ) {
      this.dateRange_dts = v;

      const p: ChartRequest = this.page.getChartParams();
      p.timespan = null; // reset the timespan
      p.fromDate = v[0];
      p.toDate = v[1];

      console.log("tms.loadReport #2");
      this.onConfiguringFilter.emit( { mode: ConfigMode.DISPLAY_CONFIRMATION, field: this.fieldName } );

      if( this.calendar.overlayVisible ) {
        this.calendar.hideOverlay();
      }
    }
  }

  public get rangeDates(): Date[] {
    return this.dateRange_dts;
  }

  protected getSettings(): Settings {
    const r = new TimespanSettingsImpl();
    this.copySettings(r, this);
    return r;
  }

  protected settingsLoaded(o: Settings): void {
    this.page.getChartParams().timespan = this.calendarView_b ? null : this.timespan_s;
    this.page.getChartParams().fromDate = this.dateRange_dts[0];
    this.page.getChartParams().toDate = this.dateRange_dts[1];

    this.timespanObject = this.findTimeSpan( this.timespan_s );
  }
}

export class TimespanSettingsImpl implements TimespanSettings {
  calendarView_b: boolean = false;
  timespan_s: string = '';
  dateRange_dts: Date[] = [new Date(), new Date()];
  key_s: string = '';
}

export interface TimespanSettings extends Settings {
  calendarView_b: boolean;
  timespan_s: string;
  dateRange_dts: Date[];
}
