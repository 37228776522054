import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { FacetItem } from '@app/shared/models/facet-item';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BasicResponse } from '@app/shared/models/basic-response';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  private geoCountries: FacetItem[];
  private geoRegions: Map<string, FacetItem[]> = new Map();

  constructor( public api: ApiService ) { }

  getCountries(): Observable<FacetItem[]> {
    if( this.geoCountries != null ) {
      return of( this.geoCountries );
    }

    if( !this.api.getToken() ) {
      console.log( 'no sessionId available');
      const br: FacetItem[] = [];
      return of(br);
    }

    return this.api.http.get( this.api.apiUrl( '/geo/countries' ) 
              + '&fieldList=country_code&filterFields=country_code&facets=country_code',
                    this.api.getOptions() ).pipe(
      map((data: FacetItem[]) => {
        this.geoCountries = data;
        return data;
      }),
      catchError(error => this.api.handleError(error, []))
    );
  }

  getRegions(countryCode: string): Observable<FacetItem[]> {
    if( this.geoRegions.has(countryCode) ) {
      return of( this.geoRegions.get(countryCode) );
    }

    if( !this.api.getToken() ) {
      console.log( 'no sessionId available');
      const br: FacetItem[] = [];
      return of(br);
    }

    return this.api.http.get( this.api.apiUrl( '/geo/regions' ) 
              + '&fieldList=region&filterFields=region&countryCode=' + countryCode,
                    this.api.getOptions() ).pipe(
      map((data: FacetItem[]) => {
        this.geoRegions.set(countryCode, data );
        return data;
      }),
      catchError(error => this.api.handleError(error, []))
    );
  }

  findPlaceNames(countryCode: string, searchPattern: string, region?: string): Observable<FacetItem[]> {    
    if( !this.api.getToken() ) {
      console.log( 'no sessionId available');
      const br: FacetItem[] = [];
      return of(br);
    }

    return this.api.http.get( this.api.apiUrl( '/geo/search' ) 
              + '&fieldList=country_code,postal_code,place_name,admin_code1,admin_name1,longitude,latitude,id&countryCode=' 
              + countryCode + '&searchPattern=' + searchPattern + ( region ? '&regionCode=' + region : '' ),
                    this.api.getOptions() ).pipe(
      map((data: FacetItem[]) => {        
        return data;
      }),
      catchError(error => this.api.handleError(error, []))
    );
  }  
}
