export class PdfOptions {
  static FONT_SIZE = 26;
  static TIMEOUT_BEFORE_TAKE_PICTURE = 2000;
  static TIMEOUT_AFTER_GENERATE_PDF = 100;
  static TIMEOUT_MOVE_BACK_CHART = 100;
  static PERCENT_LABEL_X_POS = 120;
  static PERCENT_LABEL_Y_POS = 16;

  public static getWidth( chartType, showOutLabels ): number {
    if( !showOutLabels && chartType == 'doughnut' ) {
      return 1000;
    }
    return 2000;
  }
}
