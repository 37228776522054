import jsPDF from 'jspdf';
import {PageOrientation} from '@app/shared/services/pdf/page-orientation';
import de from '@angular/common/locales/de';

export class PDF {

  public static COPYRIGHT_TEXT = 'Copyright © by TecAlliance GmbH';

  public static MARGIN_TOP = 15;
  public static MARGIN_LEFT = 10;
  public static MARGIN_RIGHT = 10;
  public static MARGIN_BOTTOM = 15;

  public static LINE_HEIGHT = 6;

  public static FONT_NAME = 'Helvetica';
  public static TITLE_FONT_SIZE = 16;
  public static TEXT_FONT_SIZE = 12;

  public orientation: PageOrientation;

  public pageNo: number = 1;
  public pageCounts: number;

  public posY: number;
  public maxY: number;
  public maxX: number;
  public pageWidth: number;
  public pageHeight: number;

  public yPosFooter: number;
  public xPosPageNumber: number;

  public maxCharColumn2: number;
  public maxCharFullWidth: number;
  public column2xPos: number;

  public title: string;
  public description: string;

  public doc: jsPDF;

  /**
   * init variables depends on orientation
   *
   * @param orientation
   * @param title
   * @param description
   * @param column2xPos
   */
  public init( orientation: PageOrientation, title: string, description: string, column2xPos?: number ) {

    this.doc = new jsPDF( orientation );
    this.title = title;
    this.description = description;

    this.column2xPos = column2xPos || 40;

    this.pageNo = 0;

    if( orientation == PageOrientation.LANDSCAPE ) {

        this.pageWidth = 297;
        this.pageHeight = 210;

        this.maxX = this.pageWidth - PDF.MARGIN_RIGHT;
        this.xPosPageNumber = 275;

        this.maxY = this.pageHeight - PDF.MARGIN_BOTTOM;
        this.yPosFooter = 200;

        this.maxCharColumn2 = 115;
        this.maxCharFullWidth = 135;
    } else {
        this.pageWidth = 210;
        this.pageHeight = 297;

        this.maxX = this.pageWidth - PDF.MARGIN_RIGHT;
        this.xPosPageNumber = 190;

        this.maxY = this.pageHeight - PDF.MARGIN_BOTTOM;
        this.yPosFooter = 285;

        this.maxCharColumn2 = 78;
        this.maxCharFullWidth = 98;
    }
    this.addPage();

    return this;
  }

  /**
   * add footer, new page and on new page a header
   */
  public addPage() {
    if( this.pageNo > 0 ) {
      this.addFooter();
      this.doc.addPage();
    }

    this.posY = PDF.MARGIN_TOP;
    this.pageNo ++;

    this.addHeader();
  }

  /**
   * add Title and Description to page
   *
   * @private
   */
  private addHeader() {
    this.doc.setFontSize(PDF.TITLE_FONT_SIZE);
    this.doc.setFont(PDF.FONT_NAME, 'bold');
    this.text(this.title, PDF.MARGIN_LEFT);

    this.posY += ( PDF.LINE_HEIGHT - 2 ) * 2;

    // add description
    this.doc.setFont(PDF.FONT_NAME, 'normal');
    this.doc.setFontSize(PDF.TEXT_FONT_SIZE);

    if (this.description) {
      this.addTextMultiline(this.description);
    }
    this.posY += ( PDF.LINE_HEIGHT - 2 ) * 2;
  }

  /**
   * add Copyright and page no to page
   *
   * @private
   */
  public addFooter() {
    const posX = PDF.MARGIN_LEFT;
    const posY = this.yPosFooter;

    this.text(PDF.COPYRIGHT_TEXT, posX, posY);

    if( this.pageCounts ) {
      this.text('' + this.pageNo + ' / ' + this.pageCounts, this.xPosPageNumber, posY);
    } else {
      this.text('' + this.pageNo, this.xPosPageNumber, posY);
    }
  }

  public text( text, x, y? ) {
    this.doc.text(text, x, y || this.posY);
  }

  /**
   * check if the image will fit to page, if not add a new page
   * and there add image
   *
   * @param canvasImage
   * @param x
   * @param width
   * @param height
   * @param y
   */
  public addImage( canvasImage: any, x: number, width: number, height: number, y?: number) {
    this.checkAddPage(height);
    this.posY -= 5;
    this.doc.addImage(canvasImage, 'JPEG', x, y || this.posY, width, height);
    this.posY += height;
  }

  public addImageCentered( canvasImage: any, width: number, height: number) {
    this.checkAddPage(height);

    let x = Math.round( ( this.pageWidth - width ) / 2 ) - 2;
    let y = Math.round( ( this.maxY - this.posY - height ) / 2 ) + this.posY - 5;

    this.doc.addImage(canvasImage, 'JPEG', x, y, width, height);
    this.posY += height;
  }

  public addImageCenteredHorizontal( canvasImage: any, width: number, height: number) {
    this.checkAddPage(height);

    let x = Math.round( ( this.pageWidth - width ) / 2 ) - 2;
    //let y = Math.round( ( this.maxY - this.posY - height ) / 2 ) + this.posY - 5;

    this.doc.addImage(canvasImage, 'JPEG', x, this.posY, width, height);
    this.posY += height;
  }

  /**
   * check if current posY + height fits to page
   * @param height
   */
  public checkAddPage( height?: number ): boolean {
    if( this.posY + (height || 0) > this.maxY ) {
      this.addFooter();
      this.addPage();
      return true;
    }
    return false;
  }

  /**
   * save as file the content
   *
   * @param fileName
   */

  public save(fileName: string) {
    this.doc.save(fileName);
  }

  /**
   * join the values by coma (,) to a line until is place there
   * and after breaks to new line and write lines to content
   *
   * @param label
   * @param values
   */

  public addLabelAndTextMultiline( label: string, values: string[] ) {
    if( label ) {
      label += ':';
    }

    const texts: string[] = this.splitToLines(values, ', ', this.maxCharColumn2);
    let labelOut = label;

    for( const text of texts ) {
      this.addLabelAndText(labelOut, text);

      labelOut = null; // only one line will have label

      if( this.checkAddPage() ) {
        labelOut = label;
      }
    }
  }

  public addTextMultiline( text: string ) {
    const lines = this.splitTextToLines(text, this.maxCharFullWidth);
    for( const line of lines ) {
      this.text(line, PDF.MARGIN_LEFT);
      this.posY += PDF.LINE_HEIGHT;

      this.checkAddPage();
    }
  }

  /**
   * adds the label and text in 2 columns
   *
   * @param label
   * @param text
   */

  public addLabelAndText( label: string, text: string ) {
    this.text(label || '', PDF.MARGIN_LEFT);
    this.text(text, this.column2xPos);

    this.posY += PDF.LINE_HEIGHT;
  }

  public splitTextToLines( text: string, maxLen: number ): string[] {
    if( !text ) {
      return [];
    }
    return this.splitToLines( text.split(' '), ' ', maxLen );
  }

  /**
   * join the values by coma (,) to a line until is place there
   * and after breaks to new line
   *
   * @param values
   */

  public splitToLines( values: string[], separator: string, maxLen: number ): string[] {
    if(!values) {
      return [];
    }

    const lines = [];
    let line = '';
    let coma = '';
    for( const v of values ) {
      // if it fits to the line with value
      if( ( line + coma + v ).length < maxLen ) {
        // add coma and new value
        line += coma + v;
      } else {
        lines.push(line + coma);
        line = v;
      }
      coma = separator;
    }
    if(line.length) {
      lines.push(line); // put the rest
    }

    return lines;
  }


}
