import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsPage } from '../analytics-page';
import { InfoTexts } from '@app/shared/models/info-texts';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { ChartRequest } from '@app/shared/models/chart-request';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { FacetItem } from '@app/shared/models/facet-item';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ActivatedRoute, Router } from '@angular/router';
import {PageRequest} from '@app/shared/models/page-request';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'rmi-overview',
  templateUrl: './rmi-overview.component.html',
  styleUrls: ['./rmi-overview.component.scss']
})
export class RmiOverviewComponent extends AnalyticsPage {

  public readonly fieldOperationName = Fields.OPERATION_NAME_BRANDLESS;
  public readonly chartOperationNamesLabel = 'RMI Modules';

  public readonly fieldEventsName = Fields.EVENT_TYPE_BRANDLESS;
  public readonly chartFunctionNamesLabel = 'RMI Functions';

  public readonly fieldCompanysName = Fields.DATASOURCE_ID_BRANDLESS;
  public readonly chartCompanysLabel = 'RMI Customers';

  @ViewChild('chartRmiOverview', {static: true}) chartRmiOverview: ChartActor;
  @ViewChild('chartRmiEvents', {static: true}) chartRmiEvents: ChartActor;
  @ViewChild('chartCompanys', {static: true}) chartCompanys: ChartActor;

  constructor(appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router ) {
      super( appInsights, confirm, translate, navigation, apiService, router);
    }

  public get infoText(): string {
    return InfoTexts.RMI_OVERVIEW;
  }
  getPageFacets(): string[] {
    return [ Fields.EVENT_TYPE_BRANDLESS, Fields.DATASOURCE_ID_BRANDLESS, Fields.OPERATION_NAME_BRANDLESS ];
  }
  getChartActors(): ChartActor[] {
    return [ this.chartRmiOverview, this.chartRmiEvents, this.chartCompanys ];
  }
  getReportPath(): string {
    return '/report/facets';
  }
  getExportPath(): string {
    return '/export/facets';
  }
  getChartRequest(): ChartRequest {
    return new ChartRequest( "*" );
  }
  reportLoaded(data: ReportResponse): void {

  }

  getPageStoreKey(): string {
    return "@RMI_ModulesOverview";
  }

  get pageName(): string {
    return 'Rmi modules overview';
  }

  itemSelected(item: FacetItem, fieldName?: string): void {
    const p = this.chartParams.clone().params;
    p.set(Fields.DATASOURCE, this.mergeDataSourceIds( item.datasource ) );

    const r = new PageRequest();
    r.fieldName = ChartRequest.extractField( fieldName );
    r.fieldValue = item.id;
    r.timespan = this.chartParams.timespan;
    r.setParams(p);

    if( r.timespan ) {
      delete r.fromDate;
      delete r.toDate;
    } else {
      r.fromDate = ChartRequest.dateAsString( this.chartParams.fromDate );
      r.toDate = ChartRequest.dateAsString( this.chartParams.toDate );
      delete r.timespan;
    }

    this.router.navigate( [ 'details/level1', r ], { relativeTo: this.route } );
  }

  protected getFeatureOnMenu(): Features {
    return Features.RMI_DATA;
  }

}
