<analytics-filter #filter [showBrandFilter]="false" [showCountryFilter]="false" [showDatasourceFilter]="true" [showGenericArticleFilter]="false"></analytics-filter>

<div class="row row-grid">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <chart
      infoText="The number of sessions performed by visitors. A session starts with the first event and ends 30 minutes after the last event."
      #visitsChart [field]="'visits'" [headLabel]="'Sessions'" showAsTimeLine="true" [chartType]="'line'"
      [showTopDropdown]="false"></chart>
  </div>
</div>

<div class="row row-grid">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <chart
      infoText="The number of page views during a session."
      #pageViewsChart [field]="'pageViews'" [headLabel]="'Page Views'" stackedBar="true" [chartType]="'bar'"
      [showTopDropdown]="false"></chart>
  </div>
</div>

<div class="row row-grid">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <chart
      infoText="The country from which a visitor visits your website."
      #countriesChart [field]="'countries'" stackedBar="true" [headLabel]="'Sessions per Countries'" [chartType]="'bar'"
      [showTopDropdown]="false"></chart>
  </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-10 col-sm-12 py-1" *ngFor="let c of catalogUsers">
        <div class="user-box-header">
          <div class="d-inline-block">
            <h3>{{c.name}}</h3>
          </div>
          <div class="d-inline-block float-right user-box-header-toolbar">
            <p-dropdown [options]="topOptions" [(ngModel)]="displayCount[c.id]" styleClass="top-selector" (onChange)="changeTop($event, c.id)"></p-dropdown>
            <button pTooltip="Save" class="btn fa ta-icon-save float-right" (click)="exportUsers(c.id)" *ngIf="downloadHandlers[c.id].downloadStatus===DownloadStatusValues.NONE"></button>
            <div *ngIf="downloadHandlers[c.id].downloadStatus!==DownloadStatusValues.NONE">
              <div *ngIf="downloadHandlers[c.id].downloadStatus===DownloadStatusValues.STARTED"
                   class="spinner-border text-primary"
                   role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <circle-progress
                *ngIf="downloadHandlers[c.id].downloadStatus===DownloadStatusValues.IN_PROGRESS"
                [percent]="downloadHandlers[c.id].contentLengthAvailable?downloadHandlers[c.id].downloadProgress:100"
                [radius]="14"
                [outerStrokeWidth]="3"
                [outerStrokeColor]="'#ffffff'"
                [showSubtitle]="false"
                [showInnerStroke]="false"
                [showTitle]="!downloadHandlers[c.id].contentLengthAvailable"
                [titleFontSize]="9"
                [titleColor]="'#ffffff'"
                [title]="downloadHandlers[c.id].downloadProgress"
                [showUnits]="false"
                [showImage]="false"
                [imageHeight]="30"
                [imageWidth]="30"
                [backgroundPadding]="0">
              </circle-progress>
              <button class="btn fa fa-close" (click)="downloadHandlers[c.id].cancel()"></button>
            </div>
          </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Username</th>
                    <th scope="col">Sessions</th>
                    <th scope="col">Actions</th>
                    <th scope="col">Max actions in one visit</th>
                    <th scope="col">Total time spent by visitors</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let u of c.users | slice:0:displayCount[c.id]">
                  <td>
                    {{u.name}}
                  </td>
                  <td>
                    {{u.count}}
                  </td>
                  <td *ngFor="let n of ['nb_actions', 'max_actions']">
                    {{u.details[n]}}
                  </td>
                  <td>
                    {{convertSecondsToHourAndMinute((u.details['sum_visit_length']))}}
                  </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
