import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import { ConfirmationService } from 'primeng/api';

import {NavigationService, TrackerService, UtilsService} from '../../../core';
import {TranslateService} from '@ngx-translate/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {ApiService, GrowlService} from '@app/shared';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {Collections} from '@app/shared/models/collections';
import {FacetItem} from '@app/shared/models/facet-item';
import {InfoTexts} from '@app/shared/models/info-texts';
import {CatalogUsers} from '@app/shared/models/catalog-users';
import {DownloadStatus} from '@app/shared/models/download-status';
import {DownloadHandler} from '@app/shared/models/download-handler';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';
import {ChartComponent} from '@app/shared/components/chart/chart.component';
import fi from '@angular/common/locales/fi';
import {ReportParams} from '@app/shared/models/i-analytics-page';

@Component({
  //moduleId: module.id,
  selector: 'ta-analytics-dashboard',
  templateUrl: 'ta-analitycs-dashboard.html',
  styleUrls: ['ta-analitycs-dashboard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TAAnalyticsDashboardPage extends AnalyticsPage implements OnInit {

  public get infoText(): string  {
    return InfoTexts.USER_STATS_INFO;
  }

  getPageStoreKey(): string {
    return "TAAnalyticsDashboardPage";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( Collections.ARTICLE_SELECTION );
  }

  loadReportCustomized(params?: ReportParams): boolean {
    if( params && params.timeLineIds && params.facetField ) {
      params.specialUrl = '/piwikApi/' + params.facetField;
    }
    return false;
  }

  getReportPath(): string {
    return '/piwikApi/all';
  }

  getExportPath(): string {
    return '/piwikApi/export';
  }

  //#region Properties

  get pageName(): string {
    return 'User statictics';
  }

  @ViewChild('visitsChart', {static: true}) visitsChart: ChartComponent;
  @ViewChild('pageViewsChart', {static: true}) pageViewsChart: ChartComponent;
  @ViewChild('countriesChart', {static: true}) countriesChart: ChartComponent;

  //#endregion

  topOptions: any;
  //#region Constructor
  constructor(
    appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected router: Router,
    protected apiService: ApiService,
    protected growl: GrowlService
  ) {
    super(appInsights, confirm, translate, navigation, apiService, router);
    this.topOptions = [
      { label: 'Top 10', value: 10 },
      { label: 'Top 20', value: 20 },
      { label: 'Top 30', value: 30 }
    ];
  }

  public catalogUsers: CatalogUsers[];
  public users: Map<string, CatalogUsers>;

  private lastData: ReportResponse;

  reportLoaded(data: ReportResponse): void {

    if( data.statusCode === 500 &&
              this.lastData !== data ) {
      this.lastData = data;
      this.growl.showErrorLong('External server error', 'External server error, not all data could be loaded, please try again later!' );
    }

    this.users = new Map();
    let catalogUsers: CatalogUsers[] = [];

    const nameIdMap: Map<string, string> = new Map();
    if(data.facets.has('catalogNames')) {
      for( const fc of data.facets.get('catalogNames').originalData ) {
        nameIdMap.set(fc.id, fc.name);
      }
    }

    data.facets.forEach((value, key) => {
      if( key.startsWith("users_") ) {
        const catId = key.substring("users_".length);
        const catName = nameIdMap.get(catId);
        let catUsers = <CatalogUsers>{id: catId, name: catName, users: []};
        for( const fc of data.facets.get(key).originalData ) {
          catUsers.users.push(fc);
        }
        catalogUsers.push(catUsers);
      }
    });
    catalogUsers.sort((a, b) => b.users.length - a.users.length);
    this.catalogUsers = catalogUsers;
    this.initConfigForEachCatalogUser();
  }

  convertSecondsToHourAndMinute(seconds: number): string {
    return UtilsService.convertSecondToHourAndMinute(seconds);
  }

  getPageFacets(): string[] {
    return [ 'visits', 'pageViews', 'countries' ];
  }

  getChartActors(): ChartActor[] {
    return [ this.visitsChart, this.pageViewsChart, this.countriesChart ];
  }

  itemSelected(item: FacetItem): void {

  }

  DownloadStatusValues = DownloadStatus;
  public downloadHandlers: {};
  exportUsers($catalogId) {
    let request: ChartRequest = this.chartParams;
    request = request.clone();
    request.facets = ['users_' + $catalogId];
    request.facetCount = -1;
    const path = this.getExportPath(); // TODO: make environment var

    let downloadHandler = this.downloadHandlers[$catalogId] as DownloadHandler;
    this.apiService.doExport(path, downloadHandler, request);
  }


  private initConfigForEachCatalogUser() {
    this.displayCount = {};
    this.downloadHandlers = {};
    this.catalogUsers.forEach(catalogUser => {
      this.displayCount[catalogUser.id] = 10;
      this.downloadHandlers[catalogUser.id]= new DownloadHandler(true);
    });
  }

  displayCount: {};

  public changeTop($event: any, catalogId) {
    this.displayCount[catalogId] = $event.value;
  }

  protected getFeatureOnMenu() {
    return Features.BASIC_USER_ACTIVITY;
  }
}
