import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminShell } from '../admin-shared/shells/admin-shell/admin-shell';

import {
    AuthGuard,
    ConfirmDeactivate
} from '../admin-shared';

import {
    TAAnalyticsDashboardPage
} from '.';
import { MostPopularBrandsComponent } from '@app/tecalliance-analytics/pages/most-popular-brands/most-popular-brands.component';
import { TaAnalyticsMostSearchedOeNumbersComponent } from '@app/tecalliance-analytics/pages/ta-analytics-most-searched-oe-numbers/ta-analytics-most-searched-oe-numbers.component';
import { TaAnalyticsMostSearchedVehiclesComponent } from '@app/tecalliance-analytics/pages/ta-analytics-most-searched-vehicles/ta-analytics-most-searched-vehicles.component';
import { MostPopularArticlenumbersComponent } from '@app/tecalliance-analytics/pages/most-popular-articlenumbers/most-popular-articlenumbers.component';
import { MostPopularGenartsComponent } from "@app/tecalliance-analytics/pages/most-popular-genarts/most-popular-genarts.component";
import { ArticlenumbersDetailsComponent } from './pages/articlenumbers-details/articlenumbers-details.component';
import { VehicleDetailsComponent } from './pages/vehicle-details/vehicle-details.component';
import { MostSearchedNumbersComponent } from './pages/most-searched-numbers/most-searched-numbers.component';
import { MostSearchedVehiclesComponent } from './pages/most-searched-vehicles/most-searched-vehicles.component';
import { FullExportListComponent } from './pages/full-export-list/full-export-list.component';
import { GenericArticleDetailsComponent } from './pages/generic-article-details/generic-article-details.component';
import { MostSelectedCountriesComponent } from './pages/most-selected-countries/most-selected-countries.component';
import { RmiOverviewComponent } from './pages/rmi-overview/rmi-overview.component';
import { RmiOverviewDetailsComponent } from '@app/tecalliance-analytics/pages/rmi-overview-details/rmi-overview-details.component';
import { CountryDetailsComponent } from './pages/country-details/country-details.component';
import {MostSearchedVisitorsComponent} from '@app/tecalliance-analytics/pages/most-searched-visitors/most-searched-visitors.component';
import { MostSearchedVinNumbersComponent } from './pages/most-searched-vin-numbers/most-searched-vin-numbers.component';
import { MostSearchedNumberPlatesComponent } from './pages/most-searched-number-plates/most-searched-number-plates.component';
import { SolrMonitoringComponent } from './pages/solr-monitoring/solr-monitoring.component';
import {GenericArticleOrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends-generic-article.component';
import {CountryOrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends-country.component';
import {ReleaseNotesComponent} from '@app/admin/pages/release-notes/release-notes.component';
import {MarketOverviewCompetitionComponent} from '@app/tecalliance-analytics/pages/market-overview-competition/market-overview-competition.component';
import {MarketOverviewTrendsComponent} from '@app/tecalliance-analytics/pages/market-overview-trends/market-overview-trends.component';
import {HomeComponent} from '@app/tecalliance-analytics/pages/home/home.component';
import { MostSearchedCountriesComponent } from './pages/most-searched-countries/most-searched-countries.component';
import { SalesBrandsComponent } from './pages/sales-brands/sales-brands.component';
import { SalesCountriesComponent } from './pages/sales-countries/sales-countries.component';
import { SalesGenartsComponent } from './pages/sales-genarts/sales-genarts.component';
import { SalesArticleNumbersComponent } from './pages/sales-article-numbers/sales-article-numbers.component';
import { SalesCountryDetailsComponent } from './pages/sales-country-details/sales-country-details.component';
import { SalesArticleNumbersDetailsComponent } from './pages/sales-article-numbers-details/sales-article-numbers-details.component';
import {
  ArticleVisibilityBrandsComponent
} from "@app/tecalliance-analytics/pages/article-visibility-brands/article-visibility-brands.component";
import {
  ArticleVisibilityGenartsComponent
} from "@app/tecalliance-analytics/pages/article-visibility-genarts/article-visibility-genarts.component";
import {
  ArticleVisibilityArticleNumberComponent
} from "@app/tecalliance-analytics/pages/article-visibility-article-number/article-visibility-article-number.component";
import { MostSearchedOeNumbersComponent } from './pages/most-searched-oe-numbers/most-searched-oe-numbers.component';

const routes: Routes = [
    {
        path: 'analytics',
        component: AdminShell,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                canActivateChild: [AuthGuard],
                children: [
                    { path: 'most-searched-oe-numbers', component: MostSearchedOeNumbersComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'most-searched-numbers', component: MostSearchedNumbersComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'most-searched-countries', component: MostSearchedCountriesComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'most-searched-vin-numbers', component: MostSearchedVinNumbersComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'most-searched-number-plates', component: MostSearchedNumberPlatesComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'most-searched-vehicles', component: MostSearchedVehiclesComponent, canDeactivate: [ConfirmDeactivate]},
                    { path: 'user-stats-premium', component: MostSearchedVisitorsComponent, canDeactivate: [ConfirmDeactivate]},
                    { path: 'user-stats', component: TAAnalyticsDashboardPage, canDeactivate: [ConfirmDeactivate] },

                    // article views
                    { path: 'article-views-brands', component: MostPopularBrandsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-oe-numbers', component: TaAnalyticsMostSearchedOeNumbersComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-vehicles', component: TaAnalyticsMostSearchedVehiclesComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-vehicles/details/level1', component: VehicleDetailsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-article-numbers', component: MostPopularArticlenumbersComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-article-numbers/details/level1', component: ArticlenumbersDetailsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-genarts', component: MostPopularGenartsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-genarts/details/level1', component: GenericArticleDetailsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-countries', component: MostSelectedCountriesComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-views-countries/details/level1', component: CountryDetailsComponent, canDeactivate: [ConfirmDeactivate] },

                    // article visibility
                    { path: 'article-visibility-brands', component: ArticleVisibilityBrandsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-visibility-genarts',  component: ArticleVisibilityGenartsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'article-visibility-article-numbers',  component: ArticleVisibilityArticleNumberComponent, canDeactivate: [ConfirmDeactivate] },


                    // Ordermanager data
                    { path: 'generic-article-order-trends', component: GenericArticleOrderTrendsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'country-order-trends', component: CountryOrderTrendsComponent, canDeactivate: [ConfirmDeactivate] },

                    // full-export
                    { path: 'full-export', component: FullExportListComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'rmi-overview', component: RmiOverviewComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'rmi-overview/details/level1', component: RmiOverviewDetailsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'monitoring/solr', component: SolrMonitoringComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'market-overview-competition', component: MarketOverviewCompetitionComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'market-overview-trends', component: MarketOverviewTrendsComponent, canDeactivate: [ConfirmDeactivate] },

                //Sales Page
                    { path: 'sales-brands', component: SalesBrandsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'sales-genarts', component: SalesGenartsComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'sales-article-numbers', component: SalesArticleNumbersComponent, canDeactivate: [ConfirmDeactivate] },
                    { path: 'sales-countries', component: SalesCountriesComponent, canDeactivate: [ConfirmDeactivate] },

                    // Default route
                    { path: '', component: HomeComponent, canDeactivate: [ConfirmDeactivate] }
                ]
            }
        ]
    },
    {
      path: '',
      component: HomeComponent,
      canActivate: [AuthGuard]
    }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class TecAllianceAnalyticsRoutingModule {}
