<div class="row py-1">
  <div class="col-lg-6 col-md-12">
    <div class="card">
      <div class="card-header">
        <span>General Informations</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-5">
                Country:
              </div>
              <div class="col-sm-7">
                {{pageRequest.countryName}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row py-3 row-grid">
  <div class="col-md-12 col-sm-12 col-lg-6">
    <chart #genartChart [field]="fieldGenartNo" headLabel="Generic Articles - Your Brand(s)" [selectable]=false [diagrammFixedLabel]="' '"></chart>
  </div>

  <div class="col-md-12 col-sm-12 col-lg-6">
    <chart #articleNumberChart [field]="fieldArticleNumber"
           headLabel="Article numbers" [selectable]="false" [showSearch]=true [diagrammFixedLabel]="' '"></chart>
  </div>

  <div class="col-md-12 col-sm-12 col-lg-6">
    <chart #oeNumberChart [field]="fieldOeNumber" headLabel="OE Numbers - Your Brand(s)"
           [showSearch]=true [diagrammFixedLabel]="' '"></chart>
  </div>

  <div class="col-md-12 col-sm-12 col-lg-6">
    <chart #vehiclesChart [field]="fieldVehicles" headLabel="Vehicles - Your Brand(s)" [selectable]="false"
           [showSearch]=true [diagrammFixedLabel]="' '"
           [searchTooltip]="vehicleSearchTooltip"></chart>
  </div>

</div>

