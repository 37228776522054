import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';


import {map, tap} from 'rxjs/operators';

import {RequestCacheService} from '../services/core/request-cache.service';
import {Observable, of} from 'rxjs';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const cachedResponse = this.cache.get(req);
    if (cachedResponse && cachedResponse.headers) {
      let newHeaders = cachedResponse.headers.set(RequestCacheService.CACHE_HEADER, 'true');
      return of(cachedResponse.clone({headers: newHeaders}));
    }
    return this.sendRequest(req, next, this.cache).pipe(
      map((resp: HttpResponse<any>) => {
        if (resp.headers) {
          let newHeaders = resp.headers.set(RequestCacheService.CACHE_HEADER, 'false');
          return resp.clone({headers: newHeaders});
        }
        return resp;
      })
    );
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService): Observable<HttpEvent<any>> {

    let cacheHandler = event => {
      try {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
          cache.cleanup();
        }
      } catch (e) {
        console.log('Error when handling cache');
        console.error(e);
      }
    };

    return next.handle(req).pipe(
      tap(cacheHandler)
    );
  }
}
