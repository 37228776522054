import {ManagerPage} from '@app/admin-shared';
import {IAnalyticsPage, ReportParams} from '@app/shared/models/i-analytics-page';
import { AfterViewInit, EventEmitter, Injector, OnDestroy, OnInit, ViewChild, Directive } from '@angular/core';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {AnalyticsFilterComponent, ApiService} from '@app/shared';
import {NavigationService, StorageService, TrackerService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ChartActor} from '@app/shared/components/chart-actor';
import {FacetItem} from '@app/shared/models/facet-item';
import {Fields} from '@app/shared/models/fields';
import {Settings} from '@app/shared/models/settings/settings';
import {Brand} from '@app/shared/models/brand';
import {BasicResponse} from '@app/shared/models/basic-response';
import {SettingNames} from '@app/shared/models/setting-names';
import {PageRequest} from '@app/shared/models/page-request';
import {FilterChangeEvent} from '@app/shared/models/filter-change-event';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {Ids} from '@app/shared/models/ids';
import {DownloadHandler} from '@app/shared/models/download-handler';
import {Router} from '@angular/router';
import { GuidedTourService } from 'ngx-guided-tour';
import { DashboardTour } from '@app/bootstrap/components/tec-portal/dashboard-tour';
import { SharedInjector } from '@app/shared/shared.module';
import {DatasourceFeaturesMap} from '@app/shared/models/datasource-features-map';
import { PageName } from '@app/shared/models/page-name';

@Directive()
export abstract class AnalyticsPage extends ManagerPage<any> implements IAnalyticsPage, OnInit, OnDestroy, AfterViewInit {

  private readonly FIELDS_WITH_ID: string[] = [Fields.BRAND_NO, Fields.GENART_NO];

  public dataSubscriber: EventEmitter<ReportResponse> = new EventEmitter();
  protected chartParams: ChartRequest;
  protected currentChartData: ReportResponse;
  protected genericArticleFavoritesLoaded: boolean = false;

  public abstract get infoText(): string;

  private active: boolean = false;
  private clickCount: number = 0;
  private viewStartedTime: number = 0;
  private trackUserBind: any = null;

  public pageRequest: PageRequest;

  private searchValue: string;
  private searchField: string;
  public hasBrandlessFacet: boolean = false;

  protected datasources: ChartData;

  @ViewChild('filter', {static: true}) filter: AnalyticsFilterComponent;

  constructor(
    appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected router: Router
    ) {
    super(appInsights, confirm, translate, navigation, apiService, router);

    this.pageRequest = this.parsePageRequest();

    console.log('pageRequest: ', this.pageRequest);

    this.chartParams = this.getChartRequest();
    this.chartParams.paramsChangeListener.subscribe(
      (event: FilterChangeEvent) => {
        if (event.field == Fields.DATASOURCE) {
          apiService.selectedDataSource = event.values;

          if (this.filter) {
            this.filter.selectedDataSource = event.values;
          }
        }
      }
    );

    this.initFacetsParams();

    this.trackUserBind = this.trackUser.bind(this);
    window.addEventListener('click', this.trackUserBind);
  }

  public getDataSourceName( id ) {
    const fc: FacetItem = this.datasources.originalData.find( (ds) => ds.id === id );
    if( fc ) {
      return fc.name;
    }
    return '';
  }

  protected initFacetsParams() {
    if (this.getPageFacets()) {

      const pageFacets = this.getPageFacets();
      for (const facet of pageFacets) {
        if ( this.chartParams.facets.indexOf(facet) == -1) {
          this.chartParams.facets.push(facet);
        }
      }
    }
  }

  private parsePageRequest(): PageRequest {
    return new PageRequest()._decode(document.location.href);
  }

  private resetTrackingVars() {
    this.clickCount = 0;
    this.viewStartedTime = new Date().getTime();
  }

  private trackUser(event) {
    //console.log( 'trackUser event.type=' + event.type + ", pageName: " + this.pageName);

    if (!this.active) {
      return;
    }

    if (event.type == 'click' && event.clientX > 280 && event.clientY > 125) {
      this.clickCount++;
      //console.log('clickCount: ' + this.clickCount + 'clientY: ' + event.clientY);
    }
  }

  getDataSubscriber(): EventEmitter<ReportResponse> {
    return this.dataSubscriber;
  }

  getChartParams(): ChartRequest {
    return this.chartParams;
  }

  public generatePDF(params: ReportParams, canvasImage: string) {
    this.apiService.generatePDF(params, canvasImage, this.currentChartData,
      this.chartParams, this, this.infoText);
  }

  protected showLoadingAnimation: boolean = true;

  /**
   * track async after 1000 milliseconds
   *
   * @param filtersChanged
   * @private
   */
  private trackFilterUsage( filtersChanged: string[] ) {
    window.setTimeout(() => {
      for( const field of filtersChanged ) {
        let name = '';
        if( field === 'timespan' ) {
          name = this.getChartParams().getTimeSpanForTracking();
        } else {
          name = this.getChartParams().params.get(field).join(',');
        }
        this._tracker.trackEvent('filters_used', field, { Name: name } );
      }
    }, 1000);
  }

  public loadReport(params?: ReportParams, downloadHandler?: DownloadHandler): void {
    const currentPath = this.router.url;
    //console.log( 'currentPath: ', currentPath );
    //console.log( 'allVisiblePaths: ', this.apiService.datasourceSettings.allVisiblePaths );

    if( this.apiService.isDatasourceChanged()
          && this.apiService.datasourceSettings && this.apiService.datasourceSettings.allVisiblePaths.length &&
            this.apiService.datasourceSettings.allVisiblePaths.indexOf(currentPath) == -1 ) {
      this.router.navigate([this.apiService.datasourceSettings.features[0].defaultPath]); // redirect first to the valid page
      return;
    }
    this.apiService.applyDatasourceId();

    this.loadReport2(params, downloadHandler);
  }

  public loadReport2(params?: ReportParams, downloadHandler?: DownloadHandler): void {
    console.log('ap.loadReport #1');

    if( this.getChartParams().userChangedFilters.size ) {
      const filtersChanged: string[] = Array.from(this.getChartParams().userChangedFilters);
      this.trackFilterUsage(filtersChanged);
      this.getChartParams().userChangedFilters.clear();
    }
    if (this.loadReportCustomized(params)) {
      return;
    }

    let request: ChartRequest = this.chartParams;

    if (params && params.specialSearch) {

      const facetField = Fields.ARTICLE_NUMBER == params.facetField ?
        Fields.RAW_ARTICLE_NUMBER : params.facetField;

      const isLinkingTargetId = Fields.VEHICLES == params.facetField
        || Fields.VEHICLES_BRANDLESS == params.facetField;

      if (params.search && params.search.trim().length) {
        let search = params.search;
        let kType: any;
        if (isLinkingTargetId) {
          kType = search.trim();
        }

        if (isLinkingTargetId && !isNaN(kType)) {
          // no add wildcard
          search = kType;
        } else if (!((params.search.startsWith('"') && params.search.endsWith('"'))
          || params.search.indexOf('*') != -1
          || params.search.indexOf('?') != -1)) {
          search = '*' + params.search + '*';
        }
        this.searchValue = search;
        this.searchField = facetField;
      } else {
        this.searchValue = null;
        this.searchField = null;
      }
      params = null;
    }

    if (this.searchValue && this.searchField) {
      request = request.clone();
      request.setIds('search.' + this.searchField, [this.searchValue]);
    }

    if (params && params.timeLine && params.facetField) {
      request = request.clone();

      if( params.timeLineIds ) {
        request.setTimeLineIds(params.facetField, params.timeLineIds);
      }

      request.facets = [params.facetField + Fields.DEMAND_TIME_LINE];
      //request.facetCount = 10;
    } else if (params && (params.facetField || params.export)) {
      request = request.clone();
      request.facets = [params.facetField];
      request.facetCount = -1;

      if (params.export) {

        const path = this.getExportPath(); // TODO: make environment var
        this.apiService.doExport(path, downloadHandler, request);
        return;
      }
    }

    this.apiService.getReport(params && params.specialUrl ? params.specialUrl : this.getReportPath(),
      request, this.showLoadingAnimation)
      .subscribe((data: ReportResponse) => {
        if (params && params.facetField && !params.timeLine && params.export) {
          return;
        } else {
          //this.removePageDependendDataSource( data );

          this.currentChartData = data;
          this.currentChartData.pageLoad = params && params.pageLoad;

          if( params && params.timeLineIds && params.callbackFunction ) {
              if( data.facets.has( params.facetField ) ) {
                params.callbackFunction( data.facets.get(params.facetField).timeLine );
              }
              return;
          }

          if ((params && params.notNeedFilterData)
            || !this.needFilterData) {

            this.emitData(data);
            this.startFirstTimeProductTour();
            return;
          }

          this.addBrands(data);
          this.addGenericArticles(data);
          this.addCountries(data);
          this.addSearchTypeId(data);
          this.addNumberPlateType(data);
          this.addVehicleTypes(data);

          if (!this.genericArticleFavoritesLoaded) {
            this.addGenericArticleFilterFavorites(data);
          }
          console.log("Data analytics page", data);

          this.startFirstTimeProductTour();
        }
      });

  }

  private startFirstTimeProductTour() {
    const storageService = SharedInjector.get(StorageService);
    let tourSeen = storageService.getCookie(DashboardTour.TOUR_SEEN_KEY);
    if (tourSeen !== 'true' && DashboardTour.isTourSupported(this.router.url)) {
      let productTour = SharedInjector.get(DashboardTour);
      let guideTourService = SharedInjector.get(GuidedTourService);
      productTour.setupTour(this.router.url);
      guideTourService.startTour(productTour.tour);
      this.apiService.saveSettings(DashboardTour.TOUR_SEEN_KEY, 'true')
        .subscribe((data: BasicResponse) => {
          storageService.setCookie(DashboardTour.TOUR_SEEN_KEY, 'true', 1);
        });;
    }
  }

  protected loadReportCustomized(params?: ReportParams): boolean {
    return false;
  }

  private emitData(data: ReportResponse) {
    setTimeout(() => {
      this.dataSubscriber.emit(data);
    }, 500);
    this.reportLoaded(data);
    this.showOrHideBrandLessChart(data);
  }

  private showOrHideBrandLessChart(data: ReportResponse) {
    this.hasBrandlessFacet = false;
    for (const facetKey of data.facets.keys()) {
      if (facetKey.includes(':bl:')) {
        this.hasBrandlessFacet = true;
        return;
      }
    }
  }

  abstract getPageFacets(): string[];

  abstract getChartActors(): ChartActor[];

  abstract getReportPath(): string;

  abstract getExportPath(): string;

  abstract getChartRequest(): ChartRequest;

  abstract reportLoaded(data: ReportResponse): void;

  abstract itemSelected(item: FacetItem): void;

  abstract getPageStoreKey(): string;

  public refresh(): void {

  }

  public hasChanges(): boolean {
    return false;
  }

  needFilterData = true;

  ngOnInit() {
    super.ngOnInit();
    if (this.filter) {
      this.filter.setPage(this);
      this.filter.selectedDataSource = this.apiService.selectedDataSource;
    }
    for (const ca of this.getChartActors()) {
      if (ca) {
        ca.setPage(this);
      }
    }
    if (this.needFilterData) {
      this.loadDataSources();
    }
  }

  loadDataSources() {
    this.apiService.getUserDataSources(this.getPageStoreKey())
      .subscribe((data: FacetItem[]) => {

        const cd = new ChartData(Fields.DATASOURCE, data, data.length, 'name', 'count', false);
        const rp = new ReportResponse();
        rp.facets.set(Fields.DATASOURCE, cd);

        this.addMapData(Fields.DATASOURCE, cd);

        this.datasources = cd;

        this.emitData(rp);
      });
  }

  ngOnDestroy() {
    //console.log('ngOnDestroy pageName: ' + this.pageName);
    this.active = false;
    window.removeEventListener('click', this.trackUserBind);
    super.ngOnDestroy();
  }

  private trackUsage(action: string, params: ReportParams) {
    this._tracker.trackEvent('usage_analysis',
      action, {
        Name: 'page_name:' + this.pageName
          + '/~~/field:' + params.facetField
          + (params.specialSearch ? '/~~/search:' + params.specialSearch : '')
          + '/~~/params:' + this.chartParams.toRequestParams()
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.resetTrackingVars();
    this.active = true;
  }

  private getStorage(): StorageService {
    return this.apiService.storage;
  }

  private getSettingsKey(): string {
    if (this.getPageStoreKey() && this.getPageStoreKey().startsWith('@')) {
      return this.getPageStoreKey() + this.apiService.getUserName();
    }
    return 'settings-' + this.apiService.getUserName();
  }

  public saveSettings(o: Settings): void {

    let settings = this.loadSettings();
    settings[o.key_s] = o;
    this.getStorage().setCookieObject(this.getStorage().getKey(this.getSettingsKey()), settings, 365);
  }

  public getSettings(o: Settings): Settings {

    let settings = this.loadSettings();
    return settings[o.key_s] || o;
  }

  private loadSettings(): any {

    const settingsKey = this.getSettingsKey();
    let settings = {};
    let savedSettings = this.getStorage().getCookieObject(this.getStorage().getKey(settingsKey));
    if (savedSettings) {
      settings = savedSettings;
    }
    return settings;
  }

  private addMapData(key: any, cd: ChartData) {
    this.apiService.pdfService.mapData.set('' + key, cd);
  }

  private addBrands(data: ReportResponse) {
    this.apiService
      .getBrands(this.getChartParams().getDatasource())
      .subscribe((brands: Brand[]) => {
        this.addMapData(Fields.BRAND_NO, data.addBrandsSelection(brands));
        this.emitData(data);
      });
  }

  private addGenericArticles(data: ReportResponse) {
    this.apiService
      .getResources('genericArticles2')
      .subscribe((gas: any) => {
        this.addMapData(Fields.GENART_NO, data.addGenericArticleSelection(gas));
        this.emitData(data);
      });
  }

  addSearchTypeId(data: ReportResponse): void {


    this.apiService.getResources('articleNumberTypes')
      .subscribe((numberTypes: any) => {

        if (this.pageName === PageName.MOST_SEARCHED_OE) {
          this.addMapData(Fields.SEARCH_TYPE_ID_OE, data.addSearchTypeIdOESelection({"a1": numberTypes["a1"]}));
        }

        this.addMapData(Fields.SEARCH_TYPE_ID, data.addSearchTypeIdSelection(numberTypes));
          this.emitData(data);


      });

  }

  addNumberPlateType(data: ReportResponse): void {
    this.apiService.getResources('numberPlateTypes')
      .subscribe((numberTypes: any) => {
        this.addMapData(Fields.NUMBER_PLATE_TYPE, data.addNumberPlateTypeSelection(numberTypes));
        this.emitData(data);
      });
  }

  addCountries(data: ReportResponse): void {
    this.apiService.getResources('countries')
      .subscribe((countries: any) => {
        this.apiService.getSettings(Ids.GEO_LOCATIONS).subscribe((favs: BasicResponse) => {
          const favorites: FacetItem[] = favs.message && favs.message.startsWith('[')
            ? JSON.parse(favs.message) : [];
          for (const f of favorites) {
            f.selected = false;
            if (f.details && f.details.distance) {
              f.name = '[' + f.details.country_code + '] ' + f.label + ' ( ' + f.details.distance + 'km )';
            } else {
              f.name = '[' + f.details.country_code + '] ' + f.label;
            }
          }

          this.addMapData(Fields.LOCATION_COUNTRY, data.addCountriesSelection(countries, favorites));
          this.emitData(data);
        });
      });
  }

  addVehicleTypes(data: ReportResponse): void {
    this.apiService.getResources('vehicleTypes')
      .subscribe((countries: any) => {
        this.addMapData(Fields.VEHICLE_TYPES, data.addVehiclesTypes(countries));
        this.emitData(data);
      });
  }

  saveGenericArticleFilterFavorites(favorites: FacetItem[]): void {
    this.apiService.saveSettings(
      SettingNames.GENERIC_ARTICLE_FAVORITES,
      JSON.stringify(favorites))
      .subscribe((response: BasicResponse) => {

      });
  }

  addGenericArticleFilterFavorites(data: ReportResponse): void {

    this.apiService.getSettings(
      SettingNames.GENERIC_ARTICLE_FAVORITES
    ).subscribe((r: BasicResponse) => {
      if (r.statusCode == 200 && r.message) {
        data.addGenericArticleFavorites(<FacetItem[]>JSON.parse(r.message));
      }
      this.genericArticleFavoritesLoaded = true;
      this.emitData(data);
    });
  }

  mergeDataSourceIds(itemDsIds: string[]) {
    if (this.chartParams && this.chartParams.params) {
      let dss: string[] = this.chartParams.params.get(Fields.DATASOURCE);
      if (dss) {
        const r: string[] = [];
        for (const ds of dss) {
          let dsCheck = ds;
          if (ds.indexOf(':') != -1) {
            dsCheck = ds.substring(0, ds.indexOf(':'));
          }
          for (const id of itemDsIds) {
            if (dsCheck === id) {
              r.push(ds);
            }
          }
        }
        return r;
      }
    }
    return itemDsIds;
  }

  getInfoText(fieldName: string): string {
    return this.apiService.getInfoText(fieldName);
  }

  runLoading(seconds: number) {
    this.apiService.runLoading(seconds);
  }

  protected isAuthorizationRequiredPage(): boolean {
    return true;
  }

  getTracker(): TrackerService {
    return this._tracker;
  }

}
