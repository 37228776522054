<div class="tec-section">
    <legend><span class="orange-square"></span>TecAlliance GmbH</legend>
    <div class="container-fluid">  
        <div class="row">
            <div class="col-xs-12">
                <h2>
                    Page <strong>not found</strong>!
                </h2>
            </div>
        </div>
    </div>
</div>