import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * AxlesBrandsPage params:
   * - `mandator`
   */
  // tslint:disable-next-line:max-line-length
  axlesBrands: { id: 'axles', name: 'AxlesBrandsPage', route: '/:mandator/parts/axles',  historyOptions: { resetHistory: true, resetQueryParams: true }},

  /**
   * AxlesBrandModelsPage params:
   * - `mandator`
   * - `manufacturerId`
   *
   * @param {NavParams} params
   */
  axlesBrandModels: { id: 'axlesbmds', name: 'AxlesBrandModelsPage', route: '/:mandator/parts/axles/:manufacturerId/models' },

  /**
   * AxlesModelsPage params:
   * - `mandator`
   *
   * @param {NavParams} params
   */
  axlesModels: { id: 'axlesmds', name: 'AxlesModelsPage', route: '/:mandator/parts/axles/models' },

  /**
   * AxlexBodyTypesPage params:
   * - `mandator`
   *
   * @param {NavParams} params
   */
  axlesBodyTypes: { id: 'axlestypes',  name: 'AxlexBodyTypesPage', route: '/:mandator/parts/axles/body-types' },
};
