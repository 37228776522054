<div #rootElement class="datasource-selector-dropdown-panel">
  <div class="trees" *ngFor="let gr of datasourceTree">

    <p-tree [value]="gr.children" selectionMode="checkbox"
            [style]="{ width: '100%' }"
            [(selection)]="selectedDatasources"
            class="tree"
            (onNodeSelect)="onNodeSelect($event, gr.children, true)"
            (onNodeUnselect)="onNodeSelect($event, gr.children, false)"></p-tree>
  </div>
</div>
