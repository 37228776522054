<div #clear_selection_btn class="multiselect-clear-btn" [hidden]="!showClearButton || selectedOptions.length == 0" pTooltip="Clear selection"
     (click)="selectedOptions = []" ></div>

<p-multiSelect #selectInput [options]="options" [(ngModel)]="selectedOptions" [optionLabel]="optionLabel" class="ta-multiselect"
[disabled]="options && options.length === 1 && options[0].disabled"
            [virtualScroll]="true" [resetFilterOnHide]="true" [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'"
                (onPanelShow)="onPanelShow()" [maxSelectedLabels]=0 [selectedItemsLabel]="displayLabel()">
    <ng-template let-facet let-i="index" pTemplate="item">
        <div class="ms-item" [ngClass]="{ 'option-disabled': ( facet.value.disabled) }" [ngClass]="{ 'group': ( facet.value.id && facet.value.id.indexOf(',') != -1 ) }">{{facet.label}}</div>
    </ng-template>
</p-multiSelect>
