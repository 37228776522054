import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {AuthService, StorageService, TrackerService, UtilsService} from '@app/core';
import {ApiService, PageComponent} from '@app/shared';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';
import { FacetItem } from "../../../../app/shared/models/facet-item";
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DatasourceInfo } from '@app/shared/models/datasource-info';
import {DatasourceSettings} from '@app/shared/models/datasource-settings';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
//This component is just redirection point
export class HomeComponent extends PageComponent implements AfterViewInit{

  readonly SOURCE_ORDER_SETTINGS = 'source_order_settings';

  @ViewChild('cardContainer', {static: false}) cardContainer: ElementRef;

  get pageName(): string {
    return 'Home';
  }

  public dataRows = [];

  public showDSLinks = false;
  public all: DatasourceInfo;
  public showLinks = false;

  public datasourcesInfo: DatasourceInfo[];
  public readyToRender = false;
  public datasourcesLoaded = false;

  constructor(
    appInsights: TrackerService,
    confirmation: ConfirmationService,
    protected apiService: ApiService,
    protected router: Router,
    public _auth: AuthService,
    private _storage: StorageService
  ) {
    super(appInsights, confirmation, apiService, router);

    _auth.loggedOut.subscribe(() => {
      this.all = null;
    })

    this.apiService.getUserDataSources("HomeComponent").subscribe((data: FacetItem[]) => {
      const datasourceIds = data.map(x => x.id);
      this.apiService.getDatasourceStats(datasourceIds).subscribe((dataSourcesInfo: DatasourceInfo[]) => {

        for (const dsInfo of dataSourcesInfo) {
          dsInfo.name = data.filter(x => x.id == dsInfo.id)[0].name;
        }
        this.datasourcesInfo = dataSourcesInfo.filter(item => item.topCountries != null);
        //order the datasource before split into rows
        if (this.getSourceOrderSettings() && Array.isArray(this.getSourceOrderSettings())) {

          const orderArray = this.getSourceOrderSettings() ;
          this.datasourcesInfo.sort(( a, b ) => {
            return orderArray.indexOf(a['id']) > orderArray.indexOf(b['id']) ? 1 : -1;
          })

        } else {
          // default sort
          this.datasourcesInfo = UtilsService.sortDatasourcesDefault(this.datasourcesInfo);

          //console.log('datasourceInfo: ', this.datasourcesInfo);
        }

        //this.showDSLinks = this.datasourcesInfo.length > 4; // TODO - do it later
        this.initDatasourceFeatures();

        this.datasourcesLoaded = true;

        this.splitIntoRows();

      })
    });
  }

  initDatasourceFeatures() {

      let that = this;
      this.apiService.menuInterface.fetchPropertiesAsync();
      const int1 = setInterval(function(){

        if( that.apiService.menuInterface.initialized() ) {
          for (const ds of that.datasourcesInfo) {
            const info: DatasourceSettings = that.apiService.menuInterface.getFeaturesForDatasource(ds.id, ds.cartEnabled);
            ds.features = info.features;
          }
          clearInterval(int1);
        }
      }, 300);

  }

  splitIntoRows() {
    this.dataRows = this.splitDataInto2Dimensions( [ ...this.datasourcesInfo ], this.itemsPerRow( window.innerWidth));
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    window.onresize = () => {
      this.splitIntoRows();
    };
  }

  ngAfterViewInit() {
    this.readyToRender = true;
  }

  public hasChanges(): boolean {
    return false;
  }

  protected isAuthorizationRequiredPage(): boolean {
    return true;
  }

  protected getFeatureOnMenu(): Features {
    return Features.HOME;
  }

  updateSourceOrderSettings() {
    const flatFace = [].concat.apply([], this.dataRows);
    this._storage.setObject(this.SOURCE_ORDER_SETTINGS, flatFace.map(s => s.id));
  }

  getSourceOrderSettings() {
    return this._storage.getObject(this.SOURCE_ORDER_SETTINGS);
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      const currentIndex = event.currentIndex > 0? event.currentIndex -1 : event.currentIndex;

      const toPrevious =  event.container.data.pop();
      const toCurrent = event.previousContainer.data[event.previousIndex];
      event.container.data.splice(currentIndex, 0, toCurrent);
      event.previousContainer.data[event.previousIndex] = toPrevious;
    }

    this.updateSourceOrderSettings();
  }

  getTimeOfDay(){
    let now = new Date().getHours()
    if(now < 12){
      return 'Good morning, ';
    }else if(now < 18){
      return 'Good afternoon, ';
    }

    return 'Good evening, ';
  }

  private itemsPerRow(screenWidth: number) {
    if (screenWidth > 1175) {
      return 3;
    }

    if (screenWidth > 900) {
      return 2;
    }

    return 1;
  }




  private createAll(): DatasourceInfo {

    if(this.all) {
      return this.all;
    }

    const ids: SelectItem[] = [];
    for( const ds of this.datasourcesInfo ) {
      ids.push(
        {
          label: ds.name,
          value: ds.id
        }
      );
    }

    this.all = <DatasourceInfo>{
      id: 'all',
      ids: ids,
      name: 'Custom selection',
      selectedIds: []
    };

    return this.all;
  }

  private splitDataInto2Dimensions(oneDim: DatasourceInfo[], itemsPerArray: number ) {
    var results = [];
    while (oneDim.length) {
      results.push(oneDim.splice(0, itemsPerArray));
    }

    const all = this.createAll();

    if( results[ results.length - 1].length < itemsPerArray ) {

      if( this.datasourcesInfo.length > 1 ) {
        results[results.length - 1].push(all);
      }
      while (results[results.length - 1].length < itemsPerArray) {
        results[results.length - 1].push('fill');
      }
    } else {
      const row = [];
      if( this.datasourcesInfo.length > 1 ) {
        row.push(all);
      }
      while (row.length < itemsPerArray) {
        row.push('fill');
      }
      results.push(row);
    }

    console.log(results);

    return results;
  }

  datasourceAnchorLinkClick(event: MouseEvent) {
    //event.stopPropagation();
    //event.preventDefault();

    //return false;
  }

  scroll(event, id: string) {
    event.stopPropagation();
    event.preventDefault();

    const elem = document.getElementById(id);
    elem.scrollIntoView();

    const child = <Element>elem.firstChild;

    child.classList.add('highlight_border');

    console.log('child: ', child);

    let added = true;
    let interval = window.setInterval(() => {
      if( added ) {
        child.classList.remove('highlight_border');
      } else {
        child.classList.add('highlight_border');
      }
      added = !added;
    }, 500);

    window.setTimeout(() => {
      window.clearInterval(interval);
      child.classList.remove('highlight_border');
    }, 5000);

  }
}
