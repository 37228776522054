import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {TrackerService, NavigationService, UtilsService} from '@app/core';
import { ApiService } from '@app/shared';
import { ChartActor } from '@app/shared/components/chart-actor';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { TimeLineRows } from '@app/shared/models/analytics/time-line-rows';
import { ChartRequest } from '@app/shared/models/chart-request';
import { FacetItem } from '@app/shared/models/facet-item';
import "chartjs-plugin-colorschemes";
import { InfoTexts } from '@app/shared/models/info-texts';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { AnalyticsPage } from '../analytics-page';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'solr-monitoring',
  templateUrl: './solr-monitoring.component.html',
  styleUrls: ['./solr-monitoring.component.scss']
})
export class SolrMonitoringComponent extends AnalyticsPage {

  public allCoreTimeLines: TimeLineRows[] = [];

  public get infoText(): string {
    return InfoTexts.SOLR_CORES_TIME_LINE;
  }
  getPageFacets(): string[] {
    return [];
  }
  getChartActors(): ChartActor[] {
    return [];
  }
  getReportPath(): string {
    return '/monitoring/eventsTimeLineAllCores'
  }
  getExportPath(): string {
    return '/monitoring/eventsTimeLineAllCores';
  }
  getChartRequest(): ChartRequest {
    return new ChartRequest('*');
  }

  reportLoaded(data: ReportResponse, checkChanges=true): void {
    this.allCoreTimeLines = [];

    for (let [facetKey, facetValue] of data.facets) {
      for (let dataset of facetValue.timeLine.datasets) {
        let timelineRows = new TimeLineRows( facetValue.timeLine.originalData );
        timelineRows.datasets = [dataset];
        dataset.borderWidth = 1;
        dataset.radius = 2;
        this.allCoreTimeLines.push(timelineRows);
      }
    }
  }

  itemSelected(item: FacetItem): void {

  }

  getPageStoreKey(): string {
    return 'CoreTimeLines';
  }
  get pageName(): string {
    return 'Core Timelines';
  }

  public chartOptions = {
    maintainAspectRatio: false,
    tooltips: {
      enabled: true,
      yAlign: 'nearest', // nearest, default avarage
      mode: 'label',
      callbacks: {
        title: function(tooltipItems, data) {
          var idx = tooltipItems[0].index;

          var dataset = data.datasets[tooltipItems[0].datasetIndex];
          var currentValue = dataset.data[idx];
          let titleDisplay = [];

          //let firstRowTooltip = data.labels[idx];

          //titleDisplay.push( firstRowTooltip );
          titleDisplay.push( UtilsService.simpleNumberFormat(currentValue) );

          return titleDisplay;
        },
        label: function(tooltipItems, data) {
          //console.log(tooltipItems);

          return tooltipItems.xLabel;
        }
      }
    },
    scales: {
      xAxes: [{
        ticks: {
          display: true,
          callback: function (value){
            return UtilsService.shortenInteger(value);
          }
        },
        gridLines: {color: 'rgba(0, 0, 0, 0)'}
      }],
      yAxes: [{
        ticks: {
          display: true,
          beginAtZero: true,
          callback: function(value) {
            return UtilsService.shortenInteger(value);
          }
        },
        gridLines: {color: 'rgba(0, 0, 0, 0.1)', borderDash: [2, 4]}
      }]
    }
  };

  constructor(appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {

      super(appInsights, confirm, translate, navigation, apiService, router);

  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected getFeatureOnMenu() {
    return Features.ADMIN;
  }

}
