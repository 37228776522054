<p-dialog header="Filter dialog" [(visible)]="display" [style]="{width: '1200px'}">
  <div class="container-fluid">
    <p-tabView>
      <p-tabPanel header="Countries">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-4 country-group-col">
              <div class="row">
                <div class="col-sm-12" *ngFor="let itm of groupItems" [ngClass]="{'preSelected': itm.preSelected}"
                  (mouseover)="onGroupMouseOver(itm, true)" (mouseout)="onGroupMouseOver(itm, false)"
                     (click)="onGroupItemClicked(itm)">
                  <i
                    [className]="itm.selected ? 'fa fa-2 fa-check-square-o' : 'fa fa-2 fa-square-o'">&nbsp;</i>{{itm.name}}
                </div>
              </div>
            </div>
            <div class="col-sm-8 overflow-auto country-col">
              <div class="row">
                <div class="col-sm-4" [ngClass]="{'preSelected': itm.preSelected}" *ngFor="let itm of items"
                  (click)="itm.selected = !itm.selected">
                  <i
                    [className]="itm.selected ? 'fa fa-2 fa-check-square-o': 'fa fa-2 fa-square-o'">&nbsp;</i>{{itm.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header=" - regions, postal-codes">
        <geo-location-user-favorites #geoLocationFavorites></geo-location-user-favorites>
      </p-tabPanel>
    </p-tabView>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4">
        <p-button label="Cancel" (onClick)="display = false"></p-button>
      </div>
      <div class="col-sm-4">
        <p-button label="Apply" (onClick)="applySelected()"></p-button>
      </div>
      <div class="col-sm-4">
        <p-button label="Clear selection" (onClick)="clearSelections()"></p-button>
      </div>
    </div>
  </div>
</p-dialog>
