import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * Login Page params:
   * - `mandator`
   */
  login: { id: 'login', name: 'LoginPage', route: '/:mandator/login', historyOptions: { resetHistory: true } },

  /**
   * Home Page params:
   * - `mandator`
   */
  home: { id: 'home', name: 'HomePage', route: '/:mandator/home', isHome: true, historyOptions: { resetHistory: true } },

  /**
   * Logout Page params:
   * - `mandator`
   */
  logout: { id: 'logout', name: 'LogoutPage', route: '/:mandator/logout', historyOptions: { resetHistory: true } },
};
