import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService, TrackerService } from '@app/core';
import { ApiService } from '@app/shared';
import { ChartActor } from '@app/shared/components/chart-actor';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { Fields } from '@app/shared/models/fields';
import { InfoTexts } from '@app/shared/models/info-texts';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { AnalyticsPage } from '../analytics-page';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'most-searched-vin-numbers',
  templateUrl: './most-searched-vin-numbers.component.html',
  styleUrls: ['./most-searched-vin-numbers.component.scss']
})
export class MostSearchedVinNumbersComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.VIN_SEARCH_INFO;
  }

  getPageStoreKey(): string {
     return "MostSearchedVinnumbersComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.VIN_SEARCH );
  }

  get pageName(): string {
    return 'Most searched vin numbers';
  }

  public readonly fieldVinNumber = Fields.SEARCH_STRING;

  public vinNumberChartHeadLabel = 'Vin numbers';
  @ViewChild('numbersChart', {static: true}) numbersChart: ChartActor;

  public fieldSearchTypeId = Fields.SEARCH_TYPE_ID_FILTER;

  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {
      super( appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.SEARCH_STRING ];
  }

  getChartActors(): ChartActor[] {
    return [ this.numbersChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem): void {


  }

  protected getFeatureOnMenu() {
    return Features.VEHICLE_SEARCHES;
  }

}
