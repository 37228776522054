import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { PageComponent, ApiService } from '@app/shared';
import { ConfirmationService } from 'primeng/api';
import {CisResponse, TrackerService, UtilsService} from '@app/core';
import { User } from '@app/shared/models/user';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserSettings } from '@app/shared/models/user-settings';
import { BrandFilter } from '@app/shared/models/settings/brand-filter';
import { Brand } from '@app/shared/models/brand';
import { FacetItem } from '@app/shared/models/facet-item';
import { DatasourcesResponse } from '@app/shared/models/settings/datasources-response';
import {AutoComplete} from 'primeng/autocomplete';
import {Listbox} from 'primeng/listbox';

import { SaveUserResponse } from '@app/shared/models/save-user-response';
import { PasswordValidationResult } from '@app/shared/models/password-validation-result';
import {UserRoleDTO} from '@app/shared/models/user-role-dto';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';
import {BasicResponse} from '@app/shared/models/basic-response';
import de from "@angular/common/locales/de";
import {DeleteUserResponse} from "@app/core/services/security/cis/delete-user-response";
import {UserRole} from "@app/shared/models/user-role";


@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserListComponent extends PageComponent implements OnInit {

  pageName: string = 'User list'

  changed = false;

  allUsers: User[] = [];
  userList: User[] = [];
  selectedUser: User;

  _optionSelectAll = { label: 'all', value: 'all' };
  _pleaseSelect = { label: ' --- please select --- ', value: '' };
  _userRole;

  sortOptions;

  userRoles = [];
  userRolesSelect = [];

  activedSelection = [];
  _activatedFilter;

  brandFiltersFilter = [];
  _brandFilter;

  datasources = [];
  features = [];

  actionSelection = [];

  showEditUserDialog = false;
  showNewUserDialog = false;

  brandFilters = [];
  brandFiltersIdMap: Map<string, BrandFilter> = new Map();
  brandNamesMap: Map<number, string>;

  editUserform: FormGroup;
  newUserform: FormGroup;

  isSuperAdmin = false;

  sortField = 'userName';
  sortAsc = true;
  defaultSortValue = '';

  countDeleteMarkedUsers = 0;

  dialogStyle = {
    height: '400px',
    'min-height': '400px',
    width: '400px',
    'min-width': '400px'
  };

  @ViewChild('featuresAutoComplete', {static: true}) featuresAutoComplete: AutoComplete;

  //public features: AutocompleteData;

  public hasChanges(): boolean {
    return this.changed;
  }

  constructor(private ms: MessageService,
              private fb: FormBuilder,
              private api: ApiService,
              private tracker: TrackerService,
              private confirm: ConfirmationService,
              protected router: Router) {
    super(tracker, confirm, api, router);

    this.selectedUser = this.newUser();

    this._userRole = this._optionSelectAll.value;
    this._activatedFilter = this._optionSelectAll.value;
    this._brandFilter = this._optionSelectAll.value;
    this.prepareActivated();
    this.initFormControls();
  }

  ngOnInit() {
    this.loadRoles();
    this.loadFeatures();
    this.loadBrandFilters();
    this.loadBrandNames();
    this.loadDataSources();
    this.loadUsers();

    window.setTimeout(() => {
      this.isSuperAdmin = this.api.isSuperAdmin();
      this.prepareActivated();
    }, 5000);
  }

  loadUsers() {
    this.api.getUsers().subscribe(
      (data: User[]) => {
        this.allUsers = this.userList = data;

        this.sortByField(this.sortField, true);

        this.checkCountDeleted();
      });
  }

  checkCountDeleted() {
    this.countDeleteMarkedUsers = 0;
    for ( const u of this.allUsers ) {
      if( u.deleted ) {
        this.countDeleteMarkedUsers++;
      }
    }
  }

  sendTestMail() {
    this.api.testMail().subscribe((data: BasicResponse) => {
      alert('Test send result - statusCode: ' + data.statusCode + ', message: ' + data.message);
    });
  }

  loadDataSources() {
    this.api.getDatasources().subscribe((data: DatasourcesResponse) => {
      const r = [];

      for( const ds of data.datasources ) {
        r.push({ label: ds.name, value: 'DS-'+ds.id });
      }

      for( const site of data.sites ) {
        r.push({ label: site.name, value: 'PWK-' + site.siteIdNumeric });
      }


      this.datasources = r;
    });
  }

  getDatasourceNamesByIds( ids: string[] ) {
    const r = [];
    for ( const ds of this.datasources ) {
      if ( ids.indexOf(ds.value) != -1 ) {
        r.push( ds.label );
      }
    }
    return r;
  }

  loadBrandFilters( event?, pListBox? ) {
    let isRefresh = false;

    if( event ) {
      event.preventDefault();
      isRefresh = this.brandFiltersIdMap.size > 0;
      this.isRefreshingFilter = true;
    }

    this.api.getBrandFilters(true).subscribe((data: BrandFilter[]) => {
      const newBfs = [];
      const bfs = [];
      const bfsAll = [];

      let brandFiltersIdMap = new Map();

      for( const bf of data ) {
        const item = { label: bf.label, value: bf.id };
        if( isRefresh && !this.brandFiltersIdMap.has(bf.id)) {
          item['isNew'] = true;
          newBfs.push(item);
        } else {
          bfs.push(item);
        }
        bfsAll.push(item);
        brandFiltersIdMap.set( bf.id, bf );
      }

      UtilsService.sortArrayIgnoreCase(bfs, 'label', );
      UtilsService.sortArrayIgnoreCase(newBfs, 'label');
      UtilsService.sortArrayIgnoreCase(bfsAll, 'label');

      this.brandFiltersIdMap = brandFiltersIdMap;
      this.brandFilters = [ ...newBfs, ...bfs ];
      this.brandFiltersFilter = [ this._optionSelectAll ].concat(bfsAll);

      this.isRefreshingFilter = false;

      if( isRefresh && pListBox ) {
        try {
          // scroll to top - where the new filters are ?
          (<Listbox>pListBox).el.nativeElement.childNodes[0].scrollTop = 0;
        } catch( ex ) {}
      }
    });
  }

  loadBrandNames() {
    this.api.getAllBrands().subscribe(
      (data: Brand[]) => {
        this.brandNamesMap = new Map();
        for( const b of data ) {
          this.brandNamesMap.set( b.brandId, b.brandName );
        }
      }
    );
  }

  getBrandFilterName( id: string ): string {
    if( id && this.brandFiltersIdMap && this.brandFiltersIdMap.has(id) ) {
      return this.brandFiltersIdMap.get(id).name;
    }
  }

  getBrandNamesByFilterId( id: string ): string {
    if( !id || !this.brandFiltersIdMap.has(id) ) {
      return '';
    }

    const bf: BrandFilter = this.brandFiltersIdMap.get(id);
    let r = [];

    for( const brandId of bf.brandIds ) {
      r.push(this.brandNamesMap.get(brandId));
    }
    r.sort();
    let bn = "";
    let i = 0;
    let l = 0;
    for( const brandName of r ) {
      if( i > 0 ) {
        bn += ", ";
        l += 2;
      }
      bn += brandName;
      l+= ('' + brandName).length;
      i++;
      if( l >= 50 ) {
        i = 0;
        l = 0;
        bn += "\n";
      }
    }
    return bn;
  }

  loadRoles() {
    const allRoles = [];
    this.api.getUserRoles().subscribe((roles: UserRoleDTO[]) => {
      for (const r of roles) {
        allRoles.push({ label: r.label, value: r.key });
      }
      allRoles.sort((a, b) => a.label.localeCompare(b.label));

      this.userRolesSelect = [this._pleaseSelect].concat(allRoles);
      this.userRoles = [this._optionSelectAll].concat(allRoles);
    });
  }

  loadFeatures() {
    this.api.getFeatures().subscribe((data: FacetItem[]) => {
      const f = []
      for(const fa of data) {
        f.push(
          { label: fa.label, value: fa.id }
        );
      }
      this.features = f;
    });
  }

  prepareActivated() {
    let activedSelection = [
      this._optionSelectAll,
      { label: 'active', value: 'active' },
      { label: 'inactive', value: 'inactive' },
      { label: 'logged in', value: 'loggedIn' } ];

    if(this.api.isSuperAdmin()) {
      activedSelection.push(
        { label: 'deleted', value: 'deleted' }
      );
    }

    this.activedSelection = activedSelection;
  }

  private sortUsers( sortField, dir, defaultValue ) {
      this.userList.sort((a: User, b: User) => {

        let aval = a[sortField];
        let bval = b[sortField];

        if( !aval ) {
          aval = defaultValue;
        }
        if( !bval ) {
          bval = defaultValue;
        }

        if (aval < bval) {
          return -1 * dir;
        }
        if (aval > bval) {
          return 1 * dir;
        }
        return 0;
      });
  }

  sortByField( field, dontChangeDir? ) {
    if( !dontChangeDir ) {
      this.sortAsc = this.sortField != field ? true : !this.sortAsc;
    }
    this.sortField = field;
    this.defaultSortValue = field == 'lastLogin' ? '1970-01-01T00:00:00Z' : '';
    this.sortUsers(field, this.sortAsc ? 1 : -1, this.defaultSortValue);
  }

  sortBy( event ) {
    const v = event.value;
    this.sortUsers( v.field, v.dir, v.defVal );
  }

  set userRole(v) {
    this._userRole = v;
    this.doFilter();
  }

  set activatedFilter(v) {
    this._activatedFilter = v;
    this.doFilter();
  }

  set brandFilter(v) {
    this._brandFilter = v;
    this.doFilter();
  }

  doFilter() {
    const usrs: User[] = [];

    for (const u of this.allUsers) {
      const roleMatch = this._userRole == this._optionSelectAll.value || this._userRole == u.settings.userRole;
      const activMatch = this._activatedFilter == this._optionSelectAll.value ||
        (this._activatedFilter == 'active' && u.isActive) ||
        (this._activatedFilter == 'inactive' && !u.isActive) ||
        (this._activatedFilter == 'loggedIn' && u.loggedIn) ||
        (this._activatedFilter == 'deleted' && u.deleted);
      const brandFilterMatch = this._brandFilter == this._optionSelectAll.value || this._brandFilter == u.settings.brandFilterId;

      if (roleMatch && activMatch && brandFilterMatch) {
        usrs.push(u);
      }
    }

    this.userList = usrs;
  }

  get userRole(): any {
    return this._userRole || this._optionSelectAll.value;
  }

  get activatedFilter(): any {
    return this._activatedFilter || this._optionSelectAll.value;
  }

  get brandFilter(): any {
    return this._brandFilter || this._optionSelectAll.value;
  }

  selectUser(event: MouseEvent, u: User) {
    const el: Element = <Element>event.srcElement;
    if (el.getAttribute('class').indexOf('ui-chkbox-') != -1
      || event.offsetX < 50) {
      return;
    }

    this.selectedUser = Object.assign({}, u);
    this.selectedUser.settings = Object.assign({}, u.settings);
    /*if( this.selectedUser.settings.features
        && this.features && this.features.length ) {
      this.features.selectedByIds = this.selectedUser.settings.features;
    }*/
    this.setDialogSize();
    this.errorMessage = null;
    this.isRequesting = false;
    this.showEditUserDialog = true;
  }

  setDialogSize() {
    this.dialogStyle = {
      height: '' + (window.innerHeight - 100) + 'px',
      'min-height': '600px',
      width: '' + '1000px',
      'min-width': '1000px'
    };
  }

  passwordValidationResult: PasswordValidationResult;
  isRefreshingFilter = false;
  handlePasswordValidation( sr: SaveUserResponse ) {
    //TODO: implement validation view
    this.passwordValidationResult = sr.passwordValidationResult;
  }

  addUser(user) {
    //console.log(this.selectedUser);

    //this.selectedUser.password = this.generatePassword();

    this.api.saveUser(this.selectedUser).subscribe((u: SaveUserResponse) => {
      this.isRequesting = false;
      if (u.status === 401 && u.passwordValidationResult) {
        this.handlePasswordValidation(u);
        this.errorMessage = null;
        return;
      } else if (u.status !== 200) {
        this.errorMessage = u.statusText;
        return;
      }
      this.userList.push(u.user);
      this.selectedUser = this.newUser();
      this.newUserform.reset();
      this.showNewUserDialog = false;

    });
  }

  saveUserChanges(user, del?) {

    //this.selectedUser = Object.assign( this.selectedUser, user );
    //console.log(this.selectedUser);

    this.api.saveUser(this.selectedUser).subscribe((u: SaveUserResponse) => {
      this.isRequesting = false;
      if (u.status === 401 && u.passwordValidationResult) {
        this.handlePasswordValidation(u);
        this.errorMessage = null;
        return;
      } else if (u.status !== 200) {
        this.errorMessage = u.statusText;
        return;
      }

      for (let i = 0; i < this.allUsers.length; i++) {
        if (this.allUsers[i].id === u.user.id && !del) {
          this.allUsers[i] = u.user;
          break;
        }
      }
      //Use new user list to force UI (p-dataView) update
      let updatedUserList: User[] = [];
      for (let i = 0; i < this.userList.length; i++) {
        if(this.userList[i].deleted && !this.api.isSuperAdmin()) {
          continue;
        }

        if (this.userList[i].id === u.user.id) {
          if( !del ) {
            this.userList[i] = u.user;
          }
          this.userList[i].deleteConfirm = false;
        }
        updatedUserList.push(this.userList[i]);
      }
      this.userList = updatedUserList;

      this.selectedUser = this.newUser();
      this.editUserform.reset();
      this.showEditUserDialog = false;
    });
  }

  newUser() {
    return <User>{
      settings: <UserSettings>{}
    };
  }

  createNewUser() {
    this.newUserform.reset();
    this.selectedUser = this.newUser();
    this.selectedUser.isActive = true;
    this.setDialogSize();
    this.showNewUserDialog = true;
    this.errorMessage = null;
    this.isRequesting = false;
  }

  @ViewChild('dv', { static: true }) dv;
  filterUser(keyword: string) {
    if (keyword != null && keyword != '') {
      keyword = keyword.trim().toLowerCase();
    }
    this.dv.filter(keyword);
  }

  isRequesting: boolean = false;
  errorMessage: string;

  onSubmit(form: FormGroup) {

    this.startValidation(form);    
    if (form.valid) {
      this.isRequesting = true;
      if (form == this.editUserform) {
        this.saveUserChanges(form.value);
      } else
        if (form == this.newUserform) {
          this.addUser(form.value);
        }
    }
  }

  startValidation(form: FormGroup) {
    Object.keys(form.controls).forEach(field => {

      const control = form.get(field);
      if (!control.valid) {
        control.markAsDirty();
      }
    });
  }

  initFormControls() {
    this.editUserform = this.fb.group({
      'userRole': ['', Validators.required],
      'email': ['', [Validators.required, Validators.email]],
      //'password': ['', [Validators.minLength(12)]],
      //'passwordConfirm': ['', [Validators.minLength(12), this.passwordMatcher.bind(this)]],
      'brandFilterId': [false], //['', Validators.required],
      'isActive': [false],
      'datasource': [false],
      'features': [false]
    });

    this.newUserform = this.fb.group({
      'userRole': ['', Validators.required],
      'userName': ['', [Validators.required, Validators.pattern('[a-zA-Z0-9_\\-\\.@]{3,25}')]],
      'email': ['', [Validators.required, Validators.email]],
      //'password': ['', [Validators.required, Validators.minLength(12)]],
      //'passwordConfirm': ['', [Validators.required, Validators.minLength(12), this.passwordMatcher.bind(this)]],
      'brandFilterId': [false], //['', Validators.required],
      'isActive': [true],
      'features': [false],
      'datasource': [false]
    });
  }

  passwordMatcher(control: FormControl): { [s: string]: boolean } {
    const form = this.showNewUserDialog ? this.newUserform : (this.showEditUserDialog ? this.editUserform : null);
    if (form != null && control != null) {
      if (control.value !== form.controls.password.value) {
        return { passwordNotMatch: true };
      }
    }
    return null;
  }

  deleteSelectedUsers() {
    alert('Will be implemented later with password prompt ! So now let it delete by developer manually :-)');
  }

  generatePassword() {
    let lower = "abcdefghijklmnopqrstuvwxyz".split('');
    let upper = "ABCDEFGHIJKLMNOPWRSTUVWXYZ".split('');
    let digits = "0123456789".split('');
    let special = " !\"#$%&\'()*+,-./:;<=>?@[]^_`{|}~".split('');

    let charArrays = [upper, lower, digits, special];

    var newPassword = "";

    // at minimum one upper, lower, digit and special
    for( let chars of charArrays ) {
      newPassword += chars[Math.floor(Math.random() * chars.length)];
    }

    while( newPassword.length < 12 ) {
      for( let chars of charArrays ) {
        // random decide to use one of the chars
        let y = Math.random();
        if (y < 0.5) {
          continue;
        }
        newPassword += chars[Math.floor(Math.random() * chars.length)];
      }
    }
    return newPassword;
  }

  protected isAuthorizationRequiredPage(): boolean {
    return true;
  }

  protected getFeatureOnMenu(): Features {
    return Features.ADMIN;
  }

  getLastLoginTitle(user: User) {
    return !user.lastLogin ? 'Did never login' :
        'Last login: ' +
       this.getDateFormated(user.lastLogin) + ' at ' +
      user.lastLogin.substring(11, 16)
  }

  getLastLoginDate(user: User, never = 'never') {
      if( user.timeAgoCalculated ) {
        return user.timeAgoCalculated;
      }

      user.timeAgoCalculated = !user.lastLogin ? never :
            UtilsService.calcDaysOrYearsAgo( user.lastLogin ) + ' ago';

      return user.timeAgoCalculated;
  }

  getDateFormated( d ) {
      return d.substring(8, 10) + '.' +
             d.substring(5, 7) + '.' +
             d.substring(0, 4);
  }


  deleteUser(user: User) {

    const brandFilter = this.getBrandFilterName( user.settings.brandFilterId );

    let deleteMsg = 'delete';
    if(user.deleted) {
      deleteMsg = 'restore';
    }

    let message =  'Do you really want to ' + deleteMsg + ' marked user <b>' + user.userName +
                ( brandFilter ? '</b> with brand filter <b>' + brandFilter + '</b> ?' : '' );

    user.deleteConfirm = true;

    this.confirmDelete(() => {
      user.deleted = !user.deleted;

      this.selectedUser = this.newUser();
      this.selectedUser.id = user.id;
      this.selectedUser.deleted = user.deleted;

      this.saveUserChanges(user, true);
      this.checkCountDeleted();
    },
      () =>  {
        user.deleteConfirm = false;
      },
    'Warning',
      message );
  }

  getUserStatusIcon(user: User) {
    if( user.deleted ) {
      return 'fa fa-trash-o user-inactive';
    }

    if( user.loggedIn ) {
      return 'pi pi-check user-inactive';
    }

    if( user.isActive ) {
      return 'pi pi-check user-active';
    }

    return 'pi pi-ban user-inactive';
  }

  shouldHiddeDeleteButton(user: User) {
    return user.userName == this.api._auth.userDetail.login
              || user.settings.userRole == UserRole.SUPER_ADMIN;
  }

  getStatusTitle(user: User) {
    if( user.deleted ) {
      return 'deleted';
    }

    if( user.loggedIn ) {
      return 'is currently logged in';
    }

    if( user.isActive ) {
      return 'account activated';
    }

    return 'account deactivated';
  }

  deleteRequestSended = false;

  deleteMarkedUsers(event) {
    event.stopImmediatePropagation();

    if( this.deleteRequestSended ) {
      return;
    }
    this.deleteRequestSended = true;

    const message = 'Are you sure to delete the waste bin marked users ?';

    this.countDeleteMarkedUsers = 0; // to make button disabled after click
    this.confirmDelete(() => {

       this.api.deleteMarkedUsers().subscribe((response: DeleteUserResponse) => {
          alert( "Count deleted users: " + response.deletedUsersCount + ". After click OK, the user list will be reloaded" );
          this.loadUsers();
          this.deleteRequestSended = false;
      });
    },
    () =>  {
        this.checkCountDeleted();
        this.deleteRequestSended = false;
    },
    'Warning',
      message );
  }

  exportUsers() {
    var rows = [
      [
        'user_id',
        'user_name',
        'email',
        'user_role',
        'datasources',
        'features',
        'brand_filter',
        'activated',
        'deleted',
        'last_login_relative',
        'last_login_time'
      ]
    ];

    for ( var user of this.userList ) {
      rows.push(
        [
          this.getString( user.id ),
          this.getString( user.userName ),
          this.getEmail( user.email ),
          this.getString( user.settings.userRole ),
          this.getString( this.getDatasourceNamesByIds( user.settings.datasource ).join(', ') ),
          this.getString( Features.getNamesByIds( user.settings.features ).join(', ') ),
          this.getString( this.getBrandFilterName( user.settings.brandFilterId ) ),
          this.getString( user.deleted ? false : user.isActive ),
          this.getString( user.deleted ),
          this.getString( this.getLastLoginDate(user, '?') ),
          this.getString( user.lastLogin )
        ]
      );
    }
    UtilsService.exportToCsv('users.csv', rows);
  }

  getString( v: any ): string {
    return typeof v == 'undefined' ? '' : '' + v;
  }

  getEmail( v: any ) {
    const r = this.getString(v);
    if ( r.endsWith('@webadmin.tecalliance.net') ) {
      return '';
    }
    return r;
  }
}
