import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoTexts } from '@app/shared/models/info-texts';
import {PageRequest} from '@app/shared/models/page-request';
import {Features} from '@app/shared/models/features';
import { ChartComponent } from '@app/shared/components/chart/chart.component';
import {ChartRequest} from '@app/shared/models/chart-request';

@Component({
  selector: 'most-selected-countries',
  templateUrl: './most-selected-countries.component.html',
  styleUrls: ['./most-selected-countries.component.scss']
})
export class MostSelectedCountriesComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.MOST_SEARCHED_COUNTRIES;
  }

  getPageStoreKey(): string {
     return "MostSelectedCountriesComponent";
  }

  getChartRequest(): ChartRequest {
    const r = new ChartRequest( EventTypes.ARTICLE_SELECTION );
    r.facetCount = -1;
    return r;
  }

  get pageName(): string {
    return 'Most searched countries';
  }

  public readonly fieldCountries = Fields.LOCATION_COUNTRY;
  public readonly fieldCountriesBrandless = Fields.LOCATION_COUNTRY_BRANDLESS;

  public worldMapChartData: any;

  public yourBrandCountriesChartHeadLabel = 'Your Brand(s)';
  @ViewChild('yourBrandCountriesChart', {static: true}) yourBrandCountriesChart: ChartActor;

  public marketViewCountriesChartHeadLabel = 'Market View';
  @ViewChild('marketViewCountriesChart', {static: true}) marketViewCountriesChart: ChartComponent;

  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router ) {
      super( appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [Fields.LOCATION_COUNTRY, Fields.LOCATION_COUNTRY_BRANDLESS];
  }

  getChartActors(): ChartActor[] {
    return [ this.yourBrandCountriesChart, this.marketViewCountriesChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  dateRangeLabel: string;
  reportLoaded(data: ReportResponse): void {
    if(data.responseData && data.responseData.facets && data.responseData.facets.location_country) {
      this.worldMapChartData = data.responseData.facets.location_country.data;
    }
    this.dateRangeLabel = data.dateRange;
  }

  itemSelected(item: FacetItem, fieldName?:string): void {

    const p = this.chartParams.clone().params;
    p.set(Fields.DATASOURCE, this.mergeDataSourceIds( item.datasource ) );

    const r = new PageRequest();
    r.countryId = item.id;
    r.countryName = item.name;
    r.fieldName = fieldName;
    r.timespan = this.chartParams.timespan;
    r.setParams( p );

    if( r.timespan ) {
      delete r.fromDate;
      delete r.toDate;
    } else {
      r.fromDate = ChartRequest.dateAsString( this.chartParams.fromDate );
      r.toDate = ChartRequest.dateAsString( this.chartParams.toDate );
      delete r.timespan;
    }

    this.router.navigate( [ 'details/level1', r ], { relativeTo: this.route } );
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }

}
