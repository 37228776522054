import { Component, OnInit } from '@angular/core';
import { AnalyticsPage } from '../analytics-page';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import { EventTypes } from '@app/shared/models/event-types';
import { ChartRequest } from '@app/shared/models/chart-request';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartActor } from '@app/shared/components/chart-actor';
import { FacetItem } from '@app/shared/models/facet-item';
import * as moment from 'moment/moment';
import { ReportParams } from '@app/shared/models/i-analytics-page';
import { FoundBy } from '@app/shared/models/found-by';
import { FoundByChartParams } from '@app/shared/models/found-by-chart-params';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent extends AnalyticsPage {

  public infoText: string;

  public foundBy: FoundBy;

  public vehicle: any = {
    make: '',
    defaultModelName: '',
    defaultTypeName: '',
    motorCodes: ''
  };

  getPageFacets(): string[] {
    return [ Fields.FOUND_BY ];
  }

  getChartActors(): ChartActor[] {
    return [];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.ARTICLE_SELECTION );
  }

  public charts: FoundByChartParams[] = [];

  report: ReportResponse;
  reportLoaded(data: ReportResponse): void {
    if(this.report) {
      this.report.mergeFacets(data);
    } else {
      this.report = data;
    }
    this.charts = [];
    this.foundBy.init(this.charts, data, this.pageRequest.fieldName);

    window.setTimeout(() => {
      this.dataSubscriber.emit(data);
    }, 300);
  }

  itemSelected(item: FacetItem, fieldName?: string): void {
    this.charts = this.foundBy.itemSelected(item, fieldName);

    setTimeout(() => {
      this.dataSubscriber.emit(this.report);
    },500);
  }

  getPageStoreKey(): string {
    return 'VehicleDetailsComponent';
  }
  pageName: string = 'Vehicle Details';

  constructor(appInsights: TrackerService,
    confirm: ConfirmationService,
    public translate: TranslateService,
    public navigation: NavigationService,
    protected router: Router,
    public apiService: ApiService) {
      super(appInsights, confirm, translate, navigation, apiService, router);
      this.foundBy = new FoundBy('v');
      this.needFilterData = false;
  }

  public isVehicleName: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    if( this.pageRequest.vehicleId ) {

      const isBrandLess = this.pageRequest.fieldName.indexOf(Fields.BRANDLESS) != -1;

      const r = this.getChartParams();

      r.params = this.pageRequest.params_map;

      r.facets = [ Fields.FOUND_BY ];
      if( isBrandLess ) {
        r.facets = [ Fields.FOUND_BY_BRANDLESS ];
      }

      r.facetCount = -1;


      r.setIds( Fields.VEHICLES, [this.pageRequest.vehicleId] );

      console.log( 'vehicle-details chart request:', r );

      if( this.pageRequest.timespan ) {
        r.timespan = this.pageRequest.timespan;
      } else {
        if( this.pageRequest.fromDate ) {
          r.fromDate = moment(this.pageRequest.fromDate).toDate();
        }
        if( this.pageRequest.toDate ) {
          r.toDate = moment(this.pageRequest.toDate).toDate();
        }
      }

      const rp = <ReportParams>{
        skipCache: true,
        notNeedFilterData: true
      };

      this.loadReport( rp );

      if( isNaN(this.pageRequest.vehicleId) ) {
          this.isVehicleName = true;
          //if( isNaN(this.pageRequest.vehicleId) ) {
            this.pageRequest.vehicleId = decodeURIComponent( this.pageRequest.vehicleId );
          //}
      } else {
        this.loadVehicleDetails( this.pageRequest.vehicleId );
      }
    }
  }

  private loadVehicleDetails( vehicleId: string ): void {
    this.apiService
          .getVehicleDetails( vehicleId )
              .subscribe((data: any) => {
                 if( data && data[vehicleId] ) {
                    this.vehicle = data[vehicleId];

                    console.log( this.vehicle );
                 }
              }
    );
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }

}
