import { Component } from '@angular/core';
import { TrackerService, NavigationService, OAuthResponse } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { Router, ActivatedRoute } from '@angular/router';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { Fields } from '@app/shared/models/fields';
import { AnalyticsPage } from '../analytics-page';
import { ChartActor } from '@app/shared/components/chart-actor';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { FacetItem } from '@app/shared/models/facet-item';
import { ReportParams } from '@app/shared/models/i-analytics-page';
import * as moment from 'moment/moment';
import { ChartData } from '@app/shared/models/analytics/chart-data';
import { FoundByChartParams } from '@app/shared/models/found-by-chart-params';
import { FoundBy } from '@app/shared/models/found-by';
import { RecommendationPage } from '@app/shared/models/recommendionsPage';
import { Recommender } from '@app/shared/models/recommender';
import { Ids } from '@app/shared/models/ids';
import { Features } from '@app/shared/models/features';

@Component({
  selector: 'articlenumbers-details',
  templateUrl: './articlenumbers-details.component.html',
  styleUrls: ['./articlenumbers-details.component.scss']
})
export class ArticlenumbersDetailsComponent extends AnalyticsPage implements RecommendationPage {

  public readonly BRAND_ASSOC = Fields.BRAND_ASSOCIATIONS;
  public readonly GENART_ASSOC = Fields.GENART_ASSOCIATIONS;

  recommendationsLoaded = false;

  public articleName: any = '';
  public brandName: any = '';

  //public articleDetails: any;
  public imageUrl: any = '';
  public hasImage: boolean = false;
  public charts: FoundByChartParams[] = [];
  public report: ReportResponse;

  public foundBy: FoundBy;
  public recommender: Recommender;

  hasBrandsRecommends = false;
  hasGenartRecommends = false;
  noRecommendation: boolean = true;
  dialogWidth = 600;
  dialogHeight = 770;

  recommendsOthers: Map<String, FacetItem[]> = new Map();
  recommendsOriginal: Map<String, FacetItem[]> = new Map();

  public chartDialogHeader: string = 'Drilldown data';
  public chartDialogVisible: boolean = false;
  public chartDialogField: string = 'recommenderDrilldown';
  public chartDialogChartLabel: string = 'Chart label';
  public chartDialogInfoText: string = '';


  //public associationsTable: FacetItem[];

  public get infoText() {
    if( this.article ) {
      return this.article.mfrName + ' ' + this.article.articleNumber + ' ' + this.article.genericArticles[0].genericArticleDescription
        + ( this.article.misc.additionalDescription ? ' ' + this.article.misc.additionalDescription : '' );
    }
    return 'Article number: ' + this.pageRequest.articleNumber;
  }

  public refresh(): void {

  }

  public get pageName(): string {
    return 'Most popular article number details';
  }

  public hasChanges(): boolean {
    return false;
  }

  getPageFacets(): string[] {
    return [Fields.FOUND_BY];
  }
  getChartActors(): ChartActor[] {
    return [];
  }
  getReportPath(): string {
    return '/report/facets';
  }
  getExportPath(): string {
    return '/export/facets';
  }
  getChartRequest(): ChartRequest {
    return new ChartRequest(EventTypes.ARTICLE_SELECTION);
  }

  reportLoaded(data: ReportResponse): void {
    if(this.report) {
      this.report.mergeFacets(data);
    } else {
      this.report = data;
    }
    this.charts = [];
    this.foundBy.init(this.charts, data);

    window.setTimeout(() => {
      this.dataSubscriber.emit(data);
    }, 300);

    this.loadRecomendations();
  }

  exportRecommendations(event) {
    this.recommender.loadBrandGenartRecommendsTable(this, (fis: FacetItem[]) => {
      ChartData.exportAsCsv( fis,
                             'recommendations'
                              + '_' + this.pageRequest.brandNo
                              + "_" + this.pageRequest.articleNumber + ".csv",
                           ["id", "name", "genArtId", "genArtName", "percent"] );
    });
  }

  itemSelected(item: FacetItem, fieldName?: string): void {

    if( fieldName == Fields.BRAND_ASSOCIATIONS ) {

      if( item.id === Ids.OTHERS ) {
        return;
      }

      this.recommender.loadGenartRecommendations(this, Number.parseInt( item.id ), this.chartDialogField, () => {
        this.chartDialogHeader = item.label;
        this.chartDialogInfoText = 'Users who selected this article, selected also articles from these GAs with Brand: ' + item.name;
        this.chartDialogChartLabel = 'Brands';
        this.chartDialogVisible = true;
        this.noRecommendation = false;
        this.dialogWidth = 600;
        this.dialogHeight = 770;
      }, this.NO_DATA_CALLBACK );

      return;
    }

    if( fieldName === Fields.GENART_ASSOCIATIONS ) {

      if( item.id === Ids.OTHERS ) {
        return;
      }

      this.recommender.loadBrandRecommendations(this, Number.parseInt( item.id ), this.chartDialogField, () => {
        this.chartDialogHeader = item.label;
        this.chartDialogInfoText = ' Users who selected this article have also selected articles from these brands from GA ' + item.name;
        this.chartDialogChartLabel = 'Generic articles';
        this.chartDialogVisible = true;
        this.noRecommendation = false;
        this.dialogWidth = 600;
        this.dialogHeight = 770;
      }, this.NO_DATA_CALLBACK);

      return;
    }

    if( fieldName == this.BRAND_ASSOC
        || fieldName == this.GENART_ASSOC ) {

        /*if( item.id == Ids.OTHERS ) {
          this.dialog.showId = true;
          this.dialog.showData( this.recommendsOthers.get(fieldName) );
        }*/
        return;
    }

    this.charts = this.foundBy.itemSelected(item, fieldName);

    setTimeout(() => {
      this.dataSubscriber.emit(this.report);
    }, 500);
  }
  getPageStoreKey(): string {
    return 'ArticlenumbersDetailsComponent';
  }

  constructor(appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    public apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
    this.foundBy = new FoundBy('a');
    this.recommender = new Recommender();
    this.needFilterData = false;
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.pageRequest.articleNumber) {

      const r = this.getChartParams();

      r.facets = [Fields.FOUND_BY];

      r.facetCount = -1;

      r.params = this.pageRequest.params_map;
      r.setIds(Fields.ARTICLE_NUMBER, [this.pageRequest.brandNo + " / " + this.pageRequest.articleNumber]);

      if (this.pageRequest.timespan) {
        r.timespan = this.pageRequest.timespan;
      } else {
        if (this.pageRequest.fromDate) {
          r.fromDate = moment(this.pageRequest.fromDate).toDate();
        }
        if (this.pageRequest.toDate) {
          r.toDate = moment(this.pageRequest.toDate).toDate();
        }
      }

      console.log('article-details chart request:', r);

      const rp = <ReportParams>{
        skipCache: true,
        notNeedFilterData: true
      };

      this.loadReport(rp);

      this.apiService.getProps().subscribe((props: any) => {
        if ( props.features && props.features.indexOf(Features.ANONYMOUS_BRANDS.id) != -1 ) {
          this.article.genericArticles[0].genericArticleDescription = 'Brake disc';
          this.apiService.getBrands().subscribe(() => {
            if (this.apiService.brandsMap.has('' + this.pageRequest.brandNo)) {
              this.article.mfrName = this.apiService.brandsMap.get('' + this.pageRequest.brandNo).brandName;
            }
          });
          this.article.images[0].imageURL200 = 'assets/images/parts/disc.jpg';
          return;
        }

        this.loadArticleDetails();

      });
    }
  }

  protected loadReportCustomized(params?: ReportParams): boolean {
    if( params
        && ( params.facetField == this.BRAND_ASSOC
         || params.facetField == this.GENART_ASSOC ) ) {
          if( params.export ) {
            ChartData.exportAsCsv( this.recommendsOriginal.get(params.facetField),
                             params.facetField
                              + '_' + this.pageRequest.brandNo
                              + "_" + this.pageRequest.articleNumber + ".csv",
                           ["id", "name", "percent"] );
          }
          return true;
    }
    return false;
  }

  private readonly NO_DATA_CALLBACK = () => {
    this.chartDialogHeader = 'No recommendations data';
    this.chartDialogVisible = true;
    this.noRecommendation = true;
    this.dialogWidth = 600;
    this.dialogHeight = 100;
  };

  loadRecomendations() {
    if(!this.articleDetailsLoaded ||
          !this.report) {
      return;
    }
    this.recommender.loadRecomendations(this);
  }

  articleDetailsLoaded = false;
  article = {
    articleNumber: '',
    mfrName: '',
    misc: {
      additionalDescription: '',
    },
    genericArticles: [{
      genericArticleDescription: '',
    }],
    images: [
      {
        imageURL200: ''
      }
    ]
  };

  private loadArticleDetails() {
    const request: ChartRequest = new ChartRequest(EventTypes.ARTICLE_SELECTION);
    if (this.pageRequest.brandNo && this.pageRequest.brandNo !== 0) {
      request.setIds(Fields.BRAND_NO, ['' + this.pageRequest.brandNo]);
    }

    this.apiService.getArticleDetails(
      request,
      this.pageRequest.articleNumber)
      .subscribe((data: any) => {

        if (data.articles && Array.isArray(data.articles) && data.articles.length > 0) {
          this.hasImage = data.images && data.images.length > 0;
          const a = data.articles[0];
          if (a.images && a.images.length) {
            a.images = [a.images[0]];
          } else {
            a.images = [];
          }
          this.article = a;
        }

        this.articleDetailsLoaded = true;
        this.loadRecomendations();

      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  protected getFeatureOnMenu(): Features {
    return Features.MOST_SELECTED_ARTICLES;
  }

}
