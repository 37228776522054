import {
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    ContentChildren,
    QueryList
} from '@angular/core';

import { BeforeChangeWizardStepEvent } from '../..';

import { WizardStepDirective } from './wizard-step/wizard-step';

@Component({
    selector: 'wizard',
    templateUrl: 'wizard.html',
    styleUrls: ['wizard.scss']
})
export class WizardComponent implements OnInit, OnDestroy, AfterViewInit {
    @ContentChildren(WizardStepDirective)
    _steps: QueryList<WizardStepDirective>;

    @Input()
    public title: string;

    @Input()
    public navigationUnlocked: boolean;

    @Output()
    public get goNext(): EventEmitter<BeforeChangeWizardStepEvent> {
        return this._goNext;
    }

    @Output()
    public get goBack(): EventEmitter<BeforeChangeWizardStepEvent> {
        return this._goBack;
    }

    @Output()
    public get finish(): EventEmitter<any> {
        return this._finish;
    }

    @Output()
    public get close(): EventEmitter<any> {
        return this._close;
    }

    _stepsData: Array<{ title: string, description: string }> = [];
    _active: number = 0;
    _goNext: EventEmitter<BeforeChangeWizardStepEvent> = new EventEmitter();
    _goBack: EventEmitter<BeforeChangeWizardStepEvent> = new EventEmitter();
    _finish: EventEmitter<any> = new EventEmitter();
    _close: EventEmitter<any> = new EventEmitter();

    constructor(
        _element: ElementRef
    ) { }

    ngOnInit() {

    }

    ngOnDestroy() {

    }

    ngAfterViewInit() {
        if (!this._steps) {
            return;
        }

        setTimeout(() => this.initialize());
    }

    initialize() {
        let data: Array<{ title: string, description: string }> = [];

        this._steps.forEach((step, index) => {
            if (index === 0) {
                step.show();
            }

            data.push({
                title: step.title,
                description: step.description
            });
        });

        this._stepsData = data;
    }

    onGoTo(index: number) {
        let params: BeforeChangeWizardStepEvent = new BeforeChangeWizardStepEvent(this._active, index);
        params.confirm = () => {
            this.hide();
            this._active = index;
            this.show();
        };
        params.discard = () => { };

        params.confirm();
    }

    onGoNext() {
        let params: BeforeChangeWizardStepEvent = new BeforeChangeWizardStepEvent(this._active, this._active + 1);
        params.confirm = () => {
            this.hide();
            this._active++;
            this.show();
        };
        params.discard = () => { };
        this._goNext.emit(params);
    }

    onGoBack() {
        let params: BeforeChangeWizardStepEvent = new BeforeChangeWizardStepEvent(this._active, this._active - 1);
        params.confirm = () => {
            this.hide();
            this._active--;
            this.show();
        };
        params.discard = () => { };
        this._goBack.emit(params);
    }

    onFinish() {
        this._finish.emit();
    }

    onClose() {
        this._close.emit();
    }

    hide() {
        let step = this._steps.find((step, index) => { return index === this._active; });

        if (step) {
            step.hide();
        }
    }

    show() {
        let step = this._steps.find((step, index) => { return index === this._active; });

        if (step) {
            step.show();
        }
    }
}