import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoTexts } from '@app/shared/models/info-texts';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'most-searched-number-plates',
  templateUrl: './most-searched-number-plates.component.html',
  styleUrls: ['./most-searched-number-plates.component.scss']
})
export class MostSearchedNumberPlatesComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.NUMBER_PLATE_SEARCH_INFO;
  }

  getPageStoreKey(): string {
     return "MostSearchedNumberPlatesComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.NUMBERPLATE_SEARCH );
  }

  get pageName(): string {
    return 'Most searched number plates';
  }

  public readonly fieldArticleNumber = Fields.SEARCH_STRING;

  public articleNumberChartHeadLabel = 'Number plates';
  @ViewChild('numbersChart', {static: true}) numbersChart: ChartActor;

  public fieldSearchTypeId = Fields.SEARCH_TYPE_ID_FILTER;

  constructor( appInsights: TrackerService,
               confirm: ConfirmationService,
               protected translate: TranslateService,
               protected navigation: NavigationService,
               protected apiService: ApiService,
               protected route: ActivatedRoute,
               protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.SEARCH_STRING ];
  }

  getChartActors(): ChartActor[] {
    return [ this.numbersChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem): void {


  }

  protected getFeatureOnMenu() {
    return Features.VEHICLE_SEARCHES;
  }

}

