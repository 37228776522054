import { Input, Output, OnInit, OnDestroy, OnChanges, ChangeDetectorRef, SimpleChanges, EventEmitter, Directive } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { GrowlService, EditResultEvent, ValidationErrorCollection } from '..';

import {
  VALIDATION_REQUIRED,
  VALIDATION_MIN_LENGTH,
  VALIDATION_MAX_LENGTH,
  VALIDATION_EMAIL,
  VALIDATION_EQUALS,
  VALIDATION_EMAIL_IN_USE, VALIDATION_NUMBERS, VALIDATION_UPPER_CASE, VALIDATION_LOWER_CASE, VALIDATION_SPECIAL_CHARS
} from '../consts/validations';

/**
 * Base editable component.
 */
@Directive()
export abstract class EditableComponent<TInput, TModel> implements OnInit, OnDestroy, OnChanges {
  //#region Validation messages

  protected readonly VALIDATION_REQUIRED     = VALIDATION_REQUIRED;
  protected readonly VALIDATION_MIN_LENGTH   = VALIDATION_MIN_LENGTH;
  protected readonly VALIDATION_MAX_LENGTH   = VALIDATION_MAX_LENGTH;
  protected readonly VALIDATION_EMAIL        = VALIDATION_EMAIL;
  protected readonly VALIDATION_EQUALS       = VALIDATION_EQUALS;
  protected readonly VALIDATION_EMAIL_IN_USE = VALIDATION_EMAIL_IN_USE;
  protected readonly VALIDATION_NUMBERS      = VALIDATION_NUMBERS;
  protected readonly VALIDATION_UPPER_CASE   = VALIDATION_UPPER_CASE;
  protected readonly VALIDATION_LOWER_CASE   = VALIDATION_LOWER_CASE;
  protected readonly VALIDATION_SPECIAL_CHARS = VALIDATION_SPECIAL_CHARS;

  //#endregion

  //#region Fields

  private _isModelReady: boolean = false;
  private _master: TModel = <TModel>{};
  private _model: TModel = <TModel>{};

  private _loadTimeoutId: any;

  //#endregion

  //#region Event Emitters

  private _loadStart: EventEmitter<any> = new EventEmitter();
  private _loadEnd: EventEmitter<EditResultEvent> = new EventEmitter();

  private _saveStart: EventEmitter<any> = new EventEmitter();
  private _saveEnd: EventEmitter<EditResultEvent> = new EventEmitter();

  private _deleteStart: EventEmitter<any> = new EventEmitter();
  private _deleteEnd: EventEmitter<EditResultEvent> = new EventEmitter();

  //#endregion

  //#region Gettters / Settters

  public get isModelReady(): boolean {
    return this._isModelReady;
  }

  public get model(): TModel {
    return this._model;
  }

  //#endregion

  //#region Inputs

  @Input()
  public value: TInput;

  //#endregion

  //#region Outputs

  @Output()
  public get loadStart(): EventEmitter<any> {
    return this._loadStart;
  }

  @Output()
  public get loadEnd(): EventEmitter<EditResultEvent> {
    return this._loadEnd;
  }

  @Output()
  public get saveStart(): EventEmitter<any> {
    return this._saveStart;
  }

  @Output()
  public get saveEnd(): EventEmitter<EditResultEvent> {
    return this._saveEnd;
  }

  @Output()
  public get deleteStart(): EventEmitter<any> {
    return this._deleteStart;
  }

  @Output()
  public get deleteEnd(): EventEmitter<EditResultEvent> {
    return this._deleteEnd;
  }

  //#endregion

  //#region Constructor

  constructor(
    protected _ref: ChangeDetectorRef,
    protected _growl: GrowlService
  ) {
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {

  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      if (changes['value'].currentValue === null || typeof changes['value'].currentValue === 'undefined') {
        this._master = null;
        this.reset();
        return;
      }

      this.load();
    }
  }

  //#endregion

  //#region Public Methods

  /**
   * Load model.
   */
  public load(): void {
    let next = (model: TModel) => {
      this._master = model;
      this.reset();
      this._loadEnd.emit(null);
    };

    if (this.value === null || typeof this.value === 'undefined') {
      // Nothing
    } else if (this.isInputValueTheEditModel()) {
      this._loadStart.emit();

      of<TModel>(JSON.parse(JSON.stringify(this.value))).subscribe(next);
    } else {
      // It's necessary adding a small delay in order
      // to avoid overload with a lot of simultaneous requests,
      // for example, when you are navigation quickly through a grid.
      if (this._loadTimeoutId) {
        clearTimeout(this._loadTimeoutId);
        this._loadTimeoutId = null;
      } else {
        this._loadStart.emit();
      }

      this._loadTimeoutId = setTimeout(() => {
        this.loadModel(this.value).subscribe(
          next,
          (err: HttpErrorResponse) => {
            let result = new EditResultEvent();
            result.status = err.status;
            result.statusText = err.statusText;
            result.action = 'load';
            result.message = err.message;

            let title = `${result.status}: ${result.statusText}`;
            let message = `<strong>${result.message}</strong>`;

            this._growl.modelError(title, message);
            this._loadEnd.emit(result);
          });
        this._loadTimeoutId = null;
      }, 500);
    }
  }

  /**
   * Save model.
   */
  public save(): void {
    if (!this.isDirty() || !this.isValid()) {
      return;
    }

    this._saveStart.emit();

    this.saveModel().subscribe(
      (result: TModel) => {
        this._master = result;
        this.resetModel();

        this._growl.modelSaved();
        this._saveEnd.emit(null);
      },
      (err: HttpErrorResponse) => {
        let brokenRules = <ValidationErrorCollection>err.error;
        let result = new EditResultEvent;
        result.status = err.status;
        result.statusText = err.statusText;
        result.action = 'save';
        result.message = brokenRules ? brokenRules.message : err.message;
        result.errors = brokenRules ? brokenRules.errors : null;

        let title = `${result.status}: ${result.statusText}`;
        let message = `<strong>${result.message}</strong>`;

        if (result.errors && result.errors.length) {
          message += '<br>';
          for (let i = 0; i < result.errors.length; i++) {
            let path = result.errors[i].field.split('.');
            if (path.length > 1) {
                path.shift();
            }

            let field = path.join('.');
            let error = result.errors[i].message;
            message += `<br><i>${field}</i>: ${error}`;
          }
        }

        this._growl.modelError(title, message);
        this._saveEnd.emit(result);
      }
    );
  }

  /**
   * Delete model.
   */
  public delete(): void {
    this._deleteStart.emit();

    this.deleteModel().subscribe(
      (result: any) => {
        this._growl.modelDeleted();
        this._deleteEnd.emit(null);
      },
      (err: HttpErrorResponse) => {
        let brokenRules = <ValidationErrorCollection>err.error;
        let result = new EditResultEvent;
        result.status = err.status;
        result.statusText = err.statusText;
        result.action = 'delete';
        result.message = brokenRules ? brokenRules.message : err.message;
        result.errors = brokenRules ? brokenRules.errors : null;

        let title = `${result.status}: ${result.statusText}`;
        let message = `<strong>${result.message}</strong>`;

        this._growl.modelError(title, message);
        this._deleteEnd.emit(result);
      }
    );
  }

  /**
   * Reset model to master (original model).
   */
  public reset(): void {
    this.resetModel();
    this._ref.detectChanges();
  }

  //#endregion

  //#region Abstract Methods

  /**
   * Checks if model is dirty.
   */
  public abstract isDirty(): boolean;

  /**
   * Checks if model is valid.
   */
  public abstract isValid(): boolean;

  /**
   * Returns a value that indicates if input value is the
   * edit model. In another case, we need to implement
   * loadModel function.
   */
  protected abstract isInputValueTheEditModel(): boolean;

  /**
   * Implements the load of the model. This method is executed when input value changes
   * and input value isn't editable value.
   */
  protected abstract loadModel(params: TInput): Observable<TModel>;

  /**
   * Implements the saved of the model.
   */
  protected abstract saveModel(): Observable<TModel>;

  /**
   * Implements the deleted of the model.
   */
  protected abstract deleteModel(): Observable<any>;

  /**
   * Executed when the model is ready. The components that extend BaseEditableComponent
   * can use this method to do some action when the model is ready.
   */
  protected abstract modelReady(): void;

  //#endregion

  //#region Helpers

  /**
   * Implements the saved of the model.
   */
  protected resetModel(): void {
    // core-js polyfills - ECMAScript 6: Object
    // this._model = this._master ? Object.assign(<TModel>{}, this._master) : null;

    // Depp copy
    this._model = this._master ? JSON.parse(JSON.stringify(this._master)) : null;

    this._isModelReady = true;
    this.modelReady();
  }

  //#endregion
}
