import {Component, ViewChild} from '@angular/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {FacetItem} from '@app/shared/models/facet-item';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ChartRequest} from '@app/shared/models/chart-request';
import {NavigationService, TrackerService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {ActivatedRoute, Router} from '@angular/router';
import {Fields} from '@app/shared/models/fields';
import * as moment from 'moment';
import {ReportParams} from '@app/shared/models/i-analytics-page';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'rmi-overview-details',
  templateUrl: './rmi-overview-details.component.html',
  styleUrls: ['./rmi-overview-details.component.scss']
})
export class RmiOverviewDetailsComponent extends AnalyticsPage {

  chartLabel = 'RMI function call vehicles chart';

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              public apiService: ApiService,
              protected route: ActivatedRoute,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
    this.needFilterData = false;
  }

  ngOnInit() {
    const r = this.getChartParams();
    r.params = this.pageRequest.params_map;

    if (Fields.EVENT_TYPE_BRANDLESS.startsWith(this.pageRequest.fieldName)
      || Fields.OPERATION_NAME_BRANDLESS.startsWith(this.pageRequest.fieldName)
      || Fields.DATASOURCE_ID_BRANDLESS.startsWith(this.pageRequest.fieldName)) {
      r.setIds(this.pageRequest.fieldName, [this.pageRequest.fieldValue]);
    }

    if( Fields.EVENT_TYPE_BRANDLESS.startsWith( this.pageRequest.fieldName ) ) {
      this.chartLabel = 'RMI function call: ' + this.pageRequest.fieldValue;
    } else
    if( Fields.OPERATION_NAME_BRANDLESS.startsWith( this.pageRequest.fieldName ) ) {
      this.chartLabel = 'RMI Module: ' + this.pageRequest.fieldValue;
    } else
    if( Fields.DATASOURCE_ID_BRANDLESS.startsWith( this.pageRequest.fieldName )  ) {
      this.chartLabel = 'RMI customer / company:  ' +  this.pageRequest.fieldValue;
    }

    const rp = <ReportParams>{
      skipCache: true,
      notNeedFilterData: true
    };

    if (this.pageRequest.timespan) {
      r.timespan = this.pageRequest.timespan;
    } else {
      if (this.pageRequest.fromDate) {
        r.fromDate = moment(this.pageRequest.fromDate).toDate();
      }
      if (this.pageRequest.toDate) {
        r.toDate = moment(this.pageRequest.toDate).toDate();
      }
    }

    this.loadReport(rp);
  }

  readonly fieldOperationName = Fields.VEHICLES_BRANDLESS;

  @ViewChild('chartRmiOverviewDetails', {static: true}) chartRmiOverviewDetails: ChartActor;

  getChartActors(): ChartActor[] {
    return [this.chartRmiOverviewDetails];
  }

  getChartRequest(): ChartRequest {
     return new ChartRequest( this.pageRequest.fieldName === Fields.EVENT_TYPE_BRANDLESS ? this.pageRequest.fieldValue : '*' );
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  getPageFacets(): string[] {
    return [Fields.VEHICLES_BRANDLESS];
  }

  getPageStoreKey(): string {
    return '@RMI_OverviewDetailsComponent';
  }

  get infoText(): string {
    return "";
  }

  itemSelected(item: FacetItem): void {

  }

  get pageName(): string {
    return 'RMI overview details';
  }

  reportLoaded(data: ReportResponse): void {

  }

  protected getFeatureOnMenu() {
    return Features.RMI_DATA;
  }

}
