<p-toast life="5000"></p-toast>
<div task-reporter></div>

<div id="print_div" class="container-fluid align-content-center">

</div>

<change-password #changePasswordComponent></change-password>
<feedback-tool #feedbackPanel></feedback-tool>
<popup-get-full-version #popupGetFullVersion></popup-get-full-version>

<ngx-guided-tour #gc [closeText]="'Close'" [nextText]="'Next'" [backText]="" [skipText]="'Skip Tour'"
  [progressIndicatorLocation]="'none'" [tourStepWidth]="tourStepWidth">
  <div #step0 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div class="img-welcome mr-3"></div>
      <div>
        <p class="tour-font-title">Welcome to the Demand Dashboard Product Tour!</p>
        <p class="tour-font-normal">With this product tour, you will learn the basic functionalities of the Demand
          Dashboard. Creating analyses and reports will become even more accessible.</p>
        <p class="tour-font-normal">Click "Next" to start the tour.</p>
      </div>
    </div>
  </div>
  <div #step1 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div>
        <p class="tour-font-title">Main Navigation</p>
        <p class="tour-font-normal"><b>Market overview</b><br/>
          Get insights in a direct overview of your products compared to the competition.
          The total article views of your products in the respective data source, e.g., the TecDoc Catalogue 3.0, is measured.
           Under Trends, you can view the market development for each generic article.
        </p>
        <p class="tour-font-normal"><b>Article views</b><br/>
          Compare the percentage of article views calculated with the following parameters: Brands, Generic articles, Article numbers, OE numbers, Vehicles, and Countries.
        </p>
        <p class="tour-font-normal"><b>Most searched</b><br/>
          Find and compare the percentage of the most searched numbers, e.g., Article numbers, OE numbers, or Competitor article numbers in the overall usage.
        </p>
      </div>
    </div>
  </div>
  <div #step2 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div>
        <p class="tour-font-normal font-weight-bold">Filter bar</p>
        <p class="tour-font-normal">
          <i class="fa ta-icon-clock"></i>
          <b>Filter by Time</b><br/>
          Define the period for your analysis with predefined values, or define your analysis period with a calendar picker.
        </p>
        <p class="tour-font-normal">
          <i class="fa ta-icon-tags"></i>
          <b>Filter by Brand</b><br/>
          Choose your brand(s) for the comparison. Include or exclude brands to refine your analysis.
        </p>
        <p class="tour-font-normal">
          <i class="fa ta-icon-location"></i>
          <b>Filter by Location</b><br/>
          Localize specific markets and look at the figures of the predefined regions or single countries.
        </p>
        <p class="tour-font-normal">
          <i class="fa ta-icon-master-data"></i>
          <b>Filter by Data Source</b><br/>
          Select the data source from which you want to draw the information for your analysis.
        </p>
        <p class="tour-font-normal">
          <i class="fa ta-icon-cw"></i>
          <b>Refresh Data</b><br/>
          Please don't forget to refresh after you change your selection. The refresh button will be disabled (light green), when the data is updated.
        </p>
      </div>
    </div>
  </div>
  <div #step3 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div>
        <p class="tour-font-normal font-weight-bold">Generic Article Filter</p>
        <p class="tour-font-normal">
          With the Generic Article Filter you can focus your research on the relevant product groups.
        </p>
        <p>
          You can search for the desired generic article via name or GenArt number in the input field. An intelligent proposal
          list helps you to quickly create your selection group.
        </p>
      </div>
    </div>
  </div>
  <div #step4 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div>
        <p class="tour-font-normal font-weight-bold">Favorites</p>
        <p class="tour-font-normal">
          To save a single or a range of generic articles from the selection field, you have to press the favorites button <i class="fa ta-icon-star"></i>. This selection will be saved as a favorite and is permanently available in the dropdown list.
        </p>
      </div>
    </div>
  </div>
  <div #step5 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div>
        <p class="tour-font-normal font-weight-bold">Detailed Information</p>
        <p class="tour-font-normal">
          If you move the mouse cursor over the <i class="fa ta-icon-info-circled"></i> icon, you will get additional information about the particular chart type and the method of how the data is collected.
        </p>
      </div>
    </div>
  </div>
  <div #step6 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div>
        <p class="tour-font-normal font-weight-bold">Export Report</p>
        <p class="tour-font-normal">
          To export a report, you have to press the save button <i class="fa ta-icon-save"></i> in the right corner of the chart header. You can choose between two formats to save your reports as CVS and PDF. When you select PDF, you get a saved view of your current selection.
        </p>
      </div>
    </div>
  </div>
  <div #step7 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div class="img-drilldown mr-3"></div>
      <div>
        <p class="tour-font-normal font-weight-bold">Chart drill-down Information</p>
        <p class="tour-font-normal">Behind many charts are hidden drill-down information; the mouse cursor changes from an
          arrow to hand by hovering a pillar when additional information is available. When you see a hand icon, click on
          the chart to see more details.</p>
      </div>
    </div>
  </div>
  <div #step8 class="d-none">
    <div class="tour-header">
      <span><i class="fa ta-icon-graduation-cap"></i></span>
      <span class="tour-font-normal">PRODUCT TOUR</span>
    </div>
    <div class="d-flex flex-row p-3">
      <div class="img-complete mr-3"></div>
      <div>
        <p class="tour-font-normal font-weight-bold">Congratulations you have completed the Product Tour!</p>
        <p class="tour-font-normal">You now know the basic functionalities of the tool and we encourage you to try your knowledge out. You can access the product tour again from the top-right corner and good luck with your analysis!</p>
      </div>
    </div>
  </div>
</ngx-guided-tour>

<nav class="navbar navbar-expand-md navbar-light justify-content-between" id="main-navbar">
  <header id="main-header">
    <div [ngClass]="'header-logo'+(_auth.isLogged?' clickable':'')" (click)="goToHome();" class="pull-left">
      <img src="../../../../assets/images/logo_ta_ddb.svg" alt="Logo">
    </div>
  
    <div class="header-buttons pull-right">
      <a class="pull-left header-button" routerLink="/portal/login" routerLinkActive="active" *ngIf="!_auth.isLogged">
        <i class="fa fa-lock"></i>
      </a>
      
      <a [pTooltip]="_auth.userDetail?.email" tooltipPosition="bottom" class="pull-left header-button " *ngIf="_auth.isLogged" (click)="changePasswordComponent.toggle($event)">
        <i class="fa  fa-user"></i>
      </a>
      
      <a pTooltip="Start product tour" tooltipPosition="bottom" class="pull-left header-button btn-tour" *ngIf="_auth.isLogged && isTourSupported()" (click)="onTourStart()">
        <i class="fa ta-icon-graduation-cap"></i>
      </a>
      <a pTooltip="Feedback" tooltipPosition="bottom" class="pull-left header-button btn-comment" *ngIf="_auth.isLogged" (click)="feedbackPanel.toggle($event)">
        <i class="fa fa-comment"></i>
      </a>
      <a pTooltip="Logout" tooltipPosition="bottom" class="pull-left header-button btn-logout" routerLink="/admin/logout" *ngIf="_auth.isLogged">
        <i class="fa fa-sign-out"></i>
      </a>
      <button class="navbar-toggler pull-right"
              type="button"
              (click)="onSwitchMenu()"
              aria-controls="sidenav"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>

    <div class="get-full-version pull-right" *ngIf="_auth.isLogged && isFreeFeaturePage" (click)="popupGetFullVersion.show()" >
      Upgrade now!
    </div>

  </header>
</nav>

<router-outlet></router-outlet>
