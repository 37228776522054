<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
  <div class="col-12">
    <chart #oeNumberChart [field]="fieldOeNumber" [headLabel]="eoNumberChartLabel"
    [showTimeLineButton]=showTimeLineButton [showSearch]=true
    [infoText]="'This graph shows the most selected articles from your brands by OE number. The OE number is matched either by any number search or direct OE number search'"></chart>
  </div>
  <div [hidden]="oeNumberBrandLessChart && oeNumberBrandLessChart.isEmptyChartData()" class="col-12">
    <chart [tourSupported]="false" #oeNumberBrandLessChart [field]="fieldOeNumberBrandLess"
    [showTimeLineButton]=showTimeLineButton [headLabel]="eoNumberChartLabelMarket" [showCoverageButton]="true"
    [infoText]="'This graph shows the most selected articles from all brands by OE number. The OE number is matched either by any number search or direct OE number search'"></chart>
  </div>
</div>
