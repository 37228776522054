import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * DirectSearchPage params:
   * - `mandator`
   *
   * DirectSearchPage query params:
   * - `query`
   * - `numberType` (not required)
   * - `exact` (not required)
   */
  // tslint:disable-next-line:max-line-length
  directSearch: { id: 'search', name: 'DirectSearchPage', route: '/:mandator/parts/search', query: ['query', 'numberType', 'exact'],  historyOptions: { resetHistory: true } },

  /**
   * OePartsPage params:
   * - `mandator`
   * - `targetType`
   * - `targetId`
   * - `typeNumber`
   * - `assemblyGroupId`
   */
  // tslint:disable-next-line:max-line-length
  oeParts: { id: 'oeparts', name: 'OePartsPage', route: '/:mandator/parts/:targetType/:targetId/:typeNumber/assemblies/:assemblyGroupId/oe' },

  /**
   * AssignedPartsPage params:
   * - `mandator`
   * - `targetType`
   *
   * AssignedPartsPage query params:
   * - `targetId` (not required if targetType is `universal` or we are searching by assembly)
   * - `typeNumber` (not required if targetType is `universal` or we are searching by assembly)
   * - `assemblyGroupId`
   * - `oe`
   * - `genArtNo`
   */
  // tslint:disable-next-line:max-line-length
  assignedParts: { id: 'lnkparts', name: 'AssignedPartsPage', route: '/:mandator/parts/:targetType/assigned', query: ['targetId', 'typeNumber', 'oe', 'assemblyGroupId', 'genArtNo'] },
};
