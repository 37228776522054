<analytics-filter #filter [showGenericArticleFilter]=true
                 [showDatasourceFilter]=false ></analytics-filter>

<div *ngIf="showVisibleDataOptions" class="row px-4">
    Select data:&nbsp;
    <p-multiSelect #selectInput [options]="visibleData" [(ngModel)]="whatIsVisible" ></p-multiSelect>&nbsp;
    <p-button (onClick)="applySelection($event)" styleClass="apply-btn btn-secondary" icon="pi pi-check"></p-button>
</div>
<div class="row">
  <ng-template ngFor let-gaOrder [ngForOf]="gaOrders">

    <div class="col-sm-5 gaOrderChart">
      <div class="row">
        <div class="col-12">
          <p-chart
            type="line" [data]="gaOrder"
            [responsive]="true" [options]="chartOptions">
          </p-chart>
        </div>
      </div>
      <div class="row">
        <div class="col-1 pr-0 text-right">
          <div *ngIf="isLoading(gaOrder.genartId)" class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
</div>

<div class="row row-grid" [hidden]="countriesHidden">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <chart #countryChart [field]="fieldCountry" [headLabel]="countryChartHeadLabel" [enableExport]=false></chart>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12">
      <chart #countryChartBrandLess [field]="fieldCountryBrandLess" [headLabel]="countryChartHeadLabelBrandLess" [enableExport]=false></chart>
  </div>
</div>
<div class="row row-grid" [hidden]="countriesHidden">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <ta-lealef-map [chartData]="worldMapChartBrandData" [panelTitle]="'Countries (your brands) by map'"></ta-lealef-map>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12">
      <ta-lealef-map [chartData]="worldMapChartMarketData" [panelTitle]="'Countries (market view) by map'"></ta-lealef-map>
  </div>
</div>
