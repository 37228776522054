import {
  NgModule,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  NgZone,
  OnDestroy
} from '@angular/core';

import { CommonModule } from '@angular/common';

import { DomHandler } from 'primeng/dom';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  selector: '[pTooltip]',
  providers: [DomHandler]
})
export class TooltipExtendedDirective extends Tooltip implements OnChanges, OnDestroy {
  @Input()
  tooltipToValidateInput = false;

  constructor(
    el: ElementRef,
    zone: NgZone) {
    super(el, zone);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tooltipToValidateInput'] &&
        (changes['tooltipToValidateInput'].currentValue === true || changes['tooltipToValidateInput'].currentValue === 'true')) {
      this.tooltipStyleClass = 'ui-warning-tooltip';
      this.tooltipEvent = 'change';
      this.escape = false;
    }

    if (changes['text'] && this.tooltipEvent === 'change') {
      this.hide();
      if (changes['text'].currentValue) {
        this.show();
        this.container.onclick = () => {
          this.hide();
        };
      }
    }
  }

  // Override method to fix appendTo behaviour in
  // relative positions.
  public getHostOffset(): { left: any; top: any; } {
    let offset: { left: any; top: any; };

    if (! (this.appendTo instanceof HTMLElement)) {
      offset = this.el.nativeElement.getBoundingClientRect();
    } else {
      const parentPos = this.appendTo.getBoundingClientRect();
      const childPos = this.el.nativeElement.getBoundingClientRect();
      offset = {
          top: childPos.top - parentPos.top,
          left: childPos.left - parentPos.left
      };
    }

    const targetLeft = offset.left + DomHandler.getWindowScrollLeft();
    const targetTop = offset.top + DomHandler.getWindowScrollTop();

    return { left: targetLeft, top: targetTop };
  }
}

@NgModule({
  imports: [CommonModule],
  exports: [TooltipExtendedDirective],
  declarations: [TooltipExtendedDirective]
})
export class TooltipExtendedModule { }
