export const VALIDATION_REQUIRED     = 'This field is required';
export const VALIDATION_MIN_LENGTH   = '{field} must be at least {length} characters long';
export const VALIDATION_MAX_LENGTH   = '{field} cannot be more than {length} characters long';
export const VALIDATION_EMAIL        = 'Invalid Email address';
export const VALIDATION_EMAIL_IN_USE = 'The email is already in use';
export const VALIDATION_EQUALS       = 'Fields do not match';
export const VALIDATION_NUMBERS      = 'No numbers found';
export const VALIDATION_UPPER_CASE   = 'No upper case letters found';
export const VALIDATION_LOWER_CASE   = 'No lower case letters found';
export const VALIDATION_SPECIAL_CHARS = 'No special chars ({chars}) found';

