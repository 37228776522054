import { Component, OnInit, ViewChild } from '@angular/core';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { InfoTexts } from '@app/shared/models/info-texts';
import { ActivatedRoute, Router } from '@angular/router';
import { PageRequest } from '@app/shared/models/page-request';
import {Features} from '@app/shared/models/features';
import { ChartComponent } from '@app/shared/components/chart/chart.component';

@Component({
  selector: 'sales-genarts',
  templateUrl: './sales-genarts.component.html',
  styleUrls: ['./sales-genarts.component.scss']
})
export class SalesGenartsComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.GENART_INFO;
  }

  getPageStoreKey(): string {
    return "SalesGenartsComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.TECDOC_3_REPORT );
  }

  public readonly fieldGenartNo = Fields.GENART_NO;
  public readonly fieldGenartNoBrandLess = Fields.GENART_NO_BRANDLESS;

  get pageName(): string {
    return 'Sales generic articles';
  }

  public genartChartHeadLabel = 'Your Brand(s)';
  public genartChartHeadLabelMarket = 'Market View';
  @ViewChild('genartChart', {static: true}) genartChart: ChartActor;
  @ViewChild('genartBrandLessChart', {static: true}) genartBrandLessChart: ChartComponent;


  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected route: ActivatedRoute,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.GENART_NO ];
  }

  getChartActors(): ChartActor[] {
    return [ this.genartChart, this.genartBrandLessChart ];
  }

  getReportPath(): string {
    return '/report/order/sales';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(): void {

  }

  protected getFeatureOnMenu() {
    return Features.SALES;
  }

}
