<div class="row py-1 row-grid">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="card-header">
        <span>General Informations</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8">
              <div class="row">
                  <div class="col-sm-5">
                    Brand:
                  </div>
                  <div class="col-sm-7">
                    {{article.mfrName}}
                  </div>
                </div>
            <div class="row">
              <div class="col-sm-5">
                Article number:
              </div>
              <div class="col-sm-7">
                {{article.articleNumber}}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Description:
              </div>
              <div class="col-sm-7">
                {{article.genericArticles[0].genericArticleDescription
                  + ( article.misc.additionalDescription ? ' ' + article.misc.additionalDescription : '' ) }}
              </div>
            </div>
          </div>
          <div class="col-sm-4" *ngFor="let im of article.images">
            <img [src]="im.imageURL200" class="article-img"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-md-12 col-sm-12 chart" *ngFor="let chart of charts">
    <chart [field]="chart.field"
           [selectable]="true"
           [headLabel]="chart.label"
           [chartType]="'doughnut'"
           [diagrammFixedLabel]="' '"
           [pageInstance]="this"></chart>
  </div>
</div>
<br>
<hr class="hr-divide" [hidden]="!hasBrandsRecommends && !hasGenartRecommends" />
<div class="card" [hidden]="!hasBrandsRecommends && !hasGenartRecommends">
  <div class="card-body">
    <h5 class="card-title">Additional Information for this article</h5>
    <p class="card-text">Notice: the data shown below is calculated based on all available tracking records. Your user specific filter settings (time span, brands, location, data source, ...) will not apply here.</p>
    <b>Export detailed recommendations</b>: <button pTooltip="Save" class="btn fa fa-save save-button" (click)="exportRecommendations($event)" [disabled]="!hasBrandsRecommends || !hasGenartRecommends">&nbsp;</button>
  </div>
</div>
<br>
<div class="row">
  <div class="col-lg-6 col-md-6 col-sm-6" [hidden]="!hasBrandsRecommends">
    <chart [field]="BRAND_ASSOC"
            [selectable]="true"
            [diagrammFixedLabel]="' '"
            [infoText]="'Users who selected this article have also selected articles from these brands: (values in %)'"
            [headLabel]="'Brands'"
            [pageInstance]="this"
            [enableToogle]=true
            [makeOthersOnRoundChart]=true
            [hasAlreadyPercents]=true></chart>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-6" [hidden]="!hasGenartRecommends">
    <chart [field]="GENART_ASSOC"
            [selectable]="true"
            [diagrammFixedLabel]="' '"
            [infoText]="'Users who selected this article have also selected articles from these GAs:  (values in %)'"
            [headLabel]="'Generic articles'"
            [pageInstance]="this"
            [enableToogle]=true
            [makeOthersOnRoundChart]=true
            [hasAlreadyPercents]=true></chart>
  </div>
</div>
<!--div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 py-4">
    <h3>Assotions overview</h3>
    <table class="table">
        <thead>
            <tr>
                <th scope="col">brand</th>
                <th scope="col"></th>
                <th scope="col">generic article</th>
                <th scope="col"></th>
                <th scope="col">count</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let a of this.associationsTable">
                <td>
                  {{a.id}}
                </td>
                <td>
                  {{a.name}}
                </td>
                <td>
                  {{a.details.genArtId}}
                </td>
                <td>
                  {{a.details.genArtName}}
                </td>
                <td>
                  {{a.count}}
                </td>
                <td>
                  {{a.percent}} %
              </td>
            </tr>
        </tbody>
    </table>
</div>
</!--div -->

<p-dialog [header]="chartDialogHeader" [modal]="true" [(visible)]="chartDialogVisible" [style]="{width: '600px', height: '770px'}"  [dismissableMask]="true">
  <chart [hidden]="noRecommendation" [field]="chartDialogField"
          [selectable]="false"
          [diagrammFixedLabel]="' '"
          [headLabel]="chartDialogChartLabel"
          [pageInstance]="this"
          [enableToogle]=false
          [enableExport]=false
          [chartType]="'doughnut'"
          [makeOthersOnRoundChart]=true
          [hasAlreadyPercents]=true
          [infoText]="chartDialogInfoText"></chart>
  <div [hidden]="!noRecommendation">There is no data for selected item</div>
</p-dialog>
