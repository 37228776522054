<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #genartChart [field]="fieldGenartNo" [headLabel]="genartChartHeadLabel" [selectable]=true
        [showTimeLineButton]=true
        [infoText]="'This graph shows the distribution of generic articles when users have selected articles from your brands'"></chart>
    </div>
    <div [hidden]="genartBrandLessChart && genartBrandLessChart.isEmptyChartData()" class="col-12">
        <chart [tourSupported]="false" #genartBrandLessChart [field]="fieldGenartNoBrandLess"
        [showTimeLineButton]=true [headLabel]="genartChartHeadLabelMarket" [selectable]=true
        [infoText]="'This graph shows the total number of generic articles when users have selected articles from all brands'"></chart>
    </div>
</div>
