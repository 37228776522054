/**
 * @file Component: Loading
 * @author David López Rguez
 */
declare var $: any;

import {
  Component,
  Input,
  DoCheck,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import {
  trigger,
  style,
  transition,
  animate
} from '@angular/animations';

import { TaskTrackerService } from '../../../core';
import { ILoadingOptions, LoadingOptions } from './LoadingOptions';

import { Observable } from 'rxjs';

const inactiveStyle = style({
    opacity: 0,
    transform: 'translateY(-40px)'
});

const timing = '.3s ease';

export interface ILoadingContext {
    message: string;
};

@Component({
  selector: 'loading',
  template: `
    <div class="loading" *ngIf="isActive()" @flyInOut>
      <div #ref> <ng-content></ng-content> </div>
<!--      <div *ngIf="ref.children.length == 0" class="ellipsis-blink tec">-->
<!--        {{ settings.message }} <span>.</span><span>.</span><span>.</span>-->
<!--      </div>-->

      <!--
        -- Spinner -->
      <div class="busy-spinner">
        <div class="busy-spinner-default-wrapper">
          <div class="busy-spinner-default-sign">
            <div class="busy-spinner-default-spinner">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
                <div class="bar4"></div>
                <div class="bar5"></div>
                <div class="bar6"></div>
                <div class="bar7"></div>
                <div class="bar8"></div>
                <div class="bar9"></div>
                <div class="bar10"></div>
                <div class="bar11"></div>
                <div class="bar12"></div>
            </div>
            <div class="busy-spinner-default-text" *ngIf="settings.message">{{ settings.message }}</div>
            <p-button id="btnCancelLoading" label="Close" (onClick)="this.clearTasks()" *ngIf="showCloseButton"></p-button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls:  ['loading.scss'],
  animations: [
    trigger('flyInOut', [
      transition('void => *', [
        inactiveStyle,
        animate(timing)
      ]),
      transition('* => void', [
        animate(timing, inactiveStyle)
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingComponent implements DoCheck, OnChanges {
  @Input() options: ILoadingOptions;
  @Input() useTaskTracker: boolean;
  @Input() busy: Observable<any> | boolean;

  defaults: LoadingOptions;
  settings: ILoadingOptions;

  private _busyActive: boolean;

  @Input()
  showCloseButton = true;

  constructor(private tracker: TaskTrackerService) {
    this.defaults = new LoadingOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['busy']) {
      if (this.busy instanceof Observable) {
        this._busyActive = true;

        this.busy.subscribe(
            () => {},
            () => {},
            () => { this._busyActive = false; });
      } else if (typeof this.busy === 'boolean') {
        this._busyActive = this.busy;
      }
    }
  }

  public clearTasks(){
    this.tracker.clearTasks();
  }

  ngDoCheck() {
    this.settings = Object.assign({}, this.defaults, this.options || {});
  }

  isActive() {
    return (this.useTaskTracker && this.tracker.anyActiveTask()) || (!this.useTaskTracker && this._busyActive);
  }
}
