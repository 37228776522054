import {
    OnInit,
    OnDestroy,
    AfterViewInit,
    Component,
    Injectable
} from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import {
    DISCART_CHANGE_TITLE,
    DISCART_CHABGE_MESSAGE,
    CONFIRM_DELETE_TITLE,
    CONFIRM_DELETE_MESSAGE,
    CONFLICT_DELETE_USER_TITLE,
    CONFLICT_DELETE_USER_MESSAGE
} from '../consts/messages';

import { TrackerService } from '../../core';
import {Features} from '@app/shared/models/features';
import {ApiService} from '@app/shared';
import {Router} from '@angular/router';

/**
 * Base entity page component.
 */

@Injectable()
export abstract class PageComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Properties

  abstract get pageName(): string;

  //#endregion

  //#region Constructor

  constructor(
    protected _tracker: TrackerService,
    private _confirmation: ConfirmationService,
    protected apiService: ApiService,
    protected router: Router
  ) {
    if (this.isAuthorizationRequiredPage()) {
      this.checkIfUrlAuthorized();
    }
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    this._tracker.trackPageView(this.pageName);
  }

  //#endregion

  //#region Abstract Methods

  /**
   * Returns a flag that indicates if the entity has changes.
   */
  public abstract hasChanges(): boolean;

  //#endregion

  //#region Public Methods

  public discartChanges(acceptCallback: Function, discartCallback?: Function): void {
    if (!this.hasChanges()) {
      acceptCallback();
      return;
    }

    this._confirmation.confirm({
      key: 'discart-changes',
      header: DISCART_CHANGE_TITLE,
      message: DISCART_CHABGE_MESSAGE,
      accept: () => {
        acceptCallback();
      },
      reject: () => {
        if (discartCallback) {
          discartCallback();
        }
      }
    });
  }

  public confirmDelete(acceptCallback: Function, discartCallback?: Function, title?: string, message?: string): void {
    this._confirmation.confirm({
      key: 'discart-changes',
      header: title || CONFIRM_DELETE_TITLE,
      message: message || CONFIRM_DELETE_MESSAGE,
      accept: () => {
        acceptCallback();
      },
      reject: () => {
        if (discartCallback) {
          discartCallback();
        }
      }
    });
  }

  public showMessage( msg: string, title: string,  acceptCallback?: Function, discartCallback?: Function): void {
    this._confirmation.confirm({
      key: 'warning-message',
      header: ' ' + title,
      message: msg,
      accept: () => {
        acceptCallback();
      },
      reject: () => {
        if (discartCallback) {
          discartCallback();
        }
      }
    });
  }

  public conflictDeleteUser() {
    this._confirmation.confirm({
      key: 'warning-message',
      header: CONFLICT_DELETE_USER_TITLE,
      message: CONFLICT_DELETE_USER_MESSAGE,
      accept: () => { }
    });
  }

  protected checkIfUrlAuthorized() {
    //User has not yet selected datasource, redirect to home
    let selectedDatasource = this.apiService.getSelectedDatasourceId();
    if ( ( !selectedDatasource && this.router.url !== '/portal/changePassword' ) || this.router.url === '/analytics') {
      this.router.navigate(['/analytics']);
      return;
    }
    //Continue staying at home page if user has not yet selected datasource
    let featureOnMenu = this.getFeatureOnMenu();
    if (Features.HOME.id == featureOnMenu.id && !selectedDatasource) {
      return;
    }
    //Check if url authorized
    this.apiService.getProps().subscribe((props: any) => {
      let authorizedFeatureIds = props.features;
      if (this.apiService.isAdmin()) {
        authorizedFeatureIds.push(Features.ADMIN.id);
      }
      if(this.apiService.isInternalUser()) {
        authorizedFeatureIds.push(Features.VEHICLE_SEARCHES.id);
      }
      let isAuthorized = authorizedFeatureIds.indexOf(featureOnMenu.id) !== -1;
      if (!isAuthorized) {
        let authorizedFeaturesOnMenu = Features.ALL.filter(f => authorizedFeatureIds.indexOf(f.id) !== -1 && f.defaultPath !== '');
        this.router.navigate([authorizedFeaturesOnMenu[0].defaultPath]);
        return;
      }
    });
  }

  public goToFirstAuthorizedItem(validFeatures?: Features[]) {
    this.apiService.getProps().subscribe((props: any) => {
      let authorizedFeatureIds = props.features;
      if (this.apiService.isAdmin()) {
        authorizedFeatureIds.push(Features.ADMIN.id);
      }
      if (this.apiService.isInternalUser()) {
        authorizedFeatureIds.push(Features.VEHICLE_SEARCHES.id);
      }
      let authorizedFeaturesOnMenu = Features.ALL.filter(f => authorizedFeatureIds.indexOf(f.id) !== -1 && f.defaultPath !== '');
      let path = authorizedFeaturesOnMenu[0].defaultPath;

      if( validFeatures && validFeatures.length ) {
        path = validFeatures[0].defaultPath;
      }

      this.router.navigate([path]);
    });
  }

  protected getFeatureOnMenu(): Features {
    return Features.MARKET_OVERVIEW;
  }

  protected isAuthorizationRequiredPage(): boolean {
    return false;
  }

  //#endregion
}
