<analytics-filter #filter [showGenericArticleFilter]=true [showBrandFilter]=false [showSearchTypeOEId]=true
                  [genartFieldTooltip]="genartFilterTooltip">

</analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #oeNumbersChart [field]="fieldArticleNumber" [headLabel]="articleNumberChartHeadLabel"
        [showTimeLineButton]=true
        [showCoverageButton]="true"
        [selectable]="false" ></chart>
    </div>
</div>
