import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { ApiService, PageComponent } from '@app/shared';
import { Brand } from '@app/shared/models/brand';
import { MenuItem, ConfirmationService, Message } from 'primeng/api';
import { BrandFilter } from '@app/shared/models/settings/brand-filter';
import { TrackerService } from '@app/core';
import { BasicResponse } from '@app/shared/models/basic-response';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';
import {InputText} from 'primeng/inputtext';
import {filter} from 'rxjs/operators';



@Component({
  selector: 'brand-filters',
  templateUrl: './brand-filters.component.html',
  styleUrls: ['./brand-filters.component.scss']
})
export class BrandFiltersComponent extends PageComponent implements OnInit {

  pageName: string = 'Brandfilter management';

  errors = {
    invalidName: false
  }

  @ViewChild('filterName', {static: true}) filterName: ElementRef<HTMLInputElement>;
  public isSaving = false;

  public hasChanges(): boolean {
    return this.changed;
  }

  constructor( private _trackerService: TrackerService, private api: ApiService, private confirmationService: ConfirmationService, protected router: Router) {
    super(_trackerService, confirmationService, api, router);
  }

  brands: Brand[];

  tabs: MenuItem[] = [];

  filters: BrandFilter[] = [];
  _selectedFilter: BrandFilter = <BrandFilter>{};

  selectedLetter;
  searchValue;

  changed = false;
  nameChange = false;
  isNew = false;
  isEditing = false;
  _selectedFilterName = '';
  isEmpty = true;

  ngOnInit() {
    this.addTabs();
    this.loadBrands();
  }

  set selectedFilter( v ) {

    this._selectedFilter = v;
    this.setSelectedByFilter(v);

    // do this, to remove not more available ids
    // TODO: show not available IDs
    this._selectedFilter.brandIds = this.getSelectedIds();
  }

  getBrandClass(brand: Brand): string {
    return 'fa fa-2 fa-check-square-o'
          + ( brand.strikeOut ? ' strikedOut' : '' )
          + (brand.isNew ? ' isNew' : '');
  }

  get selectedFilter(): BrandFilter {
    return this._selectedFilter;
  }

  loadBrands() {
    this.api.getAllBrands().subscribe(
      (brands: Brand[]) => {
        this.brands = brands;
        this.loadFilters();
      });
  }

  private readonly ALL_BRAND_FILTER_NAME = "All brands";

  public showBrandFilterUsage = false;

  showBrandFilterOverview() {
    this.showBrandFilterUsage = true;
  }

  loadFilters() {
    this.api.getBrandFilters().subscribe(
      (data: BrandFilter[]) => {
        if( data ) {
          const bfUntitled = <BrandFilter>{
                        name: 'Untitled filter',
                        brandIds: []
                      };

          if( !data.length ) {
            data = [ bfUntitled ];
          }
          this.sortFilters( data );

          this.filters = data.filter(x => x.name !== this.ALL_BRAND_FILTER_NAME);
          this.selectedFilter = this.filters.length ? this.filters[0] : bfUntitled;
        }
      }
    );
  }

  setSelectedByFilter( filter: BrandFilter ) {
    for( const b of this.brands ) {
      b.selected = filter.brandIds.indexOf(b.brandId) != -1;
      b.strikeOut = false;
      b.isNew = false;
    }
    this.changed = false;
    this.checkIsEmpty();
  }

  checkIsEmpty() {
    this.isEmpty = this.getSelectedIds().length == 0;
  }

  addTabs() {
    const start = "A".charCodeAt(0);
    const end = "Z".charCodeAt(0);

    this.tabs.push({label: 'All'});

    for( let i = start; i <= end; i++ ) {
      const charCode = String.fromCharCode(i);
      this.tabs.push( { label: charCode } );
    }
  }

  activateMenu( itm ) {
    this.selectedLetter = this.tabs[0] == itm.activeItem ? null : itm.activeItem.label;
  }

  searchInputChange( search ) {
   this.searchValue = search.value.toUpperCase();
  }

  selectAll( select ) {
    for( const b of this.brands ) {
      const isInList = this._selectedFilter.brandIds.indexOf( b.brandId ) != -1;
      b.isNew = false;
      if( select ) {
        b.strikeOut = false;
      }
      if( select
          && !b.selected
            && !isInList ) {
        b.isNew = true;
      }
      if( !select && isInList ) {
        b.strikeOut = true;
      } else {
        b.selected = select;
      }
    }
    this.changed = true;
    this.checkIsEmpty();
  }

  getSelectedIds() {
    const selectedIds = [];
    for( const b of this.brands ) {
      if( b.selected && !b.strikeOut ) {
        selectedIds.push( b.brandId );
      }
    }
    selectedIds.sort( (a, b) => a - b );
    return selectedIds;
  }

  toggleSelection( brand: Brand ) {

    if( !this.isEditing ) {
      this.showMessage('If you want edit, click first "Edit"', 'Hint');
      return;
    }

    if( brand.selected ) {
      if( brand.isNew ) {
        brand.selected = false;
      } else {
        brand.strikeOut = !brand.strikeOut;
      }
    } else {
      brand.selected = true;
      brand.isNew = this.isNew || this._selectedFilter.brandIds.indexOf(brand.brandId) == -1;
    }
    this.changed = this.checkChanged();
    this.checkIsEmpty();
  }

  checkChanged() {
    const selectedIds = this.getSelectedIds();
    return ( '' + selectedIds ) != ( '' + this._selectedFilter.brandIds );
  }

  onClearBrandFilterCache() {
    this.api.resetBrandFilterCache().subscribe((data: any) => {
      window.alert('Cache cleared');
    });
  }

  resetErrors() {
    this.errors.invalidName = false;
  }

  onSave() {

    this._selectedFilter.brandIds = this.getSelectedIds();

    this.isSaving = true;

    this.api.saveBrandFilter( this._selectedFilter )
        .subscribe((data: BrandFilter) => {

          this.isSaving = false;

          this._selectedFilter.name = data.name;
          this._selectedFilter.brandIds = data.brandIds;
          if( data.id ) {
            this._selectedFilter.id = data.id;
          }

          this.selectedFilter = this._selectedFilter;

          if( this.isNew ) {
            this._selectedFilter = data;
            this.filters.push( data );
            this.refreshFilters();
          } else
          if( this.nameChange ) {
            this.refreshFilters();
          }
          this.nameChange = false;
          this.isNew = false;
          this.changed = false;
          this.isEditing = false;
        });
  }

  sortFilters( filters: BrandFilter[] ) {
    filters.sort((a, b) => a.name.localeCompare(b.name));
  }

  refreshFilters() {
    //TODO: sort by name
    this.sortFilters( this.filters );
    this.filters = [].concat( this.filters );
  }

  focusNameField() {
    window.setTimeout(() => {
      this.filterName.nativeElement.focus();
    }, 100);
  }

  hasNameConflict() {
    const n = this._selectedFilterName.trim();
    const sf = this._selectedFilter;

    for ( const f of this.filters ) {
      let found = false;
      if( this.isNew ) {
        if( f.name == n ) {
          return true;
        }
      } else if( f.name == n && sf.id !== f.id ) {
        return true;
      }
    }
    return false;
  }

  onSaveRename() {

    this.resetErrors();

    if( this._selectedFilterName.trim().length == 0 ) {
        this.showMessage('Please give a name', 'Attention!', () => {
          this.errors.invalidName = true;
          this.focusNameField();
        });
        return;
    }

    if( this.hasNameConflict() ) {
        this.showMessage('Filter by name: "' + this._selectedFilterName + '" already exists!', 'Attention!');
        return;
    }

    if( this._selectedFilterName.trim() != ( '' + this._selectedFilter.name ).trim() || this.changed ) {

      const selectedIds = this.getSelectedIds();
      if( selectedIds.length == 0 ) {
        this.showMessage('Should select at minimum one brand', 'Attention!');
        return;
      }

      if( this.isNew ) {
        this._selectedFilter = Object.assign({}, this._selectedFilter);
      }
      this._selectedFilter.name = this._selectedFilterName.trim();
      if(this.isNew) {
        delete this._selectedFilter.id;
      }
      this.onSave();
    } else {
      this.nameChange = false;
      this.isEditing = false;
      this.isNew = false;
    }
  }

  onNew() {
    this.nameChange = true;
    this.isNew = true;
    this.isEditing = true;

    this._selectedFilterName = '';

    this.unselectAll();
    this.focusNameField();
  }

  onEdit() {
    this.nameChange = true;
    this.isEditing = true;
    this._selectedFilterName = this._selectedFilter.name;
  }

  onCancelRename() {
    this.nameChange = false;
    this.isNew = false;
    this.isEditing = false;
    this.onRestore();
    this.resetErrors();
  }

  onDelete() {
    if( this.filters && this._selectedFilter ) {
      if( this.filters.length > 1 ) {
        this.confirmDelete(() => {
            let index = this.filters.indexOf(this._selectedFilter);
            if( index != -1 ) {
              this.api.deleteBrandFilter(this._selectedFilter)
                .subscribe((data: BasicResponse) => {
                  if( data.statusCode != 200 ) {
                    this.showMessage('Delete failed! ' + data.message, 'Warning');
                  } else {
                    this.filters.splice(index, 1);
                    this.refreshFilters();
                    if( this.filters.length <= index ) {
                      index = this.filters.length - 1;
                    }
                    this.selectedFilter = this.filters[index];
                  }
                }
              );
            }
        });
      } else
      if( this.filters.length == 1 ) {
        this.showMessage('Please let at minimum one brand filter', ' Attention');
      }
    }
  }

  checkDropdownDisabled() {
    if( this.changed ) {

    }
  }

  onRestore() {
    if( this._selectedFilter ) {
      this.setSelectedByFilter( this._selectedFilter );
    }
  }

  protected isAuthorizationRequiredPage(): boolean {
    return true;
  }

  protected getFeatureOnMenu(): Features {
    return Features.ADMIN;
  }

  private unselectAll() {
    for( const b of this.brands ) {
      b.selected = false;
    }
  }


  getBrands(brF: BrandFilter) {
    const r = [];

    for( const br of this.brands ) {
      if( brF.brandIds.indexOf( br.brandId ) !== -1 ) {
        r.push( '[' + br.brandId + '] ' + br.brandName );
      }
    }
    return r;
  }

  onToggleSelectionUnusedFilter() {
    for( const brF of this.filters ) {
      if( brF.countUsage === 0 ) {
        brF.selected = !brF.selected;
      }
    }
  }

  get countSelectedFilters() {
    let r = 0;
    for( const brF of this.filters ) {
      if (brF.selected) {
        r++;
      }
    }
    return r;
  }

  get countUnusedFilters() {
    let r = 0;
    for( const brF of this.filters ) {
      if (brF.countUsage === 0) {
        r++;
      }
    }
    return r;
  }

  onDeleteSelectedFilters() {
    const filters2delete = [];

    for( const brF of this.filters ) {
      if( brF.selected ) {
        filters2delete.push( brF );
      }
    }

    if( filters2delete.length ) {

      this.confirmDelete(() => {

        const deleteReport = [];

        for( const brF of filters2delete ) {
          this.api.deleteBrandFilter(brF)
            .subscribe((data: BasicResponse) => {

              deleteReport.push( { msg: data.statusCode == 200 ? 'successful' : data.message, filter: brF } );

              // if all responses here
              if( deleteReport.length == filters2delete.length ) {

                    let msg = '';
                    for( const m of deleteReport ) {
                      msg += m.filter.name + ': ' + m.msg + '<br><br>';
                    }

                    this.showMessage( msg, 'Delete report');
                }

                this.loadFilters();
            });
        }
      });
    }
  }
}
