<p-overlayPanel #maxFavReachedOverlay [appendTo]="'body'" [styleClass]="'maxGAFavsOverlay'" (onHide)="onHideMaxFavoritesMessage()">
  {{overlayMessage}}
</p-overlayPanel>

<p-overlayPanel #tooltipGenartsSelected [appendTo]="'body'" [styleClass]="'maxGAFavsOverlay'">
  {{tooltipGenartsMessage}}
</p-overlayPanel>

<div class="col-lg-12 col-md-12 col-sm-12" #gaFilterContainer>
  <div class="row generic-article-filter" [hidden]="!showFilter">
    <div id="divGAFilter" class="left-col" #leftCol>
      <div class="d-flex">
        <i class="ta-icon-tools" tooltipPosition="top"></i>

        <div #genartsLabelDiv class="selected-genarts-labels" [hidden]="hasFocus" (click)="onGenartLabelsClick()"
             (mouseover)="onGenartLabelMouseOver($event)"
             (mouseout)="onGenartLabelMouseOut($event)">
            {{getGenartNamesList()}}
        </div>

        <p-autoComplete #autoComplete [suggestions]="results"
                        [hidden]="!hasFocus"
          [multiple]="true" field="name" [(ngModel)]="selectedOptions"
          (completeMethod)="search($event)" (onFocus)="onAutoCompleteFocus()" (onBlur)="onAutoCompleteBlur(1000)"
                        [style]="autocompleteStyle"
                         [appendTo]="'body'"
        ></p-autoComplete>
      </div>
    </div>
    <div id="divFavorite" class="right-col" #rightCol [hidden]="!rightColVisible">
      <div class="d-flex align-items-center">
        <p-button
          [style]="favButtonStyle" #favButton pTooltip="Add to favorites" styleClass="apply-btn btn-secondary default-button" icon="pi pi-star"
          (onClick)="favorClicked($event)" [disabled]="selectedOptions?.length == 0">
        </p-button>
        <p-dropdown #favDropDown [options]="favorites"
          [(ngModel)]="selectedFavorite" [optionLabel]="'name'"
          [style]="{width: 'auto'}" [placeholder]="getGenartFavoritesPlaceHolder()">
            <ng-template let-fav pTemplate="item">
              <div class="ui-helper-clearfix position-relative">
                <div class="fa fa-2 fa-trash-o" (click)="removeGenericArticleFavoriteAndSave($event, fav)"></div>
                <div>{{fav.label}}</div>
              </div>
            </ng-template>
        </p-dropdown>
      </div>
    </div>
  </div>
</div>

