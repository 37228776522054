import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RESTfulService, ContextService } from '@app/core';

import { ListItem } from '../models/ListItem';

import { Observable, BehaviorSubject } from 'rxjs';
import { AppLanguage } from '@app/core/models';
import { WebShopContext } from '@app/shared/models/web-shop-context';
import { CacheSubject } from '@app/shared/models/cache-subject';
import { TecdocCommonService } from '@app/shared/services/tecdoc-common-service';
import { LanguagesRequest } from '@app/shared/models/common/languages/languages-request';
import { share } from 'rxjs/operators';

/**
 * @file commonData.service.ts
 * @module app.share
 * @summary Common data service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2016
 */
@Injectable()
export class CommonDataService extends RESTfulService {
    private _countries = new BehaviorSubject(null);
    private _countriesObs = this._countries.asObservable();

    private _languages = new CacheSubject<AppLanguage[]>();    

    private _timeZones = new BehaviorSubject(null);
    private _timeZonesObs = this._timeZones.asObservable();

    private _cultures = new BehaviorSubject(null);
    private _culturesObs = this._cultures.asObservable();

    private _roles = new BehaviorSubject(null);
    private _rolesObs = this._roles.asObservable();

    private _context: WebShopContext;

    constructor(context: ContextService, private _http: HttpClient, private tecdocCommon: TecdocCommonService) {
        super();

        this._context = context.getInstance<WebShopContext>();
    }

    /**
     * Returns country list.
     * 
     * @returns Observable<ListItem[]>
     */
    getCountries(): Observable<ListItem[]> {
        if (this._countries.getValue() == null || typeof this._countries.getValue() === 'undefined') {
            this._http
                .get<Array<ListItem>>('api/countries')
                .subscribe(
                    data => this._countries.next(data),
                    err => {}
                );
        }

        return this._countriesObs;
    }

    /**
   * Returns language list.
   *
   * @returns Observable<AppLanguage[]>
   */
  getLanguages(): Observable<AppLanguage[]> {
    const key = `${this._context.language}-${this._context.providerId}`;

    if (this._languages.key !== key || (!this._languages.loaded && !this._languages.loading)) {
      this._languages.loading = true;
      this._languages.key = key;
      const list: AppLanguage[] = [];
      const request = <LanguagesRequest>{
        lang: this._context.language,
        provider: this._context.providerId
      };

      this.tecdocCommon
        .getLanguages(request)
        .subscribe(
          res => {
            res.data.array.forEach(item => {
              const lang = this._context.languages.find(l => l.code === item.languageCode);

              list.push(<AppLanguage>{
                code: item.languageCode,
                name: item.languageName,
                nativeName: lang ? lang.nativeName : item.languageName
              });
            });

            this._languages.value = list;
          },
          err => {
            this._languages.value = list;
          }
      );
    }

    return this._languages.obs.pipe(share());
  }

    /**
     * Returns timezone list.
     * 
     * @returns Observable<ListItem[]>
     */
    getTimeZones(): Observable<ListItem[]> {
        if (this._timeZones.getValue() == null || typeof this._timeZones.getValue() === 'undefined') {
            this._http
                .get<Array<ListItem>>('api/timezones')
                .subscribe(
                    data => this._timeZones.next(data),
                    err => {}
                );
        }

        return this._timeZonesObs;
    }

    /**
     * Returns cultures list.
     * 
     * @returns Observable<ListItem[]>
     */
    getCultures(): Observable<ListItem[]> {
        if (this._cultures.getValue() == null || typeof this._cultures.getValue() === 'undefined') {
            this._http
                .get<Array<ListItem>>('api/cultures')
                .subscribe(
                    data => this._cultures.next(data),
                    err => {}
                );
        }

        return this._culturesObs;
    }

    /**
     * Returns role list.
     * 
     * @returns Observable<ListItem[]>
     */
    getRoles(): Observable<ListItem[]> {
        if (this._roles.getValue() == null || typeof this._roles.getValue() === 'undefined') {
            let roles = <ListItem[]>[
                <ListItem>{
                    val: 'Customer',
                    desc: 'Customer'
                },
                <ListItem>{
                    val: 'Administrator',
                    desc: 'Administrator'
                }
            ];

            this._roles.next(roles);
        }

        return this._rolesObs;
    }
}