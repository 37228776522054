import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * PartDetailPage params:
   * - `mandator`
   * - `articleId`
   *
   * PartDetailPage query params:
   * - `targetType`
   * - `targetId`
   * - `typeNumber`
   * - `linkId`
   * - `oe`
   */
  // tslint:disable-next-line:max-line-length
  partDetail: { id: 'detail', name: 'PartDetailPage', route: '/:mandator/parts/:articleId/detail', query: [ 'targetType', 'targetId', 'typeNumber', 'linkId', 'oe' ] },

  /**
   * AccessoryPartsPage params:
   * - `mandator`
   * - `articleId`
   *
   * AccessoryPartsPage query params:
   * - `targetType`
   * - `targetId`
   * - `typeNumber`
   * - `linkId`
   * - `oe`
   * - `caption`
   */
  // tslint:disable-next-line:max-line-length
  accessoryParts: { id: 'acclist', name: 'AccessoryPartsPage', route: '/:mandator/parts/:articleId/accessories', query: [ 'targetType', 'targetId', 'typeNumber', 'linkId', 'oe', 'caption' ] },

  /**
   * MainPartsPage params:
   * - `mandator`
   * - `articleId`
   *
   * MainPartsPage query params:
   * - `targetType`
   * - `targetId`
   * - `typeNumber`
   * - `linkId`
   * - `oe`
   * - `caption`
   */
  // tslint:disable-next-line:max-line-length
  mainParts: { id: 'mainlist', name: 'MainPartsPage', route: '/:mandator/parts/:articleId/main', query: [ 'targetType', 'targetId', 'typeNumber', 'linkId', 'oe', 'caption' ] },

  /**
   * PartsListPage params:
   * - `mandator`
   * - `articleId`
   *
   * PartsListPage query params:
   * - `targetType`
   * - `targetId`
   * - `typeNumber`
   * - `linkId`
   * - `oe`
   * - `caption`
   */
  // tslint:disable-next-line:max-line-length
  partsList: { id: 'partlist', name: 'PartsListPage', route: '/:mandator/parts/:articleId/partlist', query: [ 'targetType', 'targetId', 'typeNumber', 'linkId', 'oe', 'caption' ] }
};
