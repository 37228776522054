import {Component} from '@angular/core';
import {Fields} from '@app/shared/models/fields';
import {OrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends.component';

@Component({
  selector: 'order-trends',
  templateUrl: './order-trends.component.html',
  styleUrls: ['./order-trends.component.scss']
})
export class CountryOrderTrendsComponent extends OrderTrendsComponent {
  showVisibleDataOptions = false;
  whatIsVisible: string[] = [ Fields.LOCATION_COUNTRY ];
}

