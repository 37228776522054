import {Component, ViewChild} from '@angular/core';
import {AnalyticsPage} from '../analytics-page';
import {NavigationService, TrackerService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {Fields} from '@app/shared/models/fields';
import {EventTypes} from '@app/shared/models/event-types';
import {ChartRequest} from '@app/shared/models/chart-request';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartActor} from '@app/shared/components/chart-actor';
import {FacetItem} from '@app/shared/models/facet-item';
import * as moment from 'moment/moment';
import {ReportParams} from '@app/shared/models/i-analytics-page';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'country-details',
  templateUrl: './country-details.component.html',
  styleUrls: ['./country-details.component.scss']
})
export class CountryDetailsComponent extends AnalyticsPage {

  public infoText: string;
  public vehicleSearchTooltip = '<div><b>E.g.</b> "golf" --> Exact match</div>'
  + '<div>golf* --> Starts with golf</div>'
  + '<div>*golf --> Ends with golf</div>'
  + '<div>*golf* --> Contains golf</div>';
  public readonly fieldGenartNo = Fields.GENART_NO;
  public readonly fieldGenartNoBrandLess = Fields.GENART_NO_BRANDLESS;
  public readonly fieldArticleNumber = Fields.ARTICLE_NUMBER;
  public fieldOeNumber = Fields.OE_NUMBER;
  public fieldOeNumberBrandLess = Fields.OE_NUMBER_BRANDLESS;
  public fieldVehicles = Fields.VEHICLES;
  public fieldVehiclesBradLess = Fields.VEHICLES_BRANDLESS;
  @ViewChild('genartChart', {static: true}) genartChart: ChartActor;
  @ViewChild('genartBrandLessChart', {static: true}) genartBrandLessChart: ChartActor;
  @ViewChild('articleNumberChart', {static: true}) articleNumberChart: ChartActor;
  @ViewChild('oeNumberChart', {static: true}) oeNumbersChart: ChartActor;
  @ViewChild('oeNumberBrandLessChart', {static: true}) oeNumberBrandLessChart: ChartActor;
  @ViewChild('vehiclesChart', {static: true}) vehiclesChart: ChartActor;
  @ViewChild('vehiclesBrandlessChart', {static: true}) vehiclesBrandlessChart: ChartActor;

  getPageFacets(): string[] {
    // uncommendted the not needed data to display - should not load
    return [Fields.GENART_NO, /* Fields.GENART_NO_BRANDLESS, */ Fields.ARTICLE_NUMBER, Fields.OE_NUMBER,
      /* Fields.OE_NUMBER_BRANDLESS, */ Fields.VEHICLES, /* Fields.VEHICLES_BRANDLESS */ ];
  }

  getChartActors(): ChartActor[] {
    return [this.genartChart, this.genartBrandLessChart, this.articleNumberChart, this.oeNumbersChart, this.oeNumberBrandLessChart, this.vehiclesChart, this.vehiclesBrandlessChart];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(EventTypes.ARTICLE_SELECTION);
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem, fieldName?: string): void {

  }

  getPageStoreKey(): string {
    return 'CountryDetailsComponent';
  }

  pageName: string = 'Country Details';

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              public translate: TranslateService,
              public navigation: NavigationService,
              protected router: Router,
              public apiService: ApiService) {
    super(appInsights, confirm, translate, navigation, apiService, router);
    this.needFilterData = false;
    this.infoText = this.pageRequest.countryName;
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.pageRequest.countryId) {
      return;
    }

    const r = this.getChartParams();

    r.params = this.pageRequest.params_map;
    r.setIds(Fields.LOCATION_COUNTRY, [this.pageRequest.countryId]);

    console.log('country-details chart request:', r);

    if (this.pageRequest.timespan) {
      r.timespan = this.pageRequest.timespan;
    } else {
      if (this.pageRequest.fromDate) {
        r.fromDate = moment(this.pageRequest.fromDate).toDate();
      }
      if (this.pageRequest.toDate) {
        r.toDate = moment(this.pageRequest.toDate).toDate();
      }
    }

    const rp = <ReportParams>{
      skipCache: true,
      notNeedFilterData: true
    };

    this.loadReport(rp);
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }

}
