
<div class="login-form">
  <div class="inner-login-form">
    <form [formGroup]="_form">
      <fieldset>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <h3 class="text-left text-secondary txt-login">Login</h3>
            </div>
          </div>
          <!-- Error message -->
          <div class="row" *ngIf="loginError" id="error-message">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <p class="badge badge-danger">{{errorMessage}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <p class="text-muted p-login" for="userName">Please login with your user data</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="text" id="userName" maxlength="50" class="required form-control" placeholder="Enter username" formControlName="userName"
                    pTooltip [pTooltip]="_formErrors.userName" tooltipPosition="top" tooltipToValidateInput="true" (keyup)="saveUserName($event)" >
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="password" id="password" maxlength="50" class="required form-control" placeholder="Enter password" formControlName="password"
                       pTooltip [pTooltip]="_formErrors.password" tooltipPosition="top" tooltipToValidateInput="true">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!--label class="text-muted" for="language">Language</label-->
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!--div class="select">
                <select class="form-control" id="language" name="language">
                  <option *ngFor="let l of languages" [ngValue]="l.val">{{ l.desc }}</option>
                </select>
              </div-->
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12">
              <button class="btn btn-block" (click)="onLogin()" [ngClass]="{'btn-primary': !countdownIntervalId, 'btn-light': countdownIntervalId}" [disabled]="countdownIntervalId">Login</button>
            </div>
          </div>
          <div class="row">
            &nbsp;
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!-- p-checkbox binary="true" label="Remember Me"></p-checkbox -->
            </div>
            <div class="col-lg-12 col-md-12 col-xs-12">
              <a class="pull-right forgot-up" href="#" (click)="onForgotPassword($event)">Forget username or password?</a>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <!-- https://developers.google.com/recaptcha/intro register to get key-->
              <p-captcha siteKey="6LdTaE8UAAAAAPf_6152n93Q3uy0b2GuXN1Ue3F9" (onResponse)="onShowResponse($event)"></p-captcha>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
