import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import {ConfirmationService, MenuItem} from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoTexts } from '@app/shared/models/info-texts';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'most-searched-vehicles',
  templateUrl: 'most-searched-vehicles.component.html',
  styleUrls: ['most-searched-vehicles.component.scss']
})
export class MostSearchedVehiclesComponent extends AnalyticsPage {

  public itemVehicles: MenuItem = { label: 'Vehicles' };
  public itemMakes: MenuItem = { label: 'Manufacturers' };
  public itemModels: MenuItem = { label: 'Models' };

  public items: MenuItem[] = [];

  public selectedItem: MenuItem = this.itemVehicles;

  public activateMenu( tab ) {
     this.selectedItem = tab.activeItem;
  }

  public get infoText(): string {
    return InfoTexts.MOST_SEARCHED_VEHICLES;
  }

  public vehicleSearchTooltip = '<div><b>E.g.</b> "golf" --> Exact match</div>'
  + '<div>golf* --> Starts with golf</div>'
  + '<div>*golf --> Ends with golf</div>'
  + '<div>*golf* --> Contains golf</div>';

  getPageStoreKey(): string {
     return "MostSearchedVehiclesComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.VEHICLE_SEARCH );
  }

  get pageName(): string {
    return 'Most searched vehicles';
  }

  public readonly fieldVehicleMakes = Fields.VEHICLE_MAKES_BRANDLESS;
  public readonly fieldVehicleModels = Fields.VEHICLE_MODELS_BRANDLESS;
  public readonly fieldVehicles = Fields.VEHICLES_BRANDLESS;


  public vehicleMakesHeadLabel = 'Manufacturers';
  public vehicleModelsHeadLabel = 'Models';
  public vehiclesChartHeadLabel = 'Vehicles';

  @ViewChild('vehicleMakesChart', {static: true}) vehicleMakesChart: ChartActor;
  @ViewChild('vehicleModelsChart', {static: true}) vehicleModelsChart: ChartActor;
  @ViewChild('vehiclesChart', {static: true}) vehiclesChart: ChartActor;

  //public fieldSearchTypeId = Fields.SEARCH_TYPE_ID_FILTER


  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router) {
      super( appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.VEHICLE_MAKES_BRANDLESS,
              Fields.VEHICLE_MODELS_BRANDLESS,
               Fields.VEHICLES_BRANDLESS ];
  }

  getChartActors(): ChartActor[] {
    return [ this.vehicleMakesChart, this.vehicleModelsChart, this.vehiclesChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

      const menuItems = [];

      if( data.hasFacets( Fields.VEHICLE_MAKES_BRANDLESS ) ) {
        console.log( data.facets.get(Fields.VEHICLE_MAKES_BRANDLESS) );
        menuItems.push(this.itemMakes);
      }
      if( data.hasFacets( Fields.VEHICLE_MODELS_BRANDLESS ) ) {
        menuItems.push(this.itemModels);
      }
      if( menuItems.length ) {
        menuItems.push(this.itemVehicles);
      } else {
        this.selectedItem = this.itemVehicles;
      }

      this.items = menuItems;
  }

  itemSelected(item: FacetItem): void {


  }

  protected getFeatureOnMenu() {
    return Features.MOST_SEARCHED_NUMBERS;
  }
}
