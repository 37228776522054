<analytics-filter #filter></analytics-filter>

<div class="column">
  <div class="mb-1">
    <ta-lealef-map [infoText]="'This map shows in which countries the users were selecting articles from your brands'"
      [chartData]="worldMapChartData" [panelTitle]="'Countries by map'" [dateRangeLabel]="dateRangeLabel"></ta-lealef-map>
  </div>
  <div class="mb-1">
    <chart [chartType]="'bar'" #yourBrandCountriesChart [field]="fieldCountries" [headLabel]="yourBrandCountriesChartHeadLabel"
           [showSearch]="false" [showTimeLineButton]=true
           [infoText]="'This graph shows in which countries the users were selecting articles from your brands'"></chart>
  </div>
  <div [hidden]="marketViewCountriesChart&&marketViewCountriesChart.isEmptyChartData()">
    <chart [tourSupported]="false" [chartType]="'bar'" #marketViewCountriesChart [field]="fieldCountriesBrandless"
      [headLabel]="marketViewCountriesChartHeadLabel" [showSearch]="false" [showTimeLineButton]=true
      [infoText]="'This graph shows in which countries the users were selecting articles from all brands'"></chart>
  </div>
</div>
