<div class="row">
  <div class="col-sm-12 py-4">
    <chart
      #chartRmiOverviewDetails
      [field]="fieldOperationName"
      [headLabel]="chartLabel"
      [selectable]=false
      [pageInstance]="this"
      [showTimeLineButton]=true
    ></chart>
  </div>
</div>
