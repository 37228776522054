<p-overlayPanel #versionBubble [appendTo]="'body'" [styleClass]="'versionBubble'" [autoZIndex]="false">
    <div class="col px-2 header">
        Product update {{version}}
    </div>
    <div class="col px-2 content bg-white">
      New product version is available with more fixes, optimizations and features. <br>
      For more details, please click on the version number below.
    </div>
    <div class="col px-2 footer">
      Please reload the website:
      <button pButton class="btn_reload_page pull-right ml-2" type="button" label="Close" (click)="onCloseClick()"></button>
      <button pButton class="btn_reload_page pull-right ml-2" type="button" label="Release notes" (click)="openReleaseNotes()"></button>
      <button pButton class="btn_reload_page pull-right ml-2" type="button" label="Reload" (click)="reloadPage()"></button>
    </div>
 </p-overlayPanel>

<footer>
  <nav class="navbar navbar-expand-md navbar-dark fixed-bottom">
    <a class="navbar-brand text company-name" href="https://www.tecalliance.net" target="_blank">
      © {{currentYear}}&nbsp;TecAlliance GmbH
    </a>
    <a [hidden]="!isLogged" id="versionLink" class="version-link text" href="/portal/release-notes" target="ddbReleaseNotes">
      {{version}}
    </a>
    <span *ngIf="!isLogged" class="version-link text">
      {{version}}
    </span>
    <div class="collapse navbar-collapse mr-auto">
      <ul class="nav navbar-nav d-flex">
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.facebook.com/TecAlliance">
            <span class="fa fa-facebook"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.youtube.com/user/TecAlliance">
            <span class="fa fa-youtube"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://twitter.com/TecAlliance">
            <span class="fa fa-twitter"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.xing.com/companies/tecalliancegmbh">
            <span class="fa fa-xing"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://plus.google.com/u/0/b/111060335767599936833/111060335767599936833/about">
            <span class="fa fa-google-plus"></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://www.linkedin.com/company/3752764?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2Cidx%3A1-1-1%2CtarId%3A1436867065836%2Ctas%3ATecAlliance">
            <span class="fa fa-linkedin"></span>
          </a>
        </li>
      </ul>
    </div>
    <ul class="navbar-nav ml-auto d-none d-lg-flex d-xl-flex">
      <li class="nav-item">
        <a class="nav-link text" routerLink="/portal/imprint">Legal notice</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" routerLink="/portal/imprint">Privacy Statement</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" routerLink="/portal/imprint">Licences</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" href="https://www.tecalliance.net/en/company/support/" target="_blank">Contact</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text">|</a>
      </li>
      <li class="navbar-item">
        <a class="nav-link text" routerLink="/portal/imprint">GTC</a>
      </li>
    </ul>
  </nav>
</footer>
