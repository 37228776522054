import { Component, OnInit, ViewChild } from '@angular/core';
import { FacetItem } from '@app/shared/models/facet-item';
import { AutocompleteData } from '@app/shared/models/autocomplete-data';
import { GeoService } from '@app/shared/services/geo/geo.service';
import { AutocompleteHandler } from '@app/shared/models/autocomplete-handler';
import { AutoComplete } from 'primeng/autocomplete';
import { DataView } from 'primeng/dataview';
import { BasicResponse } from '@app/shared/models/basic-response';
import { Ids } from '@app/shared/models/ids';
import { SelectionComponent } from '@app/shared/models/selection-component';
import { ChartData } from '@app/shared/models/analytics/chart-data';

@Component({
  selector: 'geo-location-user-favorites',
  templateUrl: './geo-location-user-favorites.component.html',
  styleUrls: ['./geo-location-user-favorites.component.scss']
})
export class GeoLocationUserFavoritesComponent implements OnInit {


  // --- variables ---
  countries: AutocompleteData;
  regions: AutocompleteData;
  postalCodes: AutocompleteData;
  distance: string = "20";
  locationList: FacetItem[] = [];

  favouritesLoaded = false;

  deletedVisible = false;
  changed = false;
  globalSelected = false;
  globalRemove = false;
  showRecycle = false;

  private items: FacetItem[];
  private target: SelectionComponent;


  // --- view childs ---
  @ViewChild('countriesAutocomplete', {static: true}) countriesAutocomplete : AutoComplete;
  @ViewChild('regionsAutocomplete', {static: true}) regionsAutocomplete: AutoComplete;
  @ViewChild('postalCodesAutocomplete', {static: true}) postalCodesAutocomplete: AutoComplete;
  @ViewChild('dv', {static: true}) dv: DataView;

  constructor( private api: GeoService ) {
    this.init();
  }

  private init() {
    const self = this;

    self.countries = new AutocompleteData(
        <AutocompleteHandler>{
          onSearch(searchString: string) {
            if(!searchString.trim().length) {
              self.resetRegion();
            }
            if(!self.countries.hasResponse) {
              self.loadCountries();
              return true;
            }
            return false;
          },
          onSelect(f: FacetItem, changed: boolean ) {
            if( changed ) {
              self.resetRegion();
            }
          }
        }
    );

    self.regions = new AutocompleteData(
      <AutocompleteHandler>{
        onSearch(searchString: string) {

          if(!searchString.trim().length) {
            self.resetPostalCode();
            return false;
          }

          if(!self.regions.hasResponse) {
            self.findRegions();
            return true;
          }
          return false;
        },
        onSelect(f: FacetItem, changed: boolean) {
          if( changed ) {
            self.resetPostalCode();
          }
        }
      });

      self.postalCodes = new AutocompleteData(
        <AutocompleteHandler>{
          onSearch(searchString: string) {
            if(searchString.trim().length < 2) {
              return false;
            }
            self.findPlaceNames(searchString);
            return true;
          },
          onSelect(f: FacetItem, changed: boolean) {

          }
        },
        true
    );
  }

  ngOnInit() {

  }

  getDistance( v: FacetItem ) {
    if( v.details && v.details.distance ) {
      return v.details.distance + ' km';
    }
    return '';
  }

  changeSelectionAll() {
    this.globalSelected = !this.globalSelected;
    for( const f of this.locationList ) {
      f.selected = this.globalSelected;
    }
  }


  changeRemoveAll() {
    this.globalRemove = !this.globalRemove;
    for( const f of this.locationList ) {
      f.remove = this.globalRemove;
    }
    this.showRecycle = this.globalRemove;
  }

  initComponent( items: FacetItem[], target: SelectionComponent ) {
    this.items = items;
    this.target = target;

    this.dv.loading = true;
    this.api.api.getSettings(Ids.GEO_LOCATIONS)
      .subscribe((data: BasicResponse) => {
        this.dv.loading = false;
        this.favouritesLoaded = true;
        if(data.message && data.message.startsWith("[")) {
          this.locationList = JSON.parse( data.message );
          this.changed = false;
          this.setSelected();
        }
    });
  }

  public getSelected(): FacetItem[] {
    const r: FacetItem[] = [];

    if( this.locationList && this.locationList.length ) {
      for( const fa of this.locationList ) {
        if( fa.selected ) {
          r.push(fa);
        }
      }
    }
    return r;
  }

  setRemoved( v: FacetItem ) {
    v.remove = !v.remove;
    this.showRecycle = this.hasItemsToRemove();
  }

  hasItemsToRemove(): boolean {
    for( const f of this.locationList ) {
      if( f.remove ) {
        return true;
      }
    }
    return false;
  }

  private setSelected( ) {
    if( this.locationList && this.locationList.length ) {
      const selectedIds: Set<string> = new Set();
      const selectedItems: FacetItem[] = this.target.getSelected();
      for( const fi of selectedItems ) {
          selectedIds.add(fi.id);
      }

      for( const fi of this.locationList ) {
        fi.selected = selectedIds.has(fi.id);
      }
    }
  }

  selectLocation(event, location:FacetItem) {
    console.log(location);
  }

  // --- request functions ---

  private loadCountries() {
    if( !this.countries.isRequesting ) {
      this.countries.isRequesting = true;
      this.api.getCountries()
        .subscribe((data: FacetItem[]) => {
          this.countries.setResponse( data );
          this.countries.doFilter();
        });
    }
  }

  private findPlaceNames( searchString: string ) {
    if(this.countries.selectedSingle) {
      this.api.findPlaceNames( this.countries.selectedSingle.id, searchString /*,  self.regions.selectedSingle ? self.regions.selectedSingle.id : null */ )
              .subscribe((data: FacetItem[]) => {
                this.postalCodes.setResponse( this.addAllSelection( data ) );
                this.postalCodes.doFilter();
              });
    }
  }

  private findRegions() {
    if( !this.regions.isRequesting ) {
      this.regions.isRequesting = true;
      this.api.getRegions( this.countries.selectedSingle.id )
              .subscribe((data: FacetItem[]) => {
                this.regions.setResponse( this.addAllSelection( data, 'All regions' ) );
                this.regions.doFilter();
              });
    }
  }

  // --- component control functions ---

  private openAutocomplete( autocomplete: AutoComplete, data: AutocompleteData ) {
    window.setTimeout(() => {
      autocomplete.focusInput();
      autocomplete.forceSelection = true;
      autocomplete.show();
      data.search({query: data.searchString});
    }, 500);
  }

  // --- reset functions ---

  resetCountry() {
    this.countries.reset( false );
    this.resetRegion();
  }

  resetRegion() {
    this.regions.reset( true );
    this.setRegionText( '' );
    this.resetPostalCode();
  }

  resetRegionOnly() {
    this.regions.reset( true );
    this.setRegionText('');
  }

  resetPostalCode() {
    this.postalCodes.reset( true );
    this.setPostalCodeText( '' );
  }

  setRegionText( v: string ) {
    this.regionsAutocomplete.inputEL.nativeElement.value = v;
  }

  setPostalCodeText( v: string ) {
    this.postalCodesAutocomplete.inputEL.nativeElement.value = v;
  }

  updatePaginator() {
    this.dv.filterBy = 'name';
    this.dv.filter('');
  }

  clearSelection() {
    for( const itm of this.locationList ) {
      itm.selected = false;
    }
  }

  resetAll() {
    this.locationList = [];
    this.showRecycle = false;
    this.globalRemove = false;
    this.globalSelected = false;
	  this.deletedVisible = false;
  }

  // --- list manage methods ---

  removeItem( v: FacetItem ) {
    const idx = this.locationList.indexOf(v);
    if( idx  != -1)  {
      this.locationList.splice(idx, 1);
    }
  }

  addFavorite() {
    let location = null;

    if( this.postalCodes.isSelected ) {

      if( this.postalCodes.selectedSingle.id === AutocompleteData.ALL ) {

        const locations = [];
        for( const fc of this.postalCodes.filtered ) {
          if( fc.id === AutocompleteData.ALL ) {
            continue;
          }

          const fa = this.createPlaceItem(fc);
          if(!ChartData.findById(this.locationList, fa.id)) {
            locations.push(fa);
          }
        }

        if( locations.length ) {
          this.locationList = locations.concat( this.locationList );
          this.changed = true;
        }

        this.postalCodes.reset(true);

      } else {
        location = this.createPlaceItem(this.postalCodes.selectedSingle);
      }

    } else
    if( this.regions.isSelected ) {

      if( this.regions.selectedSingle.id === AutocompleteData.ALL ) {
        const locations = [];
        for( const fc of this.regions.response ) {
          if( fc.id === AutocompleteData.ALL ) {
            continue;
          }

          if(!ChartData.findById(this.locationList, fc.id)) {
            const fa = this.createRegionItem(fc);
            locations.push(fa);
          }
        }

        if( locations.length ) {
          this.locationList = locations.concat( this.locationList );
          this.changed = true;
        }
      } else {
        location = this.createRegionItem( this.regions.selectedSingle );
      }
    }

    if( location ) {
      this.insertItemAsFirst( location );

      if( this.postalCodes.isSelected ) {
        this.setPostalCodeText( this.postalCodes.searchString );
        this.openAutocomplete(this.postalCodesAutocomplete, this.postalCodes);
      } else
      if( this.regions.isSelected ) {
        this.setRegionText( '' );
        this.regions.searchString = '';
        this.openAutocomplete( this.regionsAutocomplete, this.regions );
      }
    }

    this.updatePaginator();
  }

  saveFavorites( callback?: Function ) {
    this.dv.loading = true;

    let saveList = [].concat( this.locationList );
    saveList = ChartData.removeMarked( saveList, saveList );

    this.api.api.saveSettings(Ids.GEO_LOCATIONS, JSON.stringify(saveList))
        .subscribe((response: BasicResponse) => {
          this.dv.loading = false;

        if( callback ) {
          callback();
        }
    });
  }

  private addAllSelection( data: FacetItem[], text?: string ): FacetItem[] {
    return [
      <FacetItem>{
        id: AutocompleteData.ALL,
        label: text || 'All of this list'
      }
    ].concat(data);
  }

  private insertItemAsFirst( fc: FacetItem ) {
    if( !ChartData.findById(this.locationList, fc.id) ) {
      this.locationList = [fc].concat( this.locationList );
      this.changed = true;
    }
  }

  // --- factory methods ---

  private createPlaceItem( fc: FacetItem ): FacetItem {
    let distance = this.distance.trim() || "20";
    let id = fc.id.replace("~DST~", distance);

    return <FacetItem>{
      selected: false,
      id: id,
      name: fc.name,
      label: fc.label || fc.name,
      details: {
        country_code: this.countries.selectedSingle.id,
        country_name: this.countries.selectedSingle.label,
        postal_code: fc.details.postal_code,
        place_name: fc.name,
        region_code: fc.details.region_code,
        region_name: fc.details.region_name,
        latitude: fc.details.latitude,
        longitude: fc.details.longitude,
        distance: distance
      }
    };
  }

  private createRegionItem( fc: FacetItem ): FacetItem {
    return <FacetItem>{
      selected: false,
      id: fc.id,
      name: fc.name,
      label: fc.label,
      details: {
        country_code: this.countries.selectedSingle.id,
        country_name: this.countries.selectedSingle.label,
        region_code: fc.id,
        region_name: fc.label
      }
    };
  }
}
