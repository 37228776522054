import { NavPage } from '@app/core';
import { PAGE as GENERAL } from './general';
import { PAGE as PARTS } from './parts';
import { PAGE as RMI } from './rmi';
import { PAGE as SHOP } from './shop';
import { PAGE as USER_PROFILE } from './user-profile';

export const PAGE: {[key: string]: NavPage} = {
  ...GENERAL,
  ...PARTS,
  ...RMI,
  ...SHOP,
  ...USER_PROFILE
};
