import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService, GrowlService, PageComponent} from '@app/shared';
import {ConfirmationService} from 'primeng/api';
import {TrackerService} from '@app/core';
import {ReleaseNotesService} from '@app/core/services/core/release-notes-service';
import {ReleaseNotes} from '@app/shared/models/release-notes';
import {BasicResponse} from '@app/shared/models/basic-response';
import {Editor} from 'primeng/editor';
import {Dialog} from 'primeng/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent extends PageComponent implements OnInit {
  releaseNotes: ReleaseNotes = <ReleaseNotes>{ content: '', versionInfo: { version: '1.1.0', build: 111111 } };

  showAllButtons = false;
  isEditing = false;
  isRequesting = false;
  isLoaded = false;

  editContent = '';
  editContent2 = '';

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],

    ['clean']                                         // remove formatting button
  ];

  toolbarOptionsMin = [
     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
     [{ 'list': 'ordered'}, { 'list': 'bullet' }],
     [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  ];

  editor_modules_min = {
     toolbar: this.toolbarOptionsMin
  };

  editor_modules_all = {
    toolbar: this.toolbarOptions
  };

  @ViewChild('editorComponentMin', {static: true}) editorComponentMin: Editor;
  @ViewChild('editorComponentAll', {static: true}) editorComponentAll: Editor;
  //@ViewChild('editDialog', {static: true}) editDialog: Dialog;


  hasChanges(): boolean {
    return false;
  }

  get pageName(): string {
    return 'ReleaseNotes';
  }

  constructor(private tracker: TrackerService,
              private confirm: ConfirmationService,
              private _releaseService: ReleaseNotesService,
              protected _growl: GrowlService,
              protected apiService: ApiService,
              protected router: Router) {
    super(tracker, confirm, apiService, router);

    this.loadReleaseNotes();
    _releaseService.api.onPropertiesLoaded.subscribe((data: any) => {
      this.setInitialContent();
    });
  }

  private setInitialContent( error_content? ) {
    if( this.isLoaded ) {
      return;
    }

    let content = '';
    if( this.isRequesting ) {
      content = '<div>Loading ...</div>';
    } else
    if( this._releaseService.api.isAdmin() ) {
      content = '<div>No content, please double click to edit!</div>';
    }
    this.releaseNotes = <ReleaseNotes>{ content: error_content || content, versionInfo: { version: '1.1.0', build: 111111 } };
  }

  loadReleaseNotes() {
    this.isRequesting = true;

    this._releaseService.getReleaseNotes().subscribe(_releaseNotes => {
      if( _releaseNotes.statusCode == 200 ) {
        this.releaseNotes = _releaseNotes;
        this.isLoaded = true;
      } else {
        this.setInitialContent( _releaseNotes.message );
      }
      this.isRequesting = false;
      this.onEditorShow();
    });
  }

  ngOnInit() {

  }

  onTextDblClick() {
    if( this._releaseService.api.isAdmin() ) {
      this.isEditing = true;
      this.onEditorShow();
    }
  }

  saveContent( keepEditing ) {
    this.releaseNotes.content = this.showAllButtons ? this.editContent2 : this.editContent;
    this.isRequesting = true;
    this._releaseService.saveReleaseNotes( this.releaseNotes ).subscribe(( data: BasicResponse ) => {
      this.isRequesting = false;
      if( data.statusCode == 200 ) {
        this._growl.infoMessage('Saved successful', 'The release notes stored successfully!');
      } else {
        this._growl.showError('Save failed', 'The release notes could not be stored, please try later again!');
      }

      this.isEditing = keepEditing;
    });
  }

  undo() {
    if( this.showAllButtons ) {
      this.editorComponentAll.quill.history.undo();
    } else {
      this.editorComponentMin.quill.history.undo();
    }
  }
  redo() {
   if( this.showAllButtons ) {
     this.editorComponentAll.quill.history.redo();
   } else {
     this.editorComponentMin.quill.history.redo();
   }
  }

  onEditorShow() {
    if( this.isEditing ) {
      this.editContent = this.releaseNotes.content;
      this.editContent2 = this.releaseNotes.content;
    }
  }

  onEditorKeyUp(event) {
    /*if( this.showAllButtons ) {
      if (this.releaseNotes.content != this.editContent2) {
        this.releaseNotes.content = this.editContent2;
      }
    } else {
      if (this.releaseNotes.content != this.editContent) {
        this.releaseNotes.content = this.editContent;
      }
    }*/
  }
}

