<div [hidden]="true">
  <datasource-selector #datasourceSelectorComponent></datasource-selector>

</div>

<div id="divAnalyticFilter" class="row analytics-filter">
  <div>
    <button pButton id="btnApplyFilter"
            icon="fa ta-icon-cw"
            tooltipPosition="top"
            [ngClass]="brandActor.isInvalidSelectedItemNumber()?'disabled':'enabled'"
            [disabled]="!isConfiguringFilter || brandActor.isInvalidSelectedItemNumber()"
            (click)="saveFiltersAndLoadReport()"
            [pTooltip]="brandActor.exceedSelectionQuotaTooltip()">
    </button>
  </div>
  <div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 pr-0 pl-0">
      <div class="row ml-0" >
        <div class="col-sm-12 pl-0 pr-1">
          <div class="card filter-time">
            <div class="card-body row ml-0">
              <i class="filter-icon fa ta-icon-clock" pTooltip="Timespan" tooltipPosition="top"></i>
              <timespan-select class="flex-grow-1 d-flex" #timespanActor
                               [field]="'timespan'"></timespan-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 pr-0 pl-0">
      <div class="row ml-0" >
        <div class="col-sm-12 pl-0 pr-1" [hidden]="!showBrandFilter">
          <div class="card filter-tags">
            <div class="card-body row pr-0 ml-0">
              <i class="filter-icon fa ta-icon-tags" aria-hidden="true" pTooltip="Brands" tooltipPosition="top"></i>
              <brand-select #brandActor [field]="fieldBrandFilter"
                            class="flex-grow-1 d-flex"></brand-select>
            </div>
          </div>
        </div>
        <div class="col-sm-12 pl-0 pr-1" [hidden]="!showSearchTypeId">
          <div class="card filter-tags">
            <div class="card-body row ml-0">
              <i class="filter-icon fa ta-icon-tags" aria-hidden="true" pTooltip="Number type"
                 tooltipPosition="top"></i>
              <multi-select #searchTypeIdActor [field]="fieldSearchTypeId"
                            class="flex-grow-1 d-flex"></multi-select>
            </div>
          </div>
        </div>

        <div class="col-sm-12 pl-0 pr-1" [hidden]="!showSearchTypeOEId">
          <div class="card filter-tags">
            <div class="card-body row ml-0">
              <i class="filter-icon fa ta-icon-tags" aria-hidden="true" pTooltip="Number type"
                 tooltipPosition="top"></i>
              <multi-select #searchTypeIdOEActor [field]="fieldSearchTypeOEId"
                            class="flex-grow-1 d-flex"></multi-select>
            </div>
          </div>
        </div>

        <div class="col-sm-12 pl-0 pr-1" [hidden]="!showNumberPlateType">
          <div class="card filter-tags">
            <div class="card-body row ml-0">
              <i class="filter-icon fa ta-icon-tags" aria-hidden="true" pTooltip="Number type"
                 tooltipPosition="top"></i>
              <multi-select #numberPlateTypeActor [field]="fieldNumberPlateType"
                            class="flex-grow-1 d-flex"></multi-select>
            </div>
          </div>
        </div>
        <div class="col-sm-12 pl-0 pr-1" [hidden]="!showVehicleTypes">
          <div class="card filter-tags">
            <div class="card-body row ml-0">
              <i class="filter-icon fa ta-icon-tags" aria-hidden="true" pTooltip="Vehicle types"
                 tooltipPosition="top"></i>
              <multi-select #vehicleTypesActor [field]="fieldVehicleTypes"
                            class="flex-grow-1 d-flex"></multi-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 pr-0 pl-0">
      <div class="row ml-0"  #htmlDivElementCountries>
        <div class="col-sm-12 pl-0 pr-1" [hidden]="!showCountryFilter"
             [ngClass]="{'disabled': locationDisabled}">
          <div class="card filter-location">
            <div class="card-body row ml-0">
              <i class="filter-icon fa ta-icon-location" aria-hidden="true" pTooltip="Location"
                 tooltipPosition="top"></i>
              <multi-select #locationActor [field]="fieldLocation"
                            class="flex-grow-1 d-flex"></multi-select>
              <button pTooltip="Switch to detail selection" pButton type="button" icon="fa ta-icon-filtering"
                      class="ui-button-secondary ml-0"
                      (mouseup)="onAdvancedFilterClicked( fieldLocation, locationActor )"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 pr-0 pl-0">
      <div class="row ml-0"  #htmlDivElementDatasources>
        <div class="col-sm-12 pl-0 pr-0" [hidden]="!showDatasourceFilter">
          <div class="card filter-datasource">
            <div class="card-body row ml-0 pr-0">
              <i class="filter-icon fa ta-icon-master-data" aria-hidden="true" pTooltip="Data source"
                 tooltipPosition="top"></i>
              <multi-select #datasourceActor [field]="fieldDataSource" [multiSelect]="false"
                            [selectFirstAutomatic]="true"
                            class="flex-grow-1 d-flex"
                            [overlayComponent]="datasourceSelectorComponent"></multi-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row analytics-filter-line2" [hidden]="!showGenericArticleFilter">
  <generic-articles-filter #gaFilter [field]="fieldGenArt" [showFilter]="showGenericArticleFilter" class="w-100 d-flex"
  [rightColumnWidth]="[ dataSourceWidth, countryFilterWidth ]" [pTooltip]="genartFieldTooltip" [escape]="false">
  </generic-articles-filter>
</div>
<filter-dialog #filterDialog></filter-dialog>
<div class="row" [hidden]="infoText2===''">
   <div class="col-sm-12">
        <h6 class="w-100 text-center">{{infoText2}}</h6>
    </div>
</div>

