/**
 * @file Component: Backdrop
 * @author David López Rguez
 */
declare var $: any;

import {
  Component,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import {
  trigger,
  style,
  transition,
  animate
} from '@angular/animations';

import { TaskTrackerService } from '../../../core';
import { IBackdropOptions, BackdropOptions } from './BackdropOptions';

import { Observable } from 'rxjs';

const inactiveStyle = style({
  opacity: 0
});

const timing = '.3s ease';

@Component({
  selector: 'backdrop',
  template: `
    <div class="backdrop"
        @fadeInOut
        *ngIf="isActive()">
        <ng-content></ng-content>
    </div>
    `,
  styleUrls: ['backdrop.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', [inactiveStyle, animate(timing)]),
      transition('* => void', [animate(timing, inactiveStyle)])
    ])
  ]
})
export class BackdropComponent implements OnChanges {
  @Input() options: IBackdropOptions;
  @Input() useTaskTracker: boolean;
  @Input() busy: Observable<any> | boolean;

  defaults: BackdropOptions;
  settings: IBackdropOptions;

  private _busyActive: boolean;

  constructor(private tracker: TaskTrackerService) {
    this.defaults = new BackdropOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['busy']) {
      if (this.busy instanceof Observable) {
        this._busyActive = true;

        this.busy.subscribe(
          () => {},
          () => {},
          () => {
            this._busyActive = false;
          }
        );
      } else if (typeof this.busy === 'boolean') {
        this._busyActive = this.busy;
      }
    }
  }

  ngDoCheck() {
    this.settings = Object.assign({}, this.defaults, this.options || {});
  }

  isActive() {
    return (
      (this.useTaskTracker && this.tracker.anyActiveTask()) ||
      (!this.useTaskTracker && this._busyActive)
    );
  }
}
