import {
  Component,
  OnDestroy
} from '@angular/core';

import { ConfirmationService } from 'primeng/api';

import {
  TrackerService
} from '../../../core';

import {
  ApiService,
  PageComponent
} from '../../../shared';

import { LoginData } from '../../models/LoginData';
import {Router} from '@angular/router';

@Component({
  //moduleId: module.id,
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss']
})
export class LoginPage extends PageComponent implements OnDestroy {
  //#region Properties

  get pageName(): string {
    return 'Login';
  }

  //#endregion

  //#region Fields

  _loginData: LoginData = new LoginData('' , '');

  //#endregion

  //#region Constructor

  constructor(
    appInsights: TrackerService,
    confirmation: ConfirmationService,
    protected apiService: ApiService,
    protected router: Router
  ) {
    super(appInsights, confirmation, apiService, router);
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnDestroy() {

  }

  //#endregion

  //#region PageComponent Methods

  public hasChanges(): boolean {
    return false;
  }

  //#endregion
}
