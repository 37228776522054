<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
      <chart [chartType]="'bar'" [showVerticalBar]="true" #brandlessDonutChart [field]="fieldBrandNoBrandless" class="data-hj-allow"
        [headLabel]="brandlessChartHeadLabel"
             [showTopDropdown]=false
             [enableToogle]=true
             [selectedTopOptions]=30
             [makeOthersOnRoundChart]=false
             [makeCompetitorOnRoundChart]=true
             yAxesTitle="Article Views"
             [infoText]="'This graph shows how do you compare to your competitors in terms of article selection'"
      >
      </chart>
    </div>
</div>
