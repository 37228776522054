import { IAnalyticsPage } from "@app/shared/models/i-analytics-page";
import { EventEmitter, Input, OnInit, Output, Directive } from '@angular/core';
import { ChartData } from "@app/shared/models/analytics/chart-data";
import { ReportResponse } from "@app/shared/models/analytics/report-response";
import { Settings } from "../models/settings/settings";
import { FacetItem } from "../models/facet-item";
import {ConfigMode} from '@app/shared/models/config-mode';
import {FilterConfigEvent} from '@app/shared/models/filter-config-event';
import { ChartComponent } from "./chart/chart.component";

@Directive()
export abstract class ChartActor implements OnInit {

    public page: IAnalyticsPage;
    protected report: ReportResponse;

    @Input()
    public infoText: string;
    protected configMode: ConfigMode;

    @Output() public onConfiguringFilter = new EventEmitter<FilterConfigEvent>();

    @Input()
    public set pageInstance( v: IAnalyticsPage ) {
        this.setPage( v );
    }

    public setPage( p: IAnalyticsPage ) {
        if( p ) {
            this.page = p;

            if( !this.infoText ) {
              this.infoText = p.infoText;
            }

            p.getDataSubscriber().asObservable().subscribe(( d ) => { this.checkAndSetData(d)} );

            if( this.fieldName ) {
                this.loadSettings();
            }
        }
    }

    public constructor() {

    }

    public fieldName: string;
    @Input()
    protected set field( v : string ) {

        console.log( "field: " + v );

        this.fieldName = v;

        if( this.page ) {
            this.loadSettings();
        }
    }

    private setReport( report: ReportResponse ) {
        //console.log( 'setReport: ', report );
        this.report = report;
        this.reportLoaded( report );
    }

    protected reportLoaded( report: ReportResponse ) {} // just for override

    private checkAndSetData(d: ReportResponse) {
      this.setReport(d);
      //console.log( d );
      if (d) {
        if (d.facets.has(this.fieldName)) {
          //console.log( 'setData: ' + this.fieldName );
          this.setData(d.facets.get(this.fieldName), false);
        }
        else if (d.pageLoad && this.isChartComponent()) {
          let chartComponent = this as unknown as ChartComponent;
          let emptyData = chartComponent.chartData.newChartDataWithItems([]);
          chartComponent.setData(emptyData, false);
        }
      }
    }

    private isChartComponent(){
      return 'chartComponent' in this;
    }

    protected abstract setData( d: ChartData, changed: boolean );
    protected abstract getSettings(): Settings;
    protected abstract settingsLoaded( o: Settings ): void;

    private _getSettings(): Settings {
        let settings: Settings = this.getSettings();
        if( settings.key_s.endsWith('_') && this.fieldName )  {
            settings.key_s += this.fieldName;
        }
        return settings;
    }

    public saveSettings() {
        if( !this.page ) {
          return;
        }

        //console.log('saveSettings: ' + this.fieldName);

        try {
            let settings: Settings = this._getSettings();
            this.page.saveSettings( settings );
        } catch( e ) {
          console.log(e);
        }
    }

    public loadSettings(): void {
        //console.log('ChartActor.loadSettings ' + this.constructor.name);
        let settings: Settings = this._getSettings();
        try {
          if( this.page ) {
            settings = this.page.getSettings( settings );
            this.copySourceSettings(this, settings);
		  }
        } catch( e ) {
          console.log(e);
        }
        this.settingsLoaded( settings );
    }

    private copySourceSettings( target: any, source: Settings ) {
        for( const key in source ) {
            try {
                if( key.endsWith('_dts') && source[key].length == 2 ) {
                    target[key] = [
                        new Date(source[key][0]),
                        new Date(source[key][1])
                    ];
                } else
                if( key.endsWith('dt_')) {
                    target[key] = new Date( source[key] );
                } else {
                    target[key] = source[key];
                }
            } catch( e ) {

            }
        }
    }

    protected copySettings( target: Settings, source: Settings ) {
        for( const key in target ) {
            try {
                target[key] = source[key];
            } catch( e ) {}
        }
    }

    ngOnInit(): void {

    }

    protected getSelectedOptions( options: FacetItem[], selectedIds: string[] ): FacetItem[] {
        const r = [];
        if( options && selectedIds && selectedIds.length ) {
          for( const itm of options ) {
            if( selectedIds.indexOf( itm.id ) != -1 ) {
              r.push( itm );
            }
          }
        }
        return r;
      }

}
