<backdrop [busy]="_loading">
    <loading [busy]="_loading"></loading>
</backdrop>

<!-- Main content -->
<article id="main-content">
    <div class="tec-section">
        <div class="tec-header">
            <!-- Main title -->
            <p class="tec-title">
                <ng-content select="[content-type=main-title], admin-main-title"></ng-content>
            </p>

            <!-- Main content actions -->
            <div class="tec-actions">
                <ng-content select="[content-type=main-actions], admin-main-actions"></ng-content>
            </div>
        </div>

        <!-- Main content -->
        <div class="tec-body">            
            <ng-content select="[content-type=main], admin-main"></ng-content>
        </div>
    </div>
</article>

<!-- Details -->
<article id="main-detail" *ngIf="showDetail">
    <div class="tec-section">
        <div class="tec-header">
            <!-- Detail title -->
            <p class="tec-title">
                <ng-content select="[content-type=detail-title], admin-detail-title"></ng-content>
                <span *ngIf="_loadingDetail" class="ellipsis-blink"><span>.</span><span>.</span><span>.</span></span>
            </p>

            <!-- Detail actions -->
            <div class="tec-actions">
                <ng-content select="[content-type=detail-actions], admin-detail-actions"></ng-content>
            </div>
        </div>
        
        <!-- Detail content -->
        <div class="tec-body">
            <ng-content select="[content-type=detail], admin-detail"></ng-content>
        </div>
    </div>
</article>
