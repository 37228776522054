import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { ContextService } from '../core/context';
import { StorageService } from '../core/storage';

import { Observable, EMPTY } from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';

import { AuthTypes } from '../../security/auth-types';
import { AuthResponse } from '../../security/auth-response';
import { UserDetail } from '../../security/user-detail';
import { NavigationService } from '../miscellany/navigation';

import { CoreModuleConfig } from '../../core-module-config';

import { InitSession2Request } from './cis/init-session-2-request';
import { InitSession2Response } from './cis/init-session-2-response';
import { PingRequest } from './cis/ping-request';
import { StatusResponse } from './cis/status-response';

import { AuthBaseService } from './auth-base';

import { timeout } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ForgotPasswordRequest } from './cis/forgot-password-request';
import { CisResponse } from './cis/cis-response';

/**
 * @file cis-auth.js
 * @module app.core
 * @summary Core service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2018
 */
@Injectable()
export class CisAuthService extends AuthBaseService {
  //#region Authentication Properties

  public get type(): AuthTypes {
    return AuthTypes.Cis;
  }

  //#endregion

  //#region Constructor

  constructor(
    navigation: NavigationService,
    config: CoreModuleConfig,
    storage: StorageService,
    context: ContextService,
    http: HttpClient
  ) {
    super(navigation, config, context, storage, http);
  }

  //#endregion

  //#region Authentication Methods

  public forgotPassword(body: ForgotPasswordRequest): Observable<CisResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http
      .post<CisResponse>(
        environment.api.url + '/user/forgotPassword?' + ( environment.api.fixedParams || '' ),
        body,
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        catchError((err: Error, caught: Observable<HttpResponse<CisResponse>>) => {
          throw err;
        }),
        map((response: HttpResponse<CisResponse>) => {
          return response.body;
        }),
        share()
      );
  }

  public changePassword(body: ForgotPasswordRequest): Observable<CisResponse> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    headers = headers.set('X-CustomToken', this.refreshToken);

    const endpointExtension = body.endPointExtension || '';
    delete body.endPointExtension;

    return this.http
      .post<CisResponse>(
        environment.api.url + '/user/changePassword' + endpointExtension +  '?' + ( environment.api.fixedParams || '' ),
        body,
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        catchError((err: Error, caught: Observable<HttpResponse<CisResponse>>) => {
          throw err;
        }),
        map((response: HttpResponse<CisResponse>) => {
          return response.body;
        }),
        share()
      );
  }

  public login(body: InitSession2Request): Observable<AuthResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http
      .post<InitSession2Response>(
        environment.api.url + '/user/login?' + ( environment.api.fixedParams || '' ),
        {
          user: body.login,
          password: body.password,
          token: body.token
        },
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        catchError((err: Error, caught: Observable<HttpResponse<StatusResponse>>) => {
          throw err;
        }),
        map((response: HttpResponse<InitSession2Response>) => {
          const result = new AuthResponse();
          result.status = response.body.status;
          result.status2 = response.body.status2;
          result.message = response.body.statusText;
          result.frontendCachePaths = response.body.frontendCachePaths;
          if (response.ok && response.body.status === 200 && response.body.customer) {
            const data = response.body;

            this.setAccessToken(data.sessionId);
            this.setRefreshToken(data.sessionId);

            const expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + this.context.instance.auth.lifetime);
            this.setExpirationDate(expiration);

            const userDetail = this.createUser(data);
            result.userDetail = userDetail;
            this.setUser(userDetail);

            this.loggedIn.emit();
          }

          return result;
        }),
        share()
      );
  }

  public refresh(body?: PingRequest): Observable<AuthResponse> {

    console.log( "cis-auth refresh #1" );

    if (!this.isLogged) {
      console.log( "cis-auth refresh IF #2" );
      return EMPTY;
    }

    body = body || <PingRequest>{
      session: this.refreshToken
    };

    let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    headers = headers.set('X-CustomToken', body.session);

    return this.http
      .post<StatusResponse>(
        environment.api.url + '/user/refreshToken?' + ( environment.api.fixedParams || '' ),  //`${this.context.instance.auth.endpoint}`,
        {},
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        catchError((err: Error, caught: Observable<HttpResponse<StatusResponse>>) => {
          console.log( "cis-auth refresh error #3" );
          this.logout( { status: 401 } );
          throw err;
        }),
        map((response: HttpResponse<StatusResponse>) => {
          const result = new AuthResponse();
          result.status = response.body.status;
          result.message = response.body.statusText;

          if( response.body.sessionId ) {
            this.setAccessToken(response.body.sessionId);
            this.setRefreshToken(response.body.sessionId);
          }

          console.log( "cis-auth refresh response #4 " + JSON.stringify( response ) );
          if (response.ok && response.body.status === 200) {
            const expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + this.context.instance.auth.lifetime);
            this.setExpirationDate(expiration);

            this.refreshed.emit();
          } else {
            console.log( "cis-auth refresh response #5" );
            this.logout( { status: result.status } );
          }

          return result;
        }),
        share()
      );
  }

  public logout( params?: any ) {

    if (!this.isLogged) {
      return;
    }

    if( !params ) {
      params = { status: 0, msg: null };
    }

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');

    headers = headers.set('X-CustomToken', this.token);

    if( params.status != 401 ) {
      console.log( "cis-auth logout #1 " + JSON.stringify(params) );
      this.http
        .post<StatusResponse>(
          environment.api.url + '/user/logout?' + (environment.api.fixedParams || ''),  //`${this.context.instance.auth.endpoint}`,
          {},
          { headers: headers, observe: 'response' }
        )
        .pipe(
          catchError((err: Error, caught: Observable<HttpResponse<StatusResponse>>) => {
            throw err;
          }),
          map(response => response.body),
          share()
        ).subscribe();
    } else {
      params.msg = 'You was logged out by another user or session is expired.';
    }

    console.log('cis-auth logout');
    super.logout( params );
  }

  //#endregion

  //#region Helpers

  private createUser(response: InitSession2Response) {
    if (!response || !response.customer) {
      return null;
    }

    return this.config.userFactory
      ? this.config.userFactory(response.customer)
      : <UserDetail>{
          id: response.customer.customerId,
          firstName: response.customer.firstName,
          lastName: response.customer.lastName,
          email: response.customer.email,
          phone: response.customer.phone,
          culture: `${response.customer.languagecode}-${response.customer.countrycode}`,
      };
  }

  //#endregion
}
