<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #brandChart [field]="fieldBrandNo" [headLabel]="brandChartHeadLabel"
                [showTimeLineButton]=true
                [enableToogle]="true"
                [infoText]="'This graph shows how often users have selected articles from your brands'"></chart>
    </div>
</div>

