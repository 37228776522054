import {Component, OnInit, ViewChild} from '@angular/core';
import tr from '@angular/common/locales/tr';
import {Dialog} from 'primeng/dialog';
import {AuthService, UserDetail} from '@app/core';
import {User} from '@app/shared/models/user';
import {ApiService} from '@app/shared';
import {BasicResponse} from '@app/shared/models/basic-response';

@Component({
  selector: 'popup-get-full-version',
  templateUrl: './popup-get-full-version.component.html',
  styleUrls: ['./popup-get-full-version.component.scss']
})
export class PopupGetFullVersionComponent implements OnInit {

  messageSended = false;
  popUpStyle = {width: '600px',
           height: `${this.messageSended ? 100 : 600}px`};

  errorMsg;

  user = {
    name: '',
    surname: '',
    email: ''
  };

  error = {
    name: false,
    surname: false,
    email: false
  };

  constructor( public _auth: AuthService, private api: ApiService ) {

  }

  @ViewChild('dialog', {static: true}) dialog: Dialog;
  public showDialog = false;
  public isSending = false;

  ngOnInit() {
  }

  show() {
    this.showDialog = true;
    this.user.email = this._auth.userDetail.email;

    console.info(this._auth.userDetail);
  }

  sendForm(event) {

    if( event ) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.errorMsg = null;

    let hasErrors = false;

    for( const field in this.user ) {
      this.error[field] = false;
      if( this.user[field].trim().length === 0 ) {
        this.errorMsg = 'Please fill the form !';
        this.error[field] = true;
        hasErrors = true;
      }
    }

    if( hasErrors ) {
      return;
    }

    if( this.isSending ) {
      return;
    }
    this.isSending = true;

    this.api.sendFullVersionRequest(this.user)
        .subscribe((data: BasicResponse) => {

          this.isSending = false;

          this.errorMsg = '';
          if( data.statusCode != 200 ) {
            this.errorMsg = data.message;
          } else {
            this.showDialog = false;
            this.messageSended = true;
            //this._auth.fullVersionRequested = true;

            window.setTimeout(() => {
              this.showDialog = true;
            }, 500 );
          }
    });
  }
}
