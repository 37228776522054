import { RecommendationPage } from "./recommendionsPage";
import { ChartData } from "./analytics/chart-data";
import { BrandRecommendsResponse } from "./brand-recommends-response";
import { Brand } from "./brand";
import { FacetItem } from "./facet-item";
import { Converter } from "./converter";
import { Fields } from "./fields";
import { GenartRecommendsResponse } from "./genart-recommends-response";
import { AssociationsRecommendsResponse } from "./associations-recommends-response";


export class Recommender {

  public readonly BRAND_ASSOC = Fields.BRAND_ASSOCIATIONS;
  public readonly GENART_ASSOC = Fields.GENART_ASSOCIATIONS;

  private recommendationsLoaded = false;

  private brandRecommendsLoaded = false;
  private genartRecommendsLoaded = false;

  public loadRecomendations(page: RecommendationPage) {

    if (this.recommendationsLoaded) {
      return;
    }
    this.recommendationsLoaded = true;

    page.recommendsOthers = new Map();
    page.recommendsOriginal = new Map();

    this.loadBrandRecommendations(page, null, null, () => {
      this.brandRecommendsLoaded = true;
      //this.loadBrandGenartRecommendsTable(page);
    });
    this.loadGenartRecommendations(page, null, null, () => {
      this.genartRecommendsLoaded = true;
      //this.loadBrandGenartRecommendsTable(page);
    });
  }

  loadBrandGenartRecommendsTable(page: RecommendationPage, callback: any) {
    if (this.brandRecommendsLoaded && this.genartRecommendsLoaded) {

      page.apiService.getAssociationsTable(page.article ? page.pageRequest.brandNo : null,
        page.article ? page.article.articleNumber : null).subscribe((data: AssociationsRecommendsResponse) => {
          if (data && data.recommendations && data.recommendations.length > 0) {

            page.apiService.getAllBrands()
              .subscribe((brands: Brand[]) => {
                page.apiService.getResources('genericArticles2')
                  .subscribe((gaMap: object) => {


                    const brandMap: Map<string, Brand> = page.apiService.allBrandMap;
                    const fis: FacetItem[] = [];

                    for (const ass of data.recommendations) {
                      const brandId = '' + ass.brand;
                      const gaId = '' + ass.genart;
                      if( !brandMap.has(brandId) || !gaMap[gaId] )  {
                        continue;
                      }
                      fis.push( <FacetItem>{
                          id: brandId,
                          name: brandMap.get( brandId ).brandName,
                          label: brandMap.get( brandId ).brandName + ' [' + brandId + ']',
                          count: ass.percent,
                          percent: ass.percent,
                          details: {
                            genArtId: ass.genart,
                            genArtName: gaMap[ gaId ],
                            genArtLabel: gaMap[ gaId ] + ' [' + gaId + ']'
                          }
                      } );
                    }

                    ChartData.sortByCountDesc(fis);
                    //PercentCalculator.calculate(fis);

                    if(callback) {
                      callback(fis);
                    }
                  });
              });
          }
        });
    }
  }

  loadBrandRecommendations(page: RecommendationPage, genartNo?: number, field?: string, callback?: any, noDataCallback?: any) {
    page.apiService.getBrandAssociations(
      page.article ? page.pageRequest.brandNo : null,
      page.article ? page.article.articleNumber : null,
      genartNo)
      .subscribe((data: BrandRecommendsResponse) => {
        if (!data || !data.recommendations || data.recommendations.length == 0) {
          if (noDataCallback) {
            noDataCallback();
            return;
          }
        }

        page.hasBrandsRecommends = true;

        page.apiService.getAllBrands(true)
          .subscribe((brands: Brand[]) => {
            if (!brands || !page.apiService.allBrandMap) {
              return;
            }

            let fis: FacetItem[] = Converter.convertBrandRecoms(page.apiService.allBrandMap, data);
            ChartData.sortByCountDesc(fis);
            //PercentCalculator.calculate(fis);

            if (!genartNo) {
              page.recommendsOriginal.set(this.BRAND_ASSOC, fis);
            }

            page.report.addAssociations(field || Fields.BRAND_ASSOCIATIONS, fis);
            page.dataSubscriber.emit(page.report);

            if (callback) {
              callback();
            }
          });
      });
  }

  loadGenartRecommendations(page: RecommendationPage, brandNo?: number, field?: string, callback?: any, noDataCallback?: any) {

    page.apiService
      .getGenericArticleAssociations(page.article ? page.pageRequest.brandNo : null,
        page.article ? page.article.articleNumber : null,
        brandNo)
      .subscribe((data: GenartRecommendsResponse) => {
        if (!data || !data.recommendations || data.recommendations.length == 0) {
          if (noDataCallback) {
            noDataCallback();
            return;
          }
        }

        page.hasGenartRecommends = true;

        page.apiService.getResources('genericArticles2', true)
          .subscribe((gaMap: object) => {

            let fis: FacetItem[] = Converter.convertGenartRecoms(gaMap, data);
            ChartData.sortByCountDesc(fis);
            //PercentCalculator.calculate(fis);

            if (!brandNo) {
              page.recommendsOriginal.set(this.GENART_ASSOC, fis);
            }

            page.report.addAssociations(field || Fields.GENART_ASSOCIATIONS, fis);
            page.dataSubscriber.emit(page.report);

            if (callback) {
              callback();
            }

          });
      });
  }
}
