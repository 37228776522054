<analytics-filter #filter [showGenericArticleFilter]=false [showBrandFilter]=false [showVehicleTypes]=true></analytics-filter>

<div class="container-fluid vehicles-container">
  <div class="row make-tabs-row" [hidden]="items.length == 0">
    <div class="make-tabs-wrapper">
      <p-tabMenu #tab [model]="items" [activeItem]="selectedItem" styleClass="ta-tabmenu-container"
                 (click)="activateMenu(tab)">
      </p-tabMenu>
    </div>
  </div>
  <div class="row row-grid">
     <div class="col-12" [hidden]="selectedItem != itemMakes">
          <chart [tourSupported]="selectedItem == itemMakes" #vehicleMakesChart [field]="fieldVehicleMakes" [headLabel]="vehicleMakesHeadLabel" [selectable]="false"></chart>
      </div>
      <div class="col-12" [hidden]="selectedItem != itemModels">
          <chart [tourSupported]="selectedItem == itemModels" #vehicleModelsChart [field]="fieldVehicleModels" [headLabel]="vehicleModelsHeadLabel" [selectable]="false"></chart>
      </div>
      <div class="col-12" [hidden]="selectedItem != itemVehicles">
          <chart [tourSupported]="selectedItem == itemVehicles" #vehiclesChart [field]="fieldVehicles" [headLabel]="vehiclesChartHeadLabel" [selectable]="false"
            [showSearch]=true [searchTooltip]="vehicleSearchTooltip"></chart>
      </div>
  </div>
</div>
