export interface IBackdropOptions {
    delay?: number;
    minDuration?: number;
}

export class BackdropOptions implements IBackdropOptions {
    delay: number;
    minDuration: number;

    constructor(config: IBackdropOptions = {}) {
        for (let option in BACKDROP_OPTIONS_DEFAULTS) {
            this[option] = typeof config[option] !== 'undefined' && config[option] !== null
                ? config[option]
                : BACKDROP_OPTIONS_DEFAULTS[option];
        }
    }
}

export const BACKDROP_OPTIONS_DEFAULTS = {
    delay: 0,
    minDuration: 0
};
