<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #articleNumberChart [field]="fieldArticleNumber"
        [showTimeLineButton]=true
        [headLabel]="articleNumberChartHeadLabel" [showSearch]=true
        [infoText]="'This graph shows how often users have selected specific article numbers from your brands'"></chart>
    </div>
</div>
