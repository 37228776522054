// Modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    // Default route
    { path: '', redirectTo: 'portal', pathMatch: 'full' },
    // Wildcard route
    { path: '**', redirectTo: 'portal' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [ RouterModule ]
})
export class BootstrapRoutingModule {
}
