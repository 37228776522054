import {NgModule, ModuleWithProviders, Injector} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

// angular 4
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { ChartModule } from 'primeng/chart';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { CheckboxModule } from 'primeng/checkbox';
import { SpinnerModule } from 'primeng/spinner';
import { TooltipModule } from 'primeng/tooltip';
import { ContextMenuModule } from 'primeng/contextmenu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RatingModule } from 'primeng/rating';
import {MessageService} from 'primeng/api';

import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';

import {
  TableModule
} from 'primeng/table';

import {
  DataViewModule
} from 'primeng/dataview';



import {
  NgMasonryComponent,
  NgMasonryItem,
  WallpaperComponent,
  SideMenuComponent,
  BackdropComponent,
  LoadingComponent,
  TaskReporterDirective,
  TinyMCEEditorComponent,
  PortalFooterComponent,

  WizardComponent,
  WizardHeaderComponent,
  WizardStepDirective,

  SafeHtmlPipe,

  ComponentHostDirective,

  GrowlService,
  SessionManagerService,
  CommonDataService,
  UIActionsService,
  ApiService
} from '.';
import {TranslateModule} from '@ngx-translate/core';
import {ImageSourceDirective} from '@app/shared/directives/image-source/image-source';
import {AnalyticsFilterComponent} from './components/analytics-filter/analytics-filter.component';
import {MultiSelectComponent} from './components/multi-select/multi-select.component';
import {TimespanSelectComponent} from './components/timespan-select/timespan-select.component';
import {ChartComponent} from './components/chart/chart.component';
import {GenericArticlesFilterComponent} from './components/generic-articles-filter/generic-articles-filter.component';
import {FilterDialogComponent} from './components/filter-dialog/filter-dialog.component';
import {GeoLocationUserFavoritesComponent} from './components/geo-location-user-favorites/geo-location-user-favorites.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {TaLealefMapComponent} from './components/ta-lealef-map/ta-lealef-map.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {WeekdayFilterComponent} from '@app/shared/components/weekday-filter/weekday-filter.component';
import {BrandSelectComponent} from '@app/shared/components/multi-select/brand-select.component';
import {CircleProgressOptions, NgCircleProgressModule} from 'ng-circle-progress';
import { PdfService } from './services/pdf/pdf.service';
import { FeedbackToolComponent } from './components/feedback-tool/feedback-tool.component';
import { PopupGetFullVersionComponent } from './components/popup-get-full-version/popup-get-full-version.component';
import {ChangePasswordComponent} from '@app/shared/components/change-password/change-password.component';
import {GuidedTourModule, GuidedTourService} from 'ngx-guided-tour';
import { MultipleDatasourceComponent } from '@app/shared/components/multiple-datasource/multiple-datasource.component';
import {TreeModule} from "primeng";
import {DatasourceSelectorComponent} from "@app/shared/components/datasource-selector/datasource-selector.component";


/**
 * AppCommonModule defines common features shared by
 * MDP apps.
 */
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        BrowserAnimationsModule,
        MultiSelectModule,
        AutoCompleteModule,
        DropdownModule,
        InputMaskModule,
        CalendarModule,
        ChartModule,
        ToggleButtonModule,
        DialogModule,
        TableModule,
        DataViewModule,
        PanelModule,
        NgxSkeletonLoaderModule,
        TabViewModule,
        CheckboxModule,
        LeafletModule,
        InputTextModule,
        ButtonModule,
        SpinnerModule,
        TooltipModule,
        NgCircleProgressModule,
        ContextMenuModule,
        OverlayPanelModule,
        RatingModule,
        ReactiveFormsModule,
        GuidedTourModule,
        TreeModule
    ],
    declarations: [
        NgMasonryComponent,
        NgMasonryItem,
        WallpaperComponent,
        SideMenuComponent,
        BackdropComponent,
        LoadingComponent,
        TaskReporterDirective,
        TinyMCEEditorComponent,
        PortalFooterComponent,
        ImageSourceDirective,
        WizardComponent,
        WizardHeaderComponent,
        WizardStepDirective,
        ComponentHostDirective,
        SafeHtmlPipe,
        AnalyticsFilterComponent,
        MultiSelectComponent,
        BrandSelectComponent,
        TimespanSelectComponent,
        GenericArticlesFilterComponent,
        ChartComponent,
        WeekdayFilterComponent,
        FilterDialogComponent,
        GeoLocationUserFavoritesComponent,
        TaLealefMapComponent,
        FeedbackToolComponent,
        ChangePasswordComponent,
        PopupGetFullVersionComponent,
        MultipleDatasourceComponent,
        DatasourceSelectorComponent

    ],
    providers: [GuidedTourService],
    exports: [
        TranslateModule,
        NgMasonryComponent,
        NgMasonryItem,
        WallpaperComponent,
        SideMenuComponent,
        BackdropComponent,
        LoadingComponent,
        TaskReporterDirective,
        TinyMCEEditorComponent,
        PortalFooterComponent,
        ImageSourceDirective,
        WizardComponent,
        WizardStepDirective,
        ComponentHostDirective,
        SafeHtmlPipe,
        AnalyticsFilterComponent,
        ChartComponent,
        ChartModule,
        TreeModule,
        FilterDialogComponent,
        DialogModule,
        GeoLocationUserFavoritesComponent,
        TaLealefMapComponent,
        ButtonModule,
        MultiSelectModule,
        NgCircleProgressModule,
        ContextMenuModule,
        FeedbackToolComponent,
        ChangePasswordComponent,
        PopupGetFullVersionComponent,
        GuidedTourModule,
        MultipleDatasourceComponent,
        DatasourceSelectorComponent
    ]
})
export class SharedModule {
  constructor(private injector: Injector) {
    SharedInjector = this.injector;
  }
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        GrowlService,
        SessionManagerService,
        CommonDataService,
        MessageService,
        UIActionsService,
        ApiService,
        PdfService,
        CircleProgressOptions
      ]
    };
  }
}
export let SharedInjector: Injector;
