export class Features {

  public name: string;

  private constructor(private _id: string, private _defaultPath: string, private dsDependent: boolean) {

  }

  public static getById( id: string ): Features {
    for( const v of Features.ALL ) {
      if( v.id == id ) {
        return v;
      }
    }
    return null;
  }

  public static getNamesByIds( ids: string[] ) {
    const r = [];

    for ( const id of ids ) {
      const f = Features.getById(id);
      if ( f ) {
        r.push(f.defaultPath.indexOf('/') != -1 ?
          f.defaultPath.substring( f.defaultPath.lastIndexOf('/') + 1 ) : f.defaultPath );
      }
    }

    return r;
  }

  public get id(): string {
    return this._id;
  }

  public get defaultPath(): string {
    return this._defaultPath;
  }

  public get datasourceDependend(): boolean {
    return this.dsDependent;
  }

  public static readonly VEHICLE_SEARCHES: Features = new Features('ef324a552', '/analytics/most-searched-vin-numbers', true);
  public static readonly ADMIN: Features = new Features('ef324a555', '/manage/users', false);
  public static readonly RMI_DATA: Features = new Features('f8693a2a', '/analytics/rmi-overview', true);
  public static readonly BASIC_USER_ACTIVITY: Features = new Features('e30ec287', '/analytics/user-stats', true);
  public static readonly PREMIUM_USER_ACTIVITY: Features = new Features('e32ec288', '/analytics/user-stats-premium', false);
  public static readonly MOST_SELECTED_ARTICLES: Features = new Features('15b473db', '/analytics/article-views-brands', true);
  public static readonly MOST_SEARCHED_NUMBERS: Features = new Features('1a759384', '/analytics/most-searched-numbers', true);
  public static readonly FULL_EXPORT: Features = new Features('8fb73262', '/analytics/full-export', true);
  public static readonly ANONYMOUS_BRANDS: Features = new Features('2ab34323', '', false);
  public static readonly ORDER_TRENDS: Features = new Features('1a2b3c4d', '/analytics/generic-article-order-trends', false);
  public static readonly MARKET_OVERVIEW: Features = new Features('1a2b3c4e', '/analytics/market-overview-competition', true);
  public static readonly HOME: Features = new Features('home', '/analytics/', false);//This is just redirection point
  public static readonly SALES: Features = new Features('ba759389', '/analytics/sales-brands', true);
  public static readonly ARTICLE_VISIBILITY: Features = new Features('1a739389', '/analytics/article-visibility-brands', true);
  public static readonly ALL: Features[] = [

    Features.MARKET_OVERVIEW,
    Features.MOST_SELECTED_ARTICLES,
    Features.ORDER_TRENDS,
    Features.MOST_SEARCHED_NUMBERS,
    Features.VEHICLE_SEARCHES,
    Features.FULL_EXPORT,
    Features.RMI_DATA,
    Features.ADMIN,
    Features.BASIC_USER_ACTIVITY,
    Features.PREMIUM_USER_ACTIVITY,
    Features.ANONYMOUS_BRANDS,
    Features.SALES,
    Features.ARTICLE_VISIBILITY
  ];

  public static hasOnlyFreeFeatures( features ) {
    if( features.length == 0 ) {
      return false;
    }
    let freeFeatures = [ Features.BASIC_USER_ACTIVITY.id, Features.PREMIUM_USER_ACTIVITY.id ];
    for( const f of features ) {
      if( freeFeatures.indexOf(f) === -1 ) {
        return false;
      }
    }
    return true;
  }
}
