import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * EnginesPage params:
   * - `mandator`
   */
  // tslint:disable-next-line:max-line-length
  engines: { id: 'engines', name: 'EnginesPage', route: '/:mandator/parts/engines', historyOptions: { resetHistory: true, resetQueryParams: true } },

  /**
   * EnginesPage query params:
   * - `mandator`
   * - `manuId`
   * - `capacity`
   * - `capacityExact`
   * - `engineCode`
   * - `engineCodeExact`
   * - `modelYear`
   * - `power`
   * - `powerExact`
   * - `powerHP`
   * - `sells`
   * - `sellsExact`
   * - `engineNo`
   */
  // tslint:disable-next-line:max-line-length
  enginesSearch: { id: 'engsearch', name: 'EnginesSearchPage', route: '/:mandator/parts/engines/search', historyOptions: { resetHistory: true, resetQueryParams: true }, query: ['manuId', 'capacity', 'capacityExact', 'engineCode', 'engineCodeExact', 'modelYear', 'power', 'powerExact', 'powerHP', 'sells', 'sellsExact', 'engineNo'] },
};
