<div class="row py-1">
  <div class="col-lg-6 col-md-12">
    <div class="card">
      <div class="card-header">
        <span>General Informations</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-5">
                Generic article:
              </div>
              <div class="col-sm-7">
                {{ pageRequest.displayName }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-5">
                Generic article No:
              </div>
              <div class="col-sm-7">
                {{ pageRequest.genartNo }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row py-3 chart">
  <div class="col-lg-3 col-md-12 col-sm-12"
    *ngFor="let chart of charts"
  >
    <chart
      [field]="chart.field"
      [selectable]="true"
      [headLabel]="chart.label"
      [chartType]="'doughnut'"
      [diagrammFixedLabel]="' '"
      [pageInstance]="this"
      [infoText]="createInfoText(chart.info)"
    ></chart>
  </div>
</div>

<hr class="hr-divide" [hidden]="!hasBrandsRecommends && !hasGenartRecommends" />
<div class="card_ " [hidden]="!hasBrandsRecommends && !hasGenartRecommends">
  <div class="card-body">
    <h5 class="card-title">Additional Information for this generic article</h5>
    <p class="card-text">
      Notice: the data shown below is calculated based on all available tracking
      records. Your user specific filter settings (time span, brands, location,
      data source, ...) will not apply here.
    </p>
  </div>
</div>

<br />

<div class="row">
  <div class="col-sm-6" [hidden]="!hasBrandsRecommends">
    <chart
      [field]="BRAND_ASSOC"
      [selectable]="false"
      [diagrammFixedLabel]="' '"
      [headLabel]="
        'Users who selected this generic article have also selected articles from these brands:'
      "
      [pageInstance]="this"
      [enableToogle]="true"
      [makeOthersOnRoundChart]="true"
    ></chart>
  </div>
  <div class="col-sm-6" [hidden]="!hasGenartRecommends">
    <chart
      [field]="GENART_ASSOC"
      [selectable]="false"
      [diagrammFixedLabel]="' '"
      [headLabel]="
        'Users who selected this generic article have also selected articles from these GAs:'
      "
      [pageInstance]="this"
      [enableToogle]="true"
      [makeOthersOnRoundChart]="true"
    ></chart>
  </div>

</div>
