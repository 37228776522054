<h1 class="display-4 hidden-xs-down">
    TecAlliance GmbH</h1>
<hr>
<div class="row">
    <div class="col-md-12">
        <address>
            Steinheilstraße 10
            <br> 85737 Ismaning
            <br> Fon: +49 (0)89-32 12 16-0
            <br> Fax: +49 (0)89-32 12 16-100
            <br>
            <a href="mailto:#">info@tecalliance.net</a>
            <br>
        </address>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <address>
            <strong>Managing Director</strong>
            <br> Jürgen Buchert
            <br>
            <br>The company is registered in the commercial register of the Local Court Munich with the
            <br>HRB-Nr.: 134 509
            <br>USt-IdNr.: DE212306071
        </address>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <address>
            <strong>All rights reserved.</strong>
            <br> Complete copyright for this website by TecAlliance GmbH.
        </address>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <address>
            <strong>Right of use</strong>
            <br> Copyright © by
            <br>TecAlliance GmbH
            <br>Steinheilstr. 10
            <br>85737 Ismaning
        </address>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <address class="text-justify">
            <strong>Exclusion Note</strong>
            <br> Despite of a careful control of the contents, TecAlliance GmbH cannot be held liable for the contents of third
            links. For this, exclusively the operator of the correspondent website is held liable.
            <br>
            <br>TecAlliance GmbH is anxious to present the contents of your web offer always up-to-date, correctly and completely.
            Errors, however, cannot be entirely excluded.
            <br>
            <br>TecAlliance GmbH cannot be held liable for the up-to-dateness, the correct contents and the completeness of the
            information contained in your website, unless errors had been entered deliberately and grossly negligent. This
            rule applies to any material or immaterial damage of third parties that may occur by using the website.
            <br>
        </address>
    </div>
</div>