import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RESTfulService } from '../core/RESTful';
import { StorageService } from '../core/storage';

import { Observable, of } from 'rxjs';

import { AuthTypes } from '../../security/auth-types';
import { Authentication } from '../../security/authentication';
import { AuthResponse } from '../../security/auth-response';
import { UserDetail } from '../../security/user-detail';
import {ContextService, ReleaseNotesService} from '@app/core';
import { NavigationService } from '../miscellany/navigation';

import { CoreModuleConfig } from '../../core-module-config';
import { Version } from '@app/shared/models/version';
import { VersionChange } from '@app/shared/models/version-change';
import { StatusResponse } from './cis/status-response';
import {ReleaseNotes} from '@app/shared/models/release-notes';

/**
 * @file auth-base.js
 * @module app.core
 * @summary Core service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2018
 */
export abstract class AuthBaseService extends RESTfulService implements Authentication {


  private readonly STORAGE_ACCESS = 'MZTEIPFWKVCUBSXLGRJDQYOHAN';
  private readonly STORAGE_REFRESH = 'AMXZCIPHLJOKVFBUQTYRNDGSWE';
  private readonly STORAGE_EXPIRATION = 'XAGKUOTMFVIDESNWRPYZBJHCLQ';
  private readonly STORAGE_USERDETAIL = 'TEHVOKARLQICXUSBGJMZPNDFWY';

  private readonly STORAGE_VERSION = 'DDBVERSION';


  //#endregion

  //#region Authentication Properties
  // fullVersionRequested = false;

  public abstract get type(): AuthTypes;

  private initializedFromCookie = false;

  public get token(): string {
    return this.storage.getCookie(this.storage.getKey(this.STORAGE_ACCESS));
  }

  public get refreshToken(): string {
    return this.storage.getCookie(this.storage.getKey(this.STORAGE_REFRESH));
  }

  public get expirationDate(): Date {
    let storageExpiration = this.storage.getCookie(this.storage.getKey(this.STORAGE_EXPIRATION));
    if (storageExpiration !== null) {
      if (!this._expiration) {
        this._expiration = new Date(storageExpiration);
      }

      return this._expiration;
    }

    return null;
  }

  public get userDetail(): UserDetail {
    let storageUserDetail = this.storage.getCookieObject(this.storage.getKey(this.STORAGE_USERDETAIL));
    if (storageUserDetail) {
      if (!this._userDetail) {
        this._userDetail = this.config.userFactory
          ? this.config.userFactory(storageUserDetail)
          : storageUserDetail as UserDetail;
      }

      return this._userDetail;
    }

    return null;
  }

  public get  isLogged(): boolean {
    const res = (
      this.token !== null &&
      this.token !== '' &&
      typeof this.token !== 'undefined'
    );

    if( res && !this._checkSessionParams.intervalRef ) {
      this.initCheckSession();
    }

    return res;
  }

  //#endregion

  //#region Authentication Emitters

  private _loggedIn: EventEmitter<any> = new EventEmitter();
  public get loggedIn(): EventEmitter<any> {
    return this._loggedIn;
  }

  private _iddledForSeconds: EventEmitter<any> = new EventEmitter();
  public get iddledForSeconds(): EventEmitter<any> {
    return this._iddledForSeconds;
  }

  private _refreshed: EventEmitter<any> = new EventEmitter();
  public get refreshed(): EventEmitter<any> {
    return this._refreshed;
  }

  private _expired: EventEmitter<any> = new EventEmitter();
  public get expired(): EventEmitter<any> {
    return this._expired;
  }

  private _loggingOut: EventEmitter<any> = new EventEmitter();
  public get loggingOut(): EventEmitter<any> {
    return this._loggingOut;
  }

  private _loggedOut: EventEmitter<any> = new EventEmitter();
  public get loggedOut(): EventEmitter<any> {
    return this._loggedOut;
  }



  //#endregion

  //#region Fields

  private _userDetail: UserDetail = null;
  private _expiration: Date = null;

  private _checkSessionParams = {
    // Idel settings (seconds (15 min without activity - idel))
    idelTimeout: 60 * 15,
    idelTime: 0,
    idelClickTime: 0,
    // Interval time settings (seconds)
    interval: 5,
    intervalRef: 0,
    lastRefresh: new Date()
  };

  //#endregion

  //#region Constructor

  constructor(
    protected navigation: NavigationService,
    protected config: CoreModuleConfig,
    protected context: ContextService,
    protected storage: StorageService,
    protected http: HttpClient
  ) {
    super();

    this._checkSessionParams.idelTimeout = this.context.instance.auth.idelTimeout;
    this._checkSessionParams.interval = this.context.instance.auth.checkInterval;

    this.loggedIn.subscribe(this.onLoggedIn);
    this.loggedOut.subscribe(this.onLoggedOut);

    window.onclick = this.resetIdelCounter.bind(this);
    window.onmousemove = this.resetIdelCounter.bind(this);
    window.onkeypress = this.resetIdelCounter.bind(this);
  }

  //#endregion

  //#region Methods

  public initCheckSession() {
    console.log('auth-base initCheckSession');

    window.clearInterval(this._checkSessionParams.intervalRef);

    if (this.context.instance.auth.autoRefresh) {
      this._checkSessionParams.intervalRef = window.setInterval(this.checkSession, this._checkSessionParams.interval * 1000);
    }
  }

  public setUser(user: UserDetail) {
    this._userDetail = user;
    this.updateLocalStorage();
  }

  public abstract login(body: any): Observable<AuthResponse>;

  public forgotPassword(body: any): Observable<StatusResponse>  {
    return of(<StatusResponse>{status: 200});
  };

  public changePassword(body: any): Observable<StatusResponse>  {
    return of(<StatusResponse>{status: 200});
  };

  public abstract refresh(body?: any): Observable<AuthResponse>;

  public logout( params?: any ): void {

    console.log( 'auth-base logout' );

    if (this.token) {
      this._loggingOut.emit();

      this.storage.removeCookie(this.storage.getKey(this.STORAGE_ACCESS));
      this.storage.removeCookie(this.storage.getKey(this.STORAGE_REFRESH));
      this.storage.removeCookie(this.storage.getKey(this.STORAGE_EXPIRATION));
      this.storage.removeCookie(this.storage.getKey(this.STORAGE_USERDETAIL));

      this._userDetail = null;
      this._expiration = null;

      this._loggedOut.emit( params );

      this.onLoggedOut();
    }
  }

  /**
   * Returns the user detail.
   */
  public getUserDetail<T extends UserDetail>(): T {
    return (this.userDetail as T);
  }

  /**
   * Update the local storage with current user instance.
   */
  public updateLocalStorage() {
    if (!this.isLogged) {
      return;
    }

    this.storage.setCookieObject(this.storage.getKey(this.STORAGE_USERDETAIL), this._userDetail, 1);
  }

  //#endregion

  //#region Helpers

  protected setAccessToken(token: string) {
    this.storage.setCookieMinutes(this.storage.getKey(this.STORAGE_ACCESS), token, 5);

    // for test saved only for 30 seconds
    // this.storage.setCookieSeconds(this.storage.getKey(this.STORAGE_ACCESS), token, 30);
  }

  protected setRefreshToken(token: string) {
    this.storage.setCookieMinutes(this.storage.getKey(this.STORAGE_REFRESH), token, 5);
  }

  protected setExpirationDate(expiration: Date) {
    // Reset fields
    this._expiration = null;
    this.storage.setCookie(this.storage.getKey(this.STORAGE_EXPIRATION), expiration.toString(), 1);
  }

  private checkSession = () => {
    this._checkSessionParams.idelTime += this._checkSessionParams.interval;
    this._checkSessionParams.idelClickTime += this._checkSessionParams.interval;

    console.log( 'checkSession idle counter: ' + this._checkSessionParams.idelTime + ' -> ' + this._checkSessionParams.idelTimeout );

    if( this._checkSessionParams.idelTime > 5 ) {
      this._iddledForSeconds.emit( { idle: this._checkSessionParams.idelTime, idleClick: this._checkSessionParams.idelClickTime } );
    }

    if (!this.isLogged) {
      console.error('auth-base checkSession !isLogged #1', this._checkSessionParams.idelTime);
      this.onLoggedOut();
    } else if (this._checkSessionParams.idelTime >= this._checkSessionParams.idelTimeout) {
      console.error('auth-base checkSession Session expired by idel time: %s #2', this._checkSessionParams.idelTime);
      this.logout();
      this.expired.emit();
    } else if (new Date() >= this.expirationDate) {
      // If access token has expired then we try to update with
      // refresh token.

      const now = new Date();
      const dif = now.getTime() - this._checkSessionParams.lastRefresh.getTime();
      const seconds = dif / 1000;
      this._checkSessionParams.lastRefresh = now;

      console.log('Refreshing token: %s - %s', seconds, this.expirationDate.toISOString());

      this.refresh().subscribe(
        (res) => {
          if (res.status !== 200) {
            console.error('checkSession Session expired #3 ' + JSON.stringify(res) );
            this.expired.emit();
            this.onLoggedOut();
          }
        },
        (err: Error) => {
          console.error('checkSession refresh Error Session expired #4 %O', err);
          this.expired.emit();
          this.onLoggedOut();
        }
      );
    }
  }

  private resetIdelCounter = (event) => {
    console.log('auth-base resetIdelCounter ' + event.type);

    this._checkSessionParams.idelTime = 0;

    if( event.type == 'click' ) {
      this._checkSessionParams.idelClickTime = 0;
    }
  }

  private finishCheckSession() {
    window.clearInterval(this._checkSessionParams.intervalRef);
    this._checkSessionParams.intervalRef = null;
    this._checkSessionParams.idelTime = 0;
  }

  //#endregion

  //#region Events Handlers

  private onLoggedIn = () => {

    console.log( "auth-base onLoggedIn" );

    this._checkSessionParams.lastRefresh = new Date();

    if (this.context.instance.auth.homePage) {
      this.navigation.go(this.navigation.pages[this.context.instance.auth.homePage]);
    }

    this.initCheckSession();
  }

  private onLoggedOut = () => {
    if (this.context.instance.auth.loginPage) {
      console.log("navigate to login");
      this.navigation.go(this.navigation.pages[this.context.instance.auth.loginPage]);
    }

    this.finishCheckSession();
  }

}
