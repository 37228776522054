import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  HostListener
} from '@angular/core';

import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

import { MenuService } from '../..';
import { SideMenuItem, UIActionsService } from '../../../shared';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {DomInfo} from '@app/shared/models/dom-info';

@Component({
  //moduleId: module.id,
  selector: 'admin-shell',
  templateUrl: 'admin-shell.html',
  styleUrls: ['admin-shell.scss'],
  animations: [
      trigger('mainaside', [
          state('expanded', style({
            height: '*'
          })),
          state('collapsed', style({
            height: '0'
          })),
          transition('expanded => collapsed', [animate('300ms ease-out')]),
          transition('collapsed => expanded', [animate('300ms ease-out')])
      ])
  ]
})
export class AdminShell implements OnInit, OnDestroy, AfterViewInit {
  //#region Fields

  _menuItems: Array<SideMenuItem>;
  _mobileFlag = (window.innerWidth < 768);
  _state = this._mobileFlag
    ? 'collapsed'
    : 'expanded';

  //#endregion

  //#region Constructor

  constructor(
    private _menu: MenuService,
    private _element: ElementRef,
    private _uiActions: UIActionsService,
    private _router: Router
  ) {
    _uiActions.switchMenu.subscribe(() => {
      this.menuToggle();
    });

    _router.events.subscribe( (event: Event) => {

        if (event instanceof NavigationStart) {
            // Show loading indicator
        }

        if (event instanceof NavigationEnd) {
            // Hide loading indicator
            this.setMenu();
        }

        if (event instanceof NavigationError) {

        }
    });

    window.onmousemove = (event) => { this.onMouseMove(event); }
  }

  setMenu() {
    let site = 'analytics';
    if( this._router.isActive('/manage', false) ) {
      site = 'manage';
    }

    this._menu.getMainMenu( site )
        .subscribe(
        (data: SideMenuItem[]) => {
          this._menuItems = data;
        }, (err: any) => {

        });
  }

  isDatasourcePage():boolean{
    return this._router.url==='/'||this._router.url=='/analytics';
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  ngAfterViewInit() {
    // this._element.nativeElement.style.display = 'block';
  }

  //#endregion

  //#region Helpers

   menuToggle() {
    switch (this._state) {
      case 'collapsed':
        this._state = 'expanded';
        break;
      case 'expanded':
        this._state = 'collapsed';
        break;
    }
  }

  //#endregion

  //#region Events Handlers

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let flag = window.innerWidth < 768;

    if (flag === this._mobileFlag) {
      return;
    }

    this._mobileFlag = flag;
    this._state = this._mobileFlag
      ? 'collapsed'
      : 'expanded';
  }


  //#endregion
  onScroll( event ) {
     DomInfo.scrollTop = event.target.scrollTop;
  }

  onMouseMove(event: MouseEvent) {
    DomInfo.mouseX = event.pageX;
    DomInfo.mouseY = event.pageY;
  }
}
