import { Component, ViewChild, OnInit } from '@angular/core';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { Fields } from '@app/shared/models/fields';
import { ApiService } from '@app/shared';
import { ChartActor } from '@app/shared/components/chart-actor';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { Collections } from '@app/shared/models/collections';
import { FacetItem } from '@app/shared/models/facet-item';
import { InfoTexts } from '@app/shared/models/info-texts';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';
import { ChartComponent } from '@app/shared/components/chart/chart.component';

@Component({
  selector: 'ta-analytics-most-searched-oe-numbers',
  templateUrl: './ta-analytics-most-searched-oe-numbers.component.html',
  styleUrls: ['./ta-analytics-most-searched-oe-numbers.component.scss']
})
export class TaAnalyticsMostSearchedOeNumbersComponent extends AnalyticsPage implements OnInit {

  showTimeLineButton = false;

  public get infoText(): string {
    return InfoTexts.OE_NUMBER_INFO;
  }

  getPageStoreKey(): string {
    return "TaAnalyticsMostSearchedOeNumbersComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( Collections.ARTICLE_SELECTION );
}

  reportLoaded(data: ReportResponse): void {

  }

  @ViewChild('oeNumberChart', {static: true}) oeNumbersChart: ChartActor;
  @ViewChild('oeNumberBrandLessChart', {static: true}) oeNumberBrandLessChart: ChartComponent;

  constructor(appInsights: TrackerService,
    confirm: ConfirmationService,
    public translate: TranslateService,
    public navigation: NavigationService,
    public apiService: ApiService,
    protected router: Router) {
      super(appInsights, confirm, translate, navigation, apiService, router);
  }

  public fieldOeNumber = Fields.OE_NUMBER;
  public fieldOeNumberBrandLess = Fields.OE_NUMBER_BRANDLESS;

  public eoNumberChartLabelMarket = 'Market View';
  public eoNumberChartLabel = 'Your Brand(s)';

  get pageName(): string {
    return 'Most popular oe numbers';
  }

  getPageFacets(): string[] {
    return [ Fields.OE_NUMBER, Fields.OE_NUMBER_BRANDLESS ];
  }

  getChartActors(): ChartActor[] {
    return [ this.oeNumbersChart, this.oeNumberBrandLessChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  itemSelected(item: FacetItem): void {

  }

  ngOnInit(): void {
    super.ngOnInit();
    this.apiService.getProps().subscribe((props: any) => {
      if( props.features.indexOf('1c321dae') != -1 ) {
        this.showTimeLineButton = true;
      }
    });
  }

  protected getFeatureOnMenu() {
    return Features.MOST_SELECTED_ARTICLES;
  }
}
