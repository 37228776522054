import { Injectable } from "@angular/core";
import { GuidedTour } from "ngx-guided-tour";

@Injectable({
  providedIn: 'root'
})
export class DashboardTour {
  public static readonly TOUR_SEEN_KEY = 'tourSeen';
  public tour: GuidedTour;

  public setupTour(path: string) {
    this.setupSkippedSteps(path);
    this.setupStepHandlers(path);
  }

  private setupStepHandlers(path: string) {
    if (this.isPathNeedToBeScrolled(path)) {
      this.tour.steps[4].action = () => scrollToTop();
      this.tour.steps[5].action = () => scrollByUrl(path);
    }
  }

  private isPathNeedToBeScrolled(path): boolean {
    return path == '/analytics/article-views-countries';
  }

  private setupSkippedSteps(path: string) {
    for (const step of this.tour.steps) {
      step.skipStep = false;
    }
    const skipSteps = DashboardTour.TOUR_SKIPPED_STEPS[path];
    for (let i = 0; i < this.tour.steps.length; i++) {
      if (skipSteps && skipSteps.length > 0 && skipSteps.includes(i)) {
        this.tour.steps[i].skipStep = true;
      }
    }
  }

  public static isTourSupported(path): boolean {
    return DashboardTour.TOUR_SUPPORTED_URLS.includes(path);
  }

  private static readonly TOUR_SUPPORTED_URLS = ['/analytics/market-overview-competition',
    '/analytics/market-overview-trends',
    '/analytics/article-views-brands',
    '/analytics/article-views-genarts',
    '/analytics/article-views-article-numbers',
    '/analytics/article-views-oe-numbers',
    '/analytics/article-views-vehicles',
    '/analytics/article-views-countries',
    '/analytics/most-searched-numbers',
    '/analytics/most-searched-vehicles'
  ];

  //Map (url, [skip steps in tour])
  private static readonly TOUR_SKIPPED_STEPS = {
    '/analytics/most-searched-numbers': [3, 4],
    '/analytics/most-searched-vehicles': [3, 4]
  };

  public static removeDrillDownClassFunction = () => removeDrillDownClass();

  public static addDrillDownClassFunction = () => addDrillDownClass();

  public static scrollToTopFunction = () => scrollToTop();
}

function removeDrillDownClass() {
  let tourStepElement = document.getElementsByClassName('tour-step').item(0);
  let removedDrillDown = tourStepElement.getAttribute('class').replace('drilldown', '');
  tourStepElement.setAttribute('class', removedDrillDown);
}

function addDrillDownClass() {
  let tourStepElement = document.getElementsByClassName('tour-step').item(0);
  let addedDrillDown = tourStepElement.getAttribute('class') + ' drilldown';
  tourStepElement.setAttribute('class', addedDrillDown);
}

function scrollToTop() {
  const container: any = document.getElementById('main-container').childNodes[0];
  container.scrollTop = 0;
}

function scrollByUrl($url) {
  if ($url == '/analytics/article-views-countries') {
    //In article-views-countries, the tour-supported chart is under map, so need to scroll to bottom
    const tourChart: any = document.getElementsByClassName('card tour-supported')[0];
    const container: any = document.getElementById('main-container').childNodes[0];
    container.scrollTop = tourChart.offsetTop;
  }
}
