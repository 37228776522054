import { NavPage } from '@app/core';

import { PAGE as VEHICLES_SELECTION } from './vehile-selection';
import { PAGE as ENGINES } from './engines';
import { PAGE as AXLES } from './axles';
import { PAGE as ASSEMBLIES } from './assemblies';
import { PAGE as ARTICLES_OVERVIEW } from './articles-overview';
import { PAGE as ARTICLES_DETAILS } from './article-details';

export const PAGE: {[key: string]: NavPage} = {
  ...VEHICLES_SELECTION,
  ...ENGINES,
  ...AXLES,
  ...ASSEMBLIES,
  ...ARTICLES_OVERVIEW,
  ...ARTICLES_DETAILS
};
