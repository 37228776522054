/**
 * @summary UserDetail class.
 */
export class UserDetail {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  culture: string;
  timeZone: string;
  roles: Array<string>;
  dateFormat: string;  

  constructor() {
  }
}
