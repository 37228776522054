import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FacetItem} from '@app/shared/models/facet-item';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {ChartActor} from '@app/shared/components/chart-actor';
import {Settings} from '@app/shared/models/settings/settings';
import {SelectionComponent} from '@app/shared/models/selection-component';
import {MultiSelect} from 'primeng/multiselect';
import {ConfigMode} from '@app/shared/models/config-mode';
import { ApiService } from '@app/shared';
import { Fields } from '@app/shared/models/fields';
import {OverlayComponent} from '@app/shared/components/multi-select/overlay-component';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent extends ChartActor implements MultiSelectSettings, SelectionComponent {

  selectedIds_ss: string[];
  key_s: string = 'multiselect_';

  @Output()
  public changeListener: EventEmitter<string[]> = new EventEmitter();

  @Input()
  public selectFirstAutomatic: boolean = false;

  private _overlayComponent: OverlayComponent;

  @Input()
  public set overlayComponent( v : OverlayComponent ) {
    this._overlayComponent = v;

    v.getSelectionEventEmmiter().subscribe((selection: FacetItem[]) => {
      this.selectedOptions = selection;
    })
  }

  public get overlayComponent(): OverlayComponent {
    return this._overlayComponent;
  }


  constructor(private _api: ApiService) {
    super();
    this.configMode = ConfigMode.DISPLAY_CONFIRMATION;
  }

  @Input()
  public multiSelect = true;

  @ViewChild('selectInput', {static: true}) selectInput: MultiSelect;

  public showClearButton = false;
  @ViewChild('clear_selection_btn', {static: true}) clear_selection_btn: ElementRef<HTMLDivElement>;

  public chartData: ChartData;
  public options: FacetItem[];
  public varSelectedOptions: FacetItem[] = [];

  public varSelectedOptionsBackup: FacetItem[] = [];

  private enabled = true;

  public setEnabled(v: boolean) {
    this.enabled = v;
    if (v) {
      this.page.getChartParams().setIds(this.fieldName, this.selectedIds_ss);
      this.varSelectedOptions = this.varSelectedOptionsBackup;
    } else {
      this.page.getChartParams().removeParam(this.fieldName);
      this.varSelectedOptions = [];
    }
  }

  setSelected(v: FacetItem[]) {
    this.firstData = true;
    this.configMode = ConfigMode.APPLY_IMMEDIATELY;
    this.selectedOptions = v;
    this.configMode = ConfigMode.DISPLAY_CONFIRMATION;
  }

  setItems(vs: FacetItem[]) {
    if (vs && vs.length) {
      const cd = this.chartData.newChartDataWithItems(vs);
      this.report.facets.set(this.fieldName, cd);
      this.setData(cd, false);
    }
  }

  getSelected(): FacetItem[] {
    return this.selectedOptions;
  }

  public set selectedOptions(v: FacetItem[]) {
    if (!this.multiSelect) { // keep only new
      if (this.varSelectedOptions) {
        for (const f of v) {
          if (this.varSelectedOptions.indexOf(f) == -1) { // found new
            // if new is single or single was selected - select one
            if (f.single || (this.varSelectedOptions && this.varSelectedOptions.length && this.varSelectedOptions[0].single)) {
              this.varSelectedOptionsBackup = this.varSelectedOptions = [f];
              v = [f];
            } else {
              this.varSelectedOptionsBackup = this.varSelectedOptions = v;
            }
            break;
          }
        }
        if (v.length < this.varSelectedOptions.length) {
          this.varSelectedOptions = v;
        } else {
          v = this.varSelectedOptions;
        }
      }
      if (v.length == 0) {
        this.onConfiguringFilter.emit({ mode: this.configMode, field: this.fieldName });
        return;
      }
    }

    this.varSelectedOptionsBackup = v;
    if (this.enabled) {
      this.varSelectedOptions = v;
    }

    if (this.page && this.fieldName) {
      this.selectedIds_ss = this.page.getChartParams().setParams(this.fieldName, v);
      this.optimizeFilters();
      this.changeListener.emit(this.selectedIds_ss);
      this.saveDatasourceIds();

      console.log('ms.loadReport #1');
      this.onConfiguringFilter.emit({ mode: this.configMode, field: this.fieldName });
    }
  };

  protected optimizeFilters() {

  }

  public get selectedOptions(): FacetItem[] {
    return this.varSelectedOptions;
  }

  private firstData: boolean = true;

  private setSelectedByData(d: ChartData): string[] {
    const ids: string[] = [];
    for (const f of d.originalData) {
      if (f.selected) {
        ids.push(f.id);
      }
    }
    return ids;
  }

  private filterIdsByData(d: ChartData, selectedIds_ss: string[]): string[] {
    if (d && d.originalData && d.originalData.length && selectedIds_ss) {
      const r: string[] = [];
      for (const f of d.originalData) {
        for (const id of selectedIds_ss) {
          if (f.id == id) {
            r.push(id);
          }
        }
      }
      if (!r.length && this.selectFirstAutomatic) {

        // if it detects webcat 3.0, it takes this as default datasource
        for (const f of d.originalData) {
          if( f.id === '2' ) {
            r.push(f.id);
            break;
          }
        }

        if (!r.length) {
          for (const f of d.originalData) {
            r.push(f.id);

            if (!this.multiSelect) {
              break;
            }
          }
        }
      }
      return r;
    }
    return selectedIds_ss;
  }

  public setData(d: ChartData, changed: boolean) {

    this.chartData = d;
    let ids = this.chartData.datasets[0].selectedIds;
    const sortedItems: FacetItem[] = this.chartData.sortedFilterItems();

    if (this.firstData) {

      if (this.selectedIds_ss
        && this.selectedIds_ss.length) {
        ids = this.selectedIds_ss;

        if (this.enabled) {
          this.page.getChartParams().setIds(this.fieldName, ids);
        }
      } else {
        if (this.selectFirstAutomatic && this.enabled) {
          this.checkSelected(sortedItems);
          this.page.getChartParams().setIds(this.fieldName, this.setSelectedByData(d));
        }
        this.selectedIds_ss = ids = this.setSelectedByData(d);
      }
    }

    ids = this.filterIdsByData(d, this.selectedIds_ss);
    if (('' + ids) != ('' + this.selectedIds_ss)) {
      this.selectedIds_ss = ids;
      this.page.getChartParams().setIds(this.fieldName, ids);
      this.selectedOptions = this.getSelectedOptions(d.originalData, ids);
      this.saveSettings();
    }

    if (ids && ids.length) {
      this.varSelectedOptionsBackup = this.getSelectedOptions(d.originalData, ids);

      if (this.enabled) {
        this.varSelectedOptions = this.varSelectedOptionsBackup;
      }
    }

    this.options = sortedItems;
    this.originalOptions = sortedItems;

    this.firstData = false;

    this.optimizeFilters();

    if (this.selectFirstAutomatic) {
      this.page.loadReport();
    }

    if( this.overlayComponent ) {
      this.overlayComponent.setData(d, changed);
    }
  }

  public saveSettings() {
    super.saveSettings();
    this.saveDatasourceIds();
  }

  checkSelected(fis: FacetItem[]) {
    let selected = false;
    for (const fa of fis) {
      if (fa.selected) {
        selected = true;
        break;
      }
    }
    if (!selected) {
      for (const fa of fis) {
         // if it detects webcat 3.0, it takes this as default datasource
        if(fa.id === '2') {
          fa.selected = true;
          selected = true;
          break;
        }
      }

      if(!selected ) {
        fis[0].selected = true;
      }
    }
  }

  protected getSettings(): Settings {
    const r = new MultiSelectSettingsImpl();
    this.copySettings(r, this);
    return r;
  }

  private saveDatasourceIds() {
    if( !this.page ) {
      return;
    }

    if (Fields.DATASOURCE == this.fieldName) {
      this._api.setSelectedDatasourceId(this.selectedIds_ss, true);
    }
  }

  protected settingsLoaded(o: Settings): void {
    if (Fields.DATASOURCE == this.fieldName) {
      const selectedDsId = this._api.getSelectedDatasourceId();
      this.selectedIds_ss = selectedDsId ? selectedDsId : this.selectedIds_ss;
    }
    if (this.selectedIds_ss && this.selectedIds_ss.length && this.enabled) {
      //console.log('set ids for ' + this.fieldName, this.selectedIds_ss );
      this.page.getChartParams().setIds(this.fieldName, this.selectedIds_ss);

      this.changeListener.emit(this.selectedIds_ss);
      this.saveDatasourceIds();

      if( this.overlayComponent ) {
        this.overlayComponent.setSelectedIds( this.selectedIds_ss );
      }
    }
  }

  public revertSelection(): void {
    this.loadSettings();
    let selectedItem = this.getSelectedOptions(this.options, this.selectedIds_ss);
    this.selectedOptions = selectedItem;
  }

  originalOptions: FacetItem[];
  readonly optionLabel = 'name';

  onPanelShow() {

    this.deleteInputDefaultOnFilter();
    let inputField = this.selectInput.filterInputChild.nativeElement;

    let inputFieldParent = inputField.parentElement;

    let clearButton = inputFieldParent.parentElement.getElementsByTagName('a')[0];
    clearButton.style.display = 'none';
    clearButton.style.border = 'none';
    inputFieldParent.parentElement.removeChild(clearButton);
    clearButton = clearButton.cloneNode(true);
    inputFieldParent.appendChild(clearButton);
    let clearButtonClickHandler = () => {
      clearButton.style.display = 'none';
      inputField.value = '';
      this.options = this.originalOptions;
      autoScrollToTop();
    };
    clearButton.onclick = clearButtonClickHandler;

    function autoScrollToTop() {
      let elementsByTagName = document.getElementsByTagName('cdk-virtual-scroll-viewport');
      if(!elementsByTagName||elementsByTagName.length==0){
        return;
      }
      elementsByTagName.item(0).scrollTop = 0;
    }

    let inputTextChangedHandler = () => {
      clearButton.style.display =
        inputField.value.trim().length ? 'block' : 'none';
      this.options = this.filterOptions(inputField.value);
      autoScrollToTop();
    };
    inputField.oninput = inputTextChangedHandler;

    if( this.overlayComponent ) {
      var overlay = inputFieldParent.parentElement.parentElement;
      var last;
      while (last = overlay.lastChild) {
        overlay.removeChild(last);
      }
      overlay.append(this.overlayComponent.getRootElement().nativeElement);
      this.overlayComponent.setSelectedIds(this.selectedIds_ss);
    } else {
      inputField.parentElement.append( this.clear_selection_btn.nativeElement );
      this.showClearButton = true;
    }
  }

  private deleteInputDefaultOnFilter() {
    this.selectInput.onFilter = () => {};
  }

  private filterOptions(searchValue: string): FacetItem[] {
    let result1: FacetItem[] = [];
    let result2: FacetItem[] = [];
    let result3: FacetItem[] = [];
    let loweredCaseSearchValue = searchValue.toLowerCase();
    for (const option of this.originalOptions) {
      let optionStr = option[this.optionLabel].toLowerCase();
      if (optionStr.startsWith(loweredCaseSearchValue)) {
        result1.push(option);
      } else if (optionStr.indexOf(loweredCaseSearchValue) != -1) {
        result2.push(option);
      } else {
        let foundAllChars = true;
        for (let i = 0; i < loweredCaseSearchValue.length; i++) {
          if (optionStr.indexOf(loweredCaseSearchValue[i]) == -1) {
            foundAllChars = false;
            break;
          }
        }
        if (foundAllChars) {
          result3.push(option);
        }
      }
    }
    return [...result1, ...result2, ...result3];
  }


  onChange($event: any) {
    console.log('onChange', $event);
  }

  public displayLabel( ){
    if (this.options && (this.options.length === 1 && this.options[0].disabled)) {
      return this.options[0].name;
    }

    return '{0} selected';
  }

}

export class MultiSelectSettingsImpl implements MultiSelectSettings {
  selectedIds_ss: string[] = [];
  key_s: string = '';
}

export interface MultiSelectSettings extends Settings {
  selectedIds_ss: string[];
}
