import { SrsCategoryType } from '@app/admin-shared/enums';

export const SrsCategories = [
  { id: 'D01', type: SrsCategoryType.Article, name: 'Article' },
  { id: 'D02', type: SrsCategoryType.Vehicle, name: 'Vehicle / Bike' },
  { id: 'D03', name: 'Engine' },
  { id: 'D04', type: SrsCategoryType.VehicleLinkage, name: 'Vehicle linkage' },
  { id: 'D05', name: 'Engine linkage' },
  { id: 'D06', name: 'Axle' },
  { id: 'D07', name: 'Axle Linkage' },
  { id: 'D08', type: SrsCategoryType.Vehicle, name: 'Body type' },
  { id: 'D09', type: SrsCategoryType.VehicleLinkage, name: 'Body type Linkage' },
  { id: 'D10', name: 'TecRMI' },
  { id: 'U', type: SrsCategoryType.Unknown, name: 'Unknown error' }
];
