import {Component, ViewChild} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {NavigationService, TrackerService} from '@app/core';
import {ApiService} from '@app/shared';
import {TranslateService} from '@ngx-translate/core';
import {ChartRequest} from '@app/shared/models/chart-request';
import {Fields} from '@app/shared/models/fields';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {EventTypes} from '@app/shared/models/event-types';
import {FacetItem} from '@app/shared/models/facet-item';
import {InfoTexts} from '@app/shared/models/info-texts';
import {Router} from '@angular/router';
import {Features} from '@app/shared/models/features';

@Component({
  selector: 'sales-brands',
  templateUrl: './sales-brands.component.html',
  styleUrls: ['./sales-brands.component.scss']
})
export class SalesBrandsComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.BRANDS_INFO;
  }

  getPageStoreKey(): string {
    return 'SalesBrandsComponent';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(EventTypes.TECDOC_3_REPORT);
  }

  get pageName(): string {
    return 'Sales brands';
  }

  public readonly fieldBrandNo = Fields.BRAND_NO;

  public brandChartHeadLabel = 'Brands';

  @ViewChild('brandChart', {static: true}) brandChart: ChartActor;

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [Fields.BRAND_NO];
  }

  getChartActors(): ChartActor[] {
    return [this.brandChart];
  }

  getReportPath(): string {
    return '/report/order/sales';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem): void {

  }

  protected getFeatureOnMenu(): Features {
    return Features.SALES;
  }
}
