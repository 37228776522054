/**
 * Provides HTTP status codes as enums values.
 * @enum {number}
 * @readonly
 * @public
 */
export enum CisResponseStatusCodes {
  /**
   * Status 200 - OK
   */
  OK = 200,

  /**
   * Status 201 - Order OK, save basket error.
   */
  ORDER_OK_SAVE_BASKET_ERROR = 201,

  /**
   * Status 202 - Order OK, WaWi error.
   */
  ORDER_OK_WAWI_ERROR = 202,

  /**
   * Status 413 - Too many results.
   */
  TOO_MANY_RESULTS = 413,
}
