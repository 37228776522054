import { NavPage } from '@app/core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * RMI VehicleBrandsPage params:
   * - `mandator`
   */
  rmiVehicleBrands: { id: 'rbrands', name: 'VehicleBrandsPage', route: '/:mandator/rmi/brands',  historyOptions: { resetHistory: true } },

  /**
   * RMI VehicleModelsPage params:
   * - `mandator`
   * - `manufacturerId`
   */
  rmiVehicleModels: { id: 'rmodels', name: 'VehicleModelsPage', route: '/:mandator/rmi/:manufacturerId/models' },

  /**
   * RMI VehiclesPage params:
   * - `mandator`
   * - `manufacturerId`
   * - `modelId`
   */
  rmiVehicles: { id: 'rcars', name: 'VehiclesPage', route: '/:mandator/rmi/:manufacturerId/models/:modelId/vehicles' },

  /**
   * RmiManualsPage params:
   * - `mandator`
   * - `targetType`
   * - `targetId`
   *
   * RmiManualsPage query params:
   * - `bodyId`: Allows select main groups
   * - `mainId`: Allows select groups
   * - `groupId`: Show manuals
   * - `subgroupId`
   */
  // tslint:disable-next-line:max-line-length
  rmiManuals: { id: 'rmanuals', name: 'RmiManualsPage', route: '/:mandator/rmi/:targetType/:targetId', query: ['bodyId', 'mainId', 'groupId', 'subgroupId'] }
};
