import {Component, ElementRef, EventEmitter, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {ChartActor} from '@app/shared/components/chart-actor';
import {ChartData} from '@app/shared/models/analytics/chart-data';
import {MenuItem} from 'primeng/api';
import {UIChart} from 'primeng/chart';
import {FacetItem} from '@app/shared/models/facet-item';
import {ReportParams} from '@app/shared/models/i-analytics-page';
import {Settings} from '@app/shared/models/settings/settings';
import {Fields} from '@app/shared/models/fields';
import {AggregationTime, TimeLineRows} from '@app/shared/models/analytics/time-line-rows';
import {TimeLineData} from '@app/shared/models/analytics/time-line-data';
import {WeekdayFilterComponent} from '@app/shared/components/weekday-filter/weekday-filter.component';
import {DownloadStatus} from '@app/shared/models/download-status';
import {DownloadHandler} from '@app/shared/models/download-handler';
import "chartjs-plugin-colorschemes";
import {PdfOptions} from '@app/shared/components/chart/pdf-options';
import {CustomTooltip} from '@app/shared/components/chart/custom-tooltip';
import {CustomAxes} from '@app/shared/components/chart/custom-axes';
import { UtilsService } from '@app/core';
import {draw, generate} from 'patternomaly'

@Component({
  selector: 'chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChartComponent extends ChartActor implements ChartSettings {

  private originalData: ChartData;
  private originalShowTopDropdown = true;
  othersChartData: ChartData;

  timeLineData: TimeLineRows;
  timeLineAllDatasets: TimeLineData[] = [];
  _timeLineSelected: TimeLineData[] = null;
  _timeLineSelectedBackup: TimeLineData[] = [];

  chartData: ChartData;

  displayCount_n: number = 10;
  showPercentageInTooltip: boolean = false;
  key_s: string = 'chart_';
  chartTypeVar: string = 'horizontalBar';
  topOptions: any;

  exportTypes: MenuItem[];

  @Input() yAxesTitle: string='';
  @Input() tourSupported: boolean = true;

  @Input() showCoverageButton: boolean = false;

  public chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    events: ['mousemove','mouseout','wheel'],
    onHover: (event, chartElement) => {
        event.target.style.cursor = 'default';
    },
    onHoverNonSelectable: (event, chartElement) => {
        event.target.style.cursor = 'default';
    },
    onHoverSelectable: ( event, chartElement) => {
        event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    animation: {
        duration: 0,
        onComplete: function($contextChart){},
        onCompleteHandler: function ($contextChart) {
          let chartInstance = this.chart;
          if (!chartInstance) {
            chartInstance = $contextChart;
          }
          let isEmptyData = (chartInstance.data.labels && chartInstance.data.labels.length === 0)
            || (chartInstance.data.allLabels && chartInstance.data.allLabels.length === 0);
          if (chartInstance.data && chartInstance.data.aggregateError != null) {
            showChartMessage(chartInstance, chartInstance.data.aggregateError);
          } else if (isEmptyData) {
            showChartMessage(chartInstance, 'There is no data available for this specific selection.');
          }
        },
        onCompleteHorizBar: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;

            //ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
            // @ts-ignore
            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
            ctx.fillStyle = 'black';
            ctx.textAlign = 'right';
            ctx.textBaseline = 'bottom';


            var xPosAdd = 65;
            var yPosAdd = 8;

            // in case of print the font size is bigger - so the position have to be corrected
            // @ts-ignore
            if( Chart.defaults.global.defaultFontSize > 14 ) {
              xPosAdd = PdfOptions.PERCENT_LABEL_X_POS;
              yPosAdd = PdfOptions.PERCENT_LABEL_Y_POS;
            }

            var posX = chartInstance.chartArea.left + xPosAdd;

            var formatPercent = function( v ) {
              if( !v || v == 'null' || v == 'undefined' ) {
                return '';
              }
              var vs = ('' + v).trim();
              if( vs.indexOf('.') == -1 ) {
                return vs + ',00%';
              } else {
                var p = vs.split('.');
                if( p[1].length == 1 ) {
                  return vs + '0%';
                }
              }
              return UtilsService.simpleNumberFormat(Number(vs)) + '%';
            }

            this.data.datasets.forEach(function (dataset, i) {
              var meta = chartInstance.controller.getDatasetMeta(i);

              meta.data.forEach(function (bar, index) {
                if( dataset.percents ) {
                  var data = dataset.percents[index];
                  if (data) {
                    ctx.fillText(formatPercent(data), posX, bar._model.y + yPosAdd);
                  }
                }
              });
            });
            this.chart.options.animation.onCompleteHandler(this.chart);
        }
    },
    tooltips: {
      enabled: false,
      position: 'average',
      yAlign: 'top', // nearest, default avarage
      mode: 'label',
      itemSort: function(a, b) {
        return b.datasetIndex - a.datasetIndex
      },
      custom: CustomTooltip.vehicleTooltip(this),
      callbacks: {
          enabled: true,
          title: function(tooltipItems, data) {
              return [];
          },
          titleStandard: CustomTooltip.titleStandard,
          titlePercent: CustomTooltip.titlePercent,
          titleDoughnutStandard: CustomTooltip.titleDoughnutStandard,
          titleDoughnutPercent: CustomTooltip.titleDoughnutPercent,
          label: function(tooltipItems, data) {
              //console.log(tooltipItems);

              return tooltipItems.xLabel;
          },
          labelHPercent: function(tooltipItems, data) {
            return tooltipItems.xLabel + '%';
          },
          labelH: function(tooltipItems, data) {
            //console.log(data);

            return tooltipItems.xLabel;
          },
          labelV: function(tooltipItems, data) {
            //console.log(tooltipItems);

            return tooltipItems.yLabel;
          },
          labelNo: function(tooltipItems, data) {
            //console.log(tooltipItems);

            return [];
          }
      }
    },
    hover: {
        animationDuration: 0
    },
    scales: {
        xAxes: [{
            ticks: {
                stepSize: undefined,
                callback: CustomAxes.xAxisValueHandler,
                beginAtZero:true,
                display: true,
                maxTicksLimit: 20
            },
            gridLines: {

            },
            maxBarThickness: 400,
            categorySpacing: 1,
            barValueSpacing: 1,
            barDatasetSpacing: 1,
            barPercentage: 0.7,
            categoryPercentage: 1.0,
            stacked: false
        }],
        yAxes: [{
            scaleLabel: {
              display: false,
              labelString: ''
            },
            ticks: {
                stepSize: undefined,
                callback: CustomAxes.yAxisValueHandler,
                beginAtZero:true,
                display: true,
            },
            gridLines: {

            },
            maxBarThickness: 400,
            categorySpacing: 1,
            barValueSpacing: 1,
            barDatasetSpacing: 1,
            barPercentage: 0.7,
            categoryPercentage: 1.0,
            stacked: false
        }]
    },
    legend: {
      position: 'top',
      display: false
    },
    layout: {
      padding: {
        right: 5
      }
   },
    plugins: {
      colorschemes: {
        custom: null, // function(schemeColors) { return schemeColors; } //,
        scheme: [ '#3D94C2',
                  '#004DBF',
                  '#DE6726',
                  '#B82192',
                  '#911A74',
                  '#3CCC7D',
                  '#268D6C',
                  '#6F38B1',
                  '#D2D90D',
                  '#2780EB',
                  '#EC5454',
                  '#37C1C1',
                  '#5D7EFF',
                  '#01BA49',
                  '#B5A100',
                  '#BC9CFF',
                  '#B42700',
                  '#01A3A4',
                  '#C4004A',
                  '#82D89A',
                  '#FF5784',
                  '#185E3B',
                  '#6D5E00',
                  '#E7C17B',
                  '#F2B2E6',
                  '#FF97AC',
                  '#DD252F',
                  '#00A1D5',
                  '#FABC32',
                  '#FF8800' ]
      }
    }
  };

  @Input()
  showTimeLineButton = false;
  dateRangeLabel: string;

  @Input()
  public set showPercentage(show: boolean) {
    this.showPercentageInTooltip = show;
  }

  public chartLegendHeightAdjustmentPlugin = [{
    beforeInit: function(chart, options) {
      chart.legend.afterFit = function() {
        this.height += 10;
      };
    }
  }];

  private customColorSchema(schemeColors) {

      //this.schemeColors = schemeColors;
      if(!this.timeLineData) {
        return schemeColors;
      }

      const colors = this.timeLineData.getColors( schemeColors );

      return colors;
  }

  private setChartTooltipPosition() {
    this.chartOptions.tooltips.position = this.chartType == 'pie' || this.chartType == 'doughnut' ? 'average' : 'custom';
  }

  private setDisplayLegend() {
    this.chartOptions.legend.display = this.chartType == 'pie' ||
                                        this.chartType == 'doughnut' ||
                                         this.chartType == 'line' ||
                                          this.showAsTimeLine ||
                                           this.showAsSmoothTimeLine ||
                            this.chartOptions.scales.xAxes[0].stacked;
    this.chartOptions.legend.position = (this.chartType == 'pie' || this.chartType == 'doughnut') ? 'right' : 'top';

    if(this.fieldName && this.fieldName.startsWith(Fields.FOUND_BY)) {
       this.chartOptions.legend.position = 'bottom';
    }
  }

  private setAxesMaxTicksLimit() {
    if (this.chartOptions.scales.xAxes[0].stacked && this.fieldName!='countries') {
      this.chartOptions.scales.xAxes[0].ticks.maxTicksLimit = 20;
    } else {
      delete this.chartOptions.scales.xAxes[0].ticks.maxTicksLimit;
    }
  }

  private setAxesTitles() {
    this.chartOptions.scales.yAxes[0].scaleLabel.display = (this.chartType !== 'pie' && this.chartType !== 'doughnut');
    this.chartOptions.scales.yAxes[0].scaleLabel.labelString = this.yAxesTitle;
  }

  private setTooltipHandlers() {
    if (this.showCoverageButton) {
      this.chartOptions.tooltips.custom = CustomTooltip.oeCoverageTooltip(this);
    } else if (this.fieldName && this.fieldName.indexOf(Fields.VEHICLES) != -1) {
      this.chartOptions.tooltips.custom = CustomTooltip.vehicleTooltip(this);
    } else if (this.fieldName.indexOf(Fields.OE_NUMBER) != -1) {
      this.chartOptions.tooltips.custom = CustomTooltip.oeCoverageTooltip(this);
    } else if (this.chartType == 'doughnut') {
      this.chartOptions.tooltips.custom = CustomTooltip.doughnutTooltip(this);
    } else {
      this.chartOptions.tooltips.custom = CustomTooltip.standardTooltip(this);
    }
  }

  private setAxesHandlers() {
    this.chartOptions.scales.yAxes[0].ticks.callback = this.fieldName && this.fieldName.indexOf(Fields.VEHICLES)!=-1 ? CustomAxes.vehicleYAxisValueHandler : CustomAxes.yAxisValueHandler;
  }

  @Input()
  public hasAlreadyPercents = false;

  @Input()
  public set chartType( v: string ) {
      if( !v )  {
        return;
      }

      this.chartTypeVar = v;
      this.initChartOptions();

      if( this.chartComponent ) {
        this.chartComponent.options = this.chartOptions;
      }
  }

  initChartOptions() {

     const v = this.chartTypeVar;

     this.chartOptions.plugins.colorschemes.custom = this.customColorSchema.bind(this);

     this.chartOptions.animation.onComplete = v.indexOf('horiz') != -1
                            ? this.chartOptions.animation.onCompleteHorizBar
                            : this.chartOptions.animation.onCompleteHandler;

     if( v == 'pie' || v == 'doughnut' ) {
       this.chartOptions.tooltips.callbacks.title =
         this.hasAlreadyPercents ?
           this.chartOptions.tooltips.callbacks.titleDoughnutPercent :
           this.chartOptions.tooltips.callbacks.titleDoughnutStandard;
     } else {
       this.chartOptions.tooltips.callbacks.title =
         this.hasAlreadyPercents ?
           this.chartOptions.tooltips.callbacks.titlePercent :
           this.chartOptions.tooltips.callbacks.titleStandard;
     }

     if( v.indexOf('horiz') != -1 || v == 'pie' || v == 'doughnut' ) {
       this.chartOptions.tooltips.callbacks.label = this.hasAlreadyPercents ?
                       this.chartOptions.tooltips.callbacks.labelHPercent :
                        this.chartOptions.tooltips.callbacks.labelH;
     } else if (v == 'line' || v == 'bar') {
       this.chartOptions.tooltips.callbacks.label = this.chartOptions.tooltips.callbacks.labelV;
     }

     // set gridlines
      if (v == 'pie' || v == 'doughnut') {
        this.chartOptions.scales.xAxes[0].gridLines['display'] = false;
        this.chartOptions.scales.yAxes[0].gridLines['display'] = false;
        this.chartOptions.scales.yAxes[0].ticks.display = false;
        this.chartOptions.scales.xAxes[0].ticks.display = false;
        this.showTopDropdown = false;
      } else if (v.indexOf('horiz') != -1||v=='bar') {
        this.chartOptions.scales.xAxes[0].gridLines['color'] = 'rgba(0,0,0,0.1)';
        this.chartOptions.scales.yAxes[0].gridLines['color'] = 'rgba(0, 0, 0, 0.1)';
        this.chartOptions.scales.yAxes[0].ticks.display = true;
        this.chartOptions.scales.xAxes[0].ticks.display = true;
        this.chartOptions.scales.xAxes[0].gridLines['display'] = true;
        this.chartOptions.scales.yAxes[0].gridLines['display'] = true;
      }
      if( this.showAsTimeLine ) {
        this.chartOptions.scales.xAxes[0].ticks.display = false;
        this.chartOptions.scales.xAxes[0].gridLines['color'] = 'rgba(0, 0, 0, 0)';
        this.chartOptions.scales.yAxes[0].gridLines['color'] = 'rgba(0, 0, 0, 0)';
      }
      //Set stacked
      if (v == 'bar') {
        this.chartOptions.scales.xAxes[0].stacked = this.stackedBar;
        this.chartOptions.scales.yAxes[0].stacked = this.stackedBar;
      } else {
        this.chartOptions.scales.xAxes[0].stacked = false;
        this.chartOptions.scales.yAxes[0].stacked = false;
      }

      this.setAxesTitles();
      this.setAxesMaxTicksLimit();
      this.setDisplayLegend();
      this.setChartTooltipPosition();
      this.setTooltipHandlers();
      this.setAxesHandlers();
  }

  private getMaxCount() {
    if (!this.useTimeLineData()) {
      if (this.originalData && this.originalData.originalData && this.originalData.originalData.length) {
        return this.originalData.originalData[0].count;
      }
      return 0;
    }
    let maxCount = 0;
    if (this.timeLineData && this.timeLineData.datasets) {
      for (let td of this.timeLineData.datasets) {
        for (let value of td.data) {
          if (value > maxCount) {
            maxCount = value;
          }
        }
      }
    }

    return maxCount;
  }

  adaptStepSize() {
    const maxCount = this.getMaxCount();
    const v = this.chartTypeVar;
    if (v && v.startsWith('horiz')) {
      this.chartOptions.scales.xAxes[0].ticks.stepSize = maxCount > 0 && maxCount <= 5 ? 1 : undefined;
    } else {
      this.chartOptions.scales.yAxes[0].ticks.stepSize = maxCount > 0 && maxCount <= 5 ? 1 : undefined;
    }
  }

  onSpecialSearch( event ) {
      if( event.keyCode == 13 ) {
        const searchString = event.target.value;

        this.trackUsage('search', searchString);

        this.page.loadReport( <ReportParams>{ specialSearch: true, search: searchString, facetField: this.fieldName } );

      }
  }

  @Input()
  public showAsTimeLine:boolean = false;

  showAsSmoothTimeLine = false;
  chartTypeBackup = null;
  showTimeLine( event ) {
    this.showAsTimeLine = !this.showAsTimeLine;

    this.trackSwitchChartTypeTo(this.showAsTimeLine ? 'line' : this.chartTypeBackup || this.chartType );

    this.setTimeLine( true );
  }
  toggleSmoothTimeLine(event){

    this.trackUsage('timeline_smoothing_button');

    this.showAsSmoothTimeLine = !this.showAsSmoothTimeLine;
    this.setTimeLine(false);
  }

  applyTimeLineConfig() {
    if (this.hasConfigChanged()) {

      this.trackUsage('timeline_weekday_selector');

      this.weekdayActor._lastSelectedWeekdays = this.weekdayActor._selectedWeekdays;
      this.setTimeLine(false);

    }
  }

  hasConfigChanged(): boolean{
    return this.weekdayActor.isSelectionChanged();
  }

  setTimeLine( load: boolean, fromInitChart?: boolean ) {
    if(this.useTimeLineData()) {
      if( !this.chartData.timeLine && load ) {
        this.chartData.timeLine = new TimeLineRows(); // to avoid load again
        this.page.getChartParams().setFacetTimeLined( this.fieldName, true );
        this.page.loadReport( <ReportParams>{ facetField: this.fieldName, notNeedFilterData: true, timeLine: true } );
      } else {
        if(!this.chartTypeBackup) {
          this.chartTypeBackup = this.chartType;
        }
        this.chartType = this.showAsTimeLine ? 'line' : 'bar';
        if( !fromInitChart ) {
          this.initChartDataAndResize();
        }
      }
    } else {
      this.page.getChartParams().setFacetTimeLined( this.fieldName, false );
      this.timeLineData = null;
      this.chartType = this.chartTypeBackup;
      if(!fromInitChart) {
        this.initChartDataAndResize();
      }
    }
  }

  changeAggregationTimeSpan() {
    this.trackUsage( 'aggregation_time_span_change_to_' + AggregationTime.getLabelByValue(this.aggregationTime).toLowerCase() );

    this.calculateTimeLineData();
  }

  moveSingleDatasetToCenter(){
    this.timeLineData.labels = ['', this.timeLineData.labels[0], ''];

    for(let i = 0 ; i < this.timeLineData.datasets.length ; i++) {
      if(this.timeLineData.datasets[i].data.length === 1){
        this.timeLineData.datasets[i].data = [null, this.timeLineData.datasets[i].data[0], null];
      }
    }

  }

  calculateTimeLineData() {

    this.timeLineData = new TimeLineRows(this.chartData.timeLine.originalData);

    this.timeLineAllDatasets = this.timeLineData.datasets;
    this.sortDatasetSelection();
    if (this.showAggregationTimeSpan && this.aggregationTime != AggregationTime.DAILY.value ) {
      this.timeLineData.aggregateTimeline(this.getSelectedIds(),
        this.page.getChartParams(), this.aggregationTime);
    } else {
      if (this.isPiwikField()) {

        if('countries' === this.fieldName) {
          this.timeLineData.sortDescendingBySums( this.getSelectedIds() );
        }

        this.timeLineData.filterDataset(this.fieldName, this.getSelectedIds());

      } else {
        this.timeLineData.applyFilters(this.getSelectedIds(),
          this.page.getChartParams(),
          this.showAsSmoothTimeLine ? 1 : 0);
      }
    }
    if(this.chartType == 'line' && this.timeLineData.labels.length === 1){
      this.moveSingleDatasetToCenter();
    }
    this.adaptStepSize();
    if (this.chartComponent && this.chartComponent.chart) {
      this.chartComponent.chart.update();
    }
  }

  private sortDatasetSelection() {
    if (Fields.BRAND_NO == this.fieldName || Fields.GENART_NO == this.fieldName || Fields.ARTICLE_NUMBER == this.fieldName) {
      UtilsService.sortArrayIgnoreCase(this.timeLineAllDatasets, "labelWithoutId");
    }
  }

  private isPiwikField(): boolean {
    return 'countries' == this.fieldName || 'visits' == this.fieldName || 'pageViews' == this.fieldName;
  }

  private mergeChartDataWithRequestedDataset( responseDatasets: TimeLineRows ) {

    const labelMerge = 'countries' == this.fieldName;

    this.originalData.timeLine.addTimeLines(responseDatasets, labelMerge);

    this.calculateTimeLineData();
  }


  private fillData(from: TimeLineData, to: TimeLineData) {
    to.data = [];
    for (let j = 0; j < from.data.length; j++) {
      to.data.push(from.data[j]);
      to.filledData = true;
    }
  }

  private requestFillTimelineData(notFilledDatasetIds: string[]) {
    this.page.loadReport(<ReportParams>{facetField: this.fieldName,
                                        notNeedFilterData: true,
                                        timeLine: true,
                                        timeLineIds: notFilledDatasetIds,
                                        callbackFunction: ( p: any ) => {
                                          this.mergeChartDataWithRequestedDataset( p );
                                        }});
  }

  private getSelectedIds(): string[] {
    if(!this.timeLineData) {
      return [];
    }

    if (this._timeLineSelected == null) {
      this._timeLineSelected = this.isPiwikField() ? this.timeLineData.datasets.filter((v) => v.filledData) :
          TimeLineRows.getDatasets(3, this.timeLineData.datasets, this._timeLineSelectedBackup);
      this._timeLineSelectedBackup = [];
    }
    this.saveTimeLineDatasetIds();
    return this._timeLineSelected.map(x => x.id);
  }

  set timeLineSelected(v: TimeLineData[]) {
    let unchecked = this._timeLineSelected.length > v.length;
    this._timeLineSelected = v;
    this.timeLineData.datasets = v;
    let notFilledDatasetIds = this._timeLineSelected.filter(dataset => !dataset.filledData).map(x => x.id);
    if (unchecked||notFilledDatasetIds.length==0) {//User unchecked item, no need to request data
      this.calculateTimeLineData();
    } else {
      this.requestFillTimelineData(notFilledDatasetIds);
    }

    this.chartComponent.chart.update();
  }

  private saveTimeLineDatasetIds() {
    let selectedIds = this._timeLineSelected.map(x => x.id);
    this.page.getChartParams().setTimeLineIds(this.fieldName, selectedIds);

    console.log( "F22: specialParams: " + selectedIds );
  }

  get timeLineSelected(): TimeLineData[] {
    return this._timeLineSelected;
  }

  public get chartType() {
      return this.chartTypeVar;
  }

  public get chartTypeGet() {
    return this.chartType;
  }

  @Input()
  public showTopDropdown: boolean = true;

  @Input()
  public enableToogle: boolean = false;

  @Input()
  public enableExport: boolean = true;

  @Input()
  public diagrammFixedLabel: string;

  @Input()
  public showSearch: boolean = false;

  @Input()
  public searchTooltip = '<div><b>E.g.</b> "123" --> Exact match</div>'
    + '<div>123* --> Starts with 123</div>'
    + '<div>*123 --> Ends with 123</div>'
    + '<div>*123* --> Contains 123</div>'
    + '<div>12*3 --> Starts with 12, ends with 3</div>'
    + '<div>1?3 --> 103, 113, 123...</div>';

  @Input()
  public similarSearch: boolean = true;

  private _selectable = false;
  @Input()
  public set selectable( v: boolean ) {
      this._selectable = v;
      if( v ) {
          this.chartOptions.onHover = this.chartOptions.onHoverSelectable;
      } else {
          this.chartOptions.onHover = this.chartOptions.onHoverNonSelectable;
      }
  }

  public get selectable(): boolean {
       return this._selectable;
  }

  public showList(event) {
      this.page.loadReport( <ReportParams>{ facetField: this.fieldName } );
  }

  @Input()
  public makeOthersOnRoundChart = false;

  @Input()
  public makeCompetitorOnRoundChart = false;

  @Input()
  public showVerticalBar = false;

  public toggleChartType() {

      if (this.chartType == 'doughnut') {
        this.chartType = this.showVerticalBar ? 'bar' : 'horizontalBar';
      } else if (this.chartType.toLowerCase().endsWith('bar')) {
        this.chartType = 'doughnut';
      }

      this.trackSwitchChartTypeTo( this.chartType );

     window.setTimeout(() => {
      if( this.chartType == 'doughnut' ) {
      } else {
          this.othersChartData = null;
          this.showTopDropdown = this.originalShowTopDropdown;
      }
      this.initChartDataAndResize();
      this.chartComponent.reinit();
   }, 500);
  }

  DownloadStatusValues = DownloadStatus;
  public readonly downloadHandler = new DownloadHandler(true);

  private initExportMenu() {
    this.exportTypes = [
      { label: 'Save as csv', command: (event) => { this.exportCSV(); } },
      { label: 'Save as PDF', command: (event) =>  { this.generatePDF(); } }
    ];
  }

  public doExport( event, contextMenu ) {
    event.preventDefault();
    event.stopPropagation();

    this.initExportMenu();

    contextMenu.show(event);
    return false;
  }

  exportMethodChoosed = false;
  onExportMenuShow( event ) {
    this.exportMethodChoosed = false;
  }

  onExportMenuHide( event ) {
    if( !this.exportMethodChoosed ) {
      this.trackUsage('export_no_decision');
    }
  }

  private exportCSV() {

    this.exportMethodChoosed = true;

    let exportFieldName = this.useTimeLineData() ? this.fieldName + Fields.DEMAND_TIME_LINE : this.fieldName;
    this.page.loadReport(<ReportParams>{ facetField: exportFieldName, export: true }, this.downloadHandler);

    this.trackUsage( 'export_csv' );
  }

  showPrint = false;
  private generatePDF() {

    this.exportMethodChoosed = true;

    this.trackUsage('export_pdf' );

    this.page.runLoading(3);

    this.showPrint = true;
    this.chartImage.nativeElement.src = this.chartComponent.getBase64Image();

    let fixedWidth = PdfOptions.getWidth(this.chartType, false);

    // get original height and width from chart
    const heightNum = this.chartComponent.getCanvas().clientHeight;
    const widthNum = this.chartComponent.getCanvas().clientWidth;

    // calculate ratio
    const ratio = widthNum / heightNum;
    const fixedHeight = Math.round( fixedWidth / ratio ) * ( this.chartTypeVar == 'horizontalBar' ? 1.5 : 1 );

    // backup height and width
    const heightBackup = this.chartComponent.height;
    const widthBackup = this.chartComponent.width;

    // count items displayed in chart
    const countItems = this.showAsTimeLine ? this.timeLineSelected.length : Math.min( this.chartData.originalData.length,
                                                                                  this.displayCount_n );

    const div = document.getElementById('print_div');

    // add the chart component to that big div
    div.append(this.chartComponent.el.nativeElement);

    this.chartComponent.width = fixedWidth + 'px';
    this.chartComponent.height = fixedHeight + 'px';

    //this.chartOptions.initForPrint(this.chartComponent);
    this.setChartFont(PdfOptions.FONT_SIZE);

    //this.chartOptions.plugins.legend.labels.font.size = 36;

    this.chartComponent.chart.update();

    window.setTimeout(() => {

        const canvasImg = this.chartComponent.getBase64Image();

        // move chart component back to original div
        const chartType = this.showAsTimeLine ? 'line' : this.chartType;

        window.setTimeout(() => {
          this.page.generatePDF(<ReportParams>{facetField: this.fieldName, export: true,
          chartType: chartType, chartLabel: this.headLabel,
          countItems: countItems, infoText: this.infoText,
          chartHeight: fixedHeight,
          chartWidth: fixedWidth }, canvasImg);

          //window.setTimeout(() => {
          this.showPrint = false;

          this.htmlDivElementChart.nativeElement.appendChild(this.chartComponent.el.nativeElement);
          //div.remove();
          //div.style.display = 'none';

          // set chart size back to original size
          this.chartComponent.height = heightBackup;
          this.chartComponent.width = widthBackup;

          //this.chartOptions.initForDisplay(this.chartComponent);
          this.setChartFont();
          this.chartComponent.chart.update();
          //}, PdfOptions.TIMEOUT_MOVE_BACK_CHART);
        }, PdfOptions.TIMEOUT_AFTER_GENERATE_PDF);
    }, PdfOptions.TIMEOUT_BEFORE_TAKE_PICTURE);
  }

  public get selectedTopOptions() {
      return this.displayCount_n;
  }

  @Input()
  public set selectedTopOptions( o: any ) {
      this.displayCount_n = o;
  }

  @Input('headLabel') headLabel: string = '';
  @ViewChild('chartComponent', {static: true}) chartComponent: UIChart;

  @ViewChild('htmlDivElement', {static: true}) htmlDivElement: ElementRef<HTMLDivElement>;
  @ViewChild('htmlDivElementChart', {static: true}) htmlDivElementChart: ElementRef<HTMLDivElement>;
  @ViewChild('chartImage', {static: true}) chartImage: ElementRef<HTMLImageElement>;

  @ViewChild('weekdayActor', {static: true}) weekdayActor: WeekdayFilterComponent;
  ngOnInit() {
    this.weekdayActor.setPage(this.page);

    this.initChartOptions();
  }

  loadInfoText() {
    console.log('loadInfoText');
    const text = this.page.getInfoText(this.fieldName);
    if( text ) {
       this.infoText = text;
    }
  }

  constructor() {
    super();

    this.topOptions = [
        { label: 'Top 10', value: 10 },
        { label: 'Top 20', value: 20 },
        { label: 'Top 30', value: 30 }
    ];

    /*this.exportTypes = [
      { id: 'csv', label: 'Save as csv' },
      { id: 'pdf', label: 'Save as PDF' }
    ];*/

    window.addEventListener('resize', this.setSize.bind(this) );

    this.setChartFont();
    CustomTooltip.initPositioners();
  }

  private setChartFont( size? ) {
    (() => {
      // @ts-ignore
      Chart.defaults.global.defaultFontFamily = "'Roboto', sans-serif";
      // @ts-ignore
      Chart.defaults.global.defaultFontSize = size || 14;
    })();
  }

  // --- ChartActor abstract functions: begin ---
  public setData(d: ChartData, changed: boolean) {
      if( !changed ) {
        //this.originalDisplayCount = this.displayCount_n;
        this.originalData = d;
        this.originalShowTopDropdown = this.showTopDropdown;
        if(d.timeLine) {
          this._timeLineSelectedBackup = this._timeLineSelected;
          this._timeLineSelected = null;
        }
      }
      this.initChartDataAndResize();
      this.adaptStepSize();
  }

  private initChartDataAndResize() {

    const displayCount = this.showTopDropdown ? this.displayCount_n : -1;

    console.log( 'field: ' + this.fieldName + ', displayCount: ' + displayCount );

    this.dateRangeLabel = this.diagrammFixedLabel ? this.diagrammFixedLabel : this.report.dateRange;

    this.chartData = this.originalData.setDisplayCount(displayCount).initChart(this.chartType, '');
    if( this.showCoverage ) {
      this.chartData.showCoverage(true);
    }

    if (this.useTimeLineData()) {
      if (this.chartData.timeLine && this.chartData.timeLine.originalData) {
        this.calculateTimeLineData();
        this.setTimeLine(false, true);
      } else {
        this.timeLineData = null;
      }
    } else
    if(  this.chartType === 'doughnut') {
      if( this.makeCompetitorOnRoundChart ) {
        this.othersChartData = this.chartData.makeCompetitorDoughnut(-1)
          .initChart(this.chartType, this.diagrammFixedLabel ? this.diagrammFixedLabel : this.report.dateRange );
      } else
      if( this.makeOthersOnRoundChart ) {
        this.othersChartData = this.chartData.makeDoughnut(-1, this.hasAlreadyPercents)
          .initChart(this.chartType, this.diagrammFixedLabel ? this.diagrammFixedLabel : this.report.dateRange );
      }
    }

    this.setSize();

    if( this.chartComponent && this.chartComponent.chart ) {
      this.chartComponent.chart.update();
    }
  }

// --- ChartActor abstract functions: end ---

  public changeTop( event ) {

     this.trackUsage( 'view_top_' + event.value );

     this.setTop( event.value );
     this.saveSettings();
  }

  private setTop( n: number ) {
    this.displayCount_n = n;
    this.chartData.setDisplayCount( n );
    this.setSize();
    this.chartComponent.chart.update();
  }

  private setSize( event?: any ) {
    setTimeout(() => {
      this.setChartSize();
    }, 200);
  }

  private setChartSize(): void {
    console.log('chart-component setSize()');
    if (this.isEmptyChartData()) {
      this.chartComponent.height = '160px';
      return;
    }

    if( this.chartType.indexOf('horiz') != -1 ) {
        this.chartOptions.maintainAspectRatio = false;
        if( this.chartComponent && this.chartData ) {
            this.chartComponent.height = (( Math.min( this.displayCount_n, Math.max( this.chartData.originalData.length, 5 ) ) * 35 ) + 35 ) + 'px';
        }
    } else
    if( this.chartType == 'pie' || this.chartType == 'doughnut' ) {
        this.chartOptions.maintainAspectRatio = false;

        if( this.chartComponent ) {
            this.chartComponent.height = this.fitToHeight(this.getChartWidth() + 50) + 'px';
        }

    } else {
        // this will be done for vertical bar chart and line chart
        this.chartOptions.maintainAspectRatio = true;

        if ( this.chartComponent ) {
            this.chartComponent.height = this.fitToHeight( this.getChartWidth() / 2 ) + 'px';
        }
    }
  }

  private getChartWidth() {
      if( this.chartComponent.el.nativeElement.offsetWidth ) {
          return this.chartComponent.el.nativeElement.offsetWidth;
      }

      return this.chartComponent.el.nativeElement.getBoundingClientRect().width;
  }

  private fitToHeight( height ) {
    return Math.max( 300, Math.min( height, window.innerHeight - 350 ) );
  }

  public onSelectBar( event ) {
      let data = this.chartData;
      if(this.othersChartData) {
        data = this.othersChartData;
      }
      const v: FacetItem = <FacetItem>data.originalData[ event.element._index ];

      this.trackUsage('click_on_chart_' + this.fieldName, { Name: v.id } );

      this.page.itemSelected(v, this.fieldName);
  }

  protected getSettings(): Settings {
    const r = new ChartSettingsImpl();
    this.copySettings(r, this);
    return r;
  }

  protected settingsLoaded(o: Settings): void {

  }

  @Input() timeLineDatasetLimit: number = 10;
  @Input() stackedBar: boolean = false;
  getChartCssClass() {
    if (this.chartType == 'pie' || this.chartType == 'doughnut') {
      return 'col-12';
    }
    if (this.showWeekDaySelection()) {
      return 'col-10';
    }
    if(this.showAggregationTimeSpan) {
      return 'col-10';
    }
    return 'col-12';
  }

  @Input() showAggregationTimeSpan: boolean = true;
  readonly aggregationTimeOptions = AggregationTime.OPTIONS;
  aggregationTime = AggregationTime.DAILY.value;

  isEmptyTimeLineData() {
    return !this.timeLineData || this.timeLineData.labels.length === 0;
  }

  isEmptyChartData() {
    if (this.useTimeLineData()) {
      return this.isEmptyTimeLineData();
    } else {
      return !this.chartData || !this.chartData.originalData || this.chartData.originalData.length == 0;
    }
  }

  private useTimeLineData() {
    return this.showAsTimeLine || this.stackedBar;
  }

  hasIncorrectAggregationConfig() {
    return this.timeLineData && this.timeLineData.aggregateError != null;
  }

  showWeekDaySelection() {
    return (this.showAsTimeLine && !this.isPiwikField() && !this.stackedBar && !this.isEmptyTimeLineData() &&
      this.aggregationTime == AggregationTime.DAILY.value);
  }

  // --- tracking functions ---

  trackSwitchChartTypeTo( chartType ) {
    this.trackUsage('switch_chart_type_to_' + chartType );
  }

  private trackUsage( funcName, params? ) {
    try {
      this.page.getTracker().trackEvent('chart_functions', funcName, params);
    } catch( ex ) {
      try {
        console.error(ex);
      } catch( ex2 ) {}
    }
  }

  _showCoverage = false;



  set showCoverage( v ) {
    this._showCoverage = v;

    if( this.chartData ) {
      this.chartData.showCoverage(v);
      this.chartData.updateColors();

      if (this.chartComponent && this.chartComponent.chart) {
        this.chartComponent.chart.update();
      }
    }
  }

  get showCoverage() {
    return this._showCoverage;
  }

  getOECoverageTooltip() {
    return this.chartData ? ( ''
                + this.chartData.oeCoverageCount + ' of ' + this.chartData.getDisplayCount()
                  + ' displayed OE Numbers are covered by your articles' ) :
                'OE Coverage';

  }

  getOECoverageText() {
    return this.chartData ? ( 'OE Coverage ( ' + this.chartData.oeCoverageCount
      + '/' + this.chartData.getDisplayCount() + ' )' ) :
      'OE Coverage';
  }

  getTimeLineSelectedTooltip() {
    if( this.timeLineSelected && this.timeLineSelected.length > 0 ) {
      let r = '';
      for( let i = 0; i < this.timeLineSelected.length && i < 5; i++ ) {
        r += this.timeLineSelected[i].label + '\n';
      }
      if( this.timeLineSelected.length > 5 ) {
        r += '...';
      }
      return r;
    }
    return null;
  }
}

export class ChartSettingsImpl implements ChartSettings {
    displayCount_n: number = 10;
    key_s: string = '';
}

export interface ChartSettings extends Settings {
    displayCount_n: number;
}

function showChartMessage($chartInstance, $message) {
  let ctx = $chartInstance.chart.ctx;
  let width = $chartInstance.chart.width;
  let height = $chartInstance.chart.height;
  $chartInstance.clear();
  ctx.save();
  ctx.fillStyle = "black";
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.font = '20px \'Helvetica\'';
  ctx.fillText($message, width / 2, height / 2);
  ctx.restore();
}
