<analytics-filter #filter></analytics-filter>

<div class="row row-grid">
    <div class="col-12">
        <chart #brandChart [field]="fieldBrandNo" [headLabel]="brandChartHeadLabel"
                [showTimeLineButton]=false
                [enableExport]=false
                [showPercentage]=false
                [infoText]="infoText"></chart>
    </div>
</div>

