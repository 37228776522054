import {
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
    Input,
    ElementRef,
    forwardRef
} from '@angular/core';

import {
    NG_VALUE_ACCESSOR,
    ControlValueAccessor
} from '@angular/forms';

export const TINYMCE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TinyMCEEditorComponent),
  multi: true
};

declare var tinymce: any;

@Component({
    selector: 'tinymce-editor',
    template: `<textarea id="{{ inputId }}"></textarea>`,
    styleUrls:  ['tinymce-editor.scss'],
    providers: [ TINYMCE_VALUE_ACCESSOR ]
})
export class TinyMCEEditorComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges, AfterViewInit {
    //#region Inputs

    @Input()
    public inputId: string;

    @Input()
    public disabled: boolean = false;

    // @Input()
    // @HostBinding('style.width.px') 
    // @HostBinding('style.height.px')
    // public size: number = 160;

    //#endregion

    //#region View Queries
    //#endregion

    //#region Ouputs
    //#endregion

    //#region Fields

    private editor: any;
    private updateUIInterval: any;

    //#endregion

    //#region Constructor

    constructor(
        private _element: ElementRef
    ) {
    }

    //#endregion

    //#region Lifecycle Hooks

    ngOnInit() {

    }

    ngOnDestroy() {
        this.clearUpdateUIInterval();

        if (this.editor) {
            this.editor.off('change');
            this.editor.off('focus');
            tinymce.remove(this.editor);
            this.editor = null;
        }
    }

    ngAfterViewInit() {
        tinymce.init({
            selector: 'textarea#' + this.inputId,
            //target: this.input.nativeElement,
            menubar: false,
            statusbar: false,
            //entity_encoding: 'raw',
            //verify_html: false,
            paste_data_images: true,
            convert_urls: false,
            height : 300,
            plugins: ['link', 'paste', 'table', 'embed_image'],
            toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link table embed_image',
            skin_url: '/assets/skins/lightgray',
            setup: (editor: any) => {
                this.editor = editor;
                this.editor.on('focus', this.onModelTouched);
            },
        });
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    //#endregion

    //#region ControlValueAccessor

    private onModelChange: Function = (_: any) => {};
    private onModelTouched: Function = () => {};

    writeValue(value: any): void {
        this.clearUpdateUIInterval();

        if (this.editor) {
            this.editor.off('change');
            this.editor.setContent(value || '');
            this.editor.on('change', (e: any) => {
                this.onModelChange(this.editor.getContent());
            });
        } else {
            this.updateUIInterval = setInterval(() => {
                if (!this.editor) {
                    return;
                }

                this.clearUpdateUIInterval();

                this.editor.off('change');
                this.editor.setContent(value || '');
                this.editor.on('change', (e: any) => {
                    this.onModelChange(this.editor.getContent());
                });
            }, 300);

            setTimeout(this.clearUpdateUIInterval, 1000);
        }
    }

    registerOnChange(fn: any): void {
        this.onModelChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onModelTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;

        if (this.editor) {
            this.editor.setMode(isDisabled ? 'readonly' : 'design');
        }
    }

    //#endregion

    //#region Helpers

    private clearUpdateUIInterval() {
        if (!this.updateUIInterval) {
            return;
        }

        clearInterval(this.updateUIInterval);
        this.updateUIInterval = null;
    }

    //#endregion
}