import { FacetItem } from "@app/shared/models/facet-item";
import { Fields } from "../fields";
import { Ids } from "../ids";
import { UtilsService } from "@app/core";
import { PercentCalculator } from "../percent-calculator";
import { TimeLineRows } from "./time-line-rows";

export class ChartData {

    private displayCount: number = 10;
    private _showCoverage: boolean = false;

    private allLabels: Array<any>;
    public datasets: Array<Dataset>;

    private labelsCache: Array<any>;

    public labelPrefix: Array<any>;

    public labelSuffix: Array<any>;
    public selected: FacetItem;

    public timeLine: TimeLineRows;

    public label: string;

    constructor( public fieldName: string,
                 public originalData: FacetItem[],
                 public numFound: number,
                 public labelField?: string,
                 public valueField?: string,
                 public setLabelIds: boolean = true,
                 public fromCache: boolean = false) {
        this.initRawName(fromCache);
        if( fieldName.indexOf(Fields.FOUND_BY) != -1 ) {
            labelField = 'label';
            this.setPercentLabels();
        }

        if( setLabelIds ) {
          if (fieldName.indexOf(Fields.GENART_NO) != -1) {
            this.showIdPrefix();
          }

          if (fieldName.indexOf(Fields.BRAND_NO) != -1 ||
            fieldName.indexOf(Fields.COMPETITORS) != -1) {
            this.showIdSuffix();
          }

          if (fieldName.indexOf(Fields.VEHICLES) != -1) {
            this.showVehicleName();
          }
        }

        if( labelField ) {
            if( labelField == '#keys' ) {
                this.allLabels = Object.keys(originalData);
            } else {
                this.allLabels = this.extractFieldValues(labelField, valueField );
            }
        }

        if( valueField ) {
          const ds = new Dataset(this.extractFieldValues(valueField, valueField),
            this.extractFieldValues('id', valueField));

          if (ds.hasFloatValues()) {
              this.setPercentsFromValues();
          } else {
              this.calculatePercents();
          }
          ds.allPercents = this.extractFieldValues('percent', 'percent');
          this.datasets = [ds];
        }

        if(  fieldName == Fields.ARTICLE_NUMBER ) {
            this.labelPrefix = this.extractFieldValues( 'brandName', valueField );
        }

        //this.calculatePercents();
    }

    setPercentLabels() {
      const regex = /^\d+\.0+$/mg;
      for (const f of this.originalData) {
        let percentStr = "" + f.percent;
        if (regex.test(percentStr)) {
          percentStr = percentStr.substring(0, percentStr.indexOf("."));
        }
        f.label = f.rawName + " " + UtilsService.simpleNumberFormat(f.count) + " (" +  UtilsService.simpleNumberFormat(percentStr) + "%)";
      }
    }

    setPercentsFromValues() {
      for( const f of this.originalData ) {
        f.percent = f.count;
      }
    }

    showCoverage( show: boolean ) {
      this._showCoverage = show;

      if( this.datasets && this.datasets.length == 1 ) {

        if( !show ) {
          this.datasets[0].coverage = null;
          return;
        }

        this.datasets[0].coverage = [];
        for (var i = 0; i < this.originalData.length; i++) {
          this.datasets[0].coverage.push( this.isOECovered(i) );
        }
      }
    }

    isOECovered( i ) {
      const dt = this.originalData[i].details;
      return dt && dt.hasOwnProperty('covarts');
    }

    get oeCoverageCount() {
      let r = 0;
      for (var i = 0; i < this.originalData.length && i < this.displayCount; i++) {
        if (this.isOECovered(i)) {
          r++;
        }
      }
      return r;
    }

    calculatePercents() {
      if( this.originalData && this.numFound ) {
        PercentCalculator.calculateWithTotal(this.originalData, this.numFound);
      }
    }

    makeDoughnut(displayCount: number, hasPercentValues?: boolean): ChartData {
        if( this.originalData.length > 10 && !this.checkHaveOthers() ) {
            const facets: FacetItem[] = [];
            let sumVisible = 0;
            for( let i = 0; i < 10; i++ ) {
                facets.push(this.originalData[i]);
                sumVisible += this.originalData[i].count;
            }
            let sumOthers = 0;
            for( let i = 10; i < this.originalData.length; i++ ) {
                sumOthers += this.originalData[i].count;
            }

            const others = <FacetItem>{ id: Ids.OTHERS, name: Ids.OTHERS, label: Ids.OTHERS, count: sumOthers};
            facets.push(others);
            if(hasPercentValues) {
                others.percent = others.count = PercentCalculator.round( 100 - sumVisible );
            } else {
                PercentCalculator.calculate( facets );
            }
            const cd = this.newChartDataWithItems( facets );
            cd.setDisplayCount(displayCount);
            return cd;
        }

        return this;
    }

    newChartDataWithItems( facets: FacetItem[] ) {
        return new ChartData(this.fieldName, facets, this.numFound, this.labelField, this.valueField, this.setLabelIds);
    }

    getOwnBrandsCount() {
        let r = 0;
        for( let i = 0; i < this.originalData.length; i++ ) {
            if( this.originalData[i].id !== '0' ) {
                r++;
            }
        }
        return r;
    }

    isCompetitorDoughnut = false;
    makeCompetitorDoughnut( displayCount: number ): ChartData {
        if(!this.checkHaveOthers()) {
          if( this.originalData.length > 10 ) {
            const facets: FacetItem[] = [];
            const ownBrandsCount = this.getOwnBrandsCount();

            let countOthersAdd = 5; // Math.max( 10 - ownBrandsCount, 3 ); // at minimun 3
            let countOwnAdd = 5;  // - countOthersAdded;

            let countSum = 0;

            let countOthersAdded = 0;
            for( let i = 0; i < this.originalData.length; i++ ) {
                if( this.originalData[i].id === '0' && countOthersAdd > 0 ) {
                    countOthersAdd--;

                    facets.push(this.originalData[i]);
                    countSum += this.originalData[i].count;
                    countOthersAdded++;
                } else if(this.originalData[i].id !== '0' && countOwnAdd > 0 ) {
                    countOwnAdd--;

                    facets.push(this.originalData[i]);
                    countSum += this.originalData[i].count;
                }
            }

            // ChartData.sortByCountDesc(facets);
            let sumOthers = this.numFound - countSum;

            //console.log("numFound: " + this.numFound);

            facets.push(<FacetItem>{ id: Ids.OTHERS, name: Ids.OTHERS, count: sumOthers});
            PercentCalculator.calculate(facets);

            const cd = this.newChartDataWithItems( facets );
            cd.setDisplayCount(displayCount);
            cd.isCompetitorDoughnut = true;
            return cd;
          }
        }

        return this;
    }

    private checkHaveOthers(): boolean {
      if(this.originalData.length) {
        for( let i = 0; i < this.originalData.length; i++ ) {
          if(this.originalData[i].id === Ids.OTHERS) {
            return true;
          }
        }
      }
      return false;
    }

    private initRawName(fromCache: boolean) {
      if (fromCache) {
        return;
      }
      if (!Array.isArray(this.originalData)) {
        return;
      }
      for (const f of this.originalData) {
        f.rawName = f.name;
      }
    }

    private showVehicleName() {
      for (const f of this.originalData) {
        if (f.typeNr > 0) {
          f.name = '';
          let manufacturerName = f.details['manufacturer_name'];
          if (manufacturerName) {
            f.name += manufacturerName;
          }
          let modelNames = f.details['model_name'];
          if (modelNames) {
            f.name += (' ' + modelNames);
          }
          let typeNames = f.details['types_name'];
          if (typeNames) {
            if (typeNames.indexOf('(') != -1) {
              typeNames = typeNames.substring(0, typeNames.indexOf('(')).trim();
            }
            f.name += (' ' + typeNames);
          }
          let tecdocNum = f.id;
          if (tecdocNum) {
            f.name += (' [' + tecdocNum + ']');
          }
        }
      }
    }

    private showIdPrefix() {
      if (!Array.isArray(this.originalData)) {
        return;
      }
        for( const f of this.originalData ) {
            if(!f.name.startsWith("[")) {
                f.name = '[' + f.id + '] ' + f.rawName;
            }
        }
    }

    private showIdSuffix() {
        for( const f of this.originalData ) {
            if( f.name && f.name.indexOf('Brand ') === 0 ) {
                continue;
            }
            if(f.id !== '0' && !f.name.endsWith(']')) {
              if( f.id.startsWith('comp/') ) {
                f.name = f.rawName + ' [' + f.id.substring('comp/'.length) + ']';
              } else {
                f.name = f.rawName + ' [' + f.id + ']';
              }
            }
        }
    }

    public getLabels( keys: any[], getLabelAllIfAllMatched = false ): string[] {
        console.log( 'getLabels( facetField: ' + this.fieldName + ', keys: ' + keys + ' )' );

        const result: string[] = [];

        let string_key = ',' + keys.join(',') + ',';

        const fis: FacetItem[] = this.originalData;

        if( getLabelAllIfAllMatched ) {
            // check all can be matched
            let allmatched = true;
            for( const fi of fis ) {
                if( string_key.indexOf( ',' + fi.id + ',' ) == -1 ) {
                    allmatched = false;
                    break;
                }
            }
            if( allmatched ) {
                return ["all"];
            }
        }

        for( const fi of fis ) {
            if( fi.id.indexOf(',') != -1 ) {
                const idx = string_key.indexOf( fi.id );
                if( idx != -1 ) {
                    result.push( fi.name || fi.label );
                    string_key = string_key.replace(fi.id, '');
                }
            }
        }

        if( result.length ) {
            // if added groups so put the rest as single keys
            const singleKeys = string_key.split(',');
            const newKeys = [];
            for( const key of singleKeys ) {
                if( key.trim().length ) {
                    newKeys.push(key.trim());
                }
            }
            keys = newKeys;
        }

        for( const fi of fis ) {
            if( keys.indexOf( fi.id ) != -1 ) {
                result.push( fi.name || fi.label );
            }
        }
        return result;
    }

    public static rawGaName( name: string ): string {
        let idx = -1;
        if( name.startsWith("[") && ( idx = name.indexOf('] ') ) != -1 ) {
            return name.substring(idx + 2);
        }
        return name;
    }

    public static sortGAs( gas: FacetItem[] ): FacetItem[] {
      gas.sort( (a, b) => {
            let aName = this.rawGaName( a.name.toLowerCase() );
            let bName = this.rawGaName( b.name.toLowerCase() );
            if ( aName < bName )
              return -1;
            if ( aName > bName )
              return 1;
            return 0;
      });
      return gas;
    }

    public getDisplayCount() {
        return this.displayCount;
    }

    public get labels(): Array<any> {
        if( this.labelsCache &&
               ( this.labelsCache.length == this.displayCount ||
                this.labelsCache.length == ( this.allLabels ? this.allLabels.length : 0 ) ) ) {
            return this.labelsCache;
        }

        this.labelsCache = !this.allLabels ? [] :
                    ( this.displayCount < 0 ?
                        this.allLabels
                            : this.allLabels.slice(0, this.displayCount) );

        return this.labelsCache;
    }

    /**
      *  please don't set labels here / keep it empty,
      *  because if you assign to allLabels, it cause errors
     *   in selection Top 10,20,30 afterwards,
     *   please use setLabels if you need it :-)
      * @param v
      */
    public set labels( v: Array<any> ) {

    }

    public setLabels( v: Array<any> ) {
      this.allLabels = v;
      this.labelsCache = v;
    }

    private extractFieldValues( fieldName: string, valueField: string, prefix = '', suffix: string = '' ): Array<any> {
        let r = [];
        for (const key in this.originalData) {
          if (this.originalData[key][valueField] > 0 || this.fieldName == Fields.BRAND_NO || (fieldName === 'percent' && valueField === 'percent')) {
            r.push(prefix + this.originalData[key][fieldName] + suffix);
          }
        }
        return r;
    }

    public initDataset( label: string,
                        backgroudColors: string[],
                        borderColor: string,
                        index?: number ): ChartData {

        index = index || 0;
        if( this.datasets && this.datasets.length > index ) {
            const ds: Dataset = this.datasets[index];
            ds.label = label;
            ds.backgroundColorVar = backgroudColors[0];
            if( backgroudColors.length > 1 ) {
                ds.backgroundColors = backgroudColors;
            }
            ds.backgroundColor = ds.getBgColor();
            ds.borderColorVar = borderColor;
        }

        return this;
    }

    public initChart( type: string, label: string ): ChartData {
        if(type == 'line') {
            this.initLineChart( label, type );
        } else
        if( type == 'doughnut' || type == 'pie') {
            this.initRoundChart( label, type );
        } else {
            this.initBarChart( label, type );
        }
        return this;
    }

    static readonly COLORS = ['#3D94C2',
                  '#004DBF',
                  '#DE6726',
                  '#B82192',
                  '#911A74',
                  '#3CCC7D',
                  '#268D6C',
                  '#6F38B1',
                  '#D2D90D',
                  '#2780EB',
                  '#EC5454',
                  '#37C1C1',
                  '#5D7EFF',
                  '#01BA49',
                  '#B5A100',
                  '#BC9CFF',
                  '#B42700',
                  '#01A3A4',
                  '#C4004A',
                  '#82D89A',
                  '#FF5784',
                  '#185E3B',
                  '#6D5E00',
                  '#E7C17B',
                  '#F2B2E6',
                  '#FF97AC',
                  '#DD252F',
                  '#00A1D5',
                  '#FABC32',
                  '#FF8800'];

    private initRoundChart( label: string, chartType: string ): ChartData {
        let colors = [].concat( ChartData.COLORS );
        if( this.selected ) {
            const newColors = [];
            for( let i = 0; i < this.originalData.length; i++ ) {
                newColors.push( this.originalData[i] == this.selected
                                ? colors[i % colors.length]
                                : '#EEE' );
            }
            colors = newColors;
        }

        this.datasets[0].chartType = chartType;
        this.initDataset( label, colors, '#FFF' );

        return this;
    }

    public initLineChart( label: string, chartType: string ): ChartData {
        this.datasets[0].chartType = chartType;
        this.initDataset( label, [ 'rgba(255, 255, 255, .5)', '#3D94C2' ], '#004DBF' );
        return this;
    }

    public initBarChart( label: string, chartType: string ): ChartData {
        // console.log('initBarChart( ' + label + ', ' + chartType + ' )');
        this.datasets[0].chartType = chartType;
        this.initDataset( label, [ '#3D94C2', '#3D94C2' ], '#004DBF' );
        return this;
    }

    public setDisplayCount( v: number ): ChartData {
        if( this.displayCount != v ) {
            this.labelsCache = null;
        }

        this.displayCount = v;
        if( this.datasets ) {
            for( let i = 0; i < this.datasets.length; i++ ) {
                this.datasets[i].displayCount = v;
            }

            this.updateColors();
        }
        return this;
    }

    public updateColors() {
      if( this.datasets ) {
          for( let i = 0; i < this.datasets.length; i++ ) {
              this.datasets[i].backgroundColor = this.datasets[i].getBgColor();
          }
      }
    }

    public setSelectedIds( ids: string[], datasetIndex: number = 0 ) {
        if( ids && this.datasets && this.datasets.length > datasetIndex ) {
            this.datasets[datasetIndex].selectedIds = ids;
        }
    }

    public getSelectedIds( datasetIndex: number = 0 ) {
        if( this.datasets && this.datasets.length > datasetIndex ) {
            return this.datasets[datasetIndex].selectedIds;
        }
    }

    public static exportAsCsv( facets: FacetItem[], fileName: string, fields: Array<string>, header?: Array<string> ) {

        if( !header ) {
            header = fields;
        }

        const rows = [];
        rows.push(header);
        for( const f of facets ) {
            const row = [];
            for( const field of fields ) {
                if( field == 'percent' ) {
                    row.push(f[field] + ' %');
                } else {
                    if(f[field]) {
                        row.push(f[field]);
                    } else
                    if(f.details && f.details[field]) {
                        row.push(f.details[field]);
                    }
                }
            }
            rows.push(row);
        }

        UtilsService.exportToCsv( fileName, rows );
    }

    public static sortByCountDesc( fis: FacetItem[] ): void {
        fis.sort((a, b) => {
            const r = b.count - a.count;
            if(r == 0) {
                return a.label.localeCompare(b.label);
            }
            return r;
        });
    }

    public static sortByPercentDesc( fis: FacetItem[] ): void {
        fis.sort((a, b) => {
            const r = b.percent - a.percent;
            if(r == 0) {
                return a.label.localeCompare(b.label);
            }
            return r;
        });
    }

    public static createMap( fis: FacetItem[] ): Map<string, FacetItem> {
        const r: Map<string, FacetItem> = new Map();
        for( const f of fis ) {
            r.set(f.id.trim(), f);
        }
        return r;
    }

    public static findById( vs: FacetItem[], id: string ): FacetItem {
        for(const fa of vs ) {
          if( fa.id === id ) {
            return fa;
          }
        }
        return null;
    }

    public static removeMarked( oldList: FacetItem[], markedList: FacetItem[] ): FacetItem[] {
        const markedMap: Map<string, FacetItem> = this.createMap(markedList);

        //iterate through oldList - if not in newList it is removed
        // all what is not in new list will not be added = removed
        for(let i = oldList.length - 1; i >= 0; i--) {
            const f = oldList[i];
            if(markedMap.has(f.id)
                && markedMap.get(f.id).remove) {
                oldList.splice(i, 1);
            }
        }

        return oldList;
    }

    public static addNew( oldList: FacetItem[], newList: FacetItem[] ): FacetItem[] {
        const oldMap: Map<string, FacetItem> = this.createMap(oldList);
         //iterate through newList - if not found in oldList it is new
         for(const f of newList) {
            if(!f.remove && !oldMap.has(f.id)) {
                oldList.push(f);
            }
        }
        return oldList;
    }

    sortedFilterItems(): FacetItem[] {
        return ChartData.sortFilterItems(this.originalData);
    }

    public static sortFilterItems( fs: FacetItem[] ): FacetItem[] {
        return ( [].concat( fs ) ).sort((a, b) => {
            const a_ = a.name.toLowerCase();
            const b_ = b.name.toLowerCase();

            const aId_: string = a.id;
            const bId_: string = b.id;

            const hasAtA = aId_.startsWith("@");
            const hasAtB = bId_.startsWith("@");

            if( !hasAtA && hasAtB ) {
              return 1;
            } else
            if( hasAtA && !hasAtB ) {
              return -1;
            }

            const hasComaA = aId_.indexOf(",") != -1;
            const hasComaB = bId_.indexOf(",") != -1;

            if( !hasComaA && hasComaB ) {
              return 1;
            } else
            if( hasComaA && !hasComaB ) {
              return -1;
            }

            if ( a_ < b_ )
              return -1;
            if ( a_ > b_ )
              return 1;
            return 0;
      });
    }
}

export class Dataset {

    public chartType = 'bar';

    public backgroundColorVar: string;
    public backgroundColors: string[];
    public backgroundColorSelected: string = '#004DBF';
    public borderColorVar: string;
    public borderColorSelected: string = '#DD252F';

    public selectedIds: string[] = [];

    private othersIndex: number;

    public allPercents: Array<any> = [];

    public coverage: boolean[];

    constructor(public allData: Array<any>, private ids: string[], public displayCount: number = 10) {
        this.othersIndex = ids.indexOf(Ids.OTHERS);
    }

    public hasFloatValues(): boolean {
      for(const v of this.allData) {
        if( ('' + v).indexOf('.') != -1 ) {
          return true;
        }
      }
      return false;
    }

    private isSelected( index: number ): boolean {
        return false; // TODO: uncommect if needed - index < this.ids.length && this.selectedIds.indexOf( this.ids[index] ) !== -1;
    }

    private isOthers( index: number ) {
        return index == this.othersIndex;
    }

    public get data(): Array<any> {
        return !this.allData ? [] :
                    ( this.displayCount < 0 ?
                        this.allData :
                            this.allData.slice(0, this.displayCount) );
    }

    public get percents(): Array<any> {
        return !this.allPercents ? [] :
                    ( this.displayCount < 0 ?
                        this.allPercents :
                            this.allPercents.slice(0, this.displayCount) );
    }

    public label: string;

    /*public get backgroundColor(): any {
        return this.getBgColor();
    }

    public set backgroundColor( v: any ) {

    }*/

    backgroundColor: any;

    public getBgColor() : any {
        const isRoundChart = this.chartType == 'pie' || this.chartType == 'doughnut';
        const isBarChart = this.chartType == 'bar' || this.chartType == 'horizontalBar';
        const count = this.data.length;
        const r: string[] = [];

        if( this.chartType.indexOf('bar') != -1 ||
              isRoundChart ) {
            let coloredIndex = 0;
            for(let i = 0; i < count; i++ ) {
              if((isRoundChart && this.isOthers(i)) ) {
                r.push( "#ACBAC2" );
              } else if(this.ids[i] === '0') { //all unbranded brands have the same color
                r.push( "#717A80" );
              } else if(this.backgroundColors) {
                if (isRoundChart)
                  r.push( this.isSelected(i) ? this.backgroundColorSelected : this.backgroundColors[ coloredIndex++ ] );
                else
                  r.push( this.isSelected(i) ? this.backgroundColorSelected : this.backgroundColors[ i % this.backgroundColors.length ] );
              } else {
                r.push( this.isSelected(i) ? this.backgroundColorSelected : this.backgroundColorVar);
              }
            }
            //console.log("backgroundColors #1: " + r);
            return r;
        } else
        if( isBarChart ) {
          for(let i = 0; i < count; i++ ) {
            if( this.isOthers(i) ) {
              r.push( "#ACBAC2" );
            } else if(this.ids[i] === '0') {//all unbranded brands have the same color
              r.push( "#717A80" );
            } else if(this.ids[i].startsWith('comp/')) {
              r.push('#37C1C1');
            } else if( this.coverage && this.coverage[i] ) {
              r.push("#268D6C");
            } else if(this.backgroundColors) {
              r.push( this.isSelected(i) ? this.backgroundColorSelected : this.backgroundColors[ i % this.backgroundColors.length ] );
            } else {
              r.push( this.isSelected(i) ? this.backgroundColorSelected : this.backgroundColorVar);
            }
          }
          //console.log("backgroundColors #2: " + r);
          return r;
        }
        //console.log("backgroundColors (var): " + this.backgroundColorVar);
        return this.backgroundColorVar;
    }
    public get borderColor(): any {
        /*
         don't see at the moment the changes on border
        if( this.isBarChart ) {
            const r: string[] = [];
            const count = this.data.length;
            for(let i = 0; i < count; i++ ) {
                r.push( this.isSelected(i) ? this.borderColorSelected : this.borderColorVar);
            }
            return r;
        }*/
        return this.borderColorVar;
    }
}
