
<div class="login-form">
  <div class="inner-login-form">
    <form [formGroup]="_form">
      <fieldset>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <h3 class="text-left text-secondary">{{formTitle}}</h3>
            </div>
          </div>
          <!-- Error message -->
          <div class="row" *ngIf="loginError" id="error-message">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <p class="badge badge-danger text-wrap" style="text-align: left; font-weight: normal; line-height: 18px">{{errorMessage}}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="text" id="userName" maxlength="50" class="required form-control" placeholder="Username or email address" formControlName="userNameOrEmail"
                    pTooltip [pTooltip]="_formErrors.userNameOrEmail" tooltipPosition="top" tooltipToValidateInput="true" (keyup)="saveUserNameOrEmail($event)"
                    [readOnly]="tempCodeSended">
              </div>
            </div>
          </div>
          <div class="row" [hidden]="!tempCodeSended || isPasswordChange">
            <div class="col-lg-12 col-md-12 col-xs-12" title="Email ID">
              &nbsp;&nbsp;#{{email_uuid}}
            </div>
          </div>
          <div class="row" [hidden]="!tempCodeSended || isPasswordChange">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input type="text" id="tempCode" maxlength="25" class="required form-control" placeholder="Enter token (in Email ID: see above)" formControlName="tempCode"
                    pTooltip [pTooltip]="_formErrors.tempCode" tooltipPosition="top" tooltipToValidateInput="true" >
              </div>
            </div>
          </div>
          <div class="row" [hidden]="!tempCodeSended">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input onfocus="this.type = 'password';" type="text" id="newPassword" maxlength="25" class="required form-control"
                      placeholder="Enter password" formControlName="newPassword"
                       pTooltip [pTooltip]="_formErrors.newPassword" tooltipPosition="top" tooltipToValidateInput="true"
                       autocomplete="new-password">
              </div>
            </div>
          </div>
          <div class="row" [hidden]="!tempCodeSended">
            <div class="col-lg-12 col-md-12 col-xs-12">
              <div class="form-group">
                <input onfocus="this.type = 'password';" type="text" id="newPasswordRepeat" maxlength="25" class="required form-control"
                      placeholder="Repeat password" formControlName="newPasswordRepeat"
                       pTooltip [pTooltip]="_formErrors.newPasswordRepeat" tooltipPosition="top" tooltipToValidateInput="true"
                       autocomplete="new-password">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-xs-12">

            </div>
            <div class="col-lg-3 col-md-3 col-xs-3">
              <button [hidden]="isPasswordChange" class="btn btn-primary" (click)="resetState()"><</button>
            </div>
            <div class="col-lg-9 col-md-9 col-xs-9">
              <button [disabled]="countdownWaitTimerHandle" class="btn btn-block btn-primary" (click)="onResetPassword()">{{buttonText}}</button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</div>
