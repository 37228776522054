import { ChartData } from "@app/shared/models/analytics/chart-data";
import { Brand } from "../brand";
import { FacetItem } from "../facet-item";
import { Fields } from "../fields";
import {DatePipe} from '@angular/common';
import {UtilsService} from '@app/core';
import {BasicResponse} from '@app/shared/models/basic-response';
import { PageName } from "../page-name";

export class ReportResponse implements BasicResponse {
    public pageLoad: boolean;
    public statusCode?: number;
    public message?: string;

    public dateFormat: string;
    public fromDate: string;
    public toDate: string;

    public responseData: any;
    public token: string;

    public numFound: Map<string, number>;

    public get dateRange(): string {
      let fd = this.fromDate;
      let td = this.toDate;
      if (fd && fd.length > 10) {
        fd = fd.substring(0, 10);
      }
      if (td && td.length > 10) {
        td = td.substring(0, 10);
      }
      if (fd === td) {
        return UtilsService.formatDate(fd);
      }

      return UtilsService.formatDate(fd) + ' - ' + UtilsService.formatDate(td);
    }

    public facets: Map<string, ChartData> = new Map();

    public addBrandsSelection( brands: Brand[] ): ChartData {

        const brandFacets: FacetItem[] = [];
        for( const b of brands ) {
            brandFacets.push(<FacetItem> {
                id: '' + b.brandId,
                name: b.brandName,
                count: 1
            });
        }

        const cd = new ChartData( Fields.BRAND_NO_FILTER, brandFacets, brandFacets.length, 'name', 'count');

        this.facets.set( Fields.BRAND_NO_FILTER, cd);

        return cd;
    }

    private convertMap( map: any ): FacetItem[] {
        const r: FacetItem[] = [];
        for( const id in map ) {
          let fa = <FacetItem> {
            id: '' + id,
            name: map[id],
            count: 1
        };
        r.push(fa);
        }
        return r;
    }

    getLabels(key: any, keys: string[]): string[] {
        if(!this.facets.has(key)) {
            return keys;
        }
        return this.facets.get(key).getLabels(keys);
    }

    public addGenericArticleSelection( gas: any ): ChartData {
        const facets: FacetItem[] = this.convertMap(gas);

        const cd = new ChartData( Fields.GENART_NO_FILTER,
            facets, facets.length, 'name', 'count');

        this.facets.set( Fields.GENART_NO_FILTER, cd );

        return cd;
    }

    public addCountriesSelection( countries: any, favorites: FacetItem[] ): ChartData {
        const facets: FacetItem[] = favorites.concat( this.convertMap(countries) );

        const cd = new ChartData( Fields.LOCATION_COUNTRY_FILTER,
                        facets, facets.length, 'name', 'count');

        this.facets.set( Fields.LOCATION_COUNTRY_FILTER, cd);

        return cd;
    }

    public addSearchTypeIdSelection(searchTypeIds: any): ChartData {
        const facets: FacetItem[] = this.convertMap(searchTypeIds);

        const cd = new ChartData( Fields.SEARCH_TYPE_ID_FILTER,
            facets, facets.length, 'name', 'count');

        this.facets.set( Fields.SEARCH_TYPE_ID_FILTER, cd );

        return cd;
    }

    public addSearchTypeIdOESelection(searchTypeIds: any): ChartData {
      const facets: FacetItem[] =[
        <FacetItem> {
          id: 'a1',
          name: searchTypeIds['a1'] ,
          count: 1,
          disabled: true,
          selected: true
      }
      ];

      const cd = new ChartData( Fields.SEARCH_TYPE_ID_OE_FILTER,
          facets, facets.length, 'name', 'count');

      this.facets.set( Fields.SEARCH_TYPE_ID_OE_FILTER, cd );

      return cd;
  }

    public addNumberPlateTypeSelection(searchTypeIds: any): ChartData {
        const facets: FacetItem[] = this.convertMap(searchTypeIds);

        const cd = new ChartData( Fields.NUMBER_PLATE_TYPE_FILTER,
            facets, facets.length, 'name', 'count');

        this.facets.set( Fields.NUMBER_PLATE_TYPE_FILTER, cd );

        return cd;
    }

    public addGenericArticleFavorites( favs: FacetItem[] ): ChartData {
        const cd = new ChartData( Fields.GENART_NO_FAVS, favs, favs.length, 'name', 'count');

        this.facets.set( Fields.GENART_NO_FAVS, cd );

        return cd;
    }

    public addVehiclesTypes( vehicleTypes: any ): ChartData {
        const facets: FacetItem[] = this.convertMap(vehicleTypes);

        const cd = new ChartData( Fields.VEHICLE_TYPES_FILTER,
                            facets, facets.length, 'name', 'count');

        this.facets.set( Fields.VEHICLE_TYPES_FILTER, cd);

        return cd;
    }

    public addAssociations( field: string, v: FacetItem[] ): ChartData {

        const cd = new ChartData( field, v, v.length, 'label', 'count');

        this.facets.set( field, cd );

        return cd;
    }

    public mergeFacets( data: ReportResponse ) {
        data.facets.forEach((value, key) => {
            this.facets.set(key, data.facets.get(key));
        });
    }

    public hasFacets( field ) {
      return this.facets &&
              this.facets.has(field) &&
               this.facets.get(field).originalData &&
                this.facets.get(field).originalData.length > 0;
    }

}
