import { NgModule } from '@angular/core';

import { AdminSharedModule }      from '../admin-shared/admin-shared.module';

import { AdminRoutingModule }     from './admin.routing.module';

import { TecAllianceAnalyticsModule } from '../tecalliance-analytics/tecalliance-analytics.module';

import {TabMenuModule} from 'primeng/tabmenu';
import {DropdownModule} from 'primeng/dropdown';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {MenuModule} from 'primeng/menu';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {MessageModule} from 'primeng/message';

import {
  LogoutPage,
  ImprintPage,
  NotFoundPage,
  PrivacyPolicyPage,
  LicencesPage
} from '.';
import { BrandFiltersComponent } from './pages/brand-filters/brand-filters.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ListboxModule } from 'primeng/listbox';
import { EditorModule } from 'primeng/editor';
import { SpinnerModule } from 'primeng/spinner';
import { ToggleButtonModule } from 'primeng/togglebutton';
import {ReleaseNotesComponent} from './pages/release-notes/release-notes.component';

@NgModule({
    imports: [
        AdminSharedModule.forRoot(),
        TecAllianceAnalyticsModule,
        // Rest of routes
        AdminRoutingModule,
        TabMenuModule,
        DropdownModule,
        InputTextModule,
        ButtonModule,
        MenuModule,
        ConfirmDialogModule,
        DataViewModule,
        DialogModule,
        MessageModule,
        MultiSelectModule,
        ListboxModule,
        AutoCompleteModule,
        EditorModule,
        SpinnerModule,
        ToggleButtonModule
    ],
    declarations: [
        LogoutPage,
        ImprintPage,
        LicencesPage,
        NotFoundPage,
        PrivacyPolicyPage,
        BrandFiltersComponent,
        UserListComponent,
        ReleaseNotesComponent
    ],
    providers: []
})
export class AdminModule { }
