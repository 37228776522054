import { NgModule, ModuleWithProviders, Optional, SkipSelf, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTypes } from './security/auth-types';

import { CoreModuleConfig } from './core-module-config';
import * as services from './services';
import * as interceptors from './interceptors';
import {CachingInterceptor} from '@app/core/interceptors/cache';

const servicesList = [
  services.ContextService,
  services.AuthService,
  services.CisAuthService,
  services.JWTAuthService,
  services.NavigationService,
  services.OAuth2Service,
  services.StorageService,
  services.TaskTrackerService,
  services.TrackerService,
  services.UtilsService,
  services.RequestCacheService,
  services.ReleaseNotesService
];

/**
 * CoreModule defines common features shared.
 */
@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ]
})
export class CoreModule {
  static forRoot(options: CoreModuleConfig = {
    authType: AuthTypes.Oauth2,
    contextFactory: null,
    userFactory: null
  } ): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...servicesList,
        {
          provide: CoreModuleConfig,
          useValue: options
        },

        // Interceptors
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptors.ApiURLInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptors.HeadersInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: interceptors.AuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CachingInterceptor,
          multi: true
        },
        // APP_INITIALIZER
        {
          provide: APP_INITIALIZER,
          useFactory: CoreInitializer,
          deps: [services.ContextService],
          multi: true
        },
      ]
    };
  }

  constructor (
    @Optional() @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}

export function CoreInitializer(context: services.ContextService): () => Promise<any> {
  return async () => context.load();
}
