<ul class="nav navbar-nav flex-column">
  <li class="nav-item" [ngClass]="{ 'active' : item.state == 'expanded' }" [hidden]="item.hidden"
    *ngFor="let item of items; trackBy: trackByFn">
    <a class="nav-link" routerLink="{{ item.nodes && item.nodes.length > 0 ? item.nodes[0].link : '' }}"
      (click)="onToggleExpanded($event, item)" [attr.aria-expanded]="item.state == 'expanded'" [id]="item.id">
      <span>{{ item.text }}</span>
    </a>
    <ul [attr.aria-expanded]="item.state == 'expanded'" [@state]="item.state"
      *ngIf="!minimized && item.nodes && item.nodes.length > 0">
      <li class="nav-item" *ngFor="let child of item.nodes; trackBy: trackByFn">
        <a class="nav-link" routerLink="{{ child.link }}" routerLinkActive="active" [id]="child.id"
          [title]="child.text.length > 16 ? child.text : ''">
          <span>{{ child.text }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>
