import {AfterViewInit, Component, ViewChildren, ViewChild} from '@angular/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {Fields} from '@app/shared/models/fields';
import {ChartActor} from '@app/shared/components/chart-actor';
import {NavigationService, TrackerService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {EventTypes} from '@app/shared/models/event-types';
import {FacetItem} from '@app/shared/models/facet-item';
import {ActivatedRoute, Router} from '@angular/router';
import {InfoTexts} from '@app/shared/models/info-texts';
import {Features} from '@app/shared/models/features';
import { PageName } from '@app/shared/models/page-name';

@Component({
  selector: 'most-searched-oe-numbers',
  templateUrl: './most-searched-oe-numbers.component.html',
  styleUrls: ['./most-searched-oe-numbers.component.scss']
})
export class MostSearchedOeNumbersComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.OE_MOST_SEARCH_INFO;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getChartParams().params.set("search_type_id", ["a1"]);
  }

  getPageStoreKey(): string {
     return "MostSearchedOeumbersComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.ARTICLE_SEARCH );
  }

  get pageName(): string {
    return PageName.MOST_SEARCHED_OE;
  }

  public readonly fieldArticleNumber = Fields.SEARCH_STRING;

  public articleNumberChartHeadLabel = 'OE Numbers';
  @ViewChild('oeNumbersChart', {static: true}) numbersChart: ChartActor;

  public genartFilterTooltip = '<div style="background-color: #999; color: white; padding: 10px; font-size: 15px; font-weight: bold">If you select generic article, data is only available from 2022-01-01 and only for datasource webservice currently !<div>';

  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router ) {
      super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.SEARCH_STRING ];
  }

  getChartActors(): ChartActor[] {
    return [ this.numbersChart ];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem): void {


  }

  protected getFeatureOnMenu(): Features {
    return Features.MOST_SEARCHED_NUMBERS;
  }

}
