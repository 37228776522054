/**
 * @file TaskRepoter options
 * @author David López Rguez
 */

import { IBackdropOptions, BACKDROP_OPTIONS_DEFAULTS } from '../backdrop/BackdropOptions';
import { ILoadingOptions, LOADING_OPTIONS_DEFAULTS } from '../loading/LoadingOptions';


export interface ITaskReporterOptions extends ILoadingOptions, IBackdropOptions {
    backdrop?: boolean;
}

export class TaskReporterOptions implements ITaskReporterOptions  {
    backdrop: boolean;

    // IBackdropOptions
    delay: number;
    minDuration: number;

    // ILoadingOptions
    message: string;

    constructor(config: ITaskReporterOptions = {}) {
        [BACKDROP_OPTIONS_DEFAULTS
        ,LOADING_OPTIONS_DEFAULTS
        ,TASKREPORTER_OPTIONS_DEFAULTS]
        .forEach((DEFAULTS) => {
            for (let option in DEFAULTS) {
                this[option] = typeof config[option] !== 'undefined' && config[option] !== null
                    ? config[option]
                    : DEFAULTS[option];
            }
        });
    }
}

export const TASKREPORTER_OPTIONS_DEFAULTS = {
    delay: 0,
    minDuration: 0,
    backdrop: true
};