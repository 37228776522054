import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessibleShell } from './shells/accessible-shell/accessible-shell';

import { LoginGuard } from './guards/login-guard';

import {
    LoginPage
} from '.';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password';

const routes: Routes = [
    {
        path: 'portal',
        component: AccessibleShell,
        children: [
            { path: 'login',   component: LoginPage, canActivateChild: [LoginGuard] },
            { path: 'forgotPassword',   component: ForgotPasswordPage, canActivateChild: [LoginGuard] },
            { path: 'changePassword',   component: ForgotPasswordPage, canActivateChild: [LoginGuard] },
            // Default route
            { path: '',        redirectTo: 'login', pathMatch: 'full' }
        ]
    }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AccessibleRoutingModule {
}
