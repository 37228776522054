<div class="container-fluid">
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header"><h6>Views description</h6></div>
        <div class="card-body">
          <div class="row" *ngFor="let v of formatInfo">
            <div class="col-sm-1 bold">{{v.id}}</div>
            <div class="col-sm-3">{{v.name}}</div>
            <div class="col-sm-7">{{v.description}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <div class="card-header"><h6>Special File downloads</h6></div>
        <div class="card-body">
          <ul>
            <li *ngFor="let dl of downloads">
              <a *ngIf="downloadHandlers.get(dl.id).downloadStatus===DownloadStatusValues.NONE" href="javascript:"
                 (click)="downloadExportedFile(getDownloadLink(dl), dl.id)">
                {{ dl.name }}
              </a>
              <div class="d-flex special-file" *ngIf="downloadHandlers.get(dl.id).downloadStatus!==DownloadStatusValues.NONE">
                <div class="progress"
                     *ngIf="downloadHandlers.get(dl.id).downloadStatus===DownloadStatusValues.IN_PROGRESS">
                  <div class="progress-bar"
                       role="progressbar"
                       [attr.aria-valuenow]="downloadHandlers.get(dl.id).downloadProgress">
                    {{downloadHandlers.get(dl.id).downloadProgress}} MB
                  </div>
                </div>
                <div *ngIf="downloadHandlers.get(dl.id).downloadStatus===DownloadStatusValues.STARTED"
                     class="spinner-border text-primary"
                     role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <i class="fa fa-close" (click)="downloadHandlers.get(dl.id).cancel()"></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header"><h6>Special download links</h6></div>
        <div class="card-body">
          <a *ngIf="downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadStatus===DownloadStatusValues.NONE"
             class="btn btn-md btn-primary default-button" href="javascript:"
             (click)="downloadExportedFile(getLink12Month(), DOWNLOAD_12_MONTH_ID)" role="button">
            Last 12 month
          </a>
          <div class="d-flex special-download" *ngIf="downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadStatus!==DownloadStatusValues.NONE">
            <div class="progress"
                 *ngIf="downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadStatus===DownloadStatusValues.IN_PROGRESS">
              <div class="progress-bar"
                   role="progressbar"
                   [ngStyle]="{width: downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadProgress+'%'}"
                   aria-valuemin="0" aria-valuemax="100"
                   [attr.aria-valuenow]="downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadProgress">
                {{downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadProgress}}%
              </div>
            </div>
            <div *ngIf="downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadStatus===DownloadStatusValues.STARTED"
                 class="spinner-border text-primary"
                 role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <i class="fa fa-close"
               [ngStyle]="{paddingTop: downloadHandlers.get(DOWNLOAD_12_MONTH_ID).downloadStatus===DownloadStatusValues.STARTED?'0.5rem':'0'}"
               (click)="downloadHandlers.get(DOWNLOAD_12_MONTH_ID).cancel()"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="row" *ngFor="let year of years">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header"><h6>{{year}}</h6></div>
        <div class="card-body">
          <div class="row" *ngFor="let row of [['01','02','03','04'],['05','06','07','08'],['09','10','11','12']]">
            <div class="col-sm-3 month_box px-4" *ngFor="let i of [0,1,2,3]">
              <div class="month_heading">
                {{getMonth(row[i])}}
              </div>
              <div class="container">
                <div class="row" *ngFor="let mi of getDownloadLinks(year, row[i])">
                  <div class="col-sm-12">
                    <a *ngIf="downloadHandlers.get(mi.eTag).downloadStatus===DownloadStatusValues.NONE" href="javascript:"
                       (click)="downloadExportedFile(getLink(mi),mi.eTag);">
                      {{ mi.viewId }}
                    </a>
                    <div class="d-flex month-download"
                         *ngIf="downloadHandlers.get(mi.eTag).downloadStatus!==DownloadStatusValues.NONE">
                      <div class="progress"
                           *ngIf="downloadHandlers.get(mi.eTag).downloadStatus===DownloadStatusValues.IN_PROGRESS">
                        <div class="progress-bar"
                             role="progressbar"
                             [ngStyle]="{width: downloadHandlers.get(mi.eTag).downloadProgress+'%'}"
                             aria-valuemin="0" aria-valuemax="100"
                             [attr.aria-valuenow]="downloadHandlers.get(mi.eTag).downloadProgress">
                          {{downloadHandlers.get(mi.eTag).downloadProgress}}%
                        </div>
                      </div>
                      <div *ngIf="downloadHandlers.get(mi.eTag).downloadStatus===DownloadStatusValues.STARTED"
                           class="spinner-border text-primary"
                           role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                      <i class="fa fa-close" (click)="downloadHandlers.get(mi.eTag).cancel()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
