<analytics-filter #filter [showGenericArticleFilter]=false [showBrandFilter]=false [showSearchTypeId]=true></analytics-filter>

<div class="column">
  <div class="mb-1">
    <ta-lealef-map [infoText]="'This map shows in which countries the users were searching articles'"
      [chartData]="worldMapChartData" [panelTitle]="'Countries by map'" [dateRangeLabel]="dateRangeLabel"></ta-lealef-map>
  </div>
  <div class="mb-1">
    <chart [chartType]="'bar'" #yourBrandCountriesChart [field]="fieldCountries" [headLabel]="yourBrandCountriesChartHeadLabel"
           [selectable]="true"
           [showSearch]="false" [showTimeLineButton]=true
           [infoText]="'This map shows in which countries the users were searching articles'"></chart>
  </div>
</div>
