<div class="col-sm-12">
  <p-checkbox name="gWeekdays" label ="Select all" value="-1" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Monday" value="2" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Tuesday" value="3" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Wednesday" value="4" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Thursday" value="5" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Friday" value="6" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Saturday" value="7" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
  <p-checkbox name="gWeekdays" label ="Sunday" value="1" [(ngModel)]="_selectedWeekdays" (onChange)="changeSelectedWeekdays($event)"></p-checkbox>
</div>
