<p-overlayPanel #infoTextOverlay [appendTo]="'body'" [styleClass]="'infoTextOverlay'">
  {{infoText}}
</p-overlayPanel>
<div class="card lealefmap">
  <div class="card-header">
    <!-- info icon -->
    <span *ngIf="infoText" class="fa ta-icon-info-circled left" aria-hidden="true" (mouseover)="infoTextOverlay.show($event)"
      (mouseout)="infoTextOverlay.hide()"></span>
    <span class="left label">{{panelTitle}}</span>
    <span class="center label">{{dateRangeLabel}}</span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12"
           leaflet
           [leafletOptions]="lealefOptions"
           [leafletLayers]="lealefLayers"
           (leafletMapReady)="onMapReady($event)">
      </div>
    </div>
  </div>
</div>
