<p-dialog [(visible)]="showBrandFilterUsage" [header]="'Filter usage'" [modal]="true" [style]="{width: '1000px', height: '680px'}"  [appendTo]="'body'" [focusOnShow]="false">
  <div class="container-fluid row">
    <p-button [disabled]="countUnusedFilters == 0"
              [icon]="countSelectedFilters ? 'fa fa-check-square-o' : 'fa fa-square-o'" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right"
              [label]="'Unassigned filters' + (countSelectedFilters > 0 ? ' (' + countSelectedFilters + ')' : '' )" (onClick)="onToggleSelectionUnusedFilter()"></p-button>
    <p-button [disabled]="countSelectedFilters == 0" icon="pi pi-trash" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right"
              label="Delete selected ..." (onClick)="onDeleteSelectedFilters()"></p-button>
  </div>

  <div class="container-fluid">
    <div class="row" style="border-bottom: solid 2px #acacac; background-color: #dcdcdc; font-weight: bold">
        <div class="col-4">Brand Filter</div>
        <div class="col-4">Brands</div>
        <div class="col-4">Users</div>
    </div>
  </div>
  <div class="container-fluid brand-filter-table" style="overflow: auto; height: 550px;">
    <div class="row py-2" *ngFor="let brF of filters">
        <div class="col-4" style="border-right: 1px solid black">
          <p-checkbox [(ngModel)]="brF.selected" binary="true"></p-checkbox>
          <span class="pl-2">{{brF.label}}</span>
        </div>
        <div class="col-4" style="height: 150px; overflow: auto; background-color: white; border-right: 1px solid black">
          <div *ngFor="let brand of getBrands(brF)">{{brand}}</div>
        </div>
        <div class="col-4" style="height: 150px; overflow: auto; background-color: white">
          <div *ngFor="let user of brF.users"><span class="pi pi-user"></span>{{user}}</div>
        </div>
      </div>
    </div>
</p-dialog>

<div class="container-fluid">

  <div class="container-fluid row">
    <p-button [disabled]="isEditing" icon="pi pi-plus" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right"
              label="New" (onClick)="onNew()"></p-button>
    <p-button [disabled]="isEditing" icon="pi pi-pencil" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right"
              label="Edit" (onClick)="onEdit()"></p-button>
    <p-button [disabled]="isEditing" icon="pi pi-trash" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right"
              label="Delete ..." (onClick)="onDelete()"></p-button>
	<p-button icon="pi pi-sitemap" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right" label="Filter overview"
				(onClick)="showBrandFilterOverview()"></p-button>
    <p-button icon="pi pi-trash" styleClass="ui-button-secondary mr-1 mb-1" iconPos="right"
              label="Clear brand filter cache" (onClick)="onClearBrandFilterCache()"
              title="Because the brand filter has a cache of 15 minutes to affect for user"></p-button>
  </div>
  <hr/>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-2">
        <p-dropdown [options]="filters" [(ngModel)]="selectedFilter" [optionLabel]="'label'" styleClass="w-100"
                    [disabled]="changed || nameChange"></p-dropdown>
      </div>
      <div class="col-sm-10">
        <p-tabMenu #tab [model]="tabs" styleClass="ta-tabmenu-container" [activeItem]="tabs[0]" (click)="activateMenu(tab)"></p-tabMenu>
      </div>
    </div>
  </div>
  <hr/>
  <div class="container-fluid">

    <loading [busy]="isSaving"></loading>

    <div class="row">
      <div class="col-4">
        <span [hidden]="nameChange">
          <b>Current Filter</b>: {{_selectedFilter.name}}
        </span>
        <div class="container-fluid row" [hidden]="!nameChange" [ngClass]="{ 'd-flex': nameChange }">
          <input #filterName type="text" placeholder="filter name ..." [(ngModel)]="_selectedFilterName"
                 [ngClass]="{ 'invalid-name': errors.invalidName }" class="mr-4"/>
          <p-button class="default-button" icon="pi pi-save" iconPos="right" label="Save" (onClick)="onSaveRename()"></p-button>&nbsp;
          <p-button class="default-button"  icon="pi pi-times" iconPos="right" label="Cancel" (onClick)="onCancelRename()"></p-button>
        </div>
      </div>
      <div class="col-6">
        <input #search pInputText type="text" placeholder="search ..." (keyup)="searchInputChange(search)">&nbsp;
        <p-button icon="pi pi-check-circle" styleClass="default-button" iconPos="right" label="Select all" (onClick)="selectAll(true)"></p-button>
        &nbsp;<p-button icon="pi pi-circle-off" iconPos="right" styleClass="default-button" label="Clear all" (onClick)="selectAll(false)"></p-button>
      </div>
    </div>
    <hr/>

    <div class="container-fluid row">
      <div class="col-sm-2">
        <div class="row">
          <b [hidden]="!(!changed || isEmpty || nameChange)">Selected brands</b>

        </div>
        <hr/>
        <div class="row">
          <div class="col-sm-12 hoverable" [ngClass]="{ 'brand-clickable': isEditing }"
               *ngFor="let brand of brands" [hidden]="!brand.selected">
            <i [className]="getBrandClass(brand)"
               aria-hidden="true" (click)="toggleSelection( brand )"> {{brand.brandName}} [{{brand.brandId}}]</i>
          </div>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="list-title">
          <b>Brands list</b>
        </div>
        <hr>
        <div class="row">
          <div class="col-md-3 col-sm-4 col-xs-12 hoverable"
               [ngClass]="{ 'brand-clickable': isEditing }"
               *ngFor="let brand of brands"
               [hidden]="brand.selected ||
                      (selectedLetter && brand.brandName.toUpperCase().charAt(0) != selectedLetter) ||
                        (searchValue && ( brand.brandName.toUpperCase().indexOf( searchValue ) == -1
                        && !( '' + brand.brandId ).startsWith( searchValue )
                         && !( '[' + brand.brandId + ']' ).startsWith( searchValue )
                           && !( '[' + brand.brandId ).startsWith( searchValue ) ) )" (click)="toggleSelection( brand )">
        {{brand.brandName}} [{{brand.brandId}}]
    </div>

  </div>
</div>
