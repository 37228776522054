import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsPage } from '@app/tecalliance-analytics/pages/analytics-page';
import { Fields } from '@app/shared/models/fields';
import { ChartActor } from '@app/shared/components/chart-actor';
import { TrackerService, NavigationService } from '@app/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/shared';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { ChartRequest } from '@app/shared/models/chart-request';
import { EventTypes } from '@app/shared/models/event-types';
import { FacetItem } from '@app/shared/models/facet-item';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoTexts } from '@app/shared/models/info-texts';
import { PageRequest } from '@app/shared/models/page-request';
import {Features} from '@app/shared/models/features';
@Component({
  selector: 'sales-srticle-numbers',
  templateUrl: './sales-article-numbers.component.html',
  styleUrls: ['./sales-article-numbers.component.scss']
})
export class SalesArticleNumbersComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.ARTICLE_NUMBER_INFO;
  }

  getPageStoreKey(): string {
     return "SalesArticleNumbersComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.TECDOC_3_REPORT );
  }

  get pageName(): string {
    return 'Sales article numbers';
  }

  public readonly fieldArticleNumber = Fields.RAW_ARTICLE_NUMBER;

  public articleNumberChartHeadLabel = 'Your Brand(s)';
  @ViewChild('articleNumberChart', {static: true}) articleNumberChart: ChartActor;


  constructor( appInsights: TrackerService,
               confirm: ConfirmationService,
               protected translate: TranslateService,
               protected navigation: NavigationService,
               protected apiService: ApiService,
               protected route: ActivatedRoute,
               protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [ Fields.RAW_ARTICLE_NUMBER ];
  }

  getChartActors(): ChartActor[] {
    return [ this.articleNumberChart ];
  }

  getReportPath(): string {
    return '/report/order/sales';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem, fieldName?: string): void {
  }

  protected getFeatureOnMenu() {
    return Features.SALES;
  }

}
