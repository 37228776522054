import {Component, OnInit, ViewChild, HostListener, Input, ElementRef} from '@angular/core';
import { FacetItem } from '@app/shared/models/facet-item';
import { ChartActor } from '../chart-actor';
import { ChartData } from '@app/shared/models/analytics/chart-data';
import { Settings } from '@app/shared/models/settings/settings';
import {Dropdown} from 'primeng/dropdown';
import {AutoComplete} from 'primeng/autocomplete';
import {OverlayPanel} from 'primeng/overlaypanel';
import { Button} from 'primeng/button';
import { ReportResponse } from '@app/shared/models/analytics/report-response';
import { Fields } from '@app/shared/models/fields';
import {ConfigMode} from '@app/shared/models/config-mode';
import {FilterConfigEvent} from '@app/shared/models/filter-config-event';
import {UtilsService} from '@app/core';

@Component({
  selector: 'generic-articles-filter',
  templateUrl: './generic-articles-filter.component.html',
  styleUrls: ['./generic-articles-filter.component.scss']
})
export class GenericArticlesFilterComponent extends ChartActor implements GaFilterSettings {

  readonly MESSAGE_MAX_FAVS_REACHED = 'You have reached the maximum number of favorites, please delete the least important one to create a new one.';
  readonly MESSAGE_MOVED_FAV_TO_TOP = 'This selection is already saved as favorite. It is moved to top now.';

  readonly MAX_FAVORITES = 20; // TODO: can be later set by a property for user

  key_s: string = 'ga_filter_';
  selectedIds_ss: string[];

  public varSelectedOptions: FacetItem[] = [];
  public selectedFavoriteVar: FacetItem;

  public chartData: ChartData;

  overlayMessage = this.MESSAGE_MAX_FAVS_REACHED;
  private openDropdownOnHideOverlay = false;

  @Input()
  public showFilter: boolean = true;
  public rightColVisible = true;

  @Input()
  public set rightColumnWidth( width: number[] ) {
    let v = width[0];
    let favButtonStyle = { 'margin-left': '4px', 'margin-right': '3px' };

    let leftColWidth;

    if(window.innerWidth < 1000) {
      this.rightColVisible = false;
      leftColWidth = this.gaFilterContainer.nativeElement.offsetWidth;
    } else {
      this.rightColVisible = true;
      if (window.innerWidth < 1300 && width.length > 1) {
        v += width[1] + 15;
        favButtonStyle = {'margin-left': '0px', 'margin-right': '0px'};
      }
      leftColWidth = ( this.gaFilterContainer.nativeElement.offsetWidth - v + 28 );
    }
    this.favButtonStyle = favButtonStyle;

    this.leftCol.nativeElement.style.width = leftColWidth + 'px';
    this.rightCol.nativeElement.style.width = ( v - 28 ) + 'px';

    this.autocompleteStyle = Object.assign( this.autocompleteStyle, { 'width': ( leftColWidth - 67 ) + 'px' } );


  }

  @ViewChild('autoComplete', {static: true}) autoComplete: AutoComplete;

  @ViewChild('maxFavReachedOverlay', {static: true}) maxFavReachedOverlay: OverlayPanel;
  @ViewChild('tooltipGenartsSelected', {static: true}) tooltipGenartsSelected: OverlayPanel;

  @ViewChild('favButton', {static: true}) favButton: Button;

  @ViewChild('gaFilterContainer', {static: true}) gaFilterContainer: ElementRef<HTMLDivElement>;
  @ViewChild('leftCol', {static: true}) leftCol: ElementRef<HTMLDivElement>;
  @ViewChild('rightCol', {static: true}) rightCol: ElementRef<HTMLDivElement>;

  public results: FacetItem[] = [];
  public options: FacetItem[] = [];

  public favorites: FacetItem[] = [];

  private lastInput: string;

  private loadingLabel = 'loading ...';

  search(event) {
    const searchString = event.query;

    const r = [];

    if( searchString && searchString.length > 0 ) {

      for( const itm of this.options ) {
        if( this.selectedIds_ss && this.selectedIds_ss.indexOf( itm.id ) != -1 ) {
          continue;
        }
        if( itm.name.toLowerCase().indexOf(searchString.toLowerCase()) != -1 ) {
          r.push( itm );
        }
      }
    }

    this.lastInput = searchString;
    this.results = r;
  }

  @ViewChild('favDropDown', {static: true}) favDropDown: Dropdown;
  @ViewChild('genartsLabelDiv', {static: true}) genartsLabelDiv: ElementRef<HTMLDivElement>;

  favorClicked( event ) {
    this.addFavorites( event );
  }

  protected reportLoaded( report: ReportResponse ) {
    this.loadingLabel = '';
    if( report.facets && report.facets.has( Fields.GENART_NO_FAVS ) ) {
      const favs: FacetItem[] = report.facets.get(Fields.GENART_NO_FAVS).originalData;
      if( favs ) {
        this.setFavorites( favs );
      }
    }
  }

  public set selectedFavorite( v: FacetItem ) {
    this.selectedFavoriteVar = v;
    this.selectedOptions =  this.getSelectedOptions( this.options, v.id.split(',') );

    setTimeout(() => {
      this.selectedFavoriteVar = null; // this.favMainItem;
    }, 500);
  }

  public get selectedFavorite(): FacetItem {
    return this.selectedFavoriteVar;
  }

  private addFavorites( event ) {

    if( this.varSelectedOptions && this.varSelectedOptions.length ) {

      let fi: FacetItem  = <FacetItem>{
        id: this.varSelectedOptions.map( v => v.id ).join(','),
        name: this.varSelectedOptions.map( v => v.name ).join('; ')
      };

      let newFavs = [].concat( this.favorites );
      let hasItemsByIds: FacetItem[] = newFavs.filter( v => v.id == fi.id );

      // if allready in list
      if( hasItemsByIds.length > 0 ) {
        // move to top
        const hasItem: FacetItem = hasItemsByIds[0];
        const idx = newFavs.indexOf(hasItem);
        if( idx != -1 ) {
          newFavs.splice(idx, 1);
          newFavs = [ hasItem ].concat( newFavs );
        }

        this.openDropdownOnHideOverlay = false;
        this.overlayMessage = this.MESSAGE_MOVED_FAV_TO_TOP;
        this.maxFavReachedOverlay.show(event);
      } else {
        // otherwise add to top
        newFavs = [ fi ].concat( newFavs );
      }

      if( newFavs.length > this.MAX_FAVORITES ) {
        // show message and open dropdown
        this.openDropdownOnHideOverlay = true;
        this.overlayMessage = this.MESSAGE_MAX_FAVS_REACHED;
        this.maxFavReachedOverlay.show(event);
        return;
      }

      this.setFavorites( newFavs );
      this.selectedFavoriteVar = null;

      this.page.saveGenericArticleFilterFavorites( newFavs );
    }
  }

  private setFavorites( newFavs ) {
    this.favorites = [].concat( newFavs );
  }

  /**
   *
   * @param event
   * @param fav
   */
  public removeGenericArticleFavoriteAndSave( event, fav: any ) {
    event.preventDefault();
    event.stopPropagation();

    const idx = this.favorites.indexOf(fav.value);

    if( idx != -1 ) {
      this.favorites.splice(idx, 1);
      this.setFavorites(this.favorites);

      this.page.saveGenericArticleFilterFavorites( this.favorites );
    }
  }

  private firstData: boolean = true;
  hasFocus = false;
  public setData( d: ChartData, changed: boolean ) {

      //console.log( d );

      this.chartData = d;
      let ids = this.chartData.datasets[0].selectedIds;

      if( this.firstData
          && this.selectedIds_ss
            && this.selectedIds_ss.length ) {
        ids = this.selectedIds_ss;
        this.page.getChartParams().setIds(this.fieldName, ids);
      }

      if( ids && ids.length ) {
        this.varSelectedOptions = ChartData.sortGAs( this.getSelectedOptions(d.originalData, ids) );
      }
      this.options = d.originalData; /* ( [].concat( d.originalData ) ).sort((a, b) => {
            if ( a.name.toLowerCase() < b.name.toLowerCase() )
              return -1;
            if ( a.name.toLowerCase() > b.name.toLowerCase() )
              return 1;
            return 0;
      });*/

      this.firstData = false;
  }



  public set selectedOptions( v: FacetItem[] ) {
      this.varSelectedOptions = ChartData.sortGAs( v );

      if( this.page && this.fieldName ) {
        let selectedIds = this.page.getChartParams().setParams(this.fieldName, v);

        this.selectedIds_ss = selectedIds;
      }

      window.setTimeout(() => {
        this.autoComplete.forceSelection = true;
        this.autoComplete.show();
        this.search({query: this.lastInput});
      }, 500);

      this.onConfiguringFilter.emit( { mode: this.configMode, field: this.fieldName } );
  }

  public get selectedOptions(): FacetItem[] {
    return this.varSelectedOptions;
  }

  protected getSettings(): Settings {
    const r = new GAFilterSettingsImpl();
    console.log(r);
    this.copySettings(r, this);
    return r;
  }

  protected settingsLoaded(o: Settings): void {
    if( this.selectedIds_ss && this.selectedIds_ss.length ) {
      this.page.getChartParams().setIds( this.fieldName, this.selectedIds_ss );
    }
  }

  constructor() {
    super();
    this.configMode = ConfigMode.DISPLAY_CONFIRMATION;
   }

  ngOnInit() {
  }

  getGenartFavoritesPlaceHolder(): string {
    return  this.favorites.length ? 'Favorites: ' + this.favorites.length : this.loadingLabel;
  }

  onHideMaxFavoritesMessage() {
    //console.log('onHideMaxFavoritesDialog()');
    window.setTimeout(() => {
        if( this.openDropdownOnHideOverlay && !this.favDropDown.overlayVisible ) {
          this.favDropDown.containerViewChild.nativeElement.click();
        }
        this.openDropdownOnHideOverlay = false;
      }, 100);
  }

  revertSelection() {
    this.loadSettings();
    let selectedItem = this.getSelectedOptions(this.options, this.selectedIds_ss);
    this.selectedOptions = selectedItem;
  }

  getGenartNamesList() {
    const r = [];
    for( const itm of this.selectedOptions ) {
      r.push(itm.name);
    }
    if( !r.length ) {
      return
    }

    return r.join(', ');
  }

  autocompleteBlurTimer;
  tooltipGenartsMessage = '';
  autocompleteStyle = { 'position': 'absolute', 'z-index': '1000', 'width': '100%' };
  favButtonStyle = { 'margin-left': '4px', 'margin-right': '4px' };
  onAutoCompleteBlur( ms ) {
    this.autocompleteBlurTimer = window.setTimeout(() => {
      this.hasFocus = false;
    }, ms);
  }

  onAutoCompleteFocus() {
    if( this.autocompleteBlurTimer ) {
      window.clearTimeout(this.autocompleteBlurTimer);
      this.autocompleteBlurTimer = null;
    }
    this.hasFocus = true;
    this.tooltipGenartsSelected.hide();
  }

  onGenartLabelsClick() {
    this.hasFocus = true;
    this.onAutoCompleteBlur(3000);
    window.setTimeout(() => {
        this.autoComplete.focusInput();
        }, 100);

  }

  onGenartLabelMouseOver(event: MouseEvent) {
    this.tooltipGenartsMessage = this.getGenartNamesList();
    if( this.tooltipGenartsMessage && this.tooltipGenartsMessage.trim().length && UtilsService.isEllipsisActive(this.genartsLabelDiv.nativeElement) ) {
      this.tooltipGenartsSelected.show(event);
    }
  }

  onGenartLabelMouseOut(event: MouseEvent) {
    this.tooltipGenartsSelected.hide();
  }
}

export class GAFilterSettingsImpl implements GaFilterSettings {
  key_s: string = '';
  selectedIds_ss: string[] = [];
}

export interface GaFilterSettings extends Settings {
  selectedIds_ss: string[];
}
