import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { ContextService } from '../core/context';
import { StorageService } from '../core/storage';

import { Observable, EMPTY } from 'rxjs';
import { map, catchError, share } from 'rxjs/operators';

import { AuthTypes } from '../../security/auth-types';
import { AuthResponse } from '../../security/auth-response';
import { UserDetail } from '../../security/user-detail';
import { NavigationService } from '../miscellany/navigation';

import { CoreModuleConfig } from '../../core-module-config';

import { AuthBaseService } from './auth-base';

import { timeout } from 'rxjs/operators';

export class JWTResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  user_detail: string;
}

/**
 * @file jwtauth.service.js
 * @module app.core
 * @summary Core service.
 * @description
 *
 * @version 0.0.0
 * @copyright 2018
 */
@Injectable()
export class JWTAuthService extends AuthBaseService {
  //#region Authentication Properties

  public get type(): AuthTypes {
    return AuthTypes.Jwt;
  }

  //#endregion

  //#region Constructor

  constructor(
    navigation: NavigationService,
    config: CoreModuleConfig,
    storage: StorageService,
    context: ContextService,
    http: HttpClient
  ) {
    super(navigation, config, context, storage, http);
  }

  //#endregion

  //#region Authentication Methods

  public login(body?: any): Observable<AuthResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http
      .post<JWTResponse>(
        `${this.context.instance.auth.endpoint}`,
        JSON.stringify(body),
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        map((response: HttpResponse<JWTResponse>) => {
          const result = new AuthResponse();
          result.status = response.status;
          result.message = response.statusText;

          if (response.ok) {
            const data = response.body;

            this.setAccessToken(data.access_token);
            this.setRefreshToken(data.refresh_token);

            const expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + data.expires_in);
            this.setExpirationDate(expiration);

            const userDetail = this.config.userFactory
              ? this.config.userFactory(data)
              : JSON.parse(data.user_detail) as UserDetail;
            result.userDetail = userDetail;
            this.setUser(userDetail);

            this.loggedIn.emit();
          }

          return result;
        }),
        share()
      );
  }

  public refresh(body: any): Observable<AuthResponse> {
    if (!this.isLogged) {
      return EMPTY;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // TODO

    return this.http
      .post<JWTResponse>(
        `${this.context.instance.auth.endpoint}`,
        JSON.stringify(body),
        { headers: headers, observe: 'response' }
      )
      .pipe(
        timeout(this.context.instance.auth.timeout * 1000),
        catchError((err: any, caught: Observable<HttpResponse<JWTResponse>>) => {
          this.logout();
          return EMPTY;
        }),
        map((response: HttpResponse<JWTResponse>) => {
          const result = new AuthResponse();
          result.status = response.status;
          result.message = response.statusText;

          if (response.ok) {
            const data = response.body;

            this.setAccessToken(data.access_token);
            this.setRefreshToken(data.refresh_token);

            const expiration = new Date();
            expiration.setSeconds(expiration.getSeconds() + data.expires_in);
            this.setExpirationDate(expiration);

            const userDetail = this.config.userFactory
              ? this.config.userFactory(data)
              : JSON.parse(data.user_detail) as UserDetail;
            result.userDetail = userDetail;
            this.setUser(userDetail);

            this.refreshed.emit();
          } else {
            this.logout();
          }

          return result;
        }),
        share()
      );
  }

  //#endregion
}
