import {Component, ViewChild} from '@angular/core';
import {AnalyticsPage} from '@app/tecalliance-analytics/pages/analytics-page';
import {Fields} from '@app/shared/models/fields';
import {ChartActor} from '@app/shared/components/chart-actor';
import {NavigationService, TrackerService} from '@app/core';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '@app/shared';
import {ReportResponse} from '@app/shared/models/analytics/report-response';
import {ChartRequest} from '@app/shared/models/chart-request';
import {EventTypes} from '@app/shared/models/event-types';
import {FacetItem} from '@app/shared/models/facet-item';
import {ActivatedRoute, Router} from '@angular/router';
import {InfoTexts} from '@app/shared/models/info-texts';
import {Features} from '@app/shared/models/features';
import { ChartComponent } from '@app/shared/components/chart/chart.component';

@Component({
  selector: 'most-searched-countries',
  templateUrl: './most-searched-countries.component.html',
  styleUrls: ['./most-searched-countries.component.scss']
})
export class MostSearchedCountriesComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.COUNTRIES_SEARCH_INFO;
  }

  getPageStoreKey(): string {
     return "MostSearchedCountriesComponent";
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest( EventTypes.ARTICLE_SEARCH );
  }

  get pageName(): string {
    return 'Most searched countries';
  }

  public readonly fieldCountries = Fields.LOCATION_COUNTRY_BRANDLESS;

  public worldMapChartData: any;

  public yourBrandCountriesChartHeadLabel = 'Countries';
  @ViewChild('yourBrandCountriesChart', {static: true}) yourBrandCountriesChart: ChartActor;

  constructor( appInsights: TrackerService,
    confirm: ConfirmationService,
    protected translate: TranslateService,
    protected navigation: NavigationService,
    protected apiService: ApiService,
    protected route: ActivatedRoute,
    protected router: Router ) {
      super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [Fields.LOCATION_COUNTRY_BRANDLESS];
  }

  getChartActors(): ChartActor[] {
    return [this.yourBrandCountriesChart];
  }

  getReportPath(): string {
    return '/report/facets';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  dateRangeLabel: string;
  reportLoaded(data: ReportResponse): void {
    if(data && data.hasFacets(this.fieldCountries)) {
      this.worldMapChartData = data.facets.get(this.fieldCountries).originalData;
    }
    this.dateRangeLabel = data.dateRange;
  }

  itemSelected(item: FacetItem): void {


  }

  protected getFeatureOnMenu(): Features {
    return Features.MOST_SEARCHED_NUMBERS;
  }

}
