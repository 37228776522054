import {Component} from '@angular/core';
import {MultiSelectComponent} from '@app/shared/components/multi-select/multi-select.component';

@Component({
  selector: 'brand-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})

export class BrandSelectComponent extends MultiSelectComponent {
  private readonly NUMBER_OF_SELECTABLE_ITEM: number = 100;

  protected optimizeFilters() {
    if (this.options && (this.selectedIds_ss.length === this.options.length)) {//Select all brands
      this.page.getChartParams().removeParam(this.fieldName);
    }
  }

  public isInvalidSelectedItemNumber(): boolean {
    if (!this.selectedIds_ss) {
      return false;
    }
    return this.selectedIds_ss.length >= this.NUMBER_OF_SELECTABLE_ITEM && this.selectedIds_ss.length !== this.options.length;
  }

  public exceedSelectionQuotaTooltip(): string {
    if (!this.isInvalidSelectedItemNumber()) {
      return 'Apply settings';
    }
    let numberOfSelectedItem = this.selectedIds_ss.length;
    return 'You can only select ALL or maximum ' + this.NUMBER_OF_SELECTABLE_ITEM + ' brands. You selected ' + numberOfSelectedItem + ' brand(s)';
  }
}
