import { FacetItem } from "./facet-item";
import { AutocompleteHandler } from "./autocomplete-handler";

export class AutocompleteData {

    static readonly ALL = '@ALL';

    searchString: string = '';
    response: FacetItem[] = [];
    filtered: FacetItem[] = [];
    _selected: FacetItem | FacetItem[];   
    isRequesting = false;
    resetSelectionOnEmptySearchString = true;

    constructor( private handler?: AutocompleteHandler, private addAll?: boolean ) {

    }

    reset( resetResponse: boolean ) {
        if( resetResponse && this.response )
        this.response.length = 0;
        if( this.filtered )
        this.filtered.length = 0;
        this._selected = null;
    }

    setResponse( v: FacetItem[] ) {                  
        this.response = [].concat( v );
        this.isRequesting = false;        
    }

    search( event ) {
        this.searchString = event.query.toLowerCase();    
        
        if(this.resetSelectionOnEmptySearchString && 
              !this.searchString.trim().length) {
            this._selected = null;
        }

        if(!this.handler || !this.handler.onSearch(this.searchString)) {    
            this.doFilter();
        }
    }

    doFilter() {
        if(!this.response || !this.response.length) {
            this.filtered = [];
            return;
        }
              
        if( this.addAll || !this.searchString || this.searchString.trim() == '' ) {
            this.filtered = [].concat( this.response );
            return;
        }

        const r = [];  
        for( const cr of this.response ) {
            if( cr.id === AutocompleteData.ALL 
                || cr.label.toLowerCase().indexOf(this.searchString) != -1 ) {
                r.push(cr);
            }
        }

        console.log(r);
        this.filtered = r;
    }
    
    set selected( v: FacetItem | FacetItem[] ) {    
        
        if( typeof v === 'string' ) {
            return;
        }
                               
        if( this.handler && v ) {
            let changed = !this._selected || JSON.stringify(v) !== JSON.stringify(this._selected);
            this.handler.onSelect(v, changed);            
        }

        this._selected = v; 
    }

    get selected(): FacetItem | FacetItem[] {
        return this._selected;
    }

    get selectedSingle(): FacetItem {
        if( !Array.isArray(this._selected) )  {
            return <FacetItem>this._selected;
        }
        return null;
    }

    get selectedMulti(): FacetItem[] {
        if( Array.isArray(this._selected) ) {
            return <FacetItem[]>this._selected;
        }
        return null;
    }

    get isSelected(): boolean {
        return !!this._selected;
    }

    get selectedLabel(): string {
        return this.selectedSingle ? this.selectedSingle.label : '';
    }

    get hasResponse(): boolean {
        return this.response && this.response.length > 0;
    }

    set selectedByIds( v: string[] ) {
        let r = [];
        if( v && v.length ) {
            for( const f of this.response ) {
                for( const id of v ) {
                    if(f.id === id) {
                        r.push(f);
                        break;
                    }
                }
            }
        }
        this.selected = r;
    }
}