<p-dialog header="Edit user" [(visible)]="showEditUserDialog" [modal]=true [style]="dialogStyle">
  <form [formGroup]="editUserform" (ngSubmit)="onSubmit(editUserform)" class="fillhoriz fillvert">
    <div class="container-fluid fillhoriz fillvert">
      <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
      <div class="row pb-2">
        <div class="col-sm-3 text-right">
          User category:
        </div>
        <div class="col-sm-5">
          <p-dropdown [options]="userRolesSelect"
                      [(ngModel)]="selectedUser.settings.userRole" formControlName="userRole"
                      styleClass="role-selector"></p-dropdown>
        </div>
        <div class="col-sm-4">
          <p-message severity="error" text="please select user role"
                     *ngIf="!editUserform.controls['userRole'].valid && editUserform.controls['userRole'].dirty"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-3 text-right">
          User-ID:
        </div>
        <div class="col-sm-5">
          {{selectedUser.id}}
        </div>
        <div class="col-sm-4">

        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-3 text-right">
          Username:
        </div>
        <div class="col-sm-5">
          {{selectedUser.userName}}
        </div>
        <div class="col-sm-4">

        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-3 text-right">
          Email:
        </div>
        <div class="col-sm-5">
          <input pInputText type="text" [(ngModel)]="selectedUser.email" formControlName="email">
        </div>
        <div class="col-sm-4">
          <p-message severity="error" text="Email is required"
                     *ngIf="!editUserform.controls['email'].valid && editUserform.controls['email'].dirty"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-3 text-right">
          Activated:
        </div>
        <div class="col-sm-5">
          <p-checkbox [(ngModel)]="selectedUser.isActive" formControlName="isActive" binary="true"></p-checkbox>
        </div>
        <div class="col-sm-4">

        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4">
          <loading [busy]="isRefreshingFilter" [showCloseButton]="false"></loading>
          <div class="list-header-row">
            <a class="list-label" target="_blank" href="/manage/brand-filters">Brand filter:*</a>
            <button pButton
                    icon="fa ta-icon-cw"
                    class="btn-reload-brand-filter btn btn-secondary default-button"
                    tooltipPosition="top"
                    pTooltip="Reload brand filters"
                    (click)="loadBrandFilters($event, brandFilterEdit)">
            </button>
          </div>
          <p-listbox
            #brandFilterEdit
            filter="filter" checkbox="checkbox"
            [options]="brandFilters"
            [(ngModel)]="selectedUser.settings.brandFilterId" formControlName="brandFilterId"
            styleClass="brand-filter-selector">
            <ng-template let-flt let-i="index" pTemplate="item">
              <div class="ui-helper-clearfix">
                <span [ngClass]="{ 'is-new-filter' : flt.isNew }">{{flt.label}}</span>
              </div>
            </ng-template>
          </p-listbox>
          <p-message severity="error" text="Please select brand filter"
                     *ngIf="!editUserform.controls['brandFilterId'].valid && editUserform.controls['brandFilterId'].dirty"></p-message>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              Datasources:
            </span>
          </div>
          <p-listbox filter="filter" multiple="multiple" checkbox="checkbox"
                     [options]="datasources"
                     [(ngModel)]="selectedUser.settings.datasource" formControlName="datasource"
                     styleClass="datasource-selector"></p-listbox>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              Features:
            </span>
          </div>
          <p-listbox filter="filter" multiple="multiple" checkbox="checkbox"
                     [options]="features" [(ngModel)]="selectedUser.settings.features"
                     formControlName="features"
                     styleClass="feature-selector"></p-listbox>
        </div>
      </div>
      <div class="row edit-buttons">
        <div class="col-md-12 col-sm-12 text-right">
          <p-button class="default-button" label="Cancel" type="button" (onClick)="showEditUserDialog = false"
                    styleClass="mr-1"></p-button>
          <p-button class="default-button" type="submit" label="Save changes"
                    [disabled]="!editUserform.valid"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog header="New user" [(visible)]="showNewUserDialog" [modal]=true [style]="dialogStyle">
  <form [formGroup]="newUserform" (ngSubmit)="onSubmit(newUserform)" class="fillhoriz">
    <div class="container-fluid fillhoriz">
      <div class="col-sm-12 mb-2 text-center">
        <p-message severity="error" *ngIf="errorMessage!=null&&errorMessage!=''" [text]="errorMessage"></p-message>
      </div>
      <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
      <div class="row pb-2">
        <div class="col-sm-4 text-right">
          User role *:
        </div>
        <div class="col-sm-8">
          <p-dropdown [options]="userRolesSelect" formControlName="userRole"
                      [(ngModel)]="selectedUser.settings.userRole"
                      styleClass="role-selector">
          </p-dropdown>
        </div>
      </div>
      <div class="row pb-2" *ngIf="!newUserform.controls['userRole'].valid && newUserform.controls['userRole'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" text="Please select user role"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-right">
          Username *:
        </div>
        <div class="col-sm-8">
          <input pInputText type="text" formControlName="userName" placeholder="Required"
                 [(ngModel)]="selectedUser.userName">
        </div>
      </div>
      <div class="row pb-2" *ngIf="!newUserform.controls['userName'].valid&&newUserform.controls['userName'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" text="3 - 25 characters and only allowed a-zA-Z0-9_-.@"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-right">
          Email *:
        </div>
        <div class="col-sm-8">
          <input pInputText type="text" formControlName="email" placeholder="Required" [(ngModel)]="selectedUser.email">
        </div>
      </div>
      <div class="row pb-2" *ngIf="!newUserform.controls['email'].valid && newUserform.controls['email'].dirty">
        <div class="col-sm-4"></div>
        <div class="col-sm-8">
          <p-message severity="error" text="Email is required"></p-message>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4 text-right">
          Activated:
        </div>
        <div class="col-sm-8">
          <p-checkbox formControlName="isActive" binary="true" [(ngModel)]="selectedUser.isActive"></p-checkbox>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col-sm-4">
          <loading [busy]="isRefreshingFilter" [showCloseButton]="false"></loading>
          <div class="list-header-row">
            <a class="list-label" target="_blank" href="/manage/brand-filters">Brand filter:*</a>
            <button pButton
                    icon="fa ta-icon-cw"
                    tooltipPosition="top"
                    class="btn-reload-brand-filter btn btn-secondary"
                    pTooltip="Reload brand filters"
                    (click)="loadBrandFilters($event, brandFilterNew)">
            </button>
          </div>
          <p-listbox
            #brandFilterNew
            filter="filter" checkbox="checkbox"
            [options]="brandFilters"
            [(ngModel)]="selectedUser.settings.brandFilterId" formControlName="brandFilterId"
            styleClass="brand-filter-selector">
            <ng-template let-flt let-i="index" pTemplate="item">
              <div class="ui-helper-clearfix">
                <span [ngClass]="{ 'is-new-filter' : flt.isNew }">{{flt.label}}</span>
              </div>
            </ng-template>
          </p-listbox>
          <p-message severity="error" text="Please select brand filter"
                     *ngIf="!newUserform.controls['brandFilterId'].valid && newUserform.controls['brandFilterId'].dirty"></p-message>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              Datasources:
            </span>
          </div>
          <p-listbox filter="filter" multiple="multiple" checkbox="checkbox"
                     [options]="datasources"
                     [(ngModel)]="selectedUser.settings.datasource" formControlName="datasource"
                     styleClass="datasource-selector"></p-listbox>
        </div>
        <div class="col-sm-4">
          <div class="list-header-row">
            <span class="list-label">
              Features:
            </span>
          </div>
          <p-listbox filter="filter" multiple="multiple" checkbox="checkbox"
                     [options]="features" [(ngModel)]="selectedUser.settings.features"
                     formControlName="features"
                     styleClass="feature-selector"></p-listbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-sm-12 text-right">
          <p-button label="Cancel" class="default-button"
                    (onClick)="showNewUserDialog = false" iconPos="left" styleClass="mr-1"></p-button>
          &nbsp;

          <p-button class="default-button" label="Add User" type="submit"></p-button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<p-dataView #dv [value]="userList" [paginator]="true" paginatorPosition="both" [rows]="15" styleClass="ta-dataview"
            [filterBy]="'userName,email'">
  <p-header>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-2">

      </div>
      <div class="ui-g-12 ui-md-2">
        user role
      </div>
      <div class="ui-g-12 ui-md-2">
        inactive/active/logged in
      </div>
      <div class="ui-g-12 ui-md-2">
        brand filter
      </div>
      <div class="ui-g-12 ui-md-2">

      </div>
      <div class="ui-g-12 ui-md-2">

      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-2">
        <input type="search" pInputText placeholder="Search user" (input)="filterUser($event.target.value)">
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-dropdown [options]="userRoles" [(ngModel)]="userRole"></p-dropdown>
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-dropdown [options]="activedSelection"
                    [(ngModel)]="activatedFilter"></p-dropdown>
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-dropdown [options]="brandFiltersFilter"
                    [(ngModel)]="brandFilter"></p-dropdown>
      </div>
      <div class="ui-g-12 ui-md-2">

      </div>
    </div>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-2">
        <p-button label="Add User" (onClick)="createNewUser()"></p-button>
      </div>
      <div class="ui-g-12 ui-md-6">
        <p-button label="Export" (onClick)="exportUsers()"></p-button>
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-button label="Delete marked users" (click)="deleteMarkedUsers($event)" [hidden]="!isSuperAdmin"
                  [disabled]="countDeleteMarkedUsers == 0"></p-button>
      </div>
      <div class="ui-g-12 ui-md-2">
        <p-button label="Test mail ( new User )" (onClick)="sendTestMail()" [hidden]="!isSuperAdmin"></p-button>
      </div>
    </div>
    <div class="ui-g user-select-none">
      <div class="ui-g-12 ui-md-1">
        Status
      </div>
      <div class="ui-g-12 ui-md-3">
         <span class="fa" [ngClass]="{ 'fa-sort-asc': sortField == 'userName' && sortAsc,
                                       'fa-sort-desc': sortField == 'userName' && !sortAsc,
                                       'fa-sort': sortField != 'userName' }"
               (click)="sortByField('userName')"
               role="button">&nbsp;</span>&nbsp;
        <span (click)="sortByField('userName')" role="button">Username</span>
      </div>
      <div class="ui-g-12 ui-md-3">
        Mail
      </div>
      <div class="ui-g-12 ui-md-2">
        Role
      </div>
      <div class="ui-g-12 ui-md-2">
        Brand filter
      </div>
      <div class="ui-g-12 ui-md-1">
         <span class="fa" [ngClass]="{ 'fa-sort-asc': sortField == 'lastLogin' && sortAsc,
                                       'fa-sort-desc': sortField == 'lastLogin' && !sortAsc,
                                       'fa-sort': sortField != 'lastLogin' }"
               (click)="sortByField('lastLogin')"
               role="button">&nbsp;</span>&nbsp;
        <span (click)="sortByField('lastLogin')" role="button">Last seen</span>
      </div>
    </div>
  </p-header>
  <ng-template let-user pTemplate="listItem">
    <div class="ui-g user-row" title="{{user.id}}" (mouseup)="selectUser($event, user)"
         [ngClass]="{ 'bg-warning': user.deleteConfirm }">
      <div class="ui-g-12 ui-md-1">
        <i [className]="getUserStatusIcon( user )" [title]="getStatusTitle(user)">&nbsp;</i>
      </div>
      <div class="ui-g-12 ui-md-3">{{user.userName}}</div>
      <div class="ui-g-10 ui-md-3">{{user.email}}</div>
      <div class="ui-g-12 ui-md-2">{{user.settings.userRole}}</div>
      <div class="ui-g-12 ui-md-2"
           [title]=getBrandNamesByFilterId(user.settings.brandFilterId)>{{getBrandFilterName(user.settings.brandFilterId)}}</div>
      <div class="ui-g-12 ui-md-1" [title]="getLastLoginTitle(user)">
        <i [title]="user.deleted ? 'restore' : 'delete'" class="fa"
           role="button"
           [hidden]="shouldHiddeDeleteButton(user)"
           [ngClass]="{ 'fa-trash-o': !user.deleted, 'fa-undo': user.deleted }"
           (click)="deleteUser( user )">&nbsp;</i>&nbsp;{{getLastLoginDate(user)}}
      </div>
    </div>
  </ng-template>
</p-dataView>
