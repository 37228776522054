
<div class="d-flex flex-column ds">
  <div class="header">
    <!--span class="fa ta-icon-master-data float-left icon" aria-hidden="true"></span -->
    <span class="float-left label">{{ this.datasource.name }}</span>
  </div>
  <div [hidden]="!showContent" class="p-2 body">
    <div class="info-label">Data time range</div>
    <div class="info-value mb-3">{{datasource.firstDate | date }} - {{datasource.lastDate | date }}</div>
    <div [pTooltip] ="datasource.eventCountLastWeek && datasource.eventCountLastWeek.fromDate && datasource.eventCountLastWeek.toDate ? (datasource.eventCountLastWeek.fromDate  | date) + ' - \n ' +  (datasource.eventCountLastWeek.toDate  | date) : '' " tooltipPosition = 'top' class="info-label">Avg. number of events last week</div>
    <div class="info-value mb-3">{{ ( datasource.eventCountLastWeek == null ? 0 : datasource.eventCountLastWeek.events / 7) | number:'1.0-0':'de-DE' }}</div>
    <div class="info-label mb-3">Top-5 countries in terms of search volume</div>
    <div class="data-row">
      <div class="d-flex align-items-center">
        <p-chart class="mr-2"
        #chartComponent
        type="doughnut"
        [data]="chartData"
        [responsive]="true"
        [options]="chartOptions"
        [height]="200"
        ></p-chart>
      </div>
      <div class="align-items-start legend">
        <div class='d-flex align-items-center legend-item' *ngFor="let label of labels;let i=index">
          <span class="box-legend" [ngStyle] ="{'background-color': colors[i]}"></span> <span [tooltipDisabled]="legendTooltip(label, percent[i]) === ''" [pTooltip]="legendTooltip(label, percent[i])" class="text-legend">{{truncateLabel(label)}} {{percent[i]}}</span>
        </div>
      </div>
    </div>
    <div class="info-label main-functionalities-label">Main functionalities</div>
    <div class="row features-links">
      <ul>
        <li *ngFor="let feature of datasource.features">
          <span (click)="goToFeature( feature )">{{feature.name}}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="footer">
     
      <button class="data-info-toggle borderless-icon-button" [ngClass]="{ 'data-info-opened-toggle-opened': !showContent }"
              (click)="toggleDsInfo($event)"><span class="data-info-icon fa fa-info-circle"></span> Data information</button>
          <button class="btn-start default-button float-right" (click)="onSelected()">Select</button>
  </div>
  <div [hidden]="showContent" class="p-2 body-info" (dblclick)="onDescriptionClick($event)">

    <div class="description-editor" [hidden]="!editMode">
      <textarea #descriptionEditor [(ngModel)]="userDescription"></textarea>
      <div class="btns-description">
        <button class="btn-description" (click)="saveDescription(false)">Save</button>
        <button class="btn-description" [hidden]="!isSubCatalog()" (click)="saveDescription(true)">Save for all subcatalogs</button>
        <button class="btn-description" (click)="cancelDescription()">Cancel</button>
      </div>
    </div>

    <div class="description-text" [hidden]="editMode" [innerHTML]="description" (dblclick)="onDescriptionClick($event)">

    </div>

  </div>
</div>
