import {TimeLineData} from './time-line-data';
import * as moment from 'moment/moment';
import {ChartRequest} from '@app/shared/models/chart-request';
import {Moment} from 'moment';
import {UtilsService} from '@app/core';
import {last} from 'rxjs/operators';

export class TimeLineRows {

  labels: string[] = [];
  datasets: TimeLineData[] = [];
  aggregateError: string;

  colors: string[];

  constructor(public originalData?: TimeLineRows) {
    this.init();
  }

  private init() {
    if (this.originalData && this.originalData.datasets && this.originalData.datasets.length) {
      this.labels = [];
      for (let i = 0; i < this.originalData.labels.length; i++) {
        this.labels.push(this.originalData.labels[i]);
      }
      let datasets: TimeLineData[] = [];
      for (let i = 0; i < this.originalData.datasets.length; i++) {
        this.createLabelWithoutId(this.originalData.datasets[i]);
        datasets.push(this.originalData.datasets[i]);
      }
      this.datasets = datasets;
      this.colors = this.originalData.colors;
    }
  }

  private createLabelWithoutId(dataset: TimeLineData) {
    const trimmedLabel = dataset.label.trim();
    const containIdPrefix = trimmedLabel.startsWith("[") && trimmedLabel.indexOf("]") != -1;
    const labelWithoutId = containIdPrefix ? trimmedLabel.substring(trimmedLabel.indexOf("]") + 1).trim() : trimmedLabel;
    dataset.labelWithoutId = labelWithoutId;
  }


  filterDataset(fieldName: string, selectedIds: string[]) {
    const labels = "countries" == fieldName ? this.originalData.labels.slice(0, 30) : this.originalData.labels;
    const bots: TimeLineData[] = [];
    const nonbots: TimeLineData[] = [];

    if (selectedIds != null && selectedIds.length > 0) {
      // filter datasets
      for (let j = 0; j < this.originalData.datasets.length; j++) {

        if (!selectedIds.includes(this.originalData.datasets[j].id)) {
          continue;
        }

        let tlData = TimeLineRows.cloneDataset(this.originalData.datasets[j]);
        tlData.lineTension = 0.4;
        tlData.data = [];

        for (let i = 0; i < this.originalData.labels.length; i++) {
          if (labels.includes(this.originalData.labels[i]) &&
            this.originalData.datasets[j].data) {
            tlData.data.push(this.originalData.datasets[j].data[i]);
          }
        }

        if (tlData.id.startsWith('bots/')) {
          bots.push(tlData);
        } else {
          nonbots.push(tlData);
        }
      }
    }

    this.labels = 'countries' == fieldName ? labels : UtilsService.formatDates(labels);
    this.datasets = [...bots, ...nonbots];
  }

  private initColors( schemeColors ) {
    const tlWithColors = this.originalData.datasets.filter(tl => !!tl.color);

    const usedColors = new Map;

    for(const tl of tlWithColors) {
      usedColors.set(tl.id, tl.color);
    }

    for(const tl of this.originalData.datasets) {
      if(!tl.color) {
        tl.color = this.getColor(tl.id, usedColors, schemeColors);
      }
    }
  }

  applyFilters(selectedIds: string[], chartRequest: ChartRequest, averageCount: number = 0) {
    let labels = [];
    let datasets: TimeLineData[] = [];

    if (selectedIds != null && selectedIds.length > 0) {
      let weekdays = chartRequest.weekdays;
      if (chartRequest.timespan === 'yesterday') {
        //Do not filter if timespan = yesterday
        labels = [...this.originalData.labels];
      } else {
        // filter labels
        for (let i = 0; i < this.originalData.labels.length; i++) {
          if (TimeLineRows.isSuitableDate(this.originalData.labels[i], weekdays)) {
            labels.push(this.originalData.labels[i]);
          }
        }
      }
      // filter datasets
      for (let j = 0; j < this.originalData.datasets.length; j++) {

        if (!selectedIds.includes(this.originalData.datasets[j].id)) {
          continue;
        }

        let tlData = TimeLineRows.cloneDataset(this.originalData.datasets[j]);
        tlData.data = [];

        if (averageCount > 0) {
          tlData.lineTension = 0.4;
        }

        for (let i = 0; i < this.originalData.labels.length; i++) {
          if (labels.includes(this.originalData.labels[i])) {
            tlData.data.push(this.originalData.datasets[j].data[i]);
          }
        }
        if (averageCount > 0) {
          TimeLineRows.mySmooth(tlData.data, averageCount);
          //let options = { derivative: 0, windowSize: 31 };
          //let ans = savitzkyGolay(tlData.data, averageCount, options);
          //tlData.data = ans; // this.smooth(tlData.data, averageCount);
        }
        //console.log(tlData.data);
        datasets.push(tlData);
      }
    }

    this.labels = UtilsService.formatDates(labels);
    this.datasets = datasets;
  }

  getCalculatedColor( color, isBots ) {
    return isBots ? UtilsService.shadeColor( color, 20 ) : color;
  }

  private getColor( id, usedColors: Map<string, string>, schemeColors: string[] ): string {

    const isBots = id.startsWith('bots/');
    if( isBots ) {
      id = id.substring('bots/'.length);
    }

    if( usedColors.has(id) ) {
      return this.getCalculatedColor( usedColors.get(id), isBots );
    }

    const usedColorsList = Array.from( usedColors.values() );

    for( let color of schemeColors ) {
      if( !usedColorsList.includes(color) ) {
        usedColors.set(id, color);
        return this.getCalculatedColor( color, isBots );
      }
    }

    return '#efefef';
  }

  getColors( schemeColors ) {
    this.initColors(schemeColors);
    if(!this.datasets || !this.datasets.length) {
      return schemeColors;
    }
    return this.datasets.filter(tl => !!tl.color).map(tl => tl.color);
  }

  static mySmooth(data, averageCount) {
    const dataOriginal = [].concat(data);
    for (let i = 0; i < data.length; i++) {
      data[i] = parseFloat(TimeLineRows.calculateAverageValue(dataOriginal, i, averageCount).toFixed(2));
    }
  }

  static calculateAverageValue(data: number[], valueIndex: number, averageCount: number): number {
    let fromIndex = Math.max(0, valueIndex - averageCount);
    let toIndex = Math.min(data.length, valueIndex + averageCount + 1);
    let total = 0;
    for (let i = fromIndex; i < toIndex; i++) {
      total += data[i];
    }
    return total / (toIndex - fromIndex);
  }

  static getDatasets(maxGet: number, datasets: TimeLineData[], backup: TimeLineData[]): TimeLineData[] {
    let results: TimeLineData[] = [];

    if (backup && backup.length) {
      for (let i = 0; i < datasets.length; i++) {
        for (let j = 0; j < backup.length; j++) {
          if (datasets[i].id == backup[j].id && datasets[i].filledData) {
            results.push(datasets[i]);
          }
        }
      }
    }

    // if nothing selected by backup
    if (!results.length) {
      for (let i = 0; i < datasets.length; i++) {
        if (!results.includes(datasets[i]) && datasets[i].filledData) {
          results.push(datasets[i]);
          if (results.length == maxGet) {
            break;
          }
        }
      }
    }
    return results;
  }

  private static isSuitableDate(dateStr: string, weekdays: string[]): boolean {
    if (!weekdays || weekdays.includes('-1') || weekdays.length == 0) {
      return true;
    }
    let date = moment(dateStr, 'YYYY-MM-DD');
    let dayOfWeek = (date.day() + 1) + '';//Convert to pre-defined weekdays in weekday filter
    return weekdays.includes(dayOfWeek);
  }

  public static cloneDataset(originalDataset: TimeLineData): TimeLineData {
    return {
      data: [].concat(originalDataset.data),
      label: originalDataset.label,
      id: originalDataset.id,
      sourceId: originalDataset.sourceId,
      lineTension: originalDataset.lineTension,
      pointRadius: 5,
      pointHoverRadius: 7,
      pointHitRadius: 10,
      filledData: originalDataset.filledData,
      color: originalDataset.color,
      borderWidth: 2,
      pointStyle: 'circle',
      fill: false,
      radius: 5,
      cubicInterpolationMode: 'monotone',
      labelWithoutId: originalDataset.labelWithoutId
    };
  }

  readonly aggregateErrorMessage = 'Please correct the time range / aggregation time to see respective data.';

  aggregateTimeline(selectedIds: string[], chartRequest: ChartRequest, aggregationTimeSpan: number = AggregationTime.DAILY.value) {
    this.aggregateError = null;
    this.datasets = [];
    this.labels = [];
    if (!selectedIds || selectedIds.length == 0) {
      return;
    }
    if (chartRequest.timespan === 'yesterday') {
      this.aggregateError = this.aggregateErrorMessage;
      return;
    }
    if (!this.verifyAggregationConfig(aggregationTimeSpan, this.originalData.labels)) {
      this.aggregateError = this.aggregateErrorMessage;
      return;
    }
    let datasets: TimeLineData[] = [];
    let labels = [];
    for (let j = 0; j < this.originalData.datasets.length; j++) {
      if (selectedIds.includes(this.originalData.datasets[j].id)) {
        let dataset = TimeLineRows.cloneDataset(this.originalData.datasets[j]);
        dataset.lineTension = 0.4;
        datasets.push(dataset);
        let aggregationResult = this.aggregate(dataset.data, aggregationTimeSpan);
        dataset.data = aggregationResult.data;
        if (labels.length == 0) {
          labels = aggregationResult.labels;
        }
      }
    }
    this.labels = labels;
    this.datasets = datasets;
  }

  aggregate(data: number[], aggregationTime: number): AggregationResult {
    if (aggregationTime == AggregationTime.DAILY.value) {
      return new AggregationResult(data, UtilsService.formatDates(this.originalData.labels));
    }
    let dates = this.originalData.labels.map(date => moment(date, 'YYYY-MM-DD'));
    const result = new AggregationResult([], []);
    let startIndex = this.findAggregationStartIndex(aggregationTime, dates);
    while (startIndex < data.length) {
      let total = 0, count = 0;
      const expectedAggregationLength = this.findExpectedAggregationLength(dates[startIndex], aggregationTime);
      const toIndex = startIndex + expectedAggregationLength;
      const aggregatedLabel = this.getAggregatedLabel(dates[startIndex], aggregationTime);
      for (let i = startIndex; i < Math.min(toIndex, data.length); i++) {
        count++;
        total += data[i];
      }
      if (count == expectedAggregationLength) {
        result.data.push(total);
        result.labels.push(aggregatedLabel);
      }
      startIndex += count;
    }
    return result;
  }

  private getAggregatedLabel(date: Moment, aggregationTime: number): string {
    switch (aggregationTime) {
      case AggregationTime.WEEKLY.value:
        return date.year() + '-W' + date.week();
      case AggregationTime.MONTHLY.value:
        return date.year() + '-' + (date.format('MMM'));
      case AggregationTime.QUARTERLY.value:
        return date.year() + '-Q' + date.quarter();
      case AggregationTime.YEARLY.value:
        return date.year().toString();
      default:
        return '';
    }
  }

  private findExpectedAggregationLength(date: Moment, aggregationTime: number) {
    let numberOfDays: number;
    if (aggregationTime == AggregationTime.DAILY.value
      || aggregationTime == AggregationTime.WEEKLY.value
    ) {
      numberOfDays = aggregationTime;
    } else if (aggregationTime == AggregationTime.YEARLY.value) {
      numberOfDays = UtilsService.getYearLength(date);
    } else {
      if (aggregationTime == AggregationTime.MONTHLY.value) {
        numberOfDays = date.daysInMonth();
      } else {
        numberOfDays = UtilsService.getQuarterLength(date);
      }
    }
    return numberOfDays;
  }

  findAggregationStartIndex(aggregationTimeSpan: number, dates: Moment[]): number {
    switch (aggregationTimeSpan) {
      case AggregationTime.DAILY.value:
        return 0;
      case AggregationTime.WEEKLY.value:
        let daysOfWeek = dates.map(date => date.day());
        for (let i = 0; i < daysOfWeek.length; i++) {
          if (daysOfWeek[i] == 1) {
            return i;
          }
        }
        return 0;
      case AggregationTime.MONTHLY.value:
      case AggregationTime.QUARTERLY.value:
      case AggregationTime.YEARLY.value:
        let daysOfMonth = dates.map(date => date.date());
        for (let i = 0; i < daysOfMonth.length; i++) {
          if (daysOfMonth[i] == 1) {
            return i;
          }
        }
        return 0;
    }
    return 0;
  }

  verifyAggregationConfig(aggregationTime: number, stringDates: string[]) {
    if (aggregationTime === AggregationTime.DAILY.value) {
      return true;
    }
    if (!stringDates || stringDates.length < 7) {
      return false;
    }
    let dates = stringDates.map(date => moment(date, 'YYYY-MM-DD'));
    if (aggregationTime == AggregationTime.WEEKLY.value) {
      return this.verifyWeeklyConfig(dates);
    }
    if (aggregationTime == AggregationTime.MONTHLY.value) {
      return this.verifyMonthlyConfig(dates);
    }
    if (aggregationTime == AggregationTime.QUARTERLY.value) {
      return this.verifyQuarterlyConfig(dates);
    }
    if (aggregationTime == AggregationTime.YEARLY.value) {
      return this.verifyYearlyConfig(dates);
    }
    return false;
  }

  verifyWeeklyConfig(dates: Moment[]) {
    if (dates.length < 7) {
      return false;
    }
    const daysOfWeek = dates.map(date => date.day());
    for (let i = 0; i < daysOfWeek.length - 6; i++) {
      if (daysOfWeek[i] == 1) {
        return true;//Monday starts a week
      }
    }
    return false;
  }

  verifyMonthlyConfig(dates: Moment[]) {
    if (dates.length < 28) {
      return false;
    }
    for (let i = 0; i < dates.length; i++) {
      let dateObj = dates[i];
      if (dateObj.date() === 1) {
        let month = dateObj.month() + 1;
        let lastDateOfMonth = dateObj.daysInMonth();
        let expectedLastDateOfMonthPosition = i + lastDateOfMonth - 1;
        if (expectedLastDateOfMonthPosition < dates.length
          && dates[expectedLastDateOfMonthPosition].month() + 1 == month
          && dates[expectedLastDateOfMonthPosition].date() == lastDateOfMonth) {
          return true;
        }
      }
    }
    return false;
  }

  private verifyQuarterlyConfig(dates: Moment[]) {
    if (dates.length < 89) {//First quarter has 89 days
      return false;
    }
    for (let i = 0; i < dates.length; i++) {
      let dateObj = dates[i];
      if (dateObj.date() === 1) {
        const month = dateObj.month() + 1;
        const quarter = UtilsService.getQuarter(month);
        const quarterLength = UtilsService.getQuarterLength(dateObj);
        const quarterLastMonth = UtilsService.getQuarterLastMonth(quarter);
        const quarterLastDate = moment(dateObj.year() + '-' + quarterLastMonth).daysInMonth();
        let expectedLastDateOfQuarterPosition = i + quarterLength - 1;
        if (expectedLastDateOfQuarterPosition < dates.length
          && dates[expectedLastDateOfQuarterPosition].month() + 1 == quarterLastMonth
          && dates[expectedLastDateOfQuarterPosition].date() == quarterLastDate) {
          return true;
        }
      }
    }
    return false;
  }

  private verifyYearlyConfig(dates: Moment[]) {
    if (dates.length < 365) {
      return false;
    }
    for (let i = 0; i < dates.length; i++) {
      let dateObj = dates[i];
      if (dateObj.date() === 1 && dateObj.month() === 0) {//1/1 of year
        let expectedLastDateOfYearPosition = i + UtilsService.getYearLength(dateObj) - 1;
        if (expectedLastDateOfYearPosition < dates.length
          && dates[expectedLastDateOfYearPosition].month() + 1 == 12
          && dates[expectedLastDateOfYearPosition].date() == 31) {
          return true;
        }
      }
    }
    return false;
  }

  addTimeLines(newData: TimeLineRows, labelMerge = false) {

    if (!newData || !newData.datasets || !newData.datasets.length) {
      return;
    }

    console.log('addTimeLines: ', newData, labelMerge);

    if (labelMerge) {
      this.labelMerge(newData);
    } else {
      this.simpleAddById(newData);
    }
  }

  private simpleAddById(newData: TimeLineRows) {
    for( const newTimeLine of newData.datasets ) {
      const tl = this.originalData.datasets.find(tl => tl.id == newTimeLine.id);

      if (tl && !tl.filledData && newTimeLine.filledData && tl.id === newTimeLine.id) {
        tl.data = newTimeLine.data;
        tl.filledData = true;
        break;
      }
    }
  }

  private labelMerge(newData: TimeLineRows) {

    for (const newTimeLine of newData.datasets) {

      let timeLineToUpdate: TimeLineData = null;

      // find the timeline by id to update
      for (const tl of this.originalData.datasets) {
        if (tl.id === newTimeLine.id) {
          timeLineToUpdate = tl;
          break;
        }
      }

      if (!timeLineToUpdate) {
        continue;
      }

      const newTimeLineDataset = [];
      // find the existing values by label index
      for (const lbl of this.originalData.labels) {
        const idx = newData.labels.indexOf(lbl);
        newTimeLineDataset.push(idx !== -1 ? newTimeLine.data[idx] : 0);
      }

      // add missing labels and zeros
      for (let i = 0; i < newData.labels.length; i++) {
        const lbl = newData.labels[i];

        if ( !this.originalData.labels.includes(lbl) ) {
          this.originalData.labels.push(lbl);
          newTimeLineDataset.push(newTimeLine.data[i]);

          // push to other timelines 0 and to new the value
          for (const tl of this.originalData.datasets) {
            // if not found the label in another timelines - and they are filled - append 0
            if (tl.filledData && tl.id !== newTimeLine.id) {
              if (!tl.data) {
                tl.data = [];
              }
              tl.data.push(0);
            }
          }
        }
      }

      timeLineToUpdate.data = newTimeLineDataset;
      timeLineToUpdate.filledData = true;
    }
  }

  sortDescendingBySums(selected: string[]) {

    let sums = [];

    // check the sum by column and remember position
    for (let i = 0; i < this.originalData.labels.length; i++) {
      let colSum = {
        pos: i,
        sum: 0
      };
      for (const tl of this.originalData.datasets) {
        if (tl.filledData && selected.includes(tl.id)) {
          colSum.sum += tl.data[i];
        }
      }
      sums.push(colSum);
    }

    // sort all by sum
    sums.sort((a, b) => {
      return b.sum - a.sum;
    });

    // prepare arrays for each timeline
    const datas = [];
    for (const tl of this.originalData.datasets) {
      datas.push([]);
    }

    // put the data in the right sort order - labels and values
    const labels = [];
    for (const sm of sums) {
      const pos = sm.pos;
      labels.push(this.originalData.labels[pos]);
      for (let i = 0; i < this.originalData.datasets.length; i++) {
        if (this.originalData.datasets[i].filledData) {
          datas[i].push(this.originalData.datasets[i].data[pos]);
        }
      }
    }

    // assign the new ordered labels
    this.originalData.labels = labels;

    // assign all sorted data to the timelines
    for (let i = 0; i < datas.length; i++) {
      if (this.originalData.datasets[i].filledData) {
        this.originalData.datasets[i].data = datas[i];
      }
    }
  }

}

export class AggregationResult {
  data: number[];
  labels: string[];

  constructor(data: number[], labels: string[]) {
    this.data = data;
    this.labels = labels;
  }
}

export class AggregationTime {
  private constructor(public readonly label: string, public readonly value: number) {

  }

  static readonly DAILY = new AggregationTime('Daily', 1);
  static readonly WEEKLY = new AggregationTime('Weekly', 7);
  static readonly MONTHLY = new AggregationTime('Monthly', 30);
  static readonly QUARTERLY = new AggregationTime('Quarterly', 90);
  static readonly YEARLY = new AggregationTime('Yearly', 365);
  static readonly OPTIONS = [
    AggregationTime.DAILY,
    AggregationTime.WEEKLY,
    AggregationTime.MONTHLY,
    AggregationTime.QUARTERLY,
    AggregationTime.YEARLY
  ];

  static getLabelByValue(value): string {
    for (const agg of AggregationTime.OPTIONS) {
      if (value === agg.value) {
        return agg.label;
      }
    }
    return "undefined";
  }
}
