import {Component, OnInit, ViewChild} from '@angular/core';
import {AnalyticsPage} from "@app/tecalliance-analytics/pages/analytics-page";
import {InfoTexts} from "@app/shared/models/info-texts";
import {ChartRequest} from "@app/shared/models/chart-request";
import {EventTypes} from "@app/shared/models/event-types";
import {Fields} from "@app/shared/models/fields";
import {ChartActor} from "@app/shared/components/chart-actor";
import {NavigationService, TrackerService} from "@app/core";
import {ConfirmationService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";
import {ApiService} from "@app/shared";
import {Router} from "@angular/router";
import {ReportResponse} from "@app/shared/models/analytics/report-response";
import {FacetItem} from "@app/shared/models/facet-item";
import {Features} from "@app/shared/models/features";

@Component({
  selector: 'article-visibility-brands',
  templateUrl: './article-visibility-brands.component.html',
  styleUrls: ['./article-visibility-brands.component.scss']
})
export class ArticleVisibilityBrandsComponent extends AnalyticsPage {

  public get infoText(): string {
    return InfoTexts.ARTICLE_VISIBILITY_BRANDS;
  }

  getPageStoreKey(): string {
    return 'ArticleVisibleBrandsComponent';
  }

  getChartRequest(): ChartRequest {
    return new ChartRequest(EventTypes.ARTICLE_VISIBILITY);
  }

  get pageName(): string {
    return 'Most visible brands';
  }

  public readonly fieldBrandNo = Fields.BRAND_NO;

  public brandChartHeadLabel = 'Brands';

  @ViewChild('brandChart', {static: true}) brandChart: ChartActor;

  constructor(appInsights: TrackerService,
              confirm: ConfirmationService,
              protected translate: TranslateService,
              protected navigation: NavigationService,
              protected apiService: ApiService,
              protected router: Router) {
    super(appInsights, confirm, translate, navigation, apiService, router);
  }

  getPageFacets(): string[] {
    return [Fields.BRAND_NO];
  }

  getChartActors(): ChartActor[] {
    return [this.brandChart];
  }

  getReportPath(): string {
    return '/report/article/visibility';
  }

  getExportPath(): string {
    return '/export/facets';
  }

  reportLoaded(data: ReportResponse): void {

  }

  itemSelected(item: FacetItem): void {

  }

  protected getFeatureOnMenu() {
    return Features.ARTICLE_VISIBILITY;
  }

}
