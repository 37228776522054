<p-overlayPanel #feedbackPanel [dismissable]="true">
  <div class="container-fluid feedback-form">
    <div class="row title">
      Give feedback
      <loading [busy]="isRequesting" [showCloseButton]="false"></loading>
    </div>
    <div class="row py-2">
      <input #subjectInput type="text" [(ngModel)]="feedback.subject" (keyup)="validateSubjectInput()" pInputText placeholder="Subject" [maxLength]="50" />
    </div>
    <div class="row py-2">
      <textarea #messageInput pInputTextarea #message [(ngModel)]="feedback.message" (keyup)="validateMessageInput()" placeholder="Comment ..."
          [maxLength]="messageMaxLen"></textarea>
      <span>
        {{message.textLength}}/{{messageMaxLen}}
      </span>
    </div>
    <div class="row py-2">
        <span class="col-form-label">Rate Us!</span>
        <p-rating #rating class="form-control" [cancel]="false" [stars]="maxRatting" [(ngModel)]="feedback.ratting"></p-rating>
    </div>
    <div class="row alert-danger" *ngIf="errorMessage">
      {{errorMessage}}
    </div>
     <div class="row alert-info" *ngIf="successMessage">
      {{successMessage}}
    </div>
    <div class="row py-2 pull-right">
      <button type="button" pButton label="Close" (click)="feedbackPanel.hide()" class="pull-right ui-button-secondary"></button>
      <button type="button" pButton label="Send" (click)="sendFeedback()" class="ml-2 pull-right default-button" [disabled]="feedbackSended || isRequesting"></button>
    </div>
  </div>
</p-overlayPanel>
