import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminShell } from '../admin-shared/shells/admin-shell/admin-shell';

import {
    AuthGuard,
    ConfirmDeactivate
} from '../admin-shared';

import {
    LogoutPage,
    ImprintPage,
    NotFoundPage,
    PrivacyPolicyPage,
    LicencesPage
} from '.';
import { BrandFiltersComponent } from './pages/brand-filters/brand-filters.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import {ReleaseNotesComponent} from '@app/admin/pages/release-notes/release-notes.component';

const routes: Routes = [
    {
      path: 'admin',
      component: AdminShell,
      canActivate: [AuthGuard],
      children: [
        {
          path: '',
          canActivateChild: [AuthGuard],
          children: [
            { path: 'logout', component: LogoutPage },
            { path: '**', component: NotFoundPage }
          ]
        }
      ]
    },
    {
      path: 'portal',
      component: AdminShell,
      children: [
        { path: 'licences', component: LicencesPage },
        { path: 'imprint', component: ImprintPage },
        { path: 'privacy-policy', component: PrivacyPolicyPage },
        { canActivate: [AuthGuard], path: 'release-notes', component: ReleaseNotesComponent }
      ]
    },
    {
      path: 'manage',
      component: AdminShell,
      canActivateChild: [AuthGuard],
      children: [
        { path: 'brand-filters', component: BrandFiltersComponent },
        { path: 'users', component: UserListComponent },
        { path: '',  redirectTo: 'users', pathMatch: 'full' }
      ]
    }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [ RouterModule ]
})
export class AdminRoutingModule {}
