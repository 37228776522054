import {Component} from '@angular/core';
import {Fields} from '@app/shared/models/fields';
import {OrderTrendsComponent} from '@app/tecalliance-analytics/pages/order-trends/order-trends.component';

@Component({
  selector: 'order-trends',
  templateUrl: './order-trends.component.html',
  styleUrls: ['./order-trends.component.scss']
})
export class GenericArticleOrderTrendsComponent extends OrderTrendsComponent {
  visibleData = [
    {label: 'Market view', value: Fields.GENART_NO_BRANDLESS},
    {label: 'Own brands', value: Fields.GENART_NO}
  ];
  whatIsVisible: string[] = [ Fields.GENART_NO_BRANDLESS ];
}

