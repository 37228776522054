<p-confirmDialog key="discart-changes"  acceptButtonStyleClass="default-button"  rejectButtonStyleClass="default-button" [closable]="false" ></p-confirmDialog>
<p-confirmDialog key="confirm-actions" [closable]="false" ></p-confirmDialog>
<p-confirmDialog key="warning-message" [closable]="true" [acceptLabel]="'Close'" [rejectVisible]="false"></p-confirmDialog>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 col-lg-3 col-xl-3 pl-0 pr-0" id="nav-menu-container" [@mainaside]="_state" *ngIf="!isDatasourcePage()">
      <nav class="navbar-light">
        <nav-menu [items]="_menuItems" id="side-menu"></nav-menu>
      </nav>
    </div>
    <div class="col pl-0 pr-0" id="main-container">
      <main role="main" [ngClass]="isDatasourcePage()?'pl-0':''" (scroll)="onScroll($event)">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 py-3">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
<portal-footer></portal-footer>
