import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DatasourceInfo} from '@app/shared/models/datasource-info';
import {HomeComponent} from '@app/tecalliance-analytics/pages/home/home.component';
import {ApiService} from '@app/shared';
import {Router} from '@angular/router';
import {SelectItem, Tree, TreeNode} from 'primeng/primeng';
import {UtilsService} from '@app/core';

@Component({
  selector: 'multiple-datasource',
  templateUrl: './multiple-datasource.component.html',
  styleUrls: ['./multiple-datasource.component.scss']
})
export class MultipleDatasourceComponent implements OnInit {

  private _datasource: DatasourceInfo[];

  public datasourceTree: TreeNode[];
  private _selectedDatasources: TreeNode[];

  public set selectedDatasources( v: TreeNode[] ) {
    this._selectedDatasources = v;
  }

  public get selectedDatasources(): TreeNode[] {
    return this._selectedDatasources;
  }

  @Input()
  set datasource(v: DatasourceInfo[]) {
    this._datasource = v;
    this.datasourceTree = UtilsService.createDatasourceTreeByGroups(v);
  }

  get datasource(): DatasourceInfo[] {
    return this._datasource;
  }

  @Input() page: HomeComponent;

  @ViewChild('descriptionEditor', {static: true}) descriptionEditor: ElementRef<HTMLTextAreaElement>;


  constructor(private _apiService: ApiService,
              private _router: Router) {

  }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }

  private getSelectedIds() {
    const r = [];
    for( const tn of this.selectedDatasources ) {
      r.push(tn.data);
    }
    return r;
  }

  onSelected() {

    const ds = this.getSelectedIds();

    const settings = this._apiService.setSelectedDatasourceId(ds);
    this.page.goToFirstAuthorizedItem(settings.features);
  }

  onNodeSelect(children: TreeNode[]) {
    window.setTimeout(() => {
      const newSelected = [];
      for(const tn of this._selectedDatasources) {
        if( children.indexOf(tn) != -1 || children.indexOf(tn.parent) != -1 ) {
          newSelected.push( tn );
        }
      }
      this._selectedDatasources = newSelected;
    }, 100);
  }
}
