import {
  Component,
  OnDestroy
} from '@angular/core';
import { ForgotPasswordData } from '@app/accessible/models/ForgotPasswordData';

import { ConfirmationService } from 'primeng/api';

import {
  TrackerService
} from '../../../core';

import {
  ApiService,
  PageComponent
} from '../../../shared';
import {Router} from '@angular/router';

@Component({
  //moduleId: module.id,
  selector: 'page-forgot-password',
  templateUrl: 'forgot-password.html',
  styleUrls: ['forgot-password.scss']
})
export class ForgotPasswordPage extends PageComponent implements OnDestroy {
  //#region Properties

  get pageName(): string {
    return 'Login';
  }

  //#endregion

  //#region Fields

  _data: ForgotPasswordData = new ForgotPasswordData('');

  //#endregion

  //#region Constructor

  constructor(
    appInsights: TrackerService,
    confirmation: ConfirmationService,
    protected apiService: ApiService,
    protected router: Router
  ) {
    super(appInsights, confirmation, apiService, router);
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnDestroy() {

  }

  //#endregion

  //#region PageComponent Methods

  public hasChanges(): boolean {
    return false;
  }

  //#endregion
}
